import { ApiService } from "./api.service";
import { IApiBaseResponse } from "models/interface";


interface ILoggerData {
    type?:          string;
    message?:       string;
    stage?:         string;
    function_name?: string;
    db_query?:      string;
    user_agent?:    string;
    severity?:      string;
    payload?:       Payload | any;
    frontend_url?:  string;
    url?:           string;
    db_function?:   string;
    db_function_params?: any;
    errors?: any;
    platform?: 'WEB';
    status_code?: number;
}

export interface Payload {
    body?: any,
    query?: any,
}


class LoggerService {
  public log = async (loggerData: ILoggerData) => {

    let msg = 'Error from Frontend';

    if (loggerData.payload instanceof Error) {
        msg = loggerData.payload.message;
    }
    let logData: ILoggerData = {
        type: loggerData.type ? loggerData.type : 'Error',
        message: loggerData.message ? loggerData.message : msg,
        stage: process.env.REACT_APP_ENVIRONMENT?.toLocaleLowerCase(),
        function_name: loggerData.function_name ? loggerData.function_name : '',
        db_query: '',
        user_agent: window.navigator.userAgent,
        severity: loggerData.severity ? loggerData.severity : 'high',
        payload: loggerData.payload ? loggerData.payload : {body: {}, query: {}},
        frontend_url: window.location.href,
        url: '',
        db_function: '',
        db_function_params: {},
        errors: loggerData.errors ?? {},
        platform: 'WEB',
        status_code: loggerData.status_code ?? 0
    }

    return (await new ApiService().publicClient()).post<IApiBaseResponse<boolean>>('exception/log', logData);
  };


}

export { LoggerService };