import { create } from "zustand";
interface PromptTextStore {
  promptText: string;
  promptType: 'assertive' | 'polite';
  setPromptText: (text: string) => void;
  setPromptType: (text: 'assertive' | 'polite') => void;
}

const useStore = create<PromptTextStore>((set) => ({
  promptText: "",
  promptType: "assertive",
  setPromptText: (value) => {
    set(() => ({
      promptText: value,
    }));
  },

  setPromptType: (value) => {
    set(() => ({
      promptType: value,
    }));
  },
}));

export const usePromptText = useStore;
