import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IRequestExpireIndividualUser, IRequestIndividualUserListData, IRequestIndividualUserSettings, IResponseExpireIndividualUser, IResponseIndividualUserData, IResponseIndividualUserSettings } from "../models/interface";


export class AppIndividualUserListService {

    public async getIndividualUserListData(params: IRequestIndividualUserListData) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseIndividualUserData>>(RoutesEnum.APP_GET_INDIVIDUAL_USER_LIST, { params })
    }

    public async applyIndividualUserSettings(payload: IRequestIndividualUserSettings) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseIndividualUserSettings>>(RoutesEnum.APP_APPLY_INDIVIDUAL_CUSTOM_SETTINGS, payload)
    }

    public async expireIndividualUser(payload: IRequestExpireIndividualUser) {
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseExpireIndividualUser>>(RoutesEnum.APP_EXPIRE_INDIVIDUAL_USER, { data: payload })
    }

}

