import { usePromptText } from "./PromptStore";
const Prompt = () => {
  const { promptText,promptType } = usePromptText();
  return (
    <output  id="promt" aria-live={promptType} aria-atomic="true" className="promtHide">
      {promptText}
    </output>
  );
};
export default Prompt;
