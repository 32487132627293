import { Button, Form, Input, Space, message } from 'antd';
import {
  IApplicationConfiguration,
  IRequestEditUserProfile,
  IUserDetails
} from 'models/interface';
import { usePageTitle } from 'modules/shared/hooks';
import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserService } from 'modules/shared/services';
import AuthService from 'modules/shared/services/authService';
import { useUserStore } from 'modules/shared/store';
import UserProfileImageEdit from 'modules/shared/components/UserProfileImageEdit';
import { appConfig } from 'config/config';
import { InfoCircleFilled } from "@ant-design/icons";
import CustomCreatePasswordWrapper from 'modules/shared/components/CustomCreatePasswordWrapper';

interface IEditProfileForm {
  name: string;
  email: string;
  password?: string;
  confirmPassword?: string;
}

const EditUserDetailPage: React.FC<{}> = () => {
  usePageTitle('MonQcle - Edit Profile');

  const [form] = Form.useForm();
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [localProfileImage, setLocalProfileImage] = useState<
    string | ArrayBuffer | null
  >(null);
  const [deleteProfileImage, setDeleteProfileImage] = useState<boolean>(false);

  const navigate = useNavigate();
  const {
    updateUserDetails,
    userDetails,
    appConfigurationList,
    projectRolePermissionsList
  } = useUserStore();
  const formInitialValues: IEditProfileForm = {
    name: userDetails?.full_name as string,
    email: userDetails?.email as string,
    password: undefined,
    confirmPassword: undefined
  };

  const backToOrigin = () => {
    if (window.history.state && window.history.state.idx > 0) navigate(-1);
    navigate('/', { replace: true });
  };

  const onFinish = async (values: IEditProfileForm) => {
    if (!userDetails) {
      return;
    }
    const keyUpdateUserDetails = 'updateUserDetails';
    setIsSubmitting(true);
    message.loading({
      content: 'Updating user profile',
      key: keyUpdateUserDetails,
      duration: 0
    });
    const payload: IRequestEditUserProfile = {
      name: values.name,
      new_profile_image: localProfileImage?.toString() ?? null,
      avatar_url: userDetails.avatar_url,
      delete_profile_image: deleteProfileImage || Boolean(userDetails.avatar_url && localProfileImage),
      file_name: localProfileImage ? `${userDetails.user_name}_${Date.now()}` : ``,
      change_name: userDetails.full_name !== values.name
    };
    if (changePassword) {
      payload.password = values.password;
    }

    try {
      const { data } = await new UserService().editUserProfile(payload);
      await message.success({
        content: 'User profile updated successfully!',
        key: keyUpdateUserDetails,
        duration: 2
      });
      const tmpUserDetails: IUserDetails = {
        ...userDetails,
        full_name: data.data.userData.full_name,
        avatar_url: data.data.userData.avatar_url
      };
      if (changePassword) {
        window.location.href = appConfig.REACT_APP_URL;
      } else {
        updateUserDetails(tmpUserDetails);
        AuthService.getInstance().setCurrentUserLocalStorage({
          user: tmpUserDetails,
          projectRolePermissions: projectRolePermissionsList,
          applicationConfiguration:
            appConfigurationList as IApplicationConfiguration
        });
        backToOrigin();
      }
    } catch (error) {
      setIsSubmitting(false);
      message.error({
        content: 'Updating user details failed!',
        key: keyUpdateUserDetails
      });
      console.error(error);
    }
  };

  return (
    <div className="smallPageWrap">
      <h2>My Profile</h2>
      <p>Manage your profile information and profile settings</p>
      <Form
        layout="vertical"
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
        disabled={isSubmitting}
      >
        <div className="profileWrap">
          <UserProfileImageEdit
            oldProfilePicturePath={
              deleteProfileImage ? null : userDetails?.avatar_url
            }
            onChange={setLocalProfileImage}
            setDelete={setDeleteProfileImage}
            userFullName={userDetails?.full_name as string}
          />
          <div className="profileFldBlk">
            <Form.Item
              label="Your Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Name is required'
                }
              ]}
            >
              <Input placeholder="Enter Your Name" maxLength={100} autoComplete='name'/>
            </Form.Item>
            <Form.Item label="Email Address" name="email">
              <Input placeholder="Enter Your Name" disabled />
            </Form.Item>
            {!changePassword ? (
                <button
                  onClick={() => setChangePassword(true)}
                  className="linkBtn"
                >
                  Change Password
                </button>
            ) : (
              <Fragment>
                <div style={{color: 'var(--mnq-primary)', paddingBottom: '5px'}} aria-live="assertive"><InfoCircleFilled aria-label="" aria-hidden={true}/> After you change your password, you will be logged out.</div>
                <CustomCreatePasswordWrapper />
                <button
                  onClick={() => {
                    setChangePassword(false);
                    form.resetFields(['password', 'confirmPassword']);
                  }}
                  className="linkBtn"
                >
                  Cancel Change Password
                </button>
              </Fragment>
            )}
          </div>
        </div>
        <div className="blkFooter">
          <Form.Item shouldUpdate>
            {({ getFieldsError }) => {
              return (
                <Space>
                  <Button htmlType="button" onClick={() => backToOrigin()}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      Boolean(
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length
                      ) ||
                      (userDetails?.full_name === form.getFieldValue('name') &&
                        !deleteProfileImage &&
                        !localProfileImage &&
                        !changePassword)
                    }
                  >
                    Save Changes
                  </Button>
                </Space>
              );
            }}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default EditUserDetailPage;
