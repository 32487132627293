import React, { useCallback, useState } from 'react';
import { Modal, Button, message, Upload, Slider, Space } from 'antd';
import Cropper, { Area, Point } from 'react-easy-crop';
import { RcFile } from 'antd/es/upload';
import {
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';
import { getAvatar } from '../utility';

const UserProfileImageEdit: React.FC<{
  onChange?: (data: string | ArrayBuffer | null) => void;
  oldProfilePicturePath?: string | null;
  userFullName: string;
  setDelete?: (value: boolean) => void;
}> = ({ onChange, oldProfilePicturePath, userFullName, setDelete }) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [oriImageBlob, setOriImageBlob] = useState<string | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [croppedImageString, setCroppedImageString] = useState<string | ArrayBuffer | null>(null);

  const createImage = (url: string) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const onCropComplete = useCallback(
    async (_croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const getCroppedImg = async (imageSrc: string, _crop: any) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    /* setting canvas width & height allows us to resize from the original image resolution */
    canvas.width = 200;
    canvas.height = 200;

    ctx.drawImage(
      image,
      _crop.x,
      _crop.y,
      _crop.width,
      _crop.height,
      0,
      0,
      canvas.width,
      canvas.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const validateImage = async (file: RcFile) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name.toLocaleLowerCase())) {
      message.error({
        content: `Please upload image having extensions .jpeg, .jpg, .png only.`,
        className: 'toastMsg savedFailed'
      });
    }
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize < 2) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (event: any) => {
        const _loadedImageUrl: string = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          if (width >= 200 && height >= 200) {
            // set image width and height to your state here
            setOriImageBlob(_loadedImageUrl);
            setShowEditModal(true);
          } else {
            message.error(`Image height & width should be minimum 200px`);
          }
        });
      });
    } else {
      message.error(`File size should be less than 2 MB.`);
    }
    return false;
  };

  const onCancel = () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 });
    setOriImageBlob(null);
    setShowEditModal(false);
  };

  const onOk = async () => {
    if (!oriImageBlob) {
      return;
    }
    const croppedImage: any = await getCroppedImg(
      oriImageBlob,
      croppedAreaPixels
    );

    const reader = new FileReader();
    reader.readAsDataURL(croppedImage);
    reader.onloadend = function () {
      const base64data = reader.result;
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setOriImageBlob(null);
      if (onChange) {
        onChange(base64data);
      }
      setCroppedImageString(base64data);
      setShowEditModal(false);
    };
  };

  const onDelete = () => {
    if (onChange) {
      onChange(null);
    }
    if (setDelete) {
      setDelete(true);
    }
    setShowEditModal(false);
    setOriImageBlob(null);
    setCrop({ x: 0, y: 0 });
    setCroppedAreaPixels(null);
    setZoom(1);
    setCroppedImageString(null);
  }

  return (
    <React.Fragment>
      <div className="profileAvatar">
        <div className="profileUpload">
          {getAvatar(userFullName, oldProfilePicturePath, croppedImageString)}
          <Space>
            <Upload
              beforeUpload={validateImage}
              accept={'.png,.PNG,.jpg,.JPG,.jpeg,.JPEG'}
              showUploadList={false}
            >
              <Button
                aria-label="Edit profile image"
                className="leftIconBtn"
              >
                Edit
              </Button>
            </Upload>
            <Button aria-label="Delete profile image" onClick={onDelete} className="leftIconBtn" disabled={!(oldProfilePicturePath || croppedImageString)}>
              Remove
            </Button>
          </Space>
        </div>
      </div>

      {showEditModal && (
        <Modal
          title="Edit Profile Image"
          width={550}
          open={true}
          cancelText="CANCEL"
          onOk={onOk}
          onCancel={onCancel}
        >
          <div style={{ position: 'relative', width: '100%', height: '50vh' }}>
            {oriImageBlob && (
              <Cropper
                image={oriImageBlob}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>
          <div className="zoomSliderWrap">
            <Button
              className="iconOnlyBtn"
              onClick={() => setZoom(1)}
              icon={<ZoomOutOutlined />}
            />
            <Slider
              className="zoomSlider"
              tooltip={{ formatter: null }}
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(newValue: number) => setZoom(newValue)}
            />
            <Button
              className="iconOnlyBtn"
              onClick={() => setZoom(3)}
              icon={<ZoomInOutlined />}
            />
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default UserProfileImageEdit;
