import { FC, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useSiteStore } from "modules/organization/store/siteStore";

const PublishApiSiteToken: FC<{
  showConfirmAPISiteToken: boolean;
  setShowConfirmAPISiteToken: Function;
  manageSiteToken: Function;
}> = ({ showConfirmAPISiteToken, setShowConfirmAPISiteToken, manageSiteToken }) => {

  const {
    siteInfo
  } = useSiteStore();

  const okButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Modal
        className="confirmationModal"
        title={<>Site Token for {siteInfo?.site_title}</>}
        centered
        open={showConfirmAPISiteToken}
        onCancel={() => setShowConfirmAPISiteToken(false)}
        footer={[
          <Button key="no" onClick={() => setShowConfirmAPISiteToken(false)}>
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={() => {
              setShowConfirmAPISiteToken(false);
              manageSiteToken();
            }}
            ref={okButtonRef}
          >
            {siteInfo?.site_api_token ? 'Update' : 'Generate'}
          </Button>,
        ]}
      >
        <div className="notificationTxtWrap">
          <ExclamationCircleFilled
            className="mediumColor notificationIcon"
            aria-hidden
            aria-label=""
          />
          <div className="notificationTxt">
            <p>
              Are you sure you want to {siteInfo?.site_api_token ? 'update' : 'generate'}{" "}
              <strong>Site Token (apiKey)</strong> for the site{" "}
              <strong>{siteInfo?.site_title}</strong>?
              <p style={{fontStyle:'italic'}}>* This process will {siteInfo?.site_api_token ? 'expire the current site token' : 'allow 3rd party users to use your datasets'}</p>
            </p>
          </div>
        </div>
      </Modal>
  );
};

export default PublishApiSiteToken;
