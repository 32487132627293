import { Tag, Typography } from 'antd';
import { useRankingToolStore } from 'modules/organization/store';
import { FC } from 'react';
import { renderQuestionType } from 'modules/organization/utility/commonFunctions';
import { IPQuestion } from 'modules/organization/models/interface';

const EachQuestion: FC<{ question: IPQuestion }> = ({ question }) => {
  return (
    <li>
      <div className="qstRowBlk samplingList">
        <div className="qstIconNumber">
          {renderQuestionType(question.question_type_id)}
          <span>Q{question.question_order_text}</span>
        </div>
        <div className="titleTxt tr">
          {question.question} 
          <span className='tagWrap'><Tag>{question.question_variable}</Tag><Typography.Text copyable={{ text: question.question_variable }} /></span>
        </div>        
      </div>
      <ul>
        {question.responses?.map((response) => (
          <li key={response.response_variable}>
            {response.response}
            {'  '}
            <span className='tagWrap'><Tag>{response.response_variable}</Tag><Typography.Text copyable={{ text: response.response_variable }} /></span>
          </li>
        ))}
      </ul>
    </li>
  );
};

const QuestionListContainer: FC = (props) => {
  const { questionSnapshotList } = useRankingToolStore();

  return (
    <>
      {(questionSnapshotList?.length ?? 0) > 0 && (
        <div className="qstTreeWrap">
          <div className="qstTreeHeading">
            <span>Questions</span>
          </div>
          <ul>
            {questionSnapshotList?.map((question) => (
              <EachQuestion key={question.id} question={question} />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default QuestionListContainer;
