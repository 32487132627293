import { useRef } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { useSiteStore } from 'modules/organization/store/siteStore';
import { SiteListMenuKeys } from 'modules/organization/models/enums';
import { SiteService } from 'modules/organization/services';

const OrgProjectUnpublish = () => {
  const {
    siteProjectAction,
    refreshSiteProjectList,
    setSiteProjectAction,
    currentPage,
    currentPageSize,
    currentSortColumn,
    currentSortOrder,
    search,
    published,
    isPreviewOnly,
    site,
    refreshSiteInfo,
    siteInfo
  } = useSiteStore();

  const okButtonRef = useRef<HTMLButtonElement>(null);

  const onOk = async () => {
    const unpublishProjectKey = 'unpublishProjectKey';

    if (siteProjectAction.type) {
      message.open({
        key: unpublishProjectKey,
        content: (
          <span aria-live="assertive">
            {siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT
              ? 'Unpublishing project..'
              : 'Unlinking project..'}
          </span>
        ),
        type: 'loading',
        duration: 0
      });
      setSiteProjectAction({ ...siteProjectAction, showConfirm: false });
      try {
        if (
          [SiteListMenuKeys.UNPUBLISH_PROJECT, SiteListMenuKeys.UNLINK_PROJECT].includes(siteProjectAction.type) &&
          siteProjectAction.details
        ) {
          siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT ? await new SiteService().unPublishProject(
            {
              publish_id: siteProjectAction.details?.publish_id,
              site_id: siteProjectAction.details.site_id,
              other_files: siteProjectAction.details.other_files,
              is_publication_public: siteProjectAction.details.is_publication_public,
              data_pipeline_url: siteProjectAction.details.data_pipeline_url,
              site_uid: siteProjectAction.details.site_key,
              enable_law_atlas_iframe: siteProjectAction.details.enable_law_atlas_iframe
            },
            siteProjectAction.details.slug ?? ''
          ) : await new SiteService().unLinkProject(
            {
              publish_id: siteProjectAction.details?.publish_id,
              site_id: siteProjectAction.details.site_id,
              other_files: siteProjectAction.details.other_files,
              is_publication_public: siteProjectAction.details.is_publication_public,
              data_pipeline_url: siteProjectAction.details.data_pipeline_url,
              site_uid: siteProjectAction.details.site_key,
              enable_law_atlas_iframe: siteProjectAction.details.enable_law_atlas_iframe
            },
            siteProjectAction.details.slug ?? ''
          );
          message.success({
            content: (
              <span aria-live="assertive">{siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT ? 'Project unpublish successfully' : 'Project unlinked successfully'}</span>
            ),
            key: unpublishProjectKey
          });
        }
          refreshSiteProjectList({
            page_index: currentPage,
            page_size: currentPageSize,
            sort_column: currentSortColumn,
            sort_order: currentSortOrder,
            search: search === '' ? null : search,
            published: published,
            is_preview_only: isPreviewOnly,
            site_id: site
          });
          siteInfo && refreshSiteInfo({site_id: `${site}`})
      } catch (error) {
        console.log(error);
        message.error({
          content: <span aria-live="assertive">Project unpublish failed</span>,
          key: unpublishProjectKey
        });
      } finally {
        setSiteProjectAction();
      }
    }
  };

  return (
    <Modal
      className="confirmationModal"
      title={
        siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT
          ? 'Unpublish Project'
          : 'Unlink Project'
      }
      centered
      open={siteProjectAction.showConfirm}
      onCancel={() => setSiteProjectAction()}
      footer={[
        <Button key="no" onClick={() => setSiteProjectAction()}>
          Cancel
        </Button>,
        <Button key="yes" type="primary" onClick={onOk} ref={okButtonRef}>
          {siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT
            ? 'Unpublish Project'
            : 'Unlink Project'}
        </Button>
      ]}
    >
      <div className="notificationTxtWrap">
        <ExclamationCircleFilled
          className="mediumColor notificationIcon"
          aria-hidden
          aria-label=""
        />
        <div className="notificationTxt">
          {siteProjectAction.type === SiteListMenuKeys.UNPUBLISH_PROJECT && (
            <p>
              Are you sure you want to unpublish{' '}
              <strong>{siteProjectAction.details?.title}</strong> from{' '}
              <strong>{siteProjectAction.details?.site_name}</strong>?
            </p>
          )}
          {siteProjectAction.type === SiteListMenuKeys.UNLINK_PROJECT && (
            <>
              {siteProjectAction.details?.is_publication_public ? (
                <p>
                  <strong>{siteProjectAction.details?.title}</strong> will be
                  unpublished from {' '}
                  <strong>{siteProjectAction.details?.site_name}</strong> and
                  will be unlinked from the site. Do you want to continue?
                </p>
              ) : (
                <p>
                  <strong>{siteProjectAction.details?.title}</strong> will be
                  unlinked from {' '}
                  <strong>{siteProjectAction.details?.site_name}</strong>. Do
                  you want to continue?
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default OrgProjectUnpublish;
