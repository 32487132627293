import { useEffect } from "react";
import { useRbac } from "../../auth/rbac/rbac";
import { useNavigate } from "react-router-dom";
import AuthService from "../shared/services/authService";
import AuthSwitchOrganizationChannelService from "modules/auth/services/switchOrganizationChannelService";
const authService = AuthService.getInstance();
const authSwitchOrganizationChannelService = AuthSwitchOrganizationChannelService.getSwitchOrganizationInstance();

const HomePage = () => {
  const {userDetails, auth0UserDetails} = useRbac();
  const navigate = useNavigate();
  useEffect(()=>{
    if(userDetails?.is_app_admin && !auth0UserDetails?.org_id) {
      authSwitchOrganizationChannelService.getSwitchOrganizationChannel().postMessage({org_key: null, org_id: null, user_id: userDetails.sub, is_app_admin: true});
      return navigate(`/app`);
    }
    else if(auth0UserDetails?.org_id && userDetails?.organization){
      const currentOrganization = userDetails.organization.find(each => each.id === userDetails?.org_id);
      if(currentOrganization){
        authSwitchOrganizationChannelService.getSwitchOrganizationChannel().postMessage({org_key: currentOrganization.org_key, org_id: currentOrganization.id, user_id: userDetails.sub, is_app_admin: false});
        return navigate(`/org/${currentOrganization.org_key}`)
      } else {
        return navigate(`/idle-user`)
      }
    }
    else if(!auth0UserDetails?.org_id && userDetails?.organization){
      const currentOrganization = userDetails.organization.find(each => each.is_default_organization) ?? userDetails.organization[0];
      if(currentOrganization){
        authService.setCurrentOrganization(currentOrganization.id);
        authService.clearCurrentUserLocalStorage();
        authSwitchOrganizationChannelService.getSwitchOrganizationChannel().postMessage({org_key: currentOrganization.org_key, org_id: currentOrganization.id, user_id: userDetails.sub, is_app_admin: false});
        setTimeout(()=>{
          window.location.reload();
        }, 100)
      }
    } else {
      return navigate(`/idle-user`)
    }
  }, [userDetails, auth0UserDetails ,navigate])
  return (
    <></>
  );
}

export default HomePage