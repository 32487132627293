import { Button, message, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  SiteService,
} from "modules/organization/services";
import { useSiteStore } from "modules/organization/store/siteStore";

const SiteRemoveUser: React.FC = () => {
  const {
    siteInfo,
    showConfirmDeleteUser,
    userListCurrentPage,
    userListCurrentPageSize,
    userListCurrentSortColumn,
    userListCurrentSortOrder,
    userListSearch,
    site,
    selectedUser,
    refreshSiteUserList,
    setShowConfirmDeleteUser,
  } = useSiteStore();

  const doRemoveUser = async () => {
    const removeUserKey = "removeUserKey";

    if (selectedUser && siteInfo) {
      message.open({
        key: removeUserKey,
        content: "Removing User...",
        type: "loading",
        duration: 0,
      });
      setShowConfirmDeleteUser(false);
      try {
        await new SiteService().deleteSiteUser(siteInfo.id, selectedUser.user_id)
        message.success("User removed successfully");
        refreshSiteUserList({
          page_index: userListCurrentPage,
          page_size: userListCurrentPageSize,
          sort_column: userListCurrentSortColumn,
          sort_order: userListCurrentSortOrder,
          search: userListSearch === "" ? null : userListSearch,
          site_id: site,
        }).catch(console.error);
      } catch (error) {
        console.log(error);
        message.error("Failed to remove user");
      }
    }
    message.destroy(removeUserKey);
  };

  return (
    <Modal
        className="confirmationModal"
        title={<>Remove User</>}
        centered
        open={showConfirmDeleteUser}
        onCancel={() => setShowConfirmDeleteUser(false)}
        footer={[
          <Button key="no" onClick={() => setShowConfirmDeleteUser(false)}>
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={doRemoveUser}
          >
            Remove User
          </Button>,
        ]}
      >
        <div className="notificationTxtWrap">
          <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
          <div className="notificationTxt">
            <p>Are you sure you want to remove <strong>{selectedUser?.user_name}</strong> from site?</p>
          </div>
        </div>
      </Modal>
  );
};

export default SiteRemoveUser;
