import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { RBAC } from "auth/rbac/rbac";
import { useIsVisible } from "modules/shared/hooks";
import { useUserStore } from "modules/shared/store";
import { getAvatar } from "modules/shared/utility";
import SwitchOrganization from "./SwitchOrganization";
import AppLogout from "./Logout";
import SwitchOrganizationModal from "./SwitchOrganizationModal";
import siteLogo from "../../../../assets/images/logo-header.svg";
import { AppNotification } from './AppNotification'

export const Header = () => {
  const [profileClicked, setProfileClicked, wrapperRef] = useIsVisible(false);
  const [selectedOrgIdForSwitch, setSelectedOrgIdForSwitch] = useState<{
    org_id: string;
    org_key: string;
  } | null>(null);
  
  const { isAuthenticated, user } = useAuth0();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { userDetails } = useUserStore();

  const getCurrentOrganizationName = () => {
    const currentOrg = userDetails?.organization?.find(
      (each) => each.id === user?.org_id
    );
    if (currentOrg) {
      return <span>{currentOrg.name}</span>;
    }
    return "";
  };


  return (
    <Fragment>
      <header>
        <h1>
          <Link to={"/"}>
            <img
              src={siteLogo}
              alt="MonQcle by the Center for Public Health Law Research"
            />
          </Link>
        </h1>
        <ul className="headerMenu">
          {userDetails &&
            <li>
              <NavLink
                to={organizationBaseRouteUrl}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Dashboard
              </NavLink>
            </li>
          }
          
          <RBAC
            allowedPermissions={[
              ERbacPermissions.ORG_PROJECT_LIST_ALL,
              ERbacPermissions.ORG_PROJECT_LIST_ASSIGNED,
              ERbacPermissions.ORG_PROJECT_LIST_SITE,
            ]}
          >
            <li>
              <NavLink
                to={organizationBaseRouteUrl + "/projects"}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Projects
              </NavLink>
            </li>
          </RBAC>
          <RBAC allowedPermissions={[ERbacPermissions.ORG_GROUP_CREATE]}>
            <li>
              <NavLink
                to={organizationBaseRouteUrl + "/groups"}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Groups
              </NavLink>
            </li>
          </RBAC>
          <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_LIST_ALL, ERbacPermissions.ORG_SITE_LIST_ASSIGNED]}>
            <li>
              <NavLink
                to={organizationBaseRouteUrl + "/sites"}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Sites
              </NavLink>
            </li>
          </RBAC>
          <RBAC allowedPermissions={[ERbacPermissions.ORG_MANAGE_USER_LIST]}>
            <li>
              <NavLink
                to={organizationBaseRouteUrl + "/users"}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Users
              </NavLink>
            </li>
          </RBAC>
          <RBAC allowedPermissions={[ERbacPermissions.ORG_RANKING_SETUP]}>
            <li>
              <NavLink
                to={organizationBaseRouteUrl + "/ranking-setup"}
                className={({ isActive }) => (isActive ? "active" : "")}
                end
              >
                Ranking Setup
              </NavLink>
            </li>
          </RBAC>
        </ul>
        {isAuthenticated && userDetails && (
          <div className="userProfile">
            <AppNotification userDetails={userDetails}/>
            
            <button
              className="userBlk"
              ref={wrapperRef as any}
              onClick={() => setProfileClicked((prev) => !prev)}
            >
              <span className="userAvatar">
                {getAvatar(
                  userDetails?.full_name as string,
                  userDetails?.avatar_url
                )}
              </span>
              <span className="userQuickView">
                <span className="userEmail">{userDetails?.full_name}</span>
                <span className="userOrg">{getCurrentOrganizationName()}</span>
              </span>
            </button>

            {profileClicked && (
              <div className="userOptions">
                <div className="userEmail">{userDetails?.full_name}</div>
                <ul>
                  <li>
                    <Link
                      id="editProfile"
                      to={organizationBaseRouteUrl + "/edit-profile"}
                    >
                      Edit My Profile
                    </Link>
                  </li>
                  <SwitchOrganization
                    doSwitchOrganization={(org_key, org_id) => {
                      setSelectedOrgIdForSwitch({
                        org_id,
                        org_key,
                      });
                    }}
                  />
                  <AppLogout />
                </ul>
              </div>
            )}
          </div>
          
        )}
        {!isAuthenticated &&
          <div className="userProfile">
            <ul className="headerMenu">
            <li>
              <NavLink
                to={'/'}
              >
                Login
              </NavLink>
            </li>
            </ul>
          </div>
        }
        <span className="menuControl">Header Menu</span>
      </header>

      {selectedOrgIdForSwitch && (
        <SwitchOrganizationModal
          org_id={selectedOrgIdForSwitch?.org_id}
          org_key={selectedOrgIdForSwitch?.org_key}
          onCancel={() => {
            setSelectedOrgIdForSwitch(null);
          }}
        />
      )}
    </Fragment>
  );
};
