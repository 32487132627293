import React, { useState } from "react";
import { Button, Form, Input, Spin, Switch, Tooltip, message } from "antd";
import { SiteService } from "modules/organization/services";
import { useSiteStore } from "modules/organization/store/siteStore";
import { InfoCircleFilled, CopyOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
import { copyToClipboard, uniqueFeedBack } from "modules/shared/utility";
import OrgDeleteSite from 'modules/organization/orgSite/components/OrgDeleteSite';
import { RBAC } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";
import { SiteKeyIcon } from "modules/shared/components/CustomAppIcons";
import PublishApiSiteToken from "./PublishAPISiteToken";

const SiteInfo: React.FC = () => {
  const [form] = Form.useForm();
  const {
    siteInfo,
    titleExists,
    titleCheckOnSubmit,
    setSiteInfoData,
    setTitleCheckOnSubmit,
    setTitleExists,
  } = useSiteStore();
  const { setPromptType, setPromptText } = usePromptText();
  const [messageApi, contextHolder] = message.useMessage();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValueNotChanged, setIsValueNotChanged] = useState(true);
  const [isSiteTokenLoading, setIsSiteTokenLoading] = useState(false);
  const [showConfirmAPISiteToken, setShowConfirmAPISiteToken] = useState(false);
  const initialValueObject = {
    site_title: siteInfo?.site_title,
    site_url: siteInfo?.site_url,
    enable_law_atlas_iframe: siteInfo?.enable_law_atlas_iframe
  };
  const onClose = () => {
    form.resetFields();
    titleExists && setTitleExists(null);
    titleCheckOnSubmit && setTitleCheckOnSubmit(null);
    setIsValueNotChanged(true);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    titleExists && setTitleExists(null);
    titleCheckOnSubmit && setTitleCheckOnSubmit(null);
  };

  const handleCheckTitle = async (title: string, checkOnSubmit?: boolean) => {
    if (!title.trim() || form.getFieldError("site_title").length) {
      return;
    }
    if (checkOnSubmit) {
      setTitleCheckOnSubmit("checking");
    } else {
      setTitleExists("checking");
    }
    setIsSubmitting(true);
    try {
      const response = await new SiteService().checkSiteUniqueTitle({
        site_id: siteInfo?.id,
        site_title: title,
      });
      if (response.data.data.exists) {
        checkOnSubmit
          ? setTitleCheckOnSubmit("exist")
          : setTitleExists("exist");
      } else {
        checkOnSubmit
          ? setTitleCheckOnSubmit("unique")
          : setTitleExists("unique");
      }
      setIsSubmitting(false);
      return response.data.data.exists;
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      message.error({
        content: "Something went wrong please try again!",
      });
    }
  };

  const onFinish = async (formValues: {
    site_title: string;
    site_url: string;
    enable_law_atlas_iframe: boolean;
  }) => {
    setIsSubmitting(true);

    let successMessage = "Site edited successfully";
    let failedMessage = "Failed to edit site";
    let alreadyExistsMessage = "Site title already taken";
    const editSiteKey = "editSiteKey";
    messageApi.open({
      key: editSiteKey,
      type: "loading",
      content: "Editing site...",
      duration: 0,
    });
    if (siteInfo) {
      const isTitleExists = await handleCheckTitle(formValues.site_title, true);
      if (isTitleExists) {
        setIsSubmitting(false);
        messageApi.destroy(editSiteKey);
        messageApi.open({
          type: "error",
          content: alreadyExistsMessage,
          duration: 5,
        });
      } else {
        let isOperationSuccess = false;
        try {
          await new SiteService().postEditSite(
            siteInfo.id,
            formValues.site_title,
            formValues.site_url,
            formValues.enable_law_atlas_iframe
          );
          isOperationSuccess = true;
        } catch (err: any) {
          console.log(err);
          setIsValueNotChanged(true);
          titleExists && setTitleExists(null);
          titleCheckOnSubmit && setTitleCheckOnSubmit(null);
          failedMessage = err.error.response.data.message;
        }

        setIsSubmitting(false);
        messageApi.destroy(editSiteKey);
        messageApi.open({
          type: isOperationSuccess ? "success" : "error",
          content: isOperationSuccess ? successMessage : failedMessage,
          duration: 5,
        });
        if (isOperationSuccess) {
          setSiteInfoData({
            ...siteInfo,
            site_url: formValues.site_url,
            site_title: formValues.site_title,
          });
          setIsValueNotChanged(true);
          titleExists && setTitleExists(null);
          titleCheckOnSubmit && setTitleCheckOnSubmit(null);
        }
      }
    }
  };

  const handleCopyClick = () => {
    // Copy the input value to the clipboard
    let promptText = '';
    copyToClipboard(siteInfo?.site_api_token as string)
      .then(() => {
        promptText = "Site Token copied to clipboard";
        setPromptType("assertive");
        setPromptText(promptText);
        message.success(promptText);
      })
      .catch(() => {
        promptText = "Failed to copy Site Token";
        setPromptType("assertive");
        setPromptText(promptText);
        message.error(promptText);
      });
  };

  const manageSiteToken = async () => {
    const generateSiteKey = "generateSiteKey";
    let promptText = `${siteInfo?.site_api_token ? 'Updating' : 'Generating'} site publish token...`;
    setIsSiteTokenLoading(true);
    try {
      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: generateSiteKey,
        content: promptText,
        type: "loading",
        duration: 0,
      });
      const response = await new SiteService().getSiteToken(
        siteInfo?.id as number,
        siteInfo?.site_key as string,
      );
      promptText = `Site publish token ${siteInfo?.site_api_token ? 'updated' : 'generated'} successfully`;
      setPromptType("assertive");
      setPromptText(promptText);
      message.success(promptText);
      message.destroy(generateSiteKey);
      setSiteInfoData({
        ...siteInfo as any,
        site_api_token: response.data.data.siteToken
      })
      setIsSiteTokenLoading(false);
    } catch (error) {
      setIsSiteTokenLoading(false);
      promptText = `Failed to ${siteInfo?.site_api_token ? 'update' : 'generate'} site publish token`;
      setPromptType("assertive");
      setPromptText(promptText);
      message.error(promptText);
      message.destroy(generateSiteKey);
    }
  }

  return (
    <div className="tabContent">
      <h2>{`Site Details`}</h2>
      {contextHolder}
      <Spin spinning={isSubmitting}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialValueObject}
          onValuesChange={(value) => {
            setIsValueNotChanged(false);
          }}
        >
          <div className="rowBlk">
            <div className={!siteInfo?.show_iframe_switch ? "colBlk6" : "colBlk4"}>
              <Form.Item
                name="site_title"
                label="Site Title"
                rules={[
                  { required: true, message: "Site title can not be empty" },
                ]}
              >
                <Input
                  placeholder="Enter Site Title"
                  maxLength={100}
                  onChange={handleTitleChange}
                  suffix={uniqueFeedBack(titleExists, titleCheckOnSubmit, {
                    success: "Title is available",
                    error: "Title already taken",
                  })}
                />
              </Form.Item>
            </div>
            <div className={!siteInfo?.show_iframe_switch ? "colBlk6" : "colBlk4"}>
              <Form.Item
                name="site_url"
                label={
                  <>
                    <label className="labelWithIcon">Site URL</label>{" "}
                    <Tooltip
                      placement="right"
                      overlayStyle={{ maxWidth: "300px" }}
                      title={"URL must include the path of publication"}
                      trigger={["hover", "focus"]}
                    >
                      <InfoCircleFilled aria-label="" aria-hidden={true} />
                    </Tooltip>
                  </>
                }
                rules={[
                  { required: true, message: "Site url can not be empty" },
                  {
                    type: "url",
                    message: "Please enter a valid url.",
                  },
                ]}
              >
                <Input placeholder="Enter Site URL (www.example.com/publication_path)" />
              </Form.Item>
            </div>
            {siteInfo?.show_iframe_switch?
              <div className="colBlk4">
                <Form.Item
                  label="Enable Law Atlas iFrame"
                  name="enable_law_atlas_iframe"
                  valuePropName='checked'
                >
                  <Switch checkedChildren="On" unCheckedChildren="Off" />
                </Form.Item>
              </div> : null
            }
          </div>
          <div className="siteInfoBlkFooter">
            <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_DELETE]}>
              <OrgDeleteSite />
            </RBAC>
            <Button
              htmlType="button"
              onClick={onClose}
              disabled={isSubmitting || isValueNotChanged}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isSubmitting || isValueNotChanged}
            >
              Save Changes
            </Button>
          </div>
        </Form>
        <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_DELETE]}>
          <hr />
          <div className="rowBlk">
            <div className="colBlk6">
              <div className="displayFlex flexVertical">
                <div className="displayFlex">
                  <label className="labelWithIcon">Site Token (apiKey)</label>{" "}
                  <Tooltip
                    placement="right"
                    overlayStyle={{ maxWidth: "300px" }}
                    title={"Generate to use for Publish API for the site"}
                    trigger={["hover", "focus"]}
                  >
                    <InfoCircleFilled aria-label="" aria-hidden={true} />
                  </Tooltip>
                </div>
                <div className="displayFlex">
                  <Input
                    readOnly
                    disabled
                    aria-disabled
                    placeholder="Site Token"
                    value={!isSiteTokenLoading ? siteInfo?.site_api_token ?? 'Click on the Site Token button to generate site token → ' : 'Loading site token...'}
                    suffix={isSiteTokenLoading ? <LoadingOutlined style={{ color: "black" }} aria-label="" aria-hidden={true} /> : ''}
                  />
                  <div className="displayFlex" style={{ flex: '0 0 50px' }}>
                    {
                      siteInfo?.site_api_token &&
                      <Tooltip
                        title="Copy Site Token"
                        placement="top"
                        trigger={["hover", "focus"]}
                      >
                        <Button
                          onClick={handleCopyClick}
                          aria-label="Copy Site Token"
                          style={{ marginLeft: "5px" }}
                          className="actionBtn"
                        >
                          <CopyOutlined aria-label="" aria-hidden={true} className="activeIcon" />
                        </Button>
                      </Tooltip>
                    }
                    {
                      !siteInfo?.site_api_token ?
                        <Tooltip
                          title="Generate Site Token"
                          placement="top"
                          trigger={["hover", "focus"]}
                        >
                          <Button
                            onClick={() => setShowConfirmAPISiteToken(true)}
                            aria-label="Generate Site Token"
                            style={{ marginLeft: "5px" }}
                            className="actionBtn"
                          >
                            <SiteKeyIcon />
                          </Button>
                        </Tooltip>
                        :
                        <Tooltip
                          title="Change Site Token"
                          placement="top"
                          trigger={["hover", "focus"]}
                        >
                          <Button
                            onClick={() => setShowConfirmAPISiteToken(true)}
                            aria-label="Change Site Token"
                            style={{ marginLeft: "5px" }}
                            className="actionBtn"
                          >
                            <SyncOutlined aria-label="" aria-hidden={true} className="activeIcon" />
                          </Button>
                        </Tooltip>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </RBAC>
      </Spin>
      {showConfirmAPISiteToken && <PublishApiSiteToken showConfirmAPISiteToken={showConfirmAPISiteToken} setShowConfirmAPISiteToken={setShowConfirmAPISiteToken} manageSiteToken={manageSiteToken} />}
    </div>
  );
};

export default SiteInfo;
