import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { ISiteProjectListData } from "modules/organization/models/interface";
import { Button, Tooltip, Modal, Spin, message } from "antd";
import { EyeOutlined, SyncOutlined } from "@ant-design/icons";
import { SiteService } from "modules/organization/services";
import { Link } from "react-router-dom";


const OrgSiteGetPasscodeModal:FC<{siteData: ISiteProjectListData; previewUrl: string;}> = ({siteData, previewUrl}) =>{
    const [showModal, setShowModal] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [passcode, setPasscode] = useState<string | null>(null);

    const getPasscodeData = useCallback( async ()=> {
        if(siteData.preview_id){
            setLoadingData(true);
            try {
                const result = await new SiteService().getPreviewPasscode(siteData.preview_id);
                setPasscode(result.data.data.passcode_details.passcode.toString());
                
            } catch (error) {
                message.error('Failed to get passcode.')
            }
            setLoadingData(false);
        }
        
    },[siteData])


    const updatePasscodeData = useCallback( async ()=> {
        if(siteData.preview_id){
            setLoadingData(true);
            try {
                const result = await new SiteService().updatePreviewPasscode(siteData.preview_id);
                setPasscode(result.data.data.passcode_details.passcode.toString());
                
            } catch (error) {
                message.error('Failed to get passcode.')
            }
            setLoadingData(false);
        }
        
    },[siteData])



    useEffect(()=>{
        if(showModal && !passcode ){
            getPasscodeData()
        }
    }, [showModal, passcode, siteData, getPasscodeData])

    return (
        <Fragment>
            <div className="passcodeCol flexJCenter">
                <span>******</span>
                <Tooltip title="Get preview passcode" placement="right" trigger={["hover", "focus"]}>
                    <Button onClick={() => setShowModal(true)} aria-label="Get passcode" className="actionBtn">
                        <EyeOutlined className="activeIcon" />
                    </Button>
                </Tooltip>
            </div>
            <Modal
                className="confirmationModal"
                title={<> {siteData.title}</>} 
                open={showModal} 
                onCancel={()=>setShowModal(false)}
                maskClosable={false}
                footer={[
                    <Button key="cancel" onClick={()=>setShowModal(false)}>
                        Cancel
                    </Button>
                ]}
            >
                <Spin spinning={loadingData}>
                    <div className="modalContent alignCenter">
                        <label>
                            {siteData.site_name}
                        </label>
                        <Link to={previewUrl} target="_blank">{previewUrl}</Link>
                        {passcode && 
                            <div className="passcodeBlk">
                                <label>{passcode}</label>
                                <Tooltip title="Change passcode" placement="right" trigger={["hover", "focus"]}>
                                    <Button onClick={updatePasscodeData} aria-label="Change passcode" className="actionBtn">
                                        <SyncOutlined className="activeIcon" />
                                    </Button>
                                </Tooltip>
                            </div>
                        }
                        
                    </div>
                </Spin>
            </Modal>
        </Fragment>
    )
}

export default OrgSiteGetPasscodeModal;