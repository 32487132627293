import OrgInviteNewUser from "./components/OrgInviteNewUser";
import { OrgManageUserService, ProjectListService } from "../services";
import { Fragment, useEffect, useState } from "react";
import { IRoleMasterData } from "../../../models/interface";
import { RBAC, withRbac } from "../../../auth/rbac/rbac";
import { ERbacPermissions } from "../../../auth/rbac/rbacPermissionsList";
import { usePageTitle } from 'modules/shared/hooks';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import OrgUserList from './components/OrgUserList';
const OrgManageUserPage = () => {
  usePageTitle("MonQcle - Users");
  const [roleMasterData, setRoleMasterData] = useState<{
    isLoading: boolean;
    data: IRoleMasterData[];
  }>({
    isLoading: true,
    data: [],
  });
  const [refreshList, setRefreshList] = useState(false);
  const [loadingLimit, setLoadingLimit] = useState(false);
  const [limit, setLimit] = useState({
    is_allowed: false,
    limit_message: '',
  });

  const checkMemberAllowed = async () => {
    try {
      const response = await new ProjectListService().checkOrgLimit({
        type_restriction: 'user'
      });
      setLimit(response.data.data);
    } catch (error) {
      console.error(error);
    }
    return setLoadingLimit(false);
  };

  const getRoleMasterData = async () => {
    try {
      const data = (await new OrgManageUserService().getRoleMasterData()).data
        ?.data?.roleList;
      setRoleMasterData((prevState) => {
        return { ...prevState, isLoading: false, data };
      });

    } catch (err) {
      setRoleMasterData({ isLoading: false, data: [] });
      console.log(err);
    }
  };
  

  useEffect(() => {
    if (loadingLimit) {
      checkMemberAllowed();
    }
  }, [loadingLimit])
  
  useEffect(() => {
    if(refreshList){
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    getRoleMasterData();
  }, []);
  return (
    <Fragment>
      <div className="pageHeadingWrap">
        <h2>Manage User Page</h2>
        <RBAC allowedPermissions={[ERbacPermissions.ORG_MANAGE_USER_ADD]}>
          <ErrorBoundary>
            <OrgInviteNewUser roleList={roleMasterData} refreshList={refreshList} setRefreshList={setRefreshList} disabled={limit} loadingLimit={loadingLimit} />
          </ErrorBoundary>
        </RBAC>
      </div>
      <ErrorBoundary>
        <OrgUserList roleList={roleMasterData} refreshList={refreshList} setLoadingLimit={setLoadingLimit}/>
      </ErrorBoundary>
    </Fragment>
  );
};

export default withRbac(OrgManageUserPage, { allowedPermissions: [ERbacPermissions.ORG_MANAGE_USER_LIST], isAccessFromPage: true });
