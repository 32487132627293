import { RBAC, withRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import { usePageTitle } from 'modules/shared/hooks';
import React, { Fragment } from 'react';
import GroupCardList from './components/GroupCardList';
import OrgCreateNewGroup from './components/OrgCreateNewGroup';

const OrgGroupListPage = () => {
  usePageTitle('MonQcle - Groups');
  return (
    <Fragment>
      <div className="pageHeadingWrap">
        <h2>Groups</h2>
        <RBAC allowedPermissions={[ERbacPermissions.ORG_GROUP_CREATE]}>
          <ErrorBoundary>
            <OrgCreateNewGroup createButton={{ label: 'Create Group' }} />
          </ErrorBoundary>
        </RBAC>
      </div>
      <ErrorBoundary>
        <GroupCardList />
      </ErrorBoundary>
    </Fragment>
  );
};

export default withRbac(OrgGroupListPage, { allowedPermissions: [ERbacPermissions.ORG_GROUP_LIST_ALL,ERbacPermissions.ORG_GROUP_LIST_ASSIGNED], isAccessFromPage: true });
