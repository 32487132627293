import React, { useState } from "react";
import { Button, Drawer, Form, Input, message, Radio, Skeleton, Space, Spin, Tooltip } from "antd";
import { OrgManageUserService } from "../../services";
import { IRoleMasterData } from "../../../../models/interface";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";

export const CustomRoleRadioGroup:React.FC<{
  roleList: {
    isLoading: boolean;
    data: IRoleMasterData[];
  }
}> = ({roleList}) => {
  return (
      <Form.Item name="role_id" label="Role" rules={[{ required: true, message: "Role can not be empty" }]}>
        <Radio.Group>
          <Space direction="vertical">
            {roleList.data.map((eachRole) => {
              return (
                <Radio key={eachRole.id} value={eachRole.id} aria-label={`${eachRole.name}, role`}>
                  <span aria-hidden>{eachRole.name}</span>
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </Form.Item>
  )
}

const OrgInviteNewUser: React.FC<{
  roleList: {
    isLoading: boolean;
    data: IRoleMasterData[];
  },
  refreshList: boolean,
  setRefreshList: React.Dispatch<React.SetStateAction<boolean>>
  disabled?: { is_allowed: boolean; limit_message: string };
  loadingLimit?: boolean;
}> = ({ roleList, refreshList, setRefreshList, disabled, loadingLimit }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const baseUrl = window.location.origin;
  const rootElement = document.getElementById('root');
  const {setPromptText,setPromptType} = usePromptText();

  const showDrawer = () => {
    setOpenDrawer(true);
    rootElement?.setAttribute('inert', '');
  };

  const onClose = () => {
    form.resetFields();
    setOpenDrawer(false);
    rootElement?.removeAttribute('inert');
  };

  const onFinish = async (formValues: { email: string, role_id: string }) => {
    setIsSubmitting(true);
    let successMessage = "Invitation sent successfully";
    let failedMessage = "Failed to send invitation";
    const requestingInviteKey = "requestingInviteKey";
    let promptText = "Sending invitation...";
    setPromptType("assertive");
    setPromptText(promptText);
    messageApi.open({
      key: requestingInviteKey,
      type: "loading",
      content: promptText,
      duration: 0,
    });

    let isOperationSuccess = false;
    try {
      await new OrgManageUserService().postInvitationRequest(
        formValues.email,
        formValues.role_id,
        baseUrl
      );
      isOperationSuccess = true;
      onClose();

    } catch (err: any) {
      console.log(err);
      failedMessage = err.error.response.data.message;
    }

    setIsSubmitting(false);
    messageApi.destroy(requestingInviteKey);
    setPromptType("assertive");
    promptText = isOperationSuccess ? successMessage : failedMessage;
    setPromptText(promptText);
    messageApi.open({
      type: isOperationSuccess ? "success" : "error",
      content: isOperationSuccess ? successMessage : failedMessage,
      duration: 5,
    });
    if (isOperationSuccess) {
      setRefreshList(true);    
    }
  }

  return (
    <React.Fragment>
      {
        !loadingLimit ?
        <Tooltip title={disabled?.limit_message} placement="left">
          <Button type="primary" onClick={showDrawer} className="flexLeftGap" disabled={!disabled?.is_allowed}>
            Invite Users
          </Button>
        </Tooltip>
        :
        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} className="flexLeftGap"/>
      }

      <Drawer
        title={<h2>Invite User</h2>}
        className="customDrawer"
        width={720}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        maskClosable={false}
        extra={
          <Button
            onClick={onClose}
            className="closeBlk"
            disabled={isSubmitting}
          >
            Close
          </Button>
        }
      >
        <Spin spinning={roleList.isLoading}>
          {contextHolder}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            className="drawerContent"
          >
            <div className="blkContent">
              <Form.Item
                name="email"
                label="Email Address"
                rules={[{ required: true, message: "Email can not be empty" }, { type: "email", message: "Please enter a valid email" }]}
              >
                <Input placeholder="Enter Email Address" />
              </Form.Item>
              <CustomRoleRadioGroup roleList={roleList} />
            </div>
            <div className="blkFooter">
              <Form.Item>
                <Space>
                  <Button
                    htmlType="button"
                    onClick={onClose}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    Send Invitation
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </Drawer>
    </React.Fragment>
  );
};

export default OrgInviteNewUser;
