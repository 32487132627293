import { Fragment, useEffect, useRef, useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, message, Modal, Tooltip } from 'antd';
import { useSiteStore } from 'modules/organization/store/siteStore';
import { SiteService } from 'modules/organization/services';
import { useNavigate } from 'react-router-dom';
import { useOrganizationRoutes } from 'routes/organizationRoute';
import { usePromptText } from 'modules/shared/components/accessibility/PromptStore';

const OrgDeleteSite = () => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    site,
    siteListData,
    siteProjectListData,
    showTableLoader,
    siteInfo,
    setSiteListData,
    setSite,
    search,
    published
  } = useSiteStore();
  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const headingRef = useRef<HTMLParagraphElement>(null);
  const okButtonRef = useRef<HTMLButtonElement>(null);
  const {setPromptType,setPromptText} = usePromptText();

  const onOk = async () => {
    const deleteSiteKey = 'deleteSiteKey';
    let promptText = "Deleting site...";
    if (site || siteInfo) {
      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: deleteSiteKey,
        content: promptText,
        type: 'loading',
        duration: 0
      });
      setShowConfirmDelete(false);
      try {
        await new SiteService().deleteSite({ site_id: siteInfo?.id ?? site });
        setPromptType("assertive");
        promptText = "Site deleted successfully";
        setPromptText(promptText);
        message.success({
          content: promptText,
          key: deleteSiteKey
        });
        setSiteListData(null);
        const result = await new SiteService().getSiteListData();
        const { sites } = result.data.data;
        setSiteListData(sites);
        siteInfo ? navigate(`${organizationBaseRouteUrl}/sites`) : setSite(0);
      } catch (error) {
        console.log(error);
        promptText = "Site delete failed";
        setPromptType("assertive");
        setPromptText(promptText);
        message.error({
          content: promptText,
          key: deleteSiteKey
        });
      }
    }
  };

  const siteToDelete = siteListData ? siteListData.find(
    (eachSite) => eachSite.site_id === site
  ) : null;

  const isDeleteEnabled = () => {

    if (showTableLoader || (siteInfo ? siteInfo.projects_count > 0 : false)) {
      return true;
    }
    if (siteProjectListData.length > 0 || search.length || published !== null ) {
      return true;
    }
    
    return false;
  
  }
  useEffect(()=>{
    if(showConfirmDelete){
      setTimeout(()=>{
        headingRef.current?.focus();
        headingRef.current?.click();
      },1000)
    }   
  }, [showConfirmDelete])
  return (
    <Fragment>
      <Tooltip
        destroyTooltipOnHide
        trigger={['focus', 'hover']}
        title={
          siteProjectListData.length > 0
            ? 'Unlink all the project to delete site'
            : 'Delete site'
        }
      >
        <Button
          onClick={() => {setShowConfirmDelete(true); okButtonRef.current?.blur()}}
          disabled={isDeleteEnabled()}
          ref={okButtonRef}
        >
          Delete
        </Button>
      </Tooltip>
      <Modal
        className="confirmationModal"
        title={<>Delete Site</>}
        centered
        open={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(false)}
        footer={[
          <Button key="no" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </Button>,
          <Button key="yes" type="primary" onClick={onOk}>
            Delete Site
          </Button>
        ]}
      >
        <div className="notificationTxtWrap">
          <ExclamationCircleFilled
            className="mediumColor notificationIcon"
            aria-hidden
            aria-label=""
          />
          <div className="notificationTxt">
            <p tabIndex={-1} ref={headingRef}>
              Are you sure you want to delete the site{' '}
              <strong>{siteToDelete?.name}</strong>?
            </p>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default OrgDeleteSite;
