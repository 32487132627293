import React, { useCallback, useState } from "react";
import { Button, Drawer, Form, Input, message, Space, Tooltip } from "antd";
import { SiteService } from "modules/organization/services";
import { useSiteStore } from "modules/organization/store/siteStore";
import { InfoCircleFilled } from "@ant-design/icons";
import { uniqueFeedBack } from "modules/shared/utility";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useNavigate } from "react-router-dom";

const OrgCreateSite: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const rootElement = document.getElementById("root");
  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const {
    titleExists,
    titleCheckOnSubmit,
    setTitleExists,
    setTitleCheckOnSubmit,
    setSiteListData,
  } = useSiteStore();

  const showDrawer = () => {
    setOpenDrawer(true);
    rootElement?.setAttribute("inert", "");
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    titleExists && setTitleExists(null);
    titleCheckOnSubmit && setTitleCheckOnSubmit(null);
  };

  const onClose = () => {
    form.resetFields();
    setOpenDrawer(false);
    rootElement?.removeAttribute("inert");
    titleExists && setTitleExists(null);
    titleCheckOnSubmit && setTitleCheckOnSubmit(null);
  };

  const getSiteList = useCallback(async () => {
    try {
      const result = await new SiteService().getSiteListData();
      const { sites } = result.data.data;
      setSiteListData(sites);
    } catch (error) {
      console.error(error);
      await message.error({
        content: "Something went wrong please try again!",
      });
    }
  }, [setSiteListData]);

  const onSiteCreateSuccess = (site_id: number, successMessage: string, messageKey: string) => {
    getSiteList();
      message.destroy(messageKey);
      message.open({
        key: messageKey,
        type: "success",
        content: <span aria-live="assertive">{successMessage}</span>,
        duration: 5,
      });
    navigate(`${organizationBaseRouteUrl}/site/${site_id}`);
  };

  const onFinish = async (formValues: {
    site_title: string;
    site_url: string;
  }) => {
    setIsSubmitting(true);
    let successMessage = "Site added successfully";
    let failedMessage = "Failed to add site";
    const addSiteKey = "addSiteKey";
    message.open({
      key: addSiteKey,
      type: "loading",
      content: <span aria-live="assertive">Creating new site...</span>,
      duration: 0,
    });
    const checkStatus = await handleCheckTitle(
      formValues?.site_title ?? "",
      true
    ).catch(console.error);
    let isOperationSuccess = false;
    if (checkStatus) {
      setIsSubmitting(false);
      message.destroy(addSiteKey);
      message.open({
        type: "error",
        content: <span aria-live="assertive">{failedMessage}</span>,
        duration: 5,
      });
    } else {
      try {
        const response = await new SiteService().postCreateNewSite(
          formValues.site_title,
          formValues.site_url
        );
        isOperationSuccess = true;
        isOperationSuccess &&
          onSiteCreateSuccess(response.data.data.site_details?.id, successMessage,addSiteKey);
        onClose();
      } catch (err: any) {
        console.log(err);
        failedMessage = err.error.response.data.message;
        message.destroy(addSiteKey);
        message.open({
          type: "error",
          content:failedMessage,
          duration: 5,
        });
      }
      setIsSubmitting(false);
     
    }
  };
  const handleCheckTitle = async (title: string, checkOnSubmit?: boolean) => {
    if (!title.trim() || form.getFieldError("site_title").length) {
      return;
    }
    if (checkOnSubmit) {
      setTitleCheckOnSubmit("checking");
    } else {
      setTitleExists("checking");
    }
    try {
      const response = await new SiteService().checkSiteUniqueTitle({
        site_title: title,
      });
      if (response.data.data.exists) {
        checkOnSubmit
          ? setTitleCheckOnSubmit("exist")
          : setTitleExists("exist");
      } else {
        checkOnSubmit
          ? setTitleCheckOnSubmit("unique")
          : setTitleExists("unique");
      }
      return response.data.data.exists;
    } catch (error) {
      console.error(error);
      message.error({
        content: "Something went wrong please try again!",
      });
    }
  };

  return (
    <React.Fragment>
      <Button type="primary" onClick={showDrawer} className="flexLeftGap">
        Add Site
      </Button>

      <Drawer
        title={<h2>Add Site</h2>}
        className="customDrawer"
        width={720}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        maskClosable={false}
        extra={
          <Button
            onClick={onClose}
            className="closeBlk"
            disabled={isSubmitting}
          >
            Close
          </Button>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          className="drawerContent"
        >
          <div className="blkContent">
            <Form.Item
              name="site_title"
              label="Site Title"
              rules={[
                { required: true, message: "Site title can not be empty" },
              ]}
            >
              <Input
                placeholder="Enter Site Title"
                maxLength={100}
                onBlur={(event) =>
                  handleCheckTitle(event.target.value).catch(console.error)
                }
                onChange={handleTitleChange}
                suffix={uniqueFeedBack(titleExists, titleCheckOnSubmit, {
                  success: "Title is available",
                  error: "Title already taken",
                })}
              />
            </Form.Item>
            <Form.Item
              name="site_url"
              label={
                <>
                  <label className="labelWithIcon">Site URL</label>{" "}
                  <Tooltip
                    placement="right"
                    overlayStyle={{ maxWidth: "300px" }}
                    title={"URL must include the path of publication"}
                    trigger={["hover", "focus"]}
                  >
                    <InfoCircleFilled aria-label="" aria-hidden={true} />
                  </Tooltip>
                </>
              }
              rules={[
                { required: true, message: "Site url can not be empty" },
                {
                  type: "url",
                  message: "Please enter a valid url.",
                },
              ]}
            >
              <Input placeholder="Enter Site URL (www.example.com/publication_path)" />
            </Form.Item>
          </div>
          <div className="blkFooter">
            <Form.Item>
              <Space>
                <Button
                  htmlType="button"
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSubmitting}
                >
                  Add Site
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </React.Fragment>
  );
};

export default OrgCreateSite;
