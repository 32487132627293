import { message } from "antd";
import StageStepContainer from "./StageStepContainer";
import {
  STAGE_STEPS,
  useImporterToolStore,
} from "modules/organization/store/importerToolStore";
import Jurisdictions from "./tables/Jurisdictions";

const StageStepTwo = () => {
  const { dataList, setCurrentStep, setDataSubmission, setFinishStep } =
    useImporterToolStore();

  const onFinish = async () => {
    setDataSubmission(true);
    try {
      setDataSubmission(false);
      setFinishStep(STAGE_STEPS.STEP_2);
      // setCurrentStep(STAGE_STEPS.STEP_3);
      console.log(dataList)
    } catch (error) {
      console.error(error);
      setDataSubmission(false);
      message.error("Some thing went wrong!");
    }
    return;
  };

  return (
    <div className="importerContainer">
      <div className="publishRightContent">
        <StageStepContainer onFinish={onFinish}>
          <Jurisdictions/>
        </StageStepContainer>
      </div>
    </div>
  );
};

export default StageStepTwo;
