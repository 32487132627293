import { create } from 'zustand';
import {
    IGroupCard,
    IGroupDetails,
    IProjectListData,
    IProjectRoleMasterData,
    IResponseGroupDetails,
    IResponseProjectListData,
    IResponseProjectRoleMasterData,
    IToastMessages} from '../models/interface';
import {
    IOrganizationUser,
    IResponseOrganizationUserData
} from '../models/interface/organization.interface';
import { GroupService, OrganizationService, ProjectListService } from '../services';
import { GroupCardMenuKeys } from '../models/enums/menuKey.enums';
import { IHttpError } from 'models/interface';

export interface GroupDrawerOptions {
    title: string;
    submitBtnLabel: string;
    key: number;
    data: null | IGroupCard;
    toastMessage: null | IToastMessages;
}

interface GroupListState {
    groupProjectList: IProjectListData[] | null;
    groupDrawerState: boolean;
    groupDrawerOptions: GroupDrawerOptions;
    groupsLoader: boolean;
    dataFetchError: IHttpError | null;
    groups: IGroupCard[] | null;
    groupDetails: IGroupDetails | null;
    formLoader: boolean;
    groupUserList: IOrganizationUser[] | null;
    showConfirmDelete: boolean;
    groupInfo: IGroupCard | null;

    resetDrawerState: () => void;
    setGroupProjectList: (tags: IProjectListData[] | null) => void;
    setGroupUserList: (users: IOrganizationUser[] | null) => void;
    setGroupDrawerState: (state: boolean) => void;
    setGroupDrawerOptions: (key?: number, group?: IGroupCard) => void;
    getGroupDrawerDetails: (roleList: IProjectRoleMasterData[] | null, groupId: number | undefined) => Promise<void>;
    setShowConfirmDelete: (value: boolean) => void;
    setGroupInfo: (group: IGroupCard) => void;
    setFormLoader: (state: boolean) => void;
    refreshGroupList: () => Promise<void>;
}

const useStore = create<GroupListState>((set) => ({
    // states
    groupProjectList: null,
    groupDrawerState: false,
    groupDrawerOptions: {
        title: 'Create New Group',
        submitBtnLabel: 'Create New Group',
        key: 0,
        data: null,
        toastMessage: null
    },
    groupsLoader: true,
    groups: null,
    dataFetchError: null,
    groupDetails: null,
    formLoader: false,
    groupUserList: [],
    showConfirmDelete: false,
    groupInfo: null,

    //actions
    resetDrawerState: () => {
        set(() => ({
            groupProjectList: null,
            groupDrawerOptions: {
                title: 'Create New Group',
                submitBtnLabel: 'Create New Group',
                key: 0,
                data: null,
                toastMessage: null
            },
            groupDetails: null,
        }));
    },
    setGroupProjectList: (project_ids) => {
        set(() => ({
            groupProjectList: project_ids ?? []
        }));
    },
    setGroupUserList: (users) => {
        set(() => ({
            groupUserList: users ?? []
        }));
    },
    setGroupDrawerState: (state) => {
        set(() => ({
            groupDrawerState: state
        }));
    },
    setGroupDrawerOptions: (key, group) => {
        const titles: any = {
            0: 'Create New Group'
        };

        titles[GroupCardMenuKeys.EDIT_GROUP] = `Manage ${group?.name}`;

        const toastMessages: any = {
            0: {
                loading: 'Creating group...',
                success: 'Group created successfully!',
                error: 'Group creation failed. Please try again!'
            }
        };

        toastMessages[GroupCardMenuKeys.EDIT_GROUP] = {
            loading: 'Updating group...',
            success: 'Group updated successfully!',
            error: 'Error in updating group. Please try again!'
        };

        set(() => ({
            groupDrawerOptions: {
                key: key ?? 0,
                title: titles[key ?? 0],
                submitBtnLabel: key ? 'Save Changes' : 'Create New Group',
                data: group ?? null,
                toastMessage: toastMessages[key ?? 0]
            }
        }));
    },
    setFormLoader: (state) => {
        set(() => ({
            formLoader: state
        }));
    },
    setGroupInfo: (group) => {
        set(() => ({
          groupInfo: group,
        }));
    },
    setShowConfirmDelete: (state) => {
        set(() => ({
          showConfirmDelete: state,
        }));
    },
    getGroupDrawerDetails: async (roleList, groupId) => {
        set(() => ({ formLoader: true }));
        const userListResult = new OrganizationService().getOrganizationUserList();
        const projectListResult = new GroupService().getProjectListData();
        const projectRoleResult = !roleList
            ? new ProjectListService().getProjectRoleMasterData({ show_all: false })
            : Promise.resolve(null);
        const groupDetailsResult = groupId
            ? new GroupService().getGroupDetailsData(groupId)
            : Promise.resolve(null);
        Promise.all([
            userListResult,
            projectListResult,
            projectRoleResult,
            groupDetailsResult,
        ])
            .then((responses) => {
                // Handle the responses of all API calls
                const dataPromises = responses.map((response) => response?.data.data);
                return dataPromises;
            })
            .then((data) => {
                let updatedState: any = {
                    groupUserList: (data[0] as IResponseOrganizationUserData)?.userList,
                    groupProjectList: (data[1] as IResponseProjectListData)?.projects,
                    groupDetails: (data[3] as IResponseGroupDetails)?.group_details,
                };
                if (!roleList) {
                    updatedState['projectRoleData'] = (
                        data[2] as IResponseProjectRoleMasterData
                    )?.roleList;
                }
                set(() => ({
                    ...updatedState,
                    formLoader: false
                }));
            })
            .catch((error) => {
                set(() => ({ groupDrawerState: false }));
            });
    },
    refreshGroupList: async () => {
        set(() => ({ groupsLoader: true }));
        try {
            const { data } = await new GroupService().getGroupListData();
            set(() => ({
                groups: data.data.groups ? [...data.data.groups] : [],
                groupsLoader: false,
                dataFetchError: null
            }));
        } catch (error: any) {
            set(() => ({ dataFetchError: error, groupsLoader: false }));
            console.error(error);
        }
    }
}));

export const useGroupStore = useStore;
