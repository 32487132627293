import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Table, Select, MenuProps, PaginationProps, message, DatePicker, Button, Form } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { SorterResult } from "antd/es/table/interface";
import { RBAC, useRbac } from "auth/rbac/rbac";
import { IRequestOrgUserListData, IOrganizationUser, IRequestOrgUserChangeStatus } from "../../models/interface";
import { IRoleMasterData } from "../../../../models/interface";
import { OrganizationService, OrgManageUserService } from "../../services";
import Paginator from "modules/shared/components/Paginator";
import Search from "antd/es/input/Search";
import { UserListMenuKeys } from "modules/organization/models/enums/menuKey.enums";
import CustomDropdownMenu from "modules/shared/components/CustomDropdownMenu";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import {
    UserOutlined,
    DeleteOutlined,
    EditOutlined,
    MoreOutlined,
    DownloadOutlined,
    ExclamationCircleFilled,
    CloseCircleFilled
} from "@ant-design/icons";
import { filterKebabMenuItems } from 'modules/organization/utility/commonFunctions';
import { dateFormat, pageSizeConfigs } from "config/commonConfig";
import { toTitleCase, downloadBufferAsFile } from "modules/shared/utility";
import Modal from "antd/es/modal/Modal";
import OrgUserEdit from "../components/OrgUserEdit";
import { mnDate } from 'modules/shared/services';
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";

const _menuItems = [
    {
        label: "View User",
        key: UserListMenuKeys.VIEW_USER,
        icon: <UserOutlined aria-hidden />,
        allowedPermissions: [ERbacPermissions.ORG_MANAGE_USER_VIEW]
    },
    {
        label: "Edit User",
        key: UserListMenuKeys.EDIT_USER,
        icon: <EditOutlined aria-hidden />,
        allowedPermissions: [ERbacPermissions.ORG_MANAGE_USER_EDIT]
    },
    {
        label: "Delete",
        key: UserListMenuKeys.DELETE_USER,
        icon: <DeleteOutlined aria-hidden />,
        danger: true,
        divider: true,
        allowedPermissions: [ERbacPermissions.ORG_MANAGE_USER_DELETE]
    },
];
const { RangePicker } = DatePicker;

const OrgUserList: React.FC<{
    roleList: {
        isLoading: boolean;
        data: IRoleMasterData[];
    },
    refreshList: boolean,
    setLoadingLimit: Function,
}> = ({ roleList, refreshList, setLoadingLimit }) => {
    const { hasPermissions, userDetails } = useRbac();
    const baseUrl = window.location.origin;
    const [messageApi, contextHolder] = message.useMessage();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState<any>({
        showConfirm: false,
        userId: null,
        fullName: '',
        email: '',
        isSiteAdmin: false,
    });

    const manageUserListColumns: ColumnsType<IOrganizationUser> = [
        {
            title: "Full Name",
            dataIndex: "full_name",
            sorter: true,
            defaultSortOrder: "ascend",
            render: (data, record) => {

                return <div className="userCol">
                    <span>{data}</span>
                    {
                        record.user_status_id === -1 &&
                        <button disabled={sendingInvite} onClick={() => resendInvite(record)} className="linkBtn">Resend Invite</button>
                    }
                </div>;
            },
        },
        {
            title: "User Name",
            dataIndex: "user_name",
            sorter: true,
            render: (data) => {
                return <>{data}</>;
            },
        },
        {
            title: "Email Address",
            dataIndex: "email",
            sorter: true,
            render: (data) => {
                return <>{data}</>;
            },
        },
        {
            title: "Role",
            dataIndex: "role_name",
            width: 210,
            sorter: true,
            render: (data) => {
                return <>{data}</>;
            },
        },
        {
            title: "Created On",
            dataIndex: "created_on",
            width: 120,
            sorter: true,
            render: (data) => {
                return <>{mnDate(data).format(dateFormat.tableDate)}</>;
            },
        },
        {
            title: "Last Login",
            dataIndex: "logged_in",
            width: 180,
            sorter: true,
            render: (data) => {
                return <>{data ? mnDate(data).format(dateFormat.tableDateTime) : '-'}</>;
            },
        },
        {
            title: "",
            dataIndex: "id",
            width: 50,
            align: "right",
            render: (_data, record) => {

                const onSelect = (e: any) => {
                    if (UserListMenuKeys.DELETE_USER === parseInt(e.key)) {
                        setShowDeleteConfirm({
                            showConfirm: true,
                            userId: _data,
                            fullName: record.full_name,
                            email: record.email,
                            isSiteAdmin: record.role_name === "Site Administrator"
                        });
                    }

                    if (UserListMenuKeys.EDIT_USER === parseInt(e.key)) {
                        setSelectedUser(record);
                    }

                };

                const listActionItemsList: MenuProps["items"] = filterKebabMenuItems(_menuItems, hasPermissions);

                return (
                    <Fragment>
                        {record.id !== userDetails?.id && listActionItemsList.some((item) => item?.key) ? (
                            <CustomDropdownMenu menuItems={listActionItemsList} onSelect={onSelect}>
                                <MoreOutlined />
                            </CustomDropdownMenu>
                        ) : (
                            <span />
                        )}
                    </Fragment>
                );

            },
        }
    ];

    const [showTableLoader, setShowTableLoader] = useState<boolean>(false);
    const [manageUserListDataSource, setManageUserListDataSource] = useState<IOrganizationUser[]>([]);
    const [userCount, setUserCount] = useState<number>(0);
    const [searchValue, setSearchValue] = useState("");
    const [role, setRole] = useState<any>(null);
    const [sendingInvite, setSendingInvite] = useState<boolean>(false);
    const [isDataExporting, setIsDataExporting] = useState<boolean>(false);
    const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);
    const headingRef = useRef<HTMLDivElement>(null);
    const {setPromptType,setPromptText} = usePromptText();
    const [queryParams, setQueryParams] = useState<IRequestOrgUserListData>({
        page_size: pageSizeConfigs.DEFAULT_PAGE_SIZE,
        page_index: 1,
        sort_column: 'full_name',
        sort_order: 'asc',
        is_downloadable: false
    });
    const [selectedUser, setSelectedUser] = useState<IOrganizationUser>({
        id: '',
        email: '',
        full_name: '',
        user_name: '',
        role_id: ''
    });

    const dbQuery = async (params: IRequestOrgUserListData) => {
        return await new OrganizationService().getOrganizationUserListData(params);
    }

    const getUsers = useCallback(async () => {
        try {
            setShowTableLoader(true);
            setLoadingLimit(true);
            let response = await dbQuery(queryParams);
            let userList = response.data.data.count > 0 ? response.data.data.userList : [];
            let count = response.data.data.count;
            setManageUserListDataSource(userList);
            setUserCount(count);
            return response.data.data;
        } catch (error) {
            console.log(error);
            await message.error({
                content: "Something went wrong please try again!",
            });
        } finally {
            setShowTableLoader(false);
        }
    }, [queryParams, setLoadingLimit]);

    useEffect(() => {
        getUsers();
    }, [queryParams, getUsers]);

    useEffect(() => {
        if (refreshList) {
            setQueryParams({
                ...queryParams,
                page_index: 1
            })
        }
    }, [refreshList, queryParams]);


    const getDownloadData = async () => {
        try {
            let downloadParams: IRequestOrgUserListData = {
                sort_column: queryParams.sort_column,
                sort_order: queryParams.sort_order,
                search: queryParams.search ? queryParams.search : '',
                role: queryParams.role ? queryParams.role : '',
                createdOn_start: queryParams.createdOn_start ? queryParams.createdOn_start : '',
                createdOn_end: queryParams.createdOn_end ? queryParams.createdOn_end : '',
                is_downloadable: true
            }
            setIsDataExporting(true);
            let response = await dbQuery(downloadParams)
            let userList = response.data.data.count > 0 ? response.data.data.excelBufferData : null;
            return userList;
        } catch (error) {
            console.log(error);
            return [];
        } finally {
            setIsDataExporting(false);
        }

    }


    const onChange: TableProps<IOrganizationUser>["onChange"] = (
        _pagination,
        _filters,
        sorter
    ) => {
        if (sorter) {
            const { field, order } = sorter as SorterResult<IOrganizationUser>;
            field && setQueryParams({
                ...queryParams,
                sort_column: field.toString(),
                sort_order: order === "ascend" ? "asc" : "desc"
            });
        }
    };

    const setCurrentPageSize = (pageSize: number) => {
        setQueryParams({
            ...queryParams,
            page_size: pageSize,
            page_index: 1
        });
    }

    const setCurrentPage = (page: number) => {
        setQueryParams({
            ...queryParams,
            page_index: page
        });
    }

    const getShowingDataString = () => {
        let currentPage = queryParams.page_index ? queryParams.page_index : 1;
        let currentPageSize = queryParams.page_size ? queryParams.page_size : pageSizeConfigs.DEFAULT_PAGE_SIZE;
        const startIndex = (currentPage - 1) * currentPageSize + 1;
        const endIndex =
            userCount < currentPage * currentPageSize
                ? userCount
                : currentPage * currentPageSize;
        return `Showing ${startIndex} to ${endIndex} of ${userCount} Items`;
    };

    const paginationRender: PaginationProps["itemRender"] = (
        _,
        type,
        originalElement
    ) => {
        if (type === "prev") {
            return <span>Previous</span>;
        }
        if (type === "next") {
            return <span>Next</span>;
        }
        return originalElement;
    };

    const handleChange = (event: any) => {
        setSearchValue(event.target.value);
        if (event.target.value === "") {
            setSearch("");
        }
    }

    const setSearch = (val: any) => {
        setQueryParams({
            ...queryParams,
            search: val,
            page_index: 1
        });
    }

    const handleRoleChange = (val: any) => {
        if (val) {
            setQueryParams({
                ...queryParams,
                role: val,
                page_index: 1
            });
            setRole(val);
        } else {
            setQueryParams({
                ...queryParams,
                role: null,
                page_index: 1
            });
            setRole(null);
        }
    }

    const handleChangeCreatedOn = (val: any) => {
        if (val) {
            if (mnDate(val[0]).isValid() && mnDate(val[1].isValid())) {
                let startDate = mnDate(val[0]).startOf('day').format(dateFormat.fileTimestamp);
                let endDate = mnDate(val[1]).endOf('day').format(dateFormat.fileTimestamp);
                setQueryParams({
                    ...queryParams,
                    createdOn_start: startDate,
                    createdOn_end: endDate,
                    page_index: 1
                });
            }
        } else {
            setQueryParams({
                ...queryParams,
                createdOn_start: null,
                createdOn_end: null,
                page_index: 1
            });
        }
    }

    const resendInvite = async (data: any) => {
        setSendingInvite(true);
        let promptText = "Sending invitation...";
        setPromptType("assertive");
        setPromptText(promptText);
        messageApi.open({
            key: "requestingInviteKey",
            type: "loading",
            content: promptText,
            duration: 0,
        });
        let isOperationSuccess = false;
        try {
            await new OrgManageUserService().postResendInvitationRequest(
                data.id,
                data.user_name,
                data.email,
                data.role_id,
                baseUrl
            );
            isOperationSuccess = true;
        } catch (error) {
            console.log(error);
        }
        setSendingInvite(false);
        messageApi.destroy("requestingInviteKey");
        promptText = isOperationSuccess ? "Invitation resent successfully to " + data.email : "Failed to resend invitation to " + data.email;
        setPromptType("assertive");
        setPromptText(promptText);
        messageApi.open({
            type: isOperationSuccess ? "success" : "error",
            content: promptText,
            duration: 5,
        });
    }

    const exportData = async () => {
        const fileName = `User list-${mnDate().format(dateFormat.fileTimestamp)}`;
        let excelBufferData: any = await getDownloadData();
        if (excelBufferData) {
            downloadBufferAsFile(excelBufferData, fileName);
        }
    }

    const onCancelDelete = () => {
        setShowDeleteConfirm({
            showConfirm: false,
            userId: 0,
            fullName: '',
            email: '',
            isSiteAdmin: false,
        })
    }

    const onDeleteUser = async () => {
        let isOperationSuccess = false;
        let promptText = "Deleting user...";
        let params: IRequestOrgUserChangeStatus = {
            userId: showDeleteConfirm.userId,
            userStatus: -3,
            isSiteAdmin: showDeleteConfirm.isSiteAdmin
        }
        setPromptType("assertive");
        setPromptText(promptText);
        messageApi.open({
            key: "requestingDelete",
            type: "loading",
            content: promptText,
            duration: 0,
        });

        try {
            setIsDeleteInProgress(true);
            await new OrganizationService().postOrganizationUserChangeStatus(params);
            isOperationSuccess = true;
        } catch (error) {
            console.log(error);
        }
        setIsDeleteInProgress(false);
        onCancelDelete();
        setQueryParams({
            ...queryParams,
            page_index: 1
        });
        messageApi.destroy("requestingDelete");
        promptText = isOperationSuccess ? "User deleted successfully " : "Unable to delete user";
        setPromptType("assertive");
        setPromptText(promptText);
        messageApi.open({
            type: isOperationSuccess ? "success" : "error",
            content: promptText,
            duration: 5,
        });
    }
    useEffect(()=>{
        if (showDeleteConfirm.showConfirm){
            setTimeout(()=>{
              headingRef.current?.focus();
              headingRef.current?.click();
            },1000)
        }
      }, [showDeleteConfirm.showConfirm])

    return (
      <div className="tableWrapper">
        {contextHolder}
        <div className="tableSettingBlk userTblSetting">
          <Search
            aria-label={"test"}
            placeholder="Search Users"
            allowClear={{
              clearIcon: (
                <button className="clearBtn">
                  <CloseCircleFilled />
                </button>
              ),
            }}
            value={searchValue}
            onSearch={() => setSearch(searchValue)}
            onChange={handleChange}
          />
          <Form.Item label="Role">
            <Select
              showSearch
              allowClear
              value={role}
              placeholder="Select Role"
              optionFilterProp="children"
              onSelect={(value) => {
                handleRoleChange(value);
              }}
              onClear={() => handleRoleChange(null)}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={(roleList.data ?? []).map((eachRole) => ({
                value: eachRole.name,
                label: toTitleCase(eachRole.name),
              }))}
              disabled={showTableLoader || roleList.isLoading}
            />
          </Form.Item>
          <div className="tblHeadingFldWrap">
            <Form.Item label="Created On">
              <RangePicker
                format={dateFormat.default}
                onChange={handleChangeCreatedOn}
                disabledDate={(current) =>
                    mnDate().add(-1, "days") <= current && mnDate().date < current
                }
              />
            </Form.Item>
          </div>
          {userCount > 0 && (
            <Button
              onClick={exportData}
              loading={isDataExporting}
              icon={<DownloadOutlined />}
              className="flexLeftGap"
            >
              Export Data
            </Button>
          )}
        </div>
        <div className="mainTable">
          <Table
            columns={manageUserListColumns}
            dataSource={manageUserListDataSource}
            onChange={onChange}
            pagination={false}
            // scroll={{
            //   y: window.innerHeight - 330,
            //   scrollToFirstRowOnChange: true,
            // }}
            className="scrollableTable userListTbl"
            loading={showTableLoader}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend", "ascend"]}
            rowKey={(record) => record.id}
          />
        </div>
        {!showTableLoader && (
          <Paginator
            currentPageSize={
              queryParams.page_size
                ? queryParams.page_size
                : pageSizeConfigs.DEFAULT_PAGE_SIZE
            }
            count={userCount}
            setCurrentPageSize={setCurrentPageSize}
            setCurrentPage={setCurrentPage}
            getShowingDataString={getShowingDataString}
            paginationRender={paginationRender}
            currentPage={queryParams.page_index ? queryParams.page_index : 1}
          />
        )}
        <Modal
          className="confirmationModal"
          title={<>Delete User</>}
          centered
          open={showDeleteConfirm.showConfirm}
          onCancel={onCancelDelete}
          footer={[
            <Button
              key="yes"
              disabled={isDeleteInProgress}
              onClick={onCancelDelete}
            >
              Cancel
            </Button>,
            <Button
              key="no"
              loading={isDeleteInProgress}
              disabled={isDeleteInProgress}
              type="primary"
              onClick={onDeleteUser}
            >
              Delete User
            </Button>,
          ]}
        >
          <div className="notificationTxtWrap">
            <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
            <div className="notificationTxt" tabIndex={-1} ref={headingRef}>
              <p>{`Are you sure you want to remove the user from this organization?`}</p>
              <p>
                <b>
                  {showDeleteConfirm.fullName} ({showDeleteConfirm.email})
                </b>
              </p>
              {showDeleteConfirm.isSiteAdmin && <p>The user will be removed from all the associated sites.</p>}
              <p style={{ fontStyle: "italic" }}>
                * This process cannot be undone
              </p>
            </div>
          </div>
        </Modal>

        <RBAC allowedPermissions={[ERbacPermissions.ORG_MANAGE_USER_EDIT]}>
          <OrgUserEdit
            roleList={roleList}
            orgUser={selectedUser}
            setOrgUser={setSelectedUser}
            setRefreshList={() => {
              setQueryParams({
                ...queryParams,
              });
            }}
          />
        </RBAC>
      </div>
    );

};

export default OrgUserList;