import loadingImg from "../../../assets/images/loader.svg";

const PageLoader = () => {
  return (
    <div className="fullLoader active">
      <div className="loadAnimation">
        <img src={loadingImg} alt="loading" />
      </div>
      <div className="loadingTxt">Loading</div>
    </div>
  );
};

export default PageLoader;
