import React, { useCallback, useEffect } from "react";
import { useSiteStore } from "modules/organization/store/siteStore";
import OrgSiteProjectList from "modules/organization/orgSite/components/OrgSiteProjectList";

const SiteProjectTab: React.FC = () => {
  const { site, siteInfo,isFirstLoad, setSite } = useSiteStore();
  const setSiteId = useCallback(() => {
    if (siteInfo && isFirstLoad){
        setSite(siteInfo.id);
    } 
  }, [siteInfo,setSite,isFirstLoad]);

  useEffect(() => {
    setSiteId();
  }, [setSiteId]);

  return (
    <div className="tabContent">
        {site && <OrgSiteProjectList isFromSiteDetails={true} />}
    </div>
  );
};

export default SiteProjectTab;
