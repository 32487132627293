import { FC, Fragment, useRef, useState } from "react";
import {
  Button,
  Tooltip,
  Modal,
  message,
} from "antd";
import { CodepenOutlined } from "@ant-design/icons";
import { ISiteProjectListData } from "modules/organization/models/interface";
import TextArea from "antd/es/input/TextArea";
import { appConfig } from "config/config";
import { copyToClipboard } from "modules/shared/utility";

const OrgGetSiteProjectIframeModal: FC<{ siteProjectData: ISiteProjectListData }> = ({
  siteProjectData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  const getIframeHtml = () =>{
    return `<iframe id="iframe" width="100%" frameborder="0" scrolling="yes" referrerpolicy="origin" src="${appConfig.REACT_APP_IFRAME_BASE_URL}/project/${siteProjectData.url_slug}?token=${encodeURIComponent(siteProjectData.iframe_token ?? '')}" style="height:900px;width:100%;border:none;"></iframe>`;
  }

  const handleCopyClick = () => {
    copyToClipboard(getIframeHtml())
      .then(() => {
        message.success('Iframe copied successfully');
      })
      .catch(() => {
        message.error('Failed to copy Iframe');
      });
  };

  const showTooltipText = () => {

    if(!siteProjectData.iframe_token){
      return "Organization token and site token is required to get iframe.";
    }
    return "Show Iframe";
  }

  return (
    <Fragment>
      <div className="passcodeCol flexJCenter">
        <Tooltip
          title={showTooltipText()}
          placement="right"
          trigger={["hover", "focus"]}
        >
          <Button
            onClick={() => {
              setShowModal(true);
              btnRef.current?.blur();
            }}
            aria-label="Show iframe"
            className="actionBtn getIframeButton"
            disabled={!siteProjectData.iframe_token}
            ref={btnRef}
          >
            <CodepenOutlined className="activeIcon" style={{marginLeft: 0}} />
          </Button>
        </Tooltip>
      </div>
      <Modal
        className="confirmationModal"
        width={1000}
        destroyOnClose
        title={<>Iframe for {siteProjectData.site_name} site</>}
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
          key="copy"
          type="primary"
          onClick={() => {
            handleCopyClick();
          }}
        >
          Copy
        </Button>,
          
        ]}
      >
        
          <div className="modalContent alignLeft">
            <div className="displayFlex">
              <label>Project :</label>
              <p>&nbsp;{siteProjectData.title}</p>
            </div>
              <div style={{ display: "flex" }}>
                  
                  <TextArea
                    readOnly
                    disabled
                    aria-disabled
                    value={getIframeHtml()}
                    autoSize={{ minRows: 2, maxRows: 10 }}
                  />
                </div>
          </div>
      </Modal>
    </Fragment>
  );
};

export default OrgGetSiteProjectIframeModal;
