import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IRequestEditSettings, IResponseGetSettings } from '../models/interface';

export class AppSettingsService {
  public getAppSettings = async () => {
    return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseGetSettings>>(RoutesEnum.APP_GET_SETTINGS, {});
  };

  public editAppSettings = async (payload: IRequestEditSettings) => {
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(RoutesEnum.APP_EDIT_SETTINGS, payload);
  };

  public applySettingsForAll = async (payload: { type_id: number }) => {
    return (await new ApiService().privateClient()).post<IApiBaseResponse>(RoutesEnum.APP_APPLY_SETTINGS_FOR_ALL, payload);
  };

  public acceptOrganization = async (payload: { org_name: string, org_email: string }) => {
    return (await new ApiService().privateClient()).put<IApiBaseResponse>(RoutesEnum.APP_ACCEPT_ORGANIZATION_SETTINGS, payload);
  };

  public rejectOrganization = async (payload: { org_name: string, org_email: string }) => {
    return (await new ApiService().privateClient()).patch<IApiBaseResponse>(RoutesEnum.APP_REJECT_ORGANIZATION_SETTINGS, payload);
  };
}
