import { Button, Form } from "antd";
import { useProjectDetailsStore } from "modules/organization/store";
import {
  TImportingKey,
  useImporterToolStore,
} from "modules/organization/store/importerToolStore";
import Paginator from "modules/shared/components/Paginator";
import React, { Fragment, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";

const StageStepContainer: React.FC<{
  children: ReactNode;
  onFinish: () => void;
}> = ({ children, onFinish }) => {
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { projectDetails } = useProjectDetailsStore();
  const {
    currentStep,
    setCurrentStep,
    dataSubmission,
    currentPageSize,
    setCurrentPageSize,
    dataCount,
    setCurrentPage,
    currentPage,
    validationGlobalErrors
  } = useImporterToolStore();

  const navigate = useNavigate();

  const onCancel = () => {
    if (currentStep.key - 1 < 1) {
      return navigate(
        `${organizationBaseRouteUrl}/project/${projectDetails?.slug}`
      );
    }
    return setCurrentStep((currentStep.key - 1) as TImportingKey);
  };

  return (
    <Fragment>
      {children}
      <div className="rowBlk">
        <div className="colBlk12 gap10">
          <Paginator
            currentPageSize={currentPageSize}
            count={dataCount}
            setCurrentPageSize={setCurrentPageSize}
            setCurrentPage={setCurrentPage}
            getShowingDataString={() => {}}
            paginationRender={(_, type, originalElement) => {
              if (type === "prev") {
                return <span>Previous</span>;
              }
              if (type === "next") {
                return <span>Next</span>;
              }
              return originalElement;
            }}
            currentPage={currentPage}
            showChanger={false}
          />
        </div>
        <div className="colBlk12">
          <div className="btnWrap">
            {currentStep?.backBtnLabel && (
              <Button onClick={onCancel}>{currentStep?.backBtnLabel}</Button>
            )}
            <Button
              type="primary"
              onClick={() => onFinish()}
              disabled={ (Object.keys(validationGlobalErrors).length > 0) || dataSubmission}
            >
              {currentStep?.continueBtnLabel}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StageStepContainer;
