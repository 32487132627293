import React from "react";
import AccessDenied from "./AccessDenied";
import { usePageTitle } from "../hooks";
export const Forbidden: React.FC<{
  isAccessFromPage?: boolean;
}> = ({ isAccessFromPage }) => {
  usePageTitle("MonQcle - Access denied");
  const contain = () => {
    return (
        <AccessDenied isAccessFromPage={isAccessFromPage} />
    );
  };

  if (isAccessFromPage) {
    return <React.Fragment>{contain()}</React.Fragment>;
  } else {
    return contain();
  }
};
