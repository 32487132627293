import { useEffect, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import {
    useGroupStore,
} from "modules/organization/store";
import { GroupService } from "modules/organization/services";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";

const DeleteGroup = () => {

  const { groupInfo, refreshGroupList, showConfirmDelete, setShowConfirmDelete } = useGroupStore();

  const okButtonRef = useRef<HTMLButtonElement>(null);
  const headingRef = useRef<HTMLParagraphElement>(null);

  const {setPromptType,setPromptText} = usePromptText();

  const doDeleteAGroup = async () => {
    const deleteGroupKey = "deleteGroupKey";
    let promptText = "Deleting group..."

    if (groupInfo) {
      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: deleteGroupKey,
        content: promptText,
        type: "loading",
        duration: 0,
      });
      setShowConfirmDelete(false);
      try {
        if (groupInfo) {
          await new GroupService().deleteGroup({
            group_id: groupInfo.group_id
          })
          promptText = "Group deleted successfully";
          message.success(promptText);
          refreshGroupList().catch(console.error);
        }
      } catch (error) {
        console.log(error);
        promptText = "Failed to delete Group"
        message.error(promptText);
      }
      setPromptType("assertive");
      setPromptText(promptText);
    }
    message.destroy(deleteGroupKey);
  };
  useEffect(()=>{
    setTimeout(()=>{
      headingRef.current?.focus();
      headingRef.current?.click();
    },1000)
  }, [])

  return (
    <Modal
      className="confirmationModal"
      title={<>Delete Group</>}
      centered
      open={showConfirmDelete}
      onCancel={() => setShowConfirmDelete(false)}
      footer={[
        <Button key="no" onClick={() => setShowConfirmDelete(false)}>
          Cancel
        </Button>,
        <Button
          key="yes"
          type="primary"
          onClick={doDeleteAGroup}
          ref={okButtonRef}
        >
          Delete Group
        </Button>,
      ]}
    >
      <div className="notificationTxtWrap">
        <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
        <div className="notificationTxt">
          <p tabIndex={-1} ref={headingRef}>Are you sure you want to delete <strong>{groupInfo?.name}</strong>?</p>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteGroup;