import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import ErrorBoundary from "modules/shared/components/ErrorBoundary";
import { usePageTitle } from "modules/shared/hooks";
import { useSiteStore } from "../store/siteStore";
import { Button } from "antd";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { LeftOutlined } from "@ant-design/icons";
import SiteInfo from "./components/SiteInfo";
import PageLoader from "modules/shared/components/PageLoader";
import CustomError from "modules/shared/components/CustomError";
import SiteProjectTab from "./components/SiteProjectTab";
import SiteUserTab from "./components/SiteUserTab";

const OrgSiteDetailsPage = () => {
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const site_id = useParams().site_id ?? "0";
  const { siteInfo, isLoadingSiteInfo, error, refreshSiteInfo, resetState } =
    useSiteStore();
  usePageTitle(`MonQcle - ${siteInfo ? siteInfo.site_title : "Sites"}`);
  const [currentMode, setCurrentMode] = useState("info");

  const fetchSiteDetails = useCallback(async () => {
    refreshSiteInfo({ site_id }).catch(console.error);
  }, [site_id, refreshSiteInfo]);

  useEffect(() => {
    fetchSiteDetails().catch(console.error);
  }, [fetchSiteDetails]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  return (
    <Fragment>
      {isLoadingSiteInfo ? (
        <PageLoader />
      ) : (
        <Fragment>
          {error?.code ? (
            <div>
              {error?.code === 404 && (
                <CustomError errorDetails={error} isAccessFromPage={true} />
              )}
              {error && error.code !== 404 && (
                <CustomError errorDetails={error} />
              )}
            </div>
          ) : (
            <ErrorBoundary>
              <div className="pageTopSection">
                <div className="backLink">
                  <Link to={organizationBaseRouteUrl + "/sites"}>
                    <LeftOutlined /> Back to Site Management
                  </Link>
                </div>
              </div>
              <div className="pageHeadingWrap">
                <h2>{`Manage ${siteInfo?.site_title}`}</h2>
              </div>
              <div className="tabContainer">
                <div
                  className="tabMenu"
                  role="tablist"
                  aria-labelledby="tab-header"
                >
                  <Button
                    id="tab-info"
                    role="tab"
                    aria-selected={currentMode === "info"}
                    aria-controls="tabpanel-info"
                    className={currentMode === "info" ? "active" : ""}
                    onClick={() => {
                      setCurrentMode("info");
                    }}
                  >
                    Site Info
                  </Button>
                  <Button
                    id="tab-project"
                    role="tab"
                    aria-selected={currentMode === "project"}
                    aria-controls="tabpanel-project"
                    className={currentMode === "project" ? "active" : ""}
                    onClick={() => {
                      setCurrentMode("project");
                    }}
                  >
                    Projects
                  </Button>
                  <Button
                    id="tab-user"
                    role="tab"
                    aria-selected={currentMode === "user"}
                    aria-controls="tabpanel-user"
                    className={currentMode === "user" ? "active" : ""}
                    onClick={() => {
                      setCurrentMode("user");
                    }}
                  >
                    Allowed Users
                  </Button>
                </div>
                {<Fragment>{currentMode === "info" && <SiteInfo />}</Fragment>}
                {
                  <Fragment>
                    {currentMode === "project" && <SiteProjectTab />}
                  </Fragment>
                }
                {
                  <Fragment>
                    {currentMode === "user" && <SiteUserTab />}
                  </Fragment>
                }
              </div>
            </ErrorBoundary>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default withRbac(OrgSiteDetailsPage, {
  allowedPermissions: [
    ERbacPermissions.ORG_SITE_LIST_ALL,
    ERbacPermissions.ORG_SITE_LIST_ASSIGNED,
  ],
  isAccessFromPage: true,
});
