import { create } from 'zustand';
import { IOrganizationUser, IProjectListData, IRequestProjectListData } from '../models/interface';
import { pageSizeConfigs } from '../../../config/commonConfig';
import { ProjectListService } from '../services';
interface ProjectListState {
  projectListData: IProjectListData[];
  showCreateProjectLoader: boolean;
  showTableLoader: boolean;
  projectsCount: number;
  currentPageSize: number;
  currentPage: number;
  currentSortColumn: string;
  currentSortOrder: 'asc' | 'desc';
  search: string;
  role: string | null;
  tag: [];
  published: boolean | null;
  member: IOrganizationUser | null;
  group_id: number | null;
  showConfirmDelete: boolean;
  selectedProject: any;

  resetState: () => void;
  setProjectListData: (projects: IProjectListData[] | null) => void;
  setShowCreateProjectLoader: (value: boolean) => void;
  setShowTableLoader: (value: boolean) => void;
  setProjectsCount: (count: number) => void;
  setCurrentPageSize: (pageSize: number) => void;
  setCurrentPage: (page: number) => void;
  setCurrentSortColumn: (column: string) => void;
  setCurrentSortOrder: (order: 'asc' | 'desc') => void;
  setSearch: (search: string) => void;
  setRole: (role: string | null) => void;
  setTag: (tag: []) => void;
  setPublished: (published: boolean | null) => void;
  setMember: (member: IOrganizationUser | null) => void;
  setGroupId: (group_id: number | null) => void;
  setShowConfirmDelete: (value: boolean) => void;
  setSelectedProject: (project: any) => void;
  refreshProjectList: (params: IRequestProjectListData) => Promise<void>;
}

const useStore = create<ProjectListState>((set) => ({
  // state
  projectListData: [],
  showTableLoader: true,
  showCreateProjectLoader: true,
  projectsCount: 0,
  currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
  currentPage: 1,
  currentSortColumn: 'updated_at',
  currentSortOrder: 'desc',
  search: '',
  role: null,
  tag: [],
  published: null,
  member: null,
  group_id: null,
  showConfirmDelete: false,
  selectedProject: null,

  // actions
  resetState: () => {
    set(() => ({
      projectListData: [],
      showTableLoader: true,
      showCreateProjectLoader: true,
      projectsCount: 0,
      currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
      currentPage: 1,
      currentSortColumn: 'updated_at',
      currentSortOrder: 'desc',
      search: '',
      role: null,
      tag: [],
      published: null,
      member: null,
      group_id: null,
      showConfirmDelete: false
    }));
  },
  setProjectListData: (projects) => {
    set(() => ({
      projectListData: projects ?? []
    }));
  },
  setShowCreateProjectLoader: (value) => {
    set(() => ({
      showCreateProjectLoader: value
    }));
  },
  setShowTableLoader: (value) => {
    set(() => ({
      showTableLoader: value
    }));
  },
  setProjectsCount: (count) => {
    set(() => ({
      projectsCount: count
    }));
  },
  setCurrentPageSize: (pageSize) => {
    set(() => ({
      currentPageSize: pageSize,
      currentPage: 1
    }));
  },
  setCurrentPage: (page) => {
    set(() => ({
      currentPage: page
    }));
  },
  setCurrentSortColumn: (column) => {
    set(() => ({
      currentSortColumn: column
    }));
  },
  setCurrentSortOrder: (order) => {
    set(() => ({
      currentSortOrder: order
    }));
  },
  setSearch: (search) => {
    set(() => ({
      search: search,
      role: null,
      tag: [],
      published: null,
      member: null,
      currentPage: 1,
    }));
  },
  setRole: (role) => {
    set(() => ({
      role: role,
      search: '',
      currentPage: 1,
    }));
  },
  setTag: (tag) => {
    set(() => ({
      tag: tag,
      search: '',
      currentPage: 1,
    }));
  },
  setPublished: (published) => {
    set(() => ({
      published: published,
      search: '',
      currentPage: 1,
    }));
  },
  setMember: (member) => {
    set(() => ({
      member: member,
      search: '',
      currentPage: 1,
    }));
  },
  setGroupId: (group_id) => {
    set(() => ({
      group_id: group_id,
      currentPage: 1,
    }));
  },
  setShowConfirmDelete: (state) => {
    set(() => ({
      showConfirmDelete: state,
    }));
  },
  setSelectedProject: (project) => {
    set(() => ({
      selectedProject: project,
    }));
  },

  refreshProjectList: async (params) => {
    set(() => ({ showTableLoader: true, showCreateProjectLoader: true }));
    try {
      const result = await new ProjectListService().getProjectListData(params);
      const { projects, count } = result.data.data;
      set(() => ({
        projectListData: count > 0 ? [...projects] : [],
        projectsCount: count,
        showTableLoader: false
      }));
    } catch (error) {
      set(() => ({ showTableLoader: false, showCreateProjectLoader: false }));
      console.error(error);
    }
  }

}));

export const useProjectListStore = useStore;
