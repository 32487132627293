import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";


export class OrgManageUserService {
    public postInvitationRequest = async (email: string, roleId: string, baseUrl: string) => {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{ email: string }>>(RoutesEnum.ORG_MANAGE_USER_INVITE, {
            email,
            roleId,
            baseUrl
        });
    };
    public getRoleMasterData = async () => {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{ roleList: [{ id: string, name: string }] }>>(RoutesEnum.ORG_ROLE_MASTER_DATA, {});
    };

    public postResendInvitationRequest = async (userId: string, userName: string, email: string, roleId: string, baseUrl: string) => {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{ email: string }>>(RoutesEnum.ORG_MANAGE_USER_RESEND_INVITE, {
            userId,
            userName,
            email,
            roleId,
            baseUrl
        });
    };
}