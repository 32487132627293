import { create } from 'zustand';

import { IHttpError } from 'models/interface';
import { RankingProjectService } from '../services/rankingProject.service';
import { IRankingTemplateCard } from '../models/interface/rankingSetup.interface';
import { IToastMessages } from '../models/interface';
import { RankingCardMenuKeys } from '../models/enums';

export interface RankingTemplateDrawerOptions {
    title: string;
    submitBtnLabel: string;
    key: number;
    data: null | IRankingTemplateCard;
    toastMessage: null | IToastMessages;
}

interface RankingTemplateListState {
    rankingTemplateDrawerState: boolean;
    rankingTemplateDrawerOptions: RankingTemplateDrawerOptions;
    rankingTemplatesLoader: boolean;
    dataFetchError: IHttpError | null;
    rankingTemplates: IRankingTemplateCard[] | null;
    rankingTemplateInfo: IRankingTemplateCard | null;
    showConfirmDelete: boolean;
    formLoader: boolean;
 
    resetDrawerState: () => void;
    setRankingTemplateDrawerState: (state: boolean) => void;
    setRankingTemplateDrawerOptions: (key?: number, rank?: IRankingTemplateCard) => void;
    setRankingTemplateInfo: (rankingTemplate: IRankingTemplateCard) => void;
    setShowConfirmDelete: (value: boolean) => void;
    setFormLoader: (state: boolean) => void;
    refreshRankingTemplateList: () => Promise<void>;
}

const useStore = create<RankingTemplateListState>((set) => ({
    // states
    rankingTemplateDrawerState: false,
    rankingTemplateDrawerOptions: {
        title: 'Create New Template',
        submitBtnLabel: 'Create New Template',
        key: 0,
        data: null,
        toastMessage: null
    },
    rankingTemplatesLoader: true,
    rankingTemplates: null,
    rankingTemplateDetails: null,
    dataFetchError: null,
    showConfirmDelete: false,
    rankingTemplateInfo: null,
    formLoader: false,

    //actions
       
    resetDrawerState: () => {
        set(() => ({
            rankingTemplateDrawerOptions: {
                title: 'Create New Template',
                submitBtnLabel: 'Create New Template',
                key: 0,
                data: null,
                toastMessage: null
            },
            rankingTemplateInfo: null,
        }));
    },
    setRankingTemplateDrawerState: (state) => {
        set(() => ({
            rankingTemplateDrawerState: state
        }));
    },
    setRankingTemplateDrawerOptions: (key, rankingTemplate) => {        
        const titles: any = {
            0: 'Create New Template'
        };

        titles[RankingCardMenuKeys.EDIT_RANKING_TEMPLATE] = `Manage ${rankingTemplate?.title}`;

        const toastMessages: any = {
            0: {
                loading: 'Creating ranking template...',
                success: 'Ranking template created successfully!',
                error: 'Ranking template creation failed. Please try again!'
            }
        };

        toastMessages[RankingCardMenuKeys.EDIT_RANKING_TEMPLATE] = {
            loading: 'Updating ranking template...',
            success: 'Ranking template updated successfully!',
            error: 'Error in updating ranking template. Please try again!'
        };

        set(() => ({
            rankingTemplateDrawerOptions: {
                key: key ?? 0,
                title: titles[key ?? 0],
                submitBtnLabel: key ? 'Save Changes' : 'Create New Template',
                data: rankingTemplate ?? null,
                toastMessage: toastMessages[key ?? 0]
            }
        }));
    },
    setFormLoader: (state) => {
        set(() => ({
            formLoader: state
        }));
    },

    setRankingTemplateInfo: (rankingTemplate) => {
        set(() => ({
            rankingTemplateInfo: rankingTemplate,
        }));
    },
    setShowConfirmDelete: (state) => {
        set(() => ({
          showConfirmDelete: state,
        }));
    },

    refreshRankingTemplateList: async () => {
        set(() => ({ rankingTemplatesLoader: true }));
        try {
            const { data } = await new RankingProjectService().getRankingTemplates();
            set(() => ({
                rankingTemplates: data.data.ranking_templates ? [...data.data.ranking_templates] : [],
                rankingTemplatesLoader: false,
                dataFetchError: null
            }));
        } catch (error: any) {
            set(() => ({ dataFetchError: error, rankingTemplatesLoader: false }));
        }
    }
}));

export const useRankingSetupStore = useStore;
