import { useEffect, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";
import { useRankingSetupStore } from "modules/organization/store/rankingSetupStore";
import { RankingProjectService } from "modules/organization/services/rankingProject.service";

const DeleteRankingTemplate = () => {
const rankingTemplateInfo = useRankingSetupStore(state => state.rankingTemplateInfo);
const refreshRankingTemplateList = useRankingSetupStore(state => state.refreshRankingTemplateList);
const showConfirmDelete = useRankingSetupStore(state => state.showConfirmDelete);
const setShowConfirmDelete = useRankingSetupStore(state => state.setShowConfirmDelete);
const resetDrawerState = useRankingSetupStore(state => state.resetDrawerState);

  const okButtonRef = useRef<HTMLButtonElement>(null);
  const headingRef = useRef<HTMLParagraphElement>(null);

  const {setPromptType,setPromptText} = usePromptText();

  const doDeleteATemplate = async () => {
    const deleteTemplateKey = "deleteTemplateKey";
    let promptText = "Deleting template..."

    if (rankingTemplateInfo) {
      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: deleteTemplateKey,
        content: promptText,
        type: "loading",
        duration: 0,
      });
      setShowConfirmDelete(false);
      try {
        if (rankingTemplateInfo) {
          await new RankingProjectService().deleteRankingTemplate({
            id: rankingTemplateInfo.id
          })
          promptText = "Template deleted successfully";
          message.success(promptText);
          resetDrawerState();
          refreshRankingTemplateList().catch(console.error);
        }
      } catch (error) {
        console.log(error);
        promptText = "Failed to delete Template"
        message.error(promptText);
      }
      setPromptType("assertive");
      setPromptText(promptText);
    }
    message.destroy(deleteTemplateKey);
  };
  useEffect(()=>{
    setTimeout(()=>{
      headingRef.current?.focus();
      headingRef.current?.click();
    },1000)
  }, [])

  return (
    <Modal
      className="confirmationModal"
      title={<>Delete Template</>}
      centered
      open={showConfirmDelete}
      destroyOnClose
      onCancel={() =>  {setShowConfirmDelete(false); resetDrawerState()}}
      footer={[
        <Button key="no" onClick={() => {setShowConfirmDelete(false); resetDrawerState()}}>
          Cancel
        </Button>,
        <Button
          key="yes"
          type="primary"
          onClick={doDeleteATemplate}
          ref={okButtonRef}
        >
          Delete Template
        </Button>,
      ]}
    >
      <div className="notificationTxtWrap">
        <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
        <div className="notificationTxt">
          <p tabIndex={-1} ref={headingRef}>Are you sure you want to delete <strong>{rankingTemplateInfo?.title}</strong>?</p>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRankingTemplate;