import { RBAC, withRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import { usePageTitle } from 'modules/shared/hooks';
import { Fragment } from 'react';
import RankingCardList from './components/RankingCardList';
import OrgCreateNewRankingTemplate from './components/OrgCreateNewRankingTemplate';

const OrgRankingSetupPage = () => {
  usePageTitle('MonQcle - Ranking Setup');
  return (
    <Fragment>
      <div className="pageHeadingWrap">
        <h2>Ranking Setup</h2>
        <RBAC allowedPermissions={[ERbacPermissions.ORG_RANKING_SETUP_CRUD]}>
          <ErrorBoundary>
            <OrgCreateNewRankingTemplate createButton={{ label: 'Create Template' }} />
          </ErrorBoundary>
        </RBAC>
      </div>
      <ErrorBoundary>
         <RankingCardList />
      </ErrorBoundary>
    </Fragment>
  );
};

export default withRbac(OrgRankingSetupPage, { allowedPermissions: [ERbacPermissions.ORG_RANKING_SETUP], isAccessFromPage: true });