
function erf(x: number): number {
  const cofficient: number[] = [
    -1.3026537197817094, 6.4196979235649026e-1, 1.9476473204185836e-2,
    -9.561514786808631e-3, -9.46595344482036e-4, 3.66839497852761e-4,
    4.2523324806907e-5, -2.0278578112534e-5, -1.624290004647e-6,
    1.30365583558e-6, 1.5626441722e-8, -8.5238095915e-8, 6.529054439e-9,
    5.059343495e-9, -9.91364156e-10, -2.27365122e-10, 9.6467911e-11,
    2.394038e-12, -6.886027e-12, 8.94487e-13, 3.13092e-13, -1.12708e-13,
    3.81e-16, 7.106e-15, -1.523e-15, -9.4e-17, 1.21e-16, -2.8e-17
  ];

  let j = cofficient.length - 1,
    isneg = false,
    d = 0,
    dd = 0;
  let tmp;

  if (x < 0) {
    x = -x;
    isneg = true;
  }

  const t = 2 / (2 + x);
  const ty = 4 * t - 2;

  for (; j > 0; j--) {
    tmp = d;
    d = ty * d - dd + cofficient[j];
    dd = tmp;
  }
  const res = t * Math.exp(-x * x + 0.5 * (cofficient[0] + ty * d) - dd);
  return isneg ? res - 1 : 1 - res;
}

function erfc(x: number): number {
  return 1 - erf(x);
}

function erfcinv(p: number): number {
  let j = 0;
  let x: number, err: number, t: number, pp: number;
  
  if (p >= 2)
    return -100;
  if (p <= 0)
    return 100;
  
  pp = (p < 1) ? p : 2 - p;
  t = Math.sqrt(-2 * Math.log(pp / 2));
  x = -0.70711 * ((2.30753 + t * 0.27061) /
                  (1 + t * (0.99229 + t * 0.04481)) - t);
  
  for (; j < 2; j++) {
    err = erfc(x) - pp;
    x += err / (1.12837916709551257 * Math.exp(-x * x) - x * err);
  }
  
  return (p < 1) ? x : -x;
}

function inv(p: number, mean: number, std: number): number {
  return -1.41421356237309505 * std * erfcinv(2 * p) + mean;
}

/**
 * Calculates zscore
 * @param confidenceLevel 
 * @returns zscore 
 */
function calculateZScore(confidenceLevel: number): number {
  const alpha: number = 1 - confidenceLevel;
  const zScore: number = inv(1 - alpha / 2, 0, 1);
  return zScore;
}

/**
 * Calculates sample size
 * @param margin 
 * @param confidence 
 * @param response 
 * @param population 
 * @returns sample size 
 */
export function calculateSampleSize(margin: number, confidence: number, response: number, population: number): number {
  const pcn: number = calculateZScore(confidence / 100);
  const d1: number = pcn * pcn * response * (100 - response);
  const d2: number = (population - 1) * (margin * margin) + d1;
  
  if (d2 > 0) {
    return Math.ceil((population * d1) / d2);
  } else {
    return 0;
  }
}

