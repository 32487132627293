import { RoutesEnum } from "models/enums";
import { URLService } from "./url.service";
import { IApiBaseResponse } from "models/interface";
import { IResponseOrgAssociatedSiteUser } from "../models/interface";
import { ApiService } from "modules/shared/services";

export class ProjectImporterToolService {

    public async getImporterToolQuestion(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_QUESTION);
        return (await new ApiService().privateClient()).get<IApiBaseResponse>(updatedURL)
    }

    public async getImporterToolJurisdiction(project_slug: string) {
        const { updatedURL } = new URLService({}, project_slug).formatURL(RoutesEnum.ORG_PROJECT_IMPORTER_TOOL_JURISDICTION );
        return (await new ApiService().privateClient()).get<IApiBaseResponse>(updatedURL, {  })
    }
}
