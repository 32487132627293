import { create } from 'zustand';
import { ProjectPublishService } from '../services/projectPublish.service';
import { IFileUpload, IPublishInfoOfSite, IPublishingSite, IUploadOtherFiles } from '../models/interface';

export type TPublicationKey = typeof PUBLICATION_STEPS[keyof typeof PUBLICATION_STEPS]
export const PUBLICATION_STEPS = {
    STEP_1: 1,
    STEP_2: 2,
    STEP_3: 3,
    STEP_4: 4
} as const;

interface IPublishingStep {
    key: TPublicationKey;
    name: string;
    description: string;
    backBtnLabel?: string;
    continueBtnLabel: string;
    getBtnLabel?: (isPublicationPublic: boolean) => any;
    hideStepsControl?: boolean;
}

export const publishingSteps: { [K in TPublicationKey]: IPublishingStep } = {
    [PUBLICATION_STEPS.STEP_1]: {
        key: PUBLICATION_STEPS.STEP_1,
        name: "Publication Website",
        description: "Title & description",
        backBtnLabel: "Cancel",
        continueBtnLabel: "Continue to Customize",
    },
    [PUBLICATION_STEPS.STEP_2]: {
        key: PUBLICATION_STEPS.STEP_2,
        name: "Customize",
        description: "Select questions and set options",
        backBtnLabel: "Back",
        continueBtnLabel: "Continue to Publishing Options",
    },
    [PUBLICATION_STEPS.STEP_3]: {
        key: PUBLICATION_STEPS.STEP_3,
        name: "Publishing Options",
        description: "Make public or private and publish",
        backBtnLabel: "Back",
        continueBtnLabel: "Continue to Preview & Publish",
    },
    [PUBLICATION_STEPS.STEP_4]: {
        key: PUBLICATION_STEPS.STEP_4,
        name: "Publication Preview",
        description: "",
        backBtnLabel: "Back",
        hideStepsControl: true,
        continueBtnLabel: "Publish",
        getBtnLabel:(isPublicationPublic: boolean) => 
            isPublicationPublic ? "Publish" : "Publish To Preview"
    }
}

interface IProjectPublishState {
    currentStep: IPublishingStep;
    finishStep: number;
    publishingSites: IPublishingSite[];
    showLoader: boolean;
    publishDetails: null | IPublishInfoOfSite,
    dataSubmission: boolean,
    validDates: string[];
    otherFiles: IUploadOtherFiles | null;
    seoImage: IFileUpload | null;
    siteBaseUrl: string |null;

    setDataSubmission: (value: boolean) => void;
    setFinishStep: (value: number) => void;
    setCurrentStep: (step: TPublicationKey) => void;
    resetState: () => void;
    getPublishingSites: () => Promise<void>;
    setPublishDetails: (data: IPublishInfoOfSite | null) => void;
    setValidDates: (data: string[]) => void;
    updateFilesInState: () => void;
    setOtherFiles: (data: IUploadOtherFiles) => void;
    setSeoImage: (data: IFileUpload | null) => void;
    setSiteBaseUrl: (value: string) => void;
}

const useStore = create<IProjectPublishState>((set, get) => ({
    currentStep: publishingSteps[1],
    finishStep: 0,
    publishingSites: [],
    showLoader: false,
    publishDetails: null,
    dataSubmission: false,
    validDates: [],
    otherFiles: null,
    seoImage: null,
    siteBaseUrl:null,

    setDataSubmission: (value: boolean) => {
        set(() => ({
            dataSubmission: value
        }))
    },
    setFinishStep: (value: number) => {
        set(() => ({
            finishStep: value
        }))
    },
    setSiteBaseUrl: (value: string) => {
        set(() => ({
            siteBaseUrl: value
        }))
    },
    setCurrentStep: (step) =>  {    
        set(() => ({
            currentStep: publishingSteps[step]
        }));
    },
    resetState: () => {
        set(() => ({
            currentStep: publishingSteps[1],
            finishStep: 0,
            publishingSites: [],
            showLoader: false,
            publishDetails: null,
            dataSubmission: false
        }))
    },
    getPublishingSites: async () => {
        try {
          set(() => ({
            showLoader: true,
          }))
          const result = await new ProjectPublishService().getPublishingSites();
          const { sites } = result.data.data;
          set(() => ({
            publishingSites: sites??[],
            showLoader: false
          }));
        } catch (error) {
          set(() => ({ showLoader: false }));
          console.error(error);
        }
      },
    setPublishDetails: (data) => {
        set(() => ({
            publishDetails: data
        }));
    },
    setValidDates: (data) => {
        set(() => ({
            validDates: data
        }));
    },
    updateFilesInState: () => {
        const tmpOtherFiles = get().publishDetails?.other_files;
        const seoImage =  get().publishDetails?.seo_image_file;
        const defaultFile:IFileUpload = {
            new_file_data: null,
            new_file_type: null,
            new_file_name: null,
            old_file: null,
            deleted: false
        }
        set(() => ({
            otherFiles: {
                codebook: tmpOtherFiles?.codebook ? { ...defaultFile, old_file: tmpOtherFiles.codebook } : null,
                protocol: tmpOtherFiles?.protocol ? { ...defaultFile, old_file: tmpOtherFiles.protocol } : null,
                data_file: tmpOtherFiles?.data ? { ...defaultFile, old_file: tmpOtherFiles.data } : null,
                summary: tmpOtherFiles?.summary ? { ...defaultFile, old_file: tmpOtherFiles.summary } : null,
                whitepaper: tmpOtherFiles?.whitepaper ? { ...defaultFile, old_file: tmpOtherFiles.whitepaper } : null,
                readmore: tmpOtherFiles?.readmore ? { ...defaultFile, old_file: tmpOtherFiles.readmore } : null
            },
            seoImage: seoImage ? { ...defaultFile, old_file: seoImage } : null
        }));
    },
    setOtherFiles: (data) => {
        set(() => ({ otherFiles: data }));
    },
    setSeoImage: (data) => {
        set(() => ({ seoImage: data }));
    }
}));

export const useProjectPublishStore = useStore;
