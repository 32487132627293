import React, { Fragment, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsVisible } from 'modules/shared/hooks';
import { useUserStore } from 'modules/shared/store';
import { Link, NavLink } from 'react-router-dom';
import { getAvatar } from 'modules/shared/utility';
import SwitchOrganization from './SwitchOrganization';
import AppLogout from './Logout';
import SwitchOrganizationModal from './SwitchOrganizationModal';
import siteLogo from '../../../../assets/images/logo-header.svg';

interface Props {}

const AppHeader: React.FC<Props> = (props) => {
  const [profileClicked, setProfileClicked, wrapperRef] = useIsVisible(false);
  const [selectedOrgIdForSwitch, setSelectedOrgIdForSwitch] = useState<{
    org_id: string;
    org_key: string;
  } | null>(null);
  
  const { isAuthenticated } = useAuth0();
  const { userDetails } = useUserStore();
  return (
    <Fragment>
    <header>
      <h1>
        <Link to={'/app'}>
          <img
            src={siteLogo}
            alt="MonQcle by the Center for Public Health Law Research"
          />
        </Link>
      </h1>
      <ul className="headerMenu">
        <li>
          <NavLink
            to="/app"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end
          >
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/app/settings"
            className={({ isActive }) => (isActive ? 'active' : '')}
            end
          >
            Settings
          </NavLink>
        </li>
      </ul>
      {isAuthenticated && (
        <div className="userProfile">
          <button
            className="userBlk"
            ref={wrapperRef as any}
            onClick={() => setProfileClicked((prev) => !prev)}
          >
            <span className="userAvatar">
              {getAvatar(
                userDetails?.full_name as string,
                userDetails?.avatar_url
              )}
            </span>
            <span className="userEmail">{userDetails?.full_name}</span>
          </button>
          {profileClicked && (
            <div className="userOptions">
              <div className="userEmail">{userDetails?.full_name}</div>
              <ul>
                <li>
                  <Link id="editProfile" to="app/edit-profile">
                    Edit My Profile
                  </Link>
                </li>
                <SwitchOrganization doSwitchOrganization={(org_key, org_id)=>{
                setSelectedOrgIdForSwitch({
                  org_id,
                  org_key,
                });
              }}/>
                <AppLogout />
              </ul>
            </div>
          )}
        </div>
      )}
      <span className="menuControl">Header Menu</span>
    </header>
    
    {selectedOrgIdForSwitch && (
        <SwitchOrganizationModal
          org_id={selectedOrgIdForSwitch?.org_id}
          org_key={selectedOrgIdForSwitch?.org_key}
          onCancel={() => {
            setSelectedOrgIdForSwitch(null);
          }}
        />
      )}
    </Fragment>
  );
};

export default AppHeader;
