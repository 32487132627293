import { FC, Fragment } from "react";
import AuthService from "modules/shared/services/authService";
import { useUserStore } from "modules/shared/store";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import AuthSwitchOrganizationChannelService from "modules/auth/services/switchOrganizationChannelService";
const authService = AuthService.getInstance();
const authSwitchOrganizationChannelService = AuthSwitchOrganizationChannelService.getSwitchOrganizationInstance();

const SwitchOrganizationModal: FC<{
  org_key: string;
  org_id: string;
  onCancel: () => void;
}> = ({ org_key, org_id, onCancel }) => {
  const { userDetails } = useUserStore();
  const switchOrganization = () => {
    authSwitchOrganizationChannelService.getSwitchOrganizationChannel().postMessage({org_key, org_id, user_id: userDetails?.sub, is_app_admin: false});
    authService.clearCurrentOrganization();
    authService.clearCurrentUserLocalStorage();
    authService.setCurrentOrganization(org_id);
    window.location.href = "/org/" + org_key;
  };

  return (
    <Fragment>
      {org_key && org_id && (
        <Modal
          className="confirmationModal"
          title="Switch Organization"
          centered
          open={true}
          onCancel={() => onCancel()}
          footer={[
            <Button key="yes" onClick={() => onCancel()}>
              Cancel
            </Button>,
            <Button
              key="no"
              type="primary"
              onClick={() => {
                switchOrganization();
              }}
            >
              Switch
            </Button>,
          ]}
        >
          <div className="notificationTxtWrap">
            <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label="" />
            <div className="notificationTxt">
              <p>
                Are you sure you want to switch to {' '}
                <strong>
                  {
                    userDetails?.organization?.find(
                      (each) => each.id === org_id
                    )?.name
                  }
                </strong>?
              </p>
            </div>
          </div>
        </Modal>
      )}
    </Fragment>
  );
};

export default SwitchOrganizationModal;
