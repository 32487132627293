import { create } from 'zustand';
import { IPQuestion, IRankingProjectQuestion } from '../models/interface';
import { RankingProjectService } from '../services/rankingProject.service';
import CalculateEquation from '../orgRankingTools/utils/calculateEquation';
import { EquationParser } from '../orgRankingTools/utils/equationParser';

interface IPQuestionTree extends IPQuestion {
  children?: IPQuestionTree[];
}

interface IEquationObj {
  name: string;
  equation: string;
  result: 0 | 1 | null;
}

export interface IEquationAnswer {
  questionVariable: string;
  ansResponse: { [key: string]: string }[];
}
export interface RankingToolState {
  questionSnapshot: null | IRankingProjectQuestion[];
  questionSnapshotList: null | IPQuestion[];
  currentRankTab: string;
  ranks: string[];
  allEquations: { [key: string]: IEquationObj };
  currentEquation: IEquationObj | undefined;

  getQuestionSnapshot: () => Promise<void>;
  setCurrentTab: (value: string) => void;
  setEquationText: (equationText: string) => void;
}

const useStore = create<RankingToolState>((set, get) => ({
  questionSnapshot: null,
  questionSnapshotList: null,
  currentRankTab: 'silver',
  ranks: ['silver', 'gold', 'platinum'],
  allEquations: {},
  currentEquation: undefined,

  getQuestionSnapshot: async () => {
    try {
      const { data } = await new RankingProjectService().getQuestionSnapshot(1);
      const _questionSnapshotList = questionTreeToFlattenQuestionList(
        makeQuestionSnapshotTree(data.data.question_snapshot ?? [], 0)
      );
      set(() => ({
        questionSnapshotList: _questionSnapshotList,
        questionSnapshot: data.data.question_snapshot
      }));
    } catch (error) {
      console.log(error);
    }
  },

  setCurrentTab: (value) => {
    set((state) => ({
      currentRankTab: value,
      currentEquation: state.allEquations[value] ?? undefined
    }));
  },

  setEquationText: (equationText) => {
    const rank = get().currentRankTab;
    const _tmpAllEquations = get().allEquations;
    if (!_tmpAllEquations[rank]) {
      _tmpAllEquations[rank] = {
        name: rank,
        equation: equationText,
        result: null
      };
    } else {
      _tmpAllEquations[rank].equation = equationText;
    }
    set((state) => ({
      allEquations: _tmpAllEquations,
      currentEquation: _tmpAllEquations[rank]
    }));
  }
}));

const makeQuestionSnapshotTree = (
  items: IRankingProjectQuestion[],
  id: number,
  link: string = 'parent_question_id',
  layer: number = 1,
  layer_string: string = ''
): IPQuestionTree[] => {
  return items
    .filter((item) => item.parent_question_id === id)
    .sort((a, b) => a.question_order - b.question_order)
    .map((item, index) => ({
      id: item.question_id,
      question: item.description,
      question_variable: item.variable,
      parent_id: item.parent_question_id,
      question_order_text:
        layer_string !== '' ? `${layer_string}.${index + 1}` : `${index + 1}`,
      question_type: item.question_type,
      question_type_id: item.question_type_id,
      responses: item.responses.map((res) => ({
        id: res.id,
        response: res.description,
        response_variable: res.variable
      })),
      children: makeQuestionSnapshotTree(
        items,
        item.question_id,
        'parent_question_id',
        layer + 1,
        layer_string !== '' ? `${layer_string}.${index + 1}` : `${index + 1}`
      )
    }));
};

const questionTreeToFlattenQuestionList = (
  items: IPQuestionTree[]
): IPQuestion[] => {
  const flattened: IPQuestion[] = [];

  function flattenNode(node: IPQuestionTree): void {
    const {
      id,
      question,
      question_order_text,
      question_type,
      question_type_id,
      question_variable,
      responses
    } = node;
    flattened.push({
      id,
      question,
      question_order_text,
      question_type,
      question_type_id,
      question_variable,
      responses
    });

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        flattenNode(child);
      }
    }
  }

  for (const node of items) {
    flattenNode(node);
  }

  return flattened;
};

export const runRanking = async (allEquations: { [key: string]: IEquationObj }, questions: IPQuestion[], ranking_project_id: number, record_id: number): Promise<{ [key: string]: number }> => {
  const arrayAllEquations = Object.values(allEquations);
  const errors: string[] = [];
  for (const equationObject of arrayAllEquations) {
    if ((new EquationParser(equationObject.equation)).getHasError()) {
      errors.push(equationObject.name);
    }
  }
  if (errors.length) {
    throw new Error(`Fix the equation error ${errors.join(',')}`);
    
  }
  const { data } = await new RankingProjectService().getRecordAnswers(ranking_project_id, record_id);
  return calculateResult(questions, arrayAllEquations, data.data.answers ?? []);
}

const calculateResult = (questions: IPQuestion[], allEquations: IEquationObj[], answers: IEquationAnswer[]): { [key: string]: number } => {
  // const allEquations: {
  //   name: string;
  //   equation: string;
  //   result: 0 | 1 | null;
  // }[] = [
  //   {
  //     name: 'silver',
  //     equation:
  //       'AND(Minor_waivfail_New_judge_appointed, Minor_waivfail_Denied, OR(Minor_waivfail_New_judge_appointed, Minor_waivfail_Not_specified_))',
  //     result: null
  //   },
  //   {
  //     name: 'gold',
  //     equation: 'equationText',
  //     result: null
  //   }
  // ];

  // const answers = [
  //   {
  //     questionVariable: 'Minor_waivfail',
  //     ansResponse: [
  //       {
  //         Minor_waivfail_New_judge_appointed: 'abc',
  //         Minor_waivfail_Not_specified_: 'efg',
  //         Minor_waivfail_Denied: 'hik'
  //       }
  //     ]
  //   }
  // ];

  const rankingResult: { [key: string]: number } = {};

  const filterNotImportedEquations = allEquations.filter(
    (eachObj) =>
      !eachObj.equation.includes('IMPORT') ||
      !eachObj.equation.includes('import')
  );
  const filterImportedEquations = allEquations.filter(
    (eachObj) =>
      eachObj.equation.includes('IMPORT') ||
      eachObj.equation.includes('import')
  );

  filterNotImportedEquations.forEach((eachEqObj) => {
    const calculateObj = new CalculateEquation(
      eachEqObj.equation,
      questions ?? [],
      answers,
      allEquations
    );
    try {
      const result = calculateObj.getResult();
      eachEqObj.result = result as 0 | 1;
      // alert('Current result ' + result);
      rankingResult[eachEqObj.name] = result as 0 | 1;
    } catch (error: any) {
      // alert(error?.message);
      rankingResult[eachEqObj.name] = 0;
    }
  });

  filterImportedEquations.forEach((eachEqObj) => {
    const calculateObj = new CalculateEquation(
      eachEqObj.equation,
      questions ?? [],
      answers,
      allEquations
    );
    try {
      const result = calculateObj.getResult();
      eachEqObj.result = result as 0 | 1;

      rankingResult[eachEqObj.name] = result as number;
    } catch (error: any) {
      rankingResult[eachEqObj.name] = 0;
    }
  });

  return rankingResult;
};

export const useRankingToolStore = useStore;
