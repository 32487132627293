import { IUserDetails, IProjectRolePermissions, IApplicationConfiguration } from "models/interface";
import { create } from "zustand";


interface UserState {
    isLoadingUserDetails: boolean;
    userDetails: IUserDetails | null;
    projectRolePermissionsList: IProjectRolePermissions[];
    appConfigurationList: IApplicationConfiguration | null;
    setUserDetails: (_user: IUserDetails | null, projectRolePermissions: IProjectRolePermissions[], appConfigurationList: IApplicationConfiguration) => void;
    updateUserDetails: (data: IUserDetails) => void;
}

const useStore = create<UserState>((set) => ({
    isLoadingUserDetails: true,
    userDetails: null,
    projectRolePermissionsList: [],
    appConfigurationList: null,
    setUserDetails: (data, projectRolePermissions, appConfigurationList) => {        
        set(() => ({
            isLoadingUserDetails: false,
            userDetails: data,
            projectRolePermissionsList: projectRolePermissions,
            appConfigurationList: appConfigurationList
        }));
    },
    updateUserDetails: (data) => {
        set(() => ({
            userDetails: { ...data }
        }));
    }
}));

export const useUserStore = useStore;
