import { Avatar, Button, Empty, MenuProps, message, Spin, Tooltip } from 'antd';
import { IGroupCard } from 'modules/organization/models/interface/group.interface';
import React, { useEffect, useState } from 'react';
import {
  StarFilled,
  StarOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { getAvatar, getEncodedData, toTitleCase } from 'modules/shared/utility';
import { GroupCardMenuKeys } from 'modules/organization/models/enums';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import { filterKebabMenuItems } from 'modules/organization/utility/commonFunctions';
import { useRbac } from 'auth/rbac/rbac';
import CustomDropdownMenu from 'modules/shared/components/CustomDropdownMenu';
import CustomError from 'modules/shared/components/CustomError';
import { useGroupStore } from 'modules/organization/store';
import DeleteGroup from './DeleteGroup';
import { Link } from 'react-router-dom';
import { useOrganizationRoutes } from 'routes/organizationRoute';
import { GroupService } from 'modules/organization/services';

const _menuItems = [
  {
    label: 'Manage Group',
    key: GroupCardMenuKeys.EDIT_GROUP,
    icon: <EditOutlined role='img' aria-hidden/>,
    allowedPermissions: [ERbacPermissions.ORG_GROUP_EDIT]
  },
  {
    label: 'Delete',
    key: GroupCardMenuKeys.DELETE_GROUP,
    icon: <DeleteOutlined role='img' aria-hidden/>,
    danger: true,
    allowedPermissions: [ERbacPermissions.ORG_GROUP_EDIT]
  }
];

const GroupCard: React.FC<{ group: IGroupCard }> = ({ group }) => {
  const { hasPermissions } = useRbac();

  const { organizationBaseRouteUrl } = useOrganizationRoutes();

  const {
    setGroupDrawerState,
    setGroupDrawerOptions,
    showConfirmDelete,
    setShowConfirmDelete,
    setGroupInfo,
    refreshGroupList
  } = useGroupStore();

  const onSelect = (e: any, group: IGroupCard) => {
    if (
      [GroupCardMenuKeys.EDIT_GROUP].includes(
        parseInt(e.key)
      )
    ) {
      setGroupDrawerState(true);
      setGroupDrawerOptions(parseInt(e.key), group);
    } else if (
      [GroupCardMenuKeys.DELETE_GROUP].includes(
        parseInt(e.key)
      )
    ) {
      setShowConfirmDelete(!showConfirmDelete);
      setGroupInfo(group);
    }
  };

  const listActionItemsList: MenuProps['items'] = filterKebabMenuItems(
    _menuItems,
    hasPermissions,
    {
      project_role_id: group.group_role_id
    }
  );

    /** Mark group Favorite UnFavorite start */
    const [goingToFavoriteGroupId, setGoingToFavoriteGroupId] = useState<number | null>(null);
    const markFavoriteGroup = async(group: IGroupCard) => {
      if(!goingToFavoriteGroupId){
        setGoingToFavoriteGroupId(group.group_id);
        try {
          await new GroupService().favoriteGroup(group.group_id, group.is_favourite);
          refreshGroupList();
          setGoingToFavoriteGroupId(null);
        } catch (error) {
          setGoingToFavoriteGroupId(null);
          message.error('Failed to Favorite/Unfavorite group');
        }
      }
    }
    /** Mark group Favorite UnFavorite end */

  return (
    <li key={group.group_id}>
      <div className="cardHead">
        <Tooltip
          title={group.is_favourite ? "Mark Unfavorite" : "Mark Favorite"}
          trigger={['hover','focus']}
        >
          <Button
            onClick={() => {
              markFavoriteGroup(group);
            }}
            aria-label={
              group.is_favourite ? "Mark Unfavorite" : "Mark Favorite"
            }
            className="iconOnlyBtn smallIcon withFav"
          >
            {goingToFavoriteGroupId === group.group_id && (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />}
              />
            )}

            {goingToFavoriteGroupId !== group.group_id && (
              <span>
                {group.is_favourite ? (
                  <StarFilled className="active" role='img' aria-hidden/>
                ) : (
                  <StarOutlined role='img' aria-hidden/>
                )}
              </span>
            )}
          </Button>
        </Tooltip>
        <h3>{group.name}</h3>
        <span className="outdatedLaw">
          {toTitleCase(group.group_role_name)}
        </span>
        <CustomDropdownMenu
          menuItems={listActionItemsList}
          onSelect={(e) => onSelect(e, group)}
          disabled={!listActionItemsList.some((item) => item?.key)}
        >
          <EllipsisOutlined  role="img" aria-label='More'/>
        </CustomDropdownMenu>
      </div>
      <div className="cardContent">
        <div className="projectBlk">
          <label>Projects</label>
          <div
            className="numberCount"
            aria-label={
              group.project_count > 0
                ? `Project Link with ${group.project_count} projects`
                : "No project"
            }
          >
            {group.project_count > 0 ? (
              <Link
                to={
                  organizationBaseRouteUrl +
                  `/projects?filter=${getEncodedData({
                    group_id: group.group_id,
                    group_name: group.name,
                  })}`
                }
              >
                {group.project_count}
              </Link>
            ) : (
              <span aria-hidden>-</span>
            )}
          </div>
        </div>
        <div className="membersBlk">
          <label>Members</label>
          <Avatar.Group maxCount={5}>
            {group.users_associated.map((eachUser) => (
              <span className="userAvatar" key={eachUser.user_id}>
                <Tooltip title={eachUser.full_name} trigger={['hover','focus']}>
                  {getAvatar(eachUser.full_name, eachUser.avatar_url)}
                </Tooltip>
              </span>
            ))}
          </Avatar.Group>
        </div>
      </div>
    </li>
  );
};

const GroupCardList: React.FC<{}> = () => {
  const { dataFetchError, groupsLoader, refreshGroupList, groups, showConfirmDelete } = useGroupStore();

  useEffect(() => {
    refreshGroupList().catch((error) => console.log(error));
  }, [refreshGroupList]);

  return (
    <Spin spinning={groupsLoader}>
      <ul className='cardWrap'>
        {dataFetchError && (
          <CustomError
            errorDetails={dataFetchError}
            refreshAction={refreshGroupList}
            reloadPage={false}
          />
        )}
        {!dataFetchError &&
          (groups?.length ? (
            groups.map((eachGroup) => (
              <GroupCard key={eachGroup.group_id} group={eachGroup} />
            ))
          ) : (
            <li className='noDataBlk'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></li>
          ))}
        {showConfirmDelete && (
          <DeleteGroup />
        )}
      </ul>
    </Spin>
  );
};

export default GroupCardList;
