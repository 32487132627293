import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom";
import AuthLayout from "../modules/auth/layout/AuthLayout";
import AcceptInvitation from "../modules/auth/AcceptInvitation";
import { AuthenticationGuard } from "../auth/authentication-guard";
import HomePage from "../modules/home";
import DemoPrivatePage from "../modules/home/DemoPrivatePage";
import { OrganizationRoutes } from "./organizationRoute";
import RegistrationPage from "../modules/auth/RegistrationPage";
import Auth0Outlet from "auth/Auth0Outlet";
import VerifyIndividualUser from "modules/auth/VerifyIndividualUser";
import { ApplicationRoutes } from './applicationRoute';
import NotFound from 'modules/shared/components/NotFound';
import SwitchOrganizationOutlet from "auth/SwitchOrganizationOutlet";
import SwitchOrganizationSignalOutlet from "auth/SwitchOrganizationSignalOutlet";
import IdleUserPage from "modules/auth/IdleUserPage";
import { ProjectPreviewRoute } from "./projectPreviewRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<SwitchOrganizationSignalOutlet />}>
      <Route element={<Auth0Outlet />}>
        <Route path="/" element={<SwitchOrganizationOutlet />}>
          <Route index element={
            <AuthenticationGuard component={HomePage} />
          }/>
          <Route path="private" element={
            <AuthenticationGuard component={DemoPrivatePage} />
          }/>
          <Route path="app/*" element={<AuthenticationGuard component={ApplicationRoutes} />} />
          <Route path="org/*" element={<AuthenticationGuard component={OrganizationRoutes} />} />
          <Route path="preview/*" element={<ProjectPreviewRoute />} />
        </Route>

        <Route path="auth" element={<AuthLayout />}>
          <Route index element={<AcceptInvitation />} />
          <Route path="accept-invitation" element={<AcceptInvitation />} />
          <Route path="registration" element={<RegistrationPage />} />
          <Route path="verify-user" element={<VerifyIndividualUser />} />
        </Route>
        <Route path="idle-user" element={<IdleUserPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  )
)

export default function AppRoutes(){
  return <RouterProvider router={router} />
}
