import { Button, Result } from 'antd';
import React from 'react';

const AccessDenied: React.FC<{ isAccessFromPage?: boolean }> = ({
  isAccessFromPage
}) => {
  return (
    <div className="verifyMsgWrap" style={{minHeight: '80vh'}}>
      <Result
        status="403"
        title="Access denied"
        subTitle="You do not have the permission to access this page"
        extra={ isAccessFromPage &&
          <Button type="primary" href="/">
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default AccessDenied;
