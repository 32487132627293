import { useAuth0 } from "@auth0/auth0-react";
import { Button, Result } from "antd";
import { usePageTitle } from 'modules/shared/hooks';
import AuthService from "modules/shared/services/authService";
import React from "react";

interface Props {}

const authService = AuthService.getInstance();

const IdleUserPage: React.FC<Props> = () => {
  const { logout } = useAuth0();
  usePageTitle("MonQcle - Unauthorized user");
  return (
    <div className="verifyMsgWrap">
      <Result
        status="403"
        title="You are not a part of organization or your trial period expired"
        subTitle="Contact MonQcle Administrator"
        extra={
          <Button
            type="primary"
            onClick={() => {
              authService.clearCurrentOrganization();
              authService.clearCurrentUserLocalStorage();
              setTimeout(() => {
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }, 500);
            }}
          >
            Logout
          </Button>
        }
      />
    </div>
  );
};

export default IdleUserPage;
