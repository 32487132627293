import { create } from "zustand";


interface SwitchOrganizationState {
    user_id: string | null;
    org_id: string | null;
    org_key: string | null;
    is_app_admin: boolean;
    setCurrentSwitchedOrganizationData: (    
        user_id: string | null,
        org_id: string | null,
        org_key: string | null,
        is_app_admin: boolean,
    ) => void;
}

const useStore = create<SwitchOrganizationState>((set) => ({
    user_id: null,
    org_id: null,
    org_key: null,
    is_app_admin: false,
    setCurrentSwitchedOrganizationData: (           
        user_id: string | null,
        org_id: string | null,
        org_key: string | null,
        is_app_admin: boolean
        ) => {        
        set(() => ({
            user_id,
            org_id,
            org_key,
            is_app_admin
        }));
    }
}));

export const useSwitchOrganizationStore = useStore;
