import { MenuProps } from 'antd';
import {
    BinaryQstIcon,
    CheckboxQstIcon,
    CurrencyQstIcon,
    DateQstIcon,
    MutualQstIcon,
    NumericQstIcon,
    TextQstIcon,
    WeightedQstIcon
  } from 'modules/shared/components/CustomAppIcons';
import { TProjectRoleId } from '../models/interface';

export const renderQuestionType = (questionTypeId: number) => {
  switch (questionTypeId) {
    case 10:
      return <BinaryQstIcon />;
    case 12:
      return <MutualQstIcon />;
    case 8:
      return <WeightedQstIcon />;
    case 4:
      return <CheckboxQstIcon />;
    case 2:
      return <TextQstIcon />;
    case 7:
      return <DateQstIcon />;
    case 3:
      return <CurrencyQstIcon />;
    case 1:
      return <NumericQstIcon />;
    default:
      return null;
  }
};


export const filterKebabMenuItems = (menuItems: any, hasPermissions: Function, hasPermissionsParams?: {
  project_role_id?: TProjectRoleId;
  record_role_id?: number;
  direct_permissions?: string[] | null;
}) => {
  const listActionItemsList: MenuProps['items'] = [];
  menuItems.forEach((eachItem: any) => {
    let currentItem = null;
    if(!eachItem?.allowedPermissions.length || hasPermissions(eachItem.allowedPermissions, hasPermissionsParams)){
      currentItem = {...eachItem};
    }
    if(currentItem){
      if(currentItem.divider){
        listActionItemsList.push({type: 'divider'})
      }
      listActionItemsList.push({
        key: currentItem.key,
        label: currentItem.label,
        icon: currentItem.icon,
        danger: currentItem.danger,
        disabled: currentItem.disabled
      });
    }
  });
  return listActionItemsList;
}
