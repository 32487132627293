import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Select, message } from 'antd';
import {
  IOrganizationUser,
  IProjectRecordAssociatedUser,
  TProjectRoleId
} from 'modules/organization/models/interface';
import { useCreateNewProjectStore } from 'modules/organization/store';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { toTitleCase } from 'modules/shared/utility';
import { ProjectListService } from 'modules/organization/services';
import CustomSelectInput from './CustomSelectInput';
import { RBAC } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';

interface Props {
  userList: IOrganizationUser[] | null;
  usersAssociated?: IProjectRecordAssociatedUser[] | null;
  show_direct_permission?: boolean;
  currentUserRoleId?: TProjectRoleId;
}

const CustomManageUserList: React.FC<Props> = ({
  userList,
  usersAssociated,
  show_direct_permission = false,
  currentUserRoleId,
}) => {

  const form = Form.useFormInstance<any>();
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

  const { projectRoleData, setProjectRoleData, directPermissionsData, setProjectDirectPermissionsData } = useCreateNewProjectStore();

  const getProjectRoleData = useCallback(async () => {
    if (!projectRoleData) {
      try {
        const result = await new ProjectListService().getProjectRoleMasterData({
          show_all: false
        });
        const { roleList } = result.data.data;
        setProjectRoleData(roleList);
      } catch (error) {
        console.error(error);
        message.error({
          content: 'Something went wrong please try again!'
        });
      }
    }
  }, [setProjectRoleData, projectRoleData]);

  const getProjectDirectPermissionsData = useCallback(async () => {
    if (!directPermissionsData) {
      try {
        const result = await new ProjectListService().getDirectPermissionMasterData();
        const { permissionList } = result.data.data;
        setProjectDirectPermissionsData(permissionList);
      } catch (error) {
        console.error(error);
        message.error({
          content: 'Something went wrong please try again!'
        });
      }
    }
  }, [setProjectDirectPermissionsData, directPermissionsData]);

  useEffect(() => {
    getProjectRoleData().catch(console.error);
  }, [getProjectRoleData]);

  useEffect(() => {
    getProjectDirectPermissionsData().catch(console.error);
  }, [getProjectDirectPermissionsData]);

  const handleUserSelect = (value: string, index: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      const filteredSelectedUsers = [...prevSelectedUsers];
      if (prevSelectedUsers.length < index) {
        return [...filteredSelectedUsers, value];
      } else {
        filteredSelectedUsers[index] = value;
        return filteredSelectedUsers;
      }
    });
  };

  const handleUserRemove = (index: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      const updatedSelectedUsers = prevSelectedUsers.filter(
        (user, i) => i !== index
      );
      return updatedSelectedUsers;
    });
  };

  useEffect(() => {
    if (usersAssociated?.length) {
      const filteredSelectedUsers = usersAssociated.map((user) => user.user_id);
      filteredSelectedUsers?.[0] && setSelectedUsers(filteredSelectedUsers);
    } 
    else {
      form.setFieldValue('users_associated',[{user_id: undefined, permission_id: undefined, role_id: undefined},{user_id: undefined, permission_id: undefined, role_id: undefined}])
    }
  }, [usersAssociated,form]);

  useEffect(() => {
    if(selectedUsers?.length < 1){
      form.setFieldValue('users_associated',[{user_id: undefined, permission_id: undefined, role_id: undefined},{user_id: undefined, permission_id: undefined, role_id: undefined}])
    }
  },[selectedUsers,form])

  return (
    <React.Fragment>
      <label id="addMembersLabel">Add Permission by Members</label>
      <Form.List name={'users_associated'}>
        {(fields, { add, remove }) => (
          <React.Fragment>
            <ul className="fldWthDropdown roleDropdown">
              {fields.map(({ key, name, ...restField }, index) => {
                const selectedUserId = form.getFieldValue([
                  'users_associated',
                  name,
                  'user_id'
                ]);
                const availableUsers = (userList ?? []).filter(
                  (user, userIndex) =>
                    user.id === selectedUserId ||
                    (!selectedUsers.includes(user.id) && user.is_active)
                );

                return (
                  <li key={key}>
                    <div className="selectValue">
                      <Form.Item {...restField} name={[name, 'user_id']}>
                        <Select
                          placeholder="Select member"
                          aria-label="Select member"
                          className="selectValue"
                          showSearch={true}
                          loading={!userList}
                          optionFilterProp="label"
                          options={(availableUsers ?? []).map((d) => ({
                            value: d.id,
                            label: `${d.full_name} (${d.user_name})`
                          }))}
                          onChange={(user_id) => {
                            handleUserSelect(user_id, index);
                            form.setFieldValue(
                              ['users_associated', name, 'role_id'],
                              3
                            );
                          }}
                        />
                      </Form.Item>
                    </div>
                    <RBAC allowedPermissions={[ERbacPermissions.ORG_PROJECT_DIRECT_PERMISSIONS]} project_role_id={currentUserRoleId}>
                      {
                        show_direct_permission &&
                        <CustomSelectInput
                          {...restField} 
                          name={[name, 'permissions_id']}
                          options={directPermissionsData}
                          placeholder={'Select Permissions'}
                          className="selectDirectPermission"
                          responsive={true}
                        />
                      }
                    </RBAC>
                    <Form.Item {...restField} name={[name, 'role_id']}>
                      <Select
                        placeholder="Role"
                        aria-label="Select role of member"
                        className="selectRole"
                        loading={!projectRoleData}
                        options={(projectRoleData ?? []).map((d) => ({
                          value: d.id,
                          label: toTitleCase(d.name)
                        }))}
                        onChange={() => {
                          form.setFieldValue(['users_associated', name, 'permissions_id'],[]);
                        }}
                      />
                    </Form.Item>
                    <Button
                      onClick={() => {
                        handleUserRemove(index);
                        remove(name);
                      }}
                      aria-label='Remove member'
                      icon={<DeleteOutlined aria-hidden />}
                      className="noPaddingBtn"
                    ></Button>
                  </li>
                );
              })}
            </ul>
            <Button
              onClick={() => {
                const index = form
                  .getFieldValue('users_associated')
                  .findIndex(
                    (user: IProjectRecordAssociatedUser, index: number) =>
                      !user?.user_id
                  );
                if (index < 0) {
                  add();
                } else {
                  form
                    .getFieldInstance(['users_associated', index, 'user_id'])
                    .focus();
                }
              }}
              icon={<PlusCircleOutlined aria-label="" aria-hidden="true"/>}
              className="noPaddingBtn"
            >
              Add Additional Members
            </Button>
          </React.Fragment>
        )}
      </Form.List>
    </React.Fragment>
  );
};

export default CustomManageUserList;
