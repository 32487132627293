export const appConfig = {
    REACT_APP_ENVIRONMENT: process.env.REACT_APP_AUTH0_DOMAIN?.toString() as string,
    REACT_APP_API_URL:  process.env.REACT_APP_API_URL?.toString() as string,
    REACT_APP_AUTH0_DOMAIN:  process.env.REACT_APP_AUTH0_DOMAIN?.toString() as string,
    REACT_APP_AUTH0_CLIENT_ID:  process.env.REACT_APP_AUTH0_CLIENT_ID?.toString() as string,
    REACT_APP_AUTH0_AUDIENCE:  process.env.REACT_APP_AUTH0_AUDIENCE?.toString() as string,
    REACT_APP_MAP_API_KEY: process.env.REACT_APP_MAP_API_KEY?.toString() as string,
    REACT_APP_MAP_API_KEY_MONQCLE: process.env.REACT_APP_MAP_API_KEY_MONQCLE?.toString() as string,
    REACT_APP_URL: process.env.REACT_APP_URL?.toString() as string,
    REACT_APP_IFRAME_BASE_URL: process.env.REACT_APP_IFRAME_BASE_URL?.toString() as string,
    REACT_APP_LAW_ATLAS_IFRAME_BASE_URL: process.env.REACT_APP_LAW_ATLAS_IFRAME_BASE_URL?.toString() as string,
    REACT_APP_DEFAULT_TIMEZONE:  'America/Newyork',
    REACT_APP_GCP_ASSETS_BUCKET_SHARED_URL: process.env.REACT_APP_GCP_ASSETS_BUCKET_SHARED_URL?.toString() as string,
    REACT_APP_IMPORTER_TEMPLATE_URL: process.env.REACT_APP_IMPORTER_TEMPLATE_URL?.toString() as string
}