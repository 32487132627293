import { FC, Fragment, useRef, useState } from "react";
import {
  Button,
  Tooltip,
  Modal,
  message,
} from "antd";
import { ISiteProjectListData } from "modules/organization/models/interface";
import TextArea from "antd/es/input/TextArea";
import { appConfig } from "config/config";
import { LawAtlasIframeIcon } from "modules/shared/components/CustomAppIcons";
import { copyToClipboard } from "modules/shared/utility";

const OrgGetLawAtlasIframeModal: FC<{ siteProjectData: ISiteProjectListData }> = ({
  siteProjectData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  const getLawAtlasIframeHtml = () =>{
    return `<iframe id="iframe" width="100%" frameborder="0" referrerpolicy="origin" scrolling="yes" src="${appConfig.REACT_APP_LAW_ATLAS_IFRAME_BASE_URL}/#/${siteProjectData.url_slug}" style="height:900px;width:100%;border:none;"></iframe>`;
  }

  const handleCopyClick = () => {
    copyToClipboard(getLawAtlasIframeHtml())
      .then(() => {
        message.success('Law atlas iframe copied successfully');
      })
      .catch(() => {
        message.error('Failed to copy law atlas iframe');
      });
  };

  const showTooltipText = () => {
    return "Show law atlas iframe";
  }

  return (
    <Fragment>
      <div className="passcodeCol flexJCenter">
        <Tooltip
          title={showTooltipText()}
          placement="right"
          trigger={["hover", "focus"]}
        >
          <Button
            onClick={() => {
              setShowModal(true);
              btnRef.current?.blur();
            }}
            aria-label="Show law atlas iframe"
            className="actionBtn getIframeButton"
            ref={btnRef}
          >
            <LawAtlasIframeIcon/>
          </Button>
        </Tooltip>
      </div>
      <Modal
        className="confirmationModal"
        width={1000}
        destroyOnClose
        title={<>Law Atlas Iframe for {siteProjectData.site_name} site</>}
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </Button>,
          <Button
          key="copy"
          type="primary"
          onClick={() => {
            handleCopyClick();
          }}
        >
          Copy
        </Button>,
          
        ]}
      >
        
          <div className="modalContent alignLeft">
            <div className="displayFlex">
              <label>Project :</label>
              <p>&nbsp;{siteProjectData.title}</p>
            </div>
              <div style={{ display: "flex" }}>
                  
                  <TextArea
                    readOnly
                    disabled
                    aria-disabled
                    value={getLawAtlasIframeHtml()}
                    autoSize={{ minRows: 2, maxRows: 10 }}
                  />
                </div>
          </div>
      </Modal>
    </Fragment>
  );
};

export default OrgGetLawAtlasIframeModal;
