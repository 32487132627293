import { Empty, MenuProps, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import CustomError from 'modules/shared/components/CustomError';
import { useRankingSetupStore } from 'modules/organization/store/rankingSetupStore';
import { IRankingTemplateCard } from 'modules/organization/models/interface/rankingSetup.interface';
import CustomDropdownMenu from 'modules/shared/components/CustomDropdownMenu';
import { RankingCardMenuKeys } from 'modules/organization/models/enums';
import { filterKebabMenuItems } from 'modules/organization/utility/commonFunctions';
import {
    EditOutlined,
    DeleteOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import { useRbac } from 'auth/rbac/rbac';
import { ERbacPermissions } from 'auth/rbac/rbacPermissionsList';
import DeleteRankingTemplate from './DeleteRankingTemplate';


const RankingCard: React.FC<{ rankingTemplate: IRankingTemplateCard }> = ({ rankingTemplate }) => {
    const { hasPermissions } = useRbac();
    const _menuItems = [
        {
            label: 'Manage Template',
            key: RankingCardMenuKeys.EDIT_RANKING_TEMPLATE,
            icon: <EditOutlined role='img' aria-hidden />,
            allowedPermissions: [ERbacPermissions.ORG_RANKING_SETUP_CRUD]
        },
        {
            label: 'Delete',
            key: RankingCardMenuKeys.DELETE_RANKING_TEMPLATE,
            icon: <DeleteOutlined role='img' aria-hidden />,
            danger: true,
            allowedPermissions: [ERbacPermissions.ORG_RANKING_SETUP_CRUD]
        }
    ];

    const setRankingTemplateDrawerState = useRankingSetupStore(state => state.setRankingTemplateDrawerState);
    const setRankingTemplateDrawerOptions = useRankingSetupStore(state => state.setRankingTemplateDrawerOptions);
    const setRankingTemplateInfo = useRankingSetupStore(state => state.setRankingTemplateInfo);
    const setShowConfirmDelete = useRankingSetupStore(state => state.setShowConfirmDelete);

    const onSelect = (e: any, rankingTemplate: IRankingTemplateCard) => {
        if (
            [RankingCardMenuKeys.EDIT_RANKING_TEMPLATE].includes(
                parseInt(e.key)
            )
        ) {
            setRankingTemplateDrawerState(true);
            setRankingTemplateDrawerOptions(parseInt(e.key), rankingTemplate);
            setRankingTemplateInfo(rankingTemplate);
        } else if (
            [RankingCardMenuKeys.DELETE_RANKING_TEMPLATE].includes(
                parseInt(e.key)
            )
        ) {
            setShowConfirmDelete(true);
            setRankingTemplateInfo(rankingTemplate);
        }
    };

    const listActionItemsList: MenuProps['items'] = filterKebabMenuItems(
        _menuItems,
        hasPermissions,
    );
    return (
        <li key={rankingTemplate.id}>
            <div className="cardHead">
                <h3>{rankingTemplate.title}</h3>
                <CustomDropdownMenu
                    menuItems={listActionItemsList}
                    onSelect={(e) => onSelect(e, rankingTemplate)}
                    disabled={!listActionItemsList.some((item) => item?.key)}
                >
                    <EllipsisOutlined role="img" aria-label='More' />
                </CustomDropdownMenu>
            </div>
            <div className="cardContent">
                <ul className='rankItemList'>
                    {rankingTemplate.ranks.map((eachRank) => (
                        <li key={eachRank.display_order}>
                            {eachRank?.rank_icon_url ? (
                                <img src={`${process.env.REACT_APP_RANKING_ICON_URL}/${eachRank?.rank_icon_url}`} alt="Rank icon" />
                            ) : (
                                <em className='defaultImg'>Icon</em>
                            )}
                            <Typography.Text ellipsis={{tooltip:eachRank.rank_name}}><span>{eachRank.rank_name}</span></Typography.Text> 
                        </li>
                    ))}

                </ul>
            </div>

        </li>
    );
};

const RankingCardList: React.FC<{}> = () => {
    const dataFetchError = useRankingSetupStore(state => state.dataFetchError);
    const rankingTemplatesLoader = useRankingSetupStore(state => state.rankingTemplatesLoader);
    const refreshRankingTemplateList = useRankingSetupStore(state => state.refreshRankingTemplateList);
    const rankingTemplates = useRankingSetupStore(state => state.rankingTemplates);
    const showConfirmDelete = useRankingSetupStore(state => state.showConfirmDelete);

    useEffect(() => {
        refreshRankingTemplateList().catch((error) => console.log(error));
    }, [refreshRankingTemplateList]);

    return (
        <Spin spinning={rankingTemplatesLoader}>
            <ul className='cardWrap rankingCard'>
                {dataFetchError && (
                    <CustomError
                        errorDetails={dataFetchError}
                        refreshAction={refreshRankingTemplateList}
                        reloadPage={false}
                    />
                )}
                {!dataFetchError &&
                    (rankingTemplates?.length ? (
                        rankingTemplates?.map((eachRankingTemplate) => (
                            <RankingCard key={eachRankingTemplate.id} rankingTemplate={eachRankingTemplate} />
                        ))
                    ) : (
                        <li className='noDataBlk'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></li>
                    ))}
                {showConfirmDelete && (
                    <DeleteRankingTemplate />
                )}
            </ul>
        </Spin>
    );
};

export default RankingCardList;
