import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate, useParams } from "react-router-dom";
import { useRbac } from "../auth/rbac/rbac";
import OrgManageUserPage from "../modules/organization/orgManageUsers/OrgManageUserPage";
import PageLoader from "../modules/shared/components/PageLoader";
import { Header } from "../modules/shared/layout/includes/Header";
import AuthService from "../modules/shared/services/authService";
import { FooterSlim } from "modules/shared/layout/includes/FooterSlim";
import ProjectLayout from "modules/organization/orgProject/projectOutlet/ProjectOutlet";
import EditUserDetailPage from 'modules/auth/EditUserDetailPage';
import OrgGroupListPage from 'modules/organization/orgGroup/OrgGroupListPage';
import NotFound from 'modules/shared/components/NotFound';
import AuthSwitchOrganizationChannelService from "modules/auth/services/switchOrganizationChannelService";
import OrgSiteListPage from "modules/organization/orgSite/OrgSiteListPage";
import OrgSiteDetailsPage from "modules/organization/orgSiteDetails/OrgSiteDetailsPage";
import OrgRankingEquationEditorPage from 'modules/organization/orgRankingTools/equationEditor/OrgRankingEquationEditorPage';
import OrgRankingSetupPage from "modules/organization/orgRankingSetup/OrgRankingSetupPage";

const OrgDashboardPage = React.lazy(
  () => import("../modules/organization/orgDashboard/OrgDashboardPage")
);
const OrgProjectListPage = React.lazy(
  () => import("../modules/organization/orgProjectList/orgProjectListPage")
);
const OrgProjectDetailsPage = React.lazy(
  () =>
    import("../modules/organization/orgProject/orgProjectDetails/OrgProjectDetailsPage")
);
const RecordDetailsPage = React.lazy(
  () =>
    import("../modules/organization/orgProject/recordDetails/OrgProjectRecordDetailsPage")
);
const OrgCodingReviewPage = React.lazy(
  () => import("../modules/organization/orgProject/codingReview/OrgCodingReviewPage")
);
const OrgProjectPublishPage = React.lazy(
  () => import("../modules/organization/orgProject/projectPublish/OrgProjectPublishPage")
);
const OrgProjectNoPublishPreviewPage = React.lazy(
  () => import("../modules/organization/orgProject/projectPublish/OrgProjectNoPublishPreviewPage")
);
const OrgProjectErrorSamplingPage = React.lazy(
  () => import("../modules/organization/orgProject/errorSampling/OrgProjectErrorSamplingPage")
);

const authService = AuthService.getInstance();

export function OrganizationRoutes() {
  return (
    <Routes>
      <Route element={<OrgLayout />}>
        <Route index element={<div>Org Route</div>} />
        <Route path=":org_name">
          <Route
            index
            element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgDashboardPage />
              </React.Suspense>
            }
          />
          <Route path="edit-profile" element={<EditUserDetailPage />} />
          <Route path="users" element={<OrgManageUserPage />} />
          <Route path="groups" element={<OrgGroupListPage />} />
          <Route
            path="projects"
            element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgProjectListPage />
              </React.Suspense>
            }
          />
          <Route path="project/:project_slug" element={<ProjectLayout />}>
            <Route
              index
              element={
                <React.Suspense fallback={<PageLoader />}>
                  <OrgProjectDetailsPage />
                </React.Suspense>
              }
            />
            <Route path="record/:record_id" element={
              <React.Suspense fallback={<PageLoader />}>
                <RecordDetailsPage />
              </React.Suspense>
            } />
            <Route path="coding-review" element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgCodingReviewPage />
              </React.Suspense>
            } />
            <Route path="publish" element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgProjectPublishPage />
              </React.Suspense>
            } />
            <Route path="no-publish-preview" element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgProjectNoPublishPreviewPage />
              </React.Suspense>
            } />
            <Route path="error-sampling" element={
              <React.Suspense fallback={<PageLoader />}>
                <OrgProjectErrorSamplingPage />
              </React.Suspense>
            } />
            <Route path="ranking-equation-editor" element={<OrgRankingEquationEditorPage />} />
          </Route>
          <Route path="ranking-setup" element={<OrgRankingSetupPage />} />
          <Route path="sites" element={<React.Suspense fallback={<PageLoader />}>
                <OrgSiteListPage />
              </React.Suspense>} />
              <Route path="site/:site_id" element={<React.Suspense fallback={<PageLoader />}>
                <OrgSiteDetailsPage />
              </React.Suspense>} />
        </Route>
        
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const OrgLayout = () => {
  const { org_name } = useParams();
  const { userDetails, auth0UserDetails } = useRbac();
  const [redirectToHome, setRedirectToHome] = useState(false);
  const authSwitchOrganizationChannelService = AuthSwitchOrganizationChannelService.getSwitchOrganizationInstance();

  useEffect(() => {
    if (!userDetails?.organization?.length) {
      setRedirectToHome(true);
    } else if (
      org_name &&
      userDetails.organization.find((each) => each.org_key === org_name)?.id !==
      auth0UserDetails?.org_id
    ) {
      const currentOrganization = userDetails.organization.find((each) => each.org_key === org_name);
      if(currentOrganization){
        authService.setCurrentOrganization(
          userDetails.organization.find((each) => each.org_key === org_name)
            ?.id ?? ""
        );
        authService.clearCurrentUserLocalStorage();
        authSwitchOrganizationChannelService.getSwitchOrganizationChannel().postMessage({org_key: currentOrganization.org_key, org_id: currentOrganization.id, user_id: userDetails.sub, is_app_admin: false});
        setTimeout(()=>{
          window.location.reload();
        }, 10)
      }
      else{
        setRedirectToHome(true)
      }
     
    } else {
      authService.clearCurrentOrganization();
    }
  }, [org_name, userDetails, auth0UserDetails, authSwitchOrganizationChannelService]);
  return (
    <div className="mainWrapper">
      {(!org_name || redirectToHome) && <Navigate to="/" replace={true} />}
      {auth0UserDetails?.org_id && (
        <React.Fragment>
          <Header />
          <main className="mainContent">
            <Outlet />
          </main>
          <FooterSlim />
        </React.Fragment>
      )}
    </div>
  );
};

export const useOrganizationRoutes = () => {
  const [organizationBaseRouteUrl, setOrganizationBaseRouteUrl] = useState("");
  const [organizationKey, setOrganizationKey] = useState<string | null>(null);
  const { userDetails } = useRbac();

  useEffect(()=>{
    if(userDetails?.org_id){
      const currentOrganization = userDetails.organization?.find((each) => each.id === userDetails.org_id);
      if(currentOrganization){
        setOrganizationBaseRouteUrl("/org/" + currentOrganization.org_key);
        setOrganizationKey(currentOrganization.org_key)
      }
    }
  }, [userDetails]);

  return { organizationBaseRouteUrl, organizationKey};
};
