import { IHttpError } from 'models/interface';
import { create } from 'zustand';
import { ProjectListService } from '../services';
import { IProjectQuestionFlattenTree } from '../models/interface';
import { calculateSampleSize } from '../utility/statisticalFunction';
import { shuffleArray } from 'modules/shared/utility';
import { Key } from 'antd/es/table/interface';

const exportHeaders: IExcelHeader[] = [
  {
    title: "Record link",
    dataIndex: "record_link",
    width: 50,
  },
  {
    title: "Name",
    dataIndex: "name",
    width: 25,
  },
  {
    title: "Series",
    dataIndex: "series",
    width: 25,
  },
  {
    title: "Effective from",
    dataIndex: "effective_from",
    width: 20,
    isDateColumn: true
  },
  {
    title: "Through to",
    dataIndex: "through_to",
    width: 20,
    isDateColumn: true
  },
  {
    title: "Question",
    dataIndex: "question",
    width: 50
  },
  {
    title: "Variable",
    dataIndex: "variable",
    width: 25
  },
  {
    title: "Answer",
    dataIndex: "answer",
    width: 40
  },
];
export interface IErrorSamplingRecord {
  record_id: number;
  name: string;
  effective_from: string;
  through_to: string;
  series_title: string;
  answer_list: IErrorSamplingAnswer[];
  is_active: boolean;
}

export interface IErrorSamplingAnswer {
  answer_id: number;
  question_id: number;
  response_id: number[] | null;
  open_response: null | string;
}

export interface IErrorSamplingVariables {
  variable: string;
  record_id: number;
  description: string;
  question_id: number;
  response_id: number;
  question_type_id: number;
  checked: boolean;
  answer: null | number[];
}

export interface IErrorSamplingFilteredQuestion {
  question_id: number;
  hidden: boolean;
  option_ids: number[] | null;
}

export interface ISampleSizeParams {
  date: null | string;
  margin: number;
  confidence: number;
  response: number;
  population: number;
  show_hidden_records: boolean;
}

export interface IErrorSamplingDateRange {
  max_date: string;
  min_date: string;
}

interface IExcelHeader {
  dataIndex: string;
  title: string;
  width?: number;
  isDateColumn?: boolean;
}

interface IExcelData {
  record_link: string;
  name: string;
  series: string;
  effective_from: string;
  through_to: string;
  variable: string;
  answer: string | null;
  question: string;
}

export interface IErrorSamplingExcelBufferParams {
  data: IExcelData[];
  header: IExcelHeader[];
}

interface ICodingReviewState {
  recordsData: IErrorSamplingRecord[];
  variablesData: IErrorSamplingVariables[];
  sampleSizeParams: ISampleSizeParams;
  filteredQuestion: IErrorSamplingFilteredQuestion[];
  checkResults: {
    totalVariables: number;
    checkVariables: number;
    checkedRecords: IErrorSamplingRecord[];
    checkedVariables: IErrorSamplingVariables[];
  };
  dataLoader: boolean;
  dataFetchError: null | IHttpError;
  dateRange: null | IErrorSamplingDateRange;
  treeExpandedKeys: Key[];

  resetState: () => void;
  getErrorSampleData: (projectSlug: string) => Promise<void>;
  doErrorSampling: () => void;
  updateFilteredQuestion: (questionId: number, optionId?: number[]) => void;
  setSampleSizeParams: (data: ISampleSizeParams) => void;
  getExcelBufferData: (questions: IProjectQuestionFlattenTree[], projectSlug: string, orgRoute: string) => Promise<any>;
  setDataLoader: () => void;
  updateExpandedKeys: (keys?: Key[]) => void;
}

const useStore = create<ICodingReviewState>((set, get) => ({
  recordsData: [],
  variablesData: [],
  sampleSizeParams: {
    date: null,
    margin: 5,
    confidence: 95,
    response: 90,
    population: 0,
    show_hidden_records: false
  },
  checkResults: {
    totalVariables: 0,
    checkVariables: 0,
    checkedVariables: [],
    checkedRecords: [],
  },
  dataLoader: false,
  dataFetchError: null,
  filteredQuestion: [],
  dateRange: null,
  treeExpandedKeys: [],

  resetState: () => {
    set(() => ({
      recordsData: [],
      variablesData: [],
      sampleSizeParams: {
        date: null,
        margin: 5,
        confidence: 95,
        response: 90,
        population: 0,
        show_hidden_records: false
      },
      checkResults: {
        totalVariables: 0,
        checkVariables: 0,
        checkedRecords: [],
        checkedVariables: []
      },
      dataLoader: false,
      dataFetchError: null,
      filteredQuestion: [],
      dateRange: null,
      treeExpandedKeys: [],
    }));
  },
  getErrorSampleData: async (projectSlug) => {
    set(() => ({ dataLoader: true }));
    try {
      const { data } = await new ProjectListService().getErrorSamplingData(
        projectSlug
      );

      set(() => ({
        recordsData: data.data.record_list,
        variablesData: data.data.variable_list,
        dataLoader: false,
        dataFetchError: null,
        dateRange: data.data.date_range
      }));
    } catch (error: any) {
      set(() => ({ dataFetchError: error, dataLoader: false }));
      console.error(error);
    }
  },
  doErrorSampling: () => {
    set(() => ({ dataLoader: true }));
    const filteredQuestion = get().filteredQuestion;
    const { date, response, margin, confidence, show_hidden_records } = get().sampleSizeParams;
    const variablesData = get().variablesData, recordsData = get().recordsData;
    const recordsToCheck: number[] = getRecordsToCheck(recordsData, filteredQuestion, date, show_hidden_records);
    let variablesToCheck = getVariablesToCheck(variablesData, filteredQuestion, recordsToCheck);
    console.log({variablesToCheck, filteredQuestion});
    

    const totalVariables = variablesToCheck.length;
    let sampleSize = calculateSampleSize(margin, confidence, response, variablesToCheck.length);
    let checkedVariables: IErrorSamplingVariables[] = [];
    for (sampleSize; sampleSize > 0; sampleSize--) {
      variablesToCheck = shuffleArray(variablesToCheck)
      const pickedAnswer = variablesToCheck.pop();
      pickedAnswer && checkedVariables.push(pickedAnswer);
    }
    checkedVariables = checkedVariables.filter(({ question_type_id }) => [4, 8, 10, 12].includes(question_type_id));
    const filteredRecord = get().recordsData.filter(({ record_id }) => recordsToCheck.includes(record_id));

    set(() => ({
      checkResults: {
        checkedVariables: checkedVariables,
        checkedRecords: filteredRecord,
        totalVariables,
        checkVariables: checkedVariables.length
      },
      dataLoader: false
    }));
  },

  updateFilteredQuestion: (questionId, optionId) => {
    const filteredQuestion = get().filteredQuestion;
    const currentQuestion = filteredQuestion.find(eachQues => eachQues.question_id === questionId);
    if (optionId && !currentQuestion?.hidden) {
      const tmpFilteredQuestion = filteredQuestion.filter(eachQues => eachQues.question_id !== questionId)
      if (optionId.length > 0) {
        tmpFilteredQuestion.push({
          question_id: questionId,
          hidden: false,
          option_ids: [...optionId]
        });
      }
      set(() => ({
        filteredQuestion: tmpFilteredQuestion
      }))
    }
    else if (!currentQuestion?.hidden) {
      set(() => ({
        filteredQuestion: [
          ...filteredQuestion.filter(eachQues => eachQues.question_id !== questionId),
          {
            question_id: questionId,
            hidden: true,
            option_ids: null
          }
        ]
      }));
    } else {
      set(() => ({
        filteredQuestion: filteredQuestion.filter(eachQues => eachQues.question_id !== questionId)
      }));
    }
  },

  setSampleSizeParams: (data) => {
    set(() => ({ sampleSizeParams: { ...data } }));
  },
  updateExpandedKeys: (keys) => {
    set(() => ({ treeExpandedKeys: keys ?? [] }))
  },

  getExcelBufferData: async (questions, projectSlug, orgRoute) => {
    const { checkedRecords, checkedVariables } = get().checkResults;
    const binaryOptions: { [key: number]: string } = {
      0: "No",
      1: "Yes"
    };
  
    const exportData: IExcelData[] = [];
  
    checkedRecords.forEach((eachRecord) => {
      const currentRecordVariables = checkedVariables.filter(
        ({ record_id }) => eachRecord.record_id === record_id
      );
  
      currentRecordVariables.forEach((eachVariable) => {
        const question = questions.find(
          (eachQues) => eachQues.data.question_id === eachVariable.question_id
        );
  
        const checkedResponse = question?.data.responses?.find(
          ({ response_id }) =>
            eachVariable.question_type_id !== 4 &&
            response_id
              ? eachVariable.answer?.includes(response_id)
              : eachVariable.response_id === response_id
        );
  
        const answer =
          eachVariable.question_type_id === 10 &&
          eachVariable.answer?.length
            ? binaryOptions[eachVariable.answer[0]]
            : checkedResponse?.description ?? "";
  
        exportData.push({
          record_link: `${process.env.REACT_APP_URL}${orgRoute}/project/${projectSlug}/record/${eachRecord.record_id}`,
          name: eachRecord.name,
          series: eachRecord.series_title,
          effective_from: eachRecord.effective_from,
          through_to: eachRecord.through_to,
          variable: eachVariable.variable,
          answer: eachVariable.question_type_id === 4 ? (eachVariable.checked ? answer : '') : answer,
          question: `${question?.data.question} ${question?.data.question_type_id === 4 ? answer : ''}`
        });
      });
    });

    const { data } = await new ProjectListService().getErrorSamplingExportBuffer(projectSlug, { data: exportData, header: exportHeaders });

    return data.data.excelBuffer;
  },
  setDataLoader: () => {
    set(({ dataLoader }) => ({ dataLoader: !dataLoader }));
  }
}));

const getRecordsToCheck = (recordsData: IErrorSamplingRecord[], filteredQuestion: IErrorSamplingFilteredQuestion[], date: string | null, show_hidden_records: boolean): number[] => {
  const recordsToCheck: number[] = [];

  recordsData.forEach((eachRecord) => {
    if (checkHiddenRecord(eachRecord, show_hidden_records) && isDateInRange(date, eachRecord) && hasAllAnsweredOptions(eachRecord, filteredQuestion)) {
      recordsToCheck.push(eachRecord.record_id);
    }
  });

  return recordsToCheck;
}

const getVariablesToCheck = (totalVariables: IErrorSamplingVariables[], filteredQuestion: IErrorSamplingFilteredQuestion[], recordsToCheck: number[]): IErrorSamplingVariables[] => {
  return totalVariables.filter((eachQues) => {
    const currentQuestion = filteredQuestion.find(
      ({ question_id }) => eachQues.question_id === question_id);
    
    const isFilteredOption =
      eachQues.question_type_id === 4 && currentQuestion?.option_ids
        ? currentQuestion?.option_ids.includes(eachQues.response_id)
        : eachQues.question_id === currentQuestion?.question_id;

    if (!currentQuestion?.hidden && recordsToCheck.includes(eachQues.record_id) && !isFilteredOption) {
      return true;
    }
    return false;
  });
}

const isDateInRange = (date: string | null, eachRecord: IErrorSamplingRecord): boolean => (
  !date || (date >= eachRecord.effective_from && date <= eachRecord.through_to)
);

const checkHiddenRecord = (eachRecord: IErrorSamplingRecord, show_hidden_records: boolean): boolean => {
  if(show_hidden_records){
    return true;
  }
  return eachRecord.is_active;
};

const hasAllAnsweredOptions = (record: IErrorSamplingRecord, filteredQuestions: IErrorSamplingFilteredQuestion[]): boolean => {
  const nulledAnswer = record.answer_list.every(answer => answer);

  for (const filteredQuestion of filteredQuestions) {
    // Check if the filtered question is hidden or if the option_ids are null
    if (!filteredQuestion.hidden && filteredQuestion.option_ids !== null) {
      if (!nulledAnswer) {
        return false;
      }

      const answerForQuestion = record.answer_list.find(answer => answer.question_id === filteredQuestion.question_id);
      for (const optionId of filteredQuestion.option_ids) {
        // Check if the variable exists and if the selected option is in the response_id
        if (!answerForQuestion?.response_id?.includes(optionId)) {
          // If any option is not answered for a selected question, return false
          return false;
        }
      }
    }
  }

  return true;
}

export const useProjectErrorSamplingStore = useStore;
