import React, { useEffect, useRef, useState } from "react";
import { Button, Drawer, Form, message, Space, Spin } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { OrganizationService } from "../../services";
import { IRoleMasterData } from "../../../../models/interface";
import { IOrganizationUser, IRequestOrgUserChangeRole } from "../../models/interface";
import { CustomRoleRadioGroup } from './OrgInviteNewUser';
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";

const OrgUserEdit: React.FC<{
    roleList: {
        isLoading: boolean;
        data: IRoleMasterData[];
    },
    setRefreshList: React.Dispatch<React.SetStateAction<boolean>>,
    orgUser: IOrganizationUser,
    setOrgUser: React.Dispatch<React.SetStateAction<IOrganizationUser>>
}> = ({ roleList, setRefreshList, orgUser, setOrgUser }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const {setPromptType,setPromptText} = usePromptText();
    const [associatedSite, setAssociatedSite] = useState(false);
    const [loader, setLoader] = useState(false);
    const headingRef = useRef<HTMLParagraphElement>(null);

    const onClose = () => {
        form.resetFields();
        setOrgUser({
            id: '',
            email: '',
            full_name: '',
            user_name: '',
            role_id: ''
        })
    };

    const handleAfterOpenChange = async(open: boolean) => {
        if(open && orgUser.role_name === "Site Administrator") {
            setLoader(true);
            const result = await new OrganizationService().getCheckAssociatedSites({site_admin_id: orgUser.id});
            if(result){
                setAssociatedSite(result.data.data.is_associate_to_site);
                setLoader(false);
            }
        }
    } 

    useEffect(() => {
        form.resetFields();
        return(() => {
            setAssociatedSite(false);
        })
    }, [orgUser, form])

    useEffect(()=>{
        if(associatedSite){
            setTimeout(()=>{
                headingRef.current?.focus();
                headingRef.current?.click();
              },1000)
        }
      }, [associatedSite]);

    const onFinish = async (formValues: { role_id: string }) => {
        let isOperationSuccess = false;
        let promptText = "Updating user role...";
        let params: IRequestOrgUserChangeRole = {
            userId: orgUser.id,
            roleId: formValues.role_id,
            oldRoleId: orgUser.role_id ? orgUser.role_id : '',
            isAssociateToSite: associatedSite
        }
        setPromptType("assertive");
        setPromptText(promptText);
        messageApi.open({
            key: "requestingRoleChange",
            type: "loading",
            content: promptText,
            duration: 0,
        });

        try {
            setIsSubmitting(true);
            await new OrganizationService().postChangeUserRole(params);
            isOperationSuccess = true;
        } catch (error) {
            console.log(error);
        }
        setIsSubmitting(false);
        messageApi.destroy("requestingRoleChange");
        setPromptType("assertive");
        promptText = isOperationSuccess ? "User role updated successfully " : "Unable to update user role";
        setPromptText(promptText);
        messageApi.open({
            type: isOperationSuccess ? "success" : "error",
            content: promptText,
            duration: 5,
        });
        setRefreshList(true);
        onClose();
    };

    return (
        <Drawer
          title={<h2 aria-label="Edit user">Edit User</h2>}
          className="customDrawer"
          width={560}
          closable={false}
          onClose={onClose}
          open={orgUser.id !== ""}
          afterOpenChange={(open) => handleAfterOpenChange(open)}
          destroyOnClose={true}
          maskClosable={!isSubmitting}
          extra={
            <Button
              onClick={onClose}
              className="closeBlk"
              disabled={isSubmitting}
            >
              Close
            </Button>
          }
        >
          <Spin spinning={roleList.isLoading || loader}>
            {contextHolder}
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
              className="drawerContent"
              initialValues={orgUser}
            >
              <div className="blkContent">
                <Form.Item label="Full Name">{orgUser.full_name}</Form.Item>
                <Form.Item label="Email">{orgUser.email}</Form.Item>
                <Form.Item label="User Name">{orgUser.user_name}</Form.Item>
                <CustomRoleRadioGroup roleList={roleList} />
                {associatedSite && (
                  <div className="notificationTxtWrap">
                    <ExclamationCircleFilled
                      className="mediumColor notificationIcon"
                      aria-hidden
                      aria-label=""
                    />
                    <div className="notificationTxt">
                      <p tabIndex={-1} ref={headingRef}>
                      Changing <strong>{orgUser.full_name}</strong>'s role will result in the removal of his/her association with site(s).
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className="blkFooter">
                <Form.Item>
                  <Space>
                    <Button
                      htmlType="button"
                      onClick={onClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  </Space>
                </Form.Item>
              </div>
            </Form>
          </Spin>
        </Drawer>
    );

};
export default OrgUserEdit;