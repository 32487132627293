import { Fragment, useCallback, useEffect, useState } from "react";
import {
  Table,
  Input,
  Select,
  MenuProps,
  PaginationProps,
  Tag,
  Tooltip,
  message,
  Form,
  Button,
  Spin,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { IProjectListData } from "../../models/interface";
import { OrganizationService, ProjectListService } from "../../services";
import { SorterResult } from "antd/es/table/interface";
import {
  StarOutlined,
  StarFilled,
  MoreOutlined,
  UserOutlined,
  CopyOutlined,
  GlobalOutlined,
  DeleteOutlined,
  EditOutlined,
  CloseCircleFilled,
  LockFilled,
  LoadingOutlined,
  CloseOutlined,
  CodeOutlined,
  CalculatorOutlined,
  HistoryOutlined
} from "@ant-design/icons";
import {
  getQueryParams,
  renderExtraTags,
  TooltipParagraph,
  toTitleCase,
} from "../../../shared/utility";
import { useLocation, useNavigate } from "react-router-dom";
import { useProjectListStore } from "../../store";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useCreateNewProjectStore } from "modules/organization/store/createNewProjectStore";
import Paginator from "modules/shared/components/Paginator";
import { MenuKeys } from "modules/organization/models/enums/menuKey.enums";
import CustomDropdownMenu from "modules/shared/components/CustomDropdownMenu";
import { RBAC, useRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { filterKebabMenuItems } from 'modules/organization/utility/commonFunctions';
import { colorConfigs, dateFormat } from "config/commonConfig";
import CloneProject from "./OrgCloneProject";
import DeleteProject from "./DeleteProject";
import { mnDate } from 'modules/shared/services';

const { Search } = Input;

export const _menuItems = [
  {
    label: "Edit Project",
    key: MenuKeys.EDIT_PROJECT,
    icon: <EditOutlined aria-hidden/>,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_EDIT],
  },
  {
    label: "Manage Users",
    key: MenuKeys.MANAGE_PROJECT_USERS,
    icon: <UserOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_TEAM_MODIFY_PERMISSION],
  },
  {
    label: "Duplicate",
    key: MenuKeys.CLONE_PROJECT,
    icon: <CopyOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_CLONE],
  },
  {
    label: "Publish",
    key: MenuKeys.PUBLISH_PROJECT,
    icon: <GlobalOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_PUBLISH],
    show_published: false,
    questionCount: true,
    recordCount: true,
  },
  {
    label: "Republish",
    key: MenuKeys.PUBLISH_PROJECT,
    icon: <GlobalOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_PUBLISH],
    show_published: true,
    questionCount: true,
    recordCount: true,
  },
  {
    label: "Coding Review",
    key: MenuKeys.CODING_REVIEW,
    icon: <CodeOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_CODING_REVIEW],
    questionCount: true,
    recordCount: true,
  },
  {
    label: "Error Sampling",
    key: MenuKeys.ERROR_SAMPLING,
    icon: <CalculatorOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_ERROR_SAMPLING],
    questionCount: true,
    recordCount: true,
  },
  {
    label: "Suggested Text History",
    key: MenuKeys.SUGGESTIVE_TEXTS_HISTORY,
    icon: <HistoryOutlined aria-hidden />,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_SUGGESTIVE_TEXT_MANAGE],
    questionCount: true,
    recordCount: true,
    disabled: false,
    disableOnList: true
  },
  {
    label: "Delete",
    key: MenuKeys.DELETE_PROJECT,
    icon: <DeleteOutlined aria-hidden />,
    danger: true,
    divider: true,
    allowedPermissions: [ERbacPermissions.ORG_PROJECT_DELETE],
    show_published: false
  },
]

const OrgProjectList = () => {

  const { hasPermissions } = useRbac();

  const {
    projectListData,
    showTableLoader,
    projectsCount,
    currentPageSize,
    currentPage,
    currentSortColumn,
    currentSortOrder,
    search,
    role,
    tag,
    published,
    member,
    group_id,
    showConfirmDelete,

    resetState,
    setCurrentPageSize,
    setCurrentPage,
    setCurrentSortColumn,
    setCurrentSortOrder,
    setSearch,
    setRole,
    setTag,
    setPublished,
    setMember,
    setGroupId,
    setShowConfirmDelete,
    setSelectedProject,
    refreshProjectList,
  } = useProjectListStore();

  const {
    memberList,
    tagList,
    setTagList,
    setMemberList,
    projectAllRoleData,
    setProjectAllRoleData,
    setDrawerState,
    setDrawerOptions,
    projectCreateEditStatus,
  } = useCreateNewProjectStore();

  const [searchValue, setSearchValue] = useState("");

  const [isPageReady, setIsPageReady] = useState(false);

  const [cloneProjectDetails, setCloneProjectDetails] = useState<{projectSlug: string; projectTitle: string; projectRoleId: number | null; } | null>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const [groupName, setGroupName] = useState<string | null>(null);

  const { organizationBaseRouteUrl } = useOrganizationRoutes();

  const renderProjectsTags = (tags: string[] | null) => {
    if( !tags )  {
      return "-"
    }
    const firstElement = tags.shift();
    const firstTag = <span key="firstTag"><Tag color={colorConfigs.DEFAULT_TAG_COLOR}>
    {
      firstElement && firstElement.length > 10 ? <Tooltip title={firstElement}>{firstElement.slice(0, 10).trim()}...</Tooltip> : firstElement
    }
    </Tag></span>
    const secondTag = (
      <span key="secondTag">
        <Tooltip title={tags.join(", ")} trigger={["hover", "focus"]}>
          <Tag color={colorConfigs.DEFAULT_TAG_COLOR}>+{tags.length}</Tag>
        </Tooltip>
      </span>
    );
    if( tags.length > 0 ) {
      return [ firstTag, secondTag ]
    }
    return [firstTag]
  }

  /** Mark project Favorite UnFavorite start */
  const [goingToFavoriteProjectId, setGoingToFavoriteProjectId] = useState<number | null>(null);
  const markFavoriteProject = async(project: IProjectListData) => {
    if(!goingToFavoriteProjectId){
      setGoingToFavoriteProjectId(project.project_id);
      try {
        await new ProjectListService().favoriteProject(project.slug, project.is_favourite);
        fetchProjectList();
       setGoingToFavoriteProjectId(null);
      } catch (error) {
        setGoingToFavoriteProjectId(null);
        message.error('Failed to Favorite/Unfavorite project');
      }
    }
  }
  /** Mark project Favorite UnFavorite end */

  const projectListColumns: ColumnsType<IProjectListData> = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      render: (data, record) => {
        const antIcon = <LoadingOutlined style={{ fontSize: 15 }} spin />;
        return (
          <div className="tblTitle withFav">
            <Tooltip title={record.is_favourite ? 'Mark Unfavorite' : 'Mark Favorite'} trigger={["hover", "focus"]}>
              <Button onClick={() => {
                markFavoriteProject(record)
              }}
              aria-label={record.is_favourite ? 'Mark Unfavorite' : 'Mark Favorite'}
              className="iconOnlyBtn smallIcon"
              >
              
                  {goingToFavoriteProjectId === record.project_id &&
                    <Spin indicator={antIcon} />
                  }
                  
                  {goingToFavoriteProjectId !== record.project_id &&
                    <Fragment>
                    {record.is_favourite ? (
                      <StarFilled className="active" />
                    ) : (
                      <StarOutlined />
                    )}
                    </Fragment>
                  }
              </Button>
            </Tooltip>
            <div className="titleLinkWrap" aria-label={`Project title: ${data}. ${!record.project_role_id ? 'This project has limited access' : ''}`}>
                <TooltipParagraph className="recent-projects" toLink={organizationBaseRouteUrl + `/project/${record.slug}`}>
                  {data}
                </TooltipParagraph>
            </div>
            {!record.project_role_id && (
              <span>
                <Tooltip title="Limited access" trigger={["hover", "focus"]}>
                  <LockFilled className='active' />
                </Tooltip>
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "TAGS",
      dataIndex: "tags",
      width: 160,
      render: (tags) => {
        const tmpTags = tags?.filter((tag: string) => tag);
        tags = tmpTags?.length ? tmpTags : null;
        return (
          renderProjectsTags(tags)
        );
      },
    },
    {
      title: "NO. OF RECORDS",
      dataIndex: "record_count",
      width: 160,
      sorter: true,
      align: "right",
      render: (data) => {
        return <>{data || "-"}</>;
      },
    },
    {
      title: "Your role",
      dataIndex: "project_role_name",
      width: 125,
      sorter: true,
      render: (data) => {
        return toTitleCase(data === "individual editor" ? "editor" : data);
      },
    },
    {
      title: "LAST UPDATED",
      dataIndex: "updated_at",
      width: 180,
      sorter: true,
      defaultSortOrder: "descend",
      render: (data) => {
        return <>{mnDate(data).format(dateFormat.tableDateTime)}</>;
      },
    },
    {
      title: "PUBLISHED",
      dataIndex: "is_published",
      width: 115,
      align: "center",
      sorter: true,
      render: (data) => {
        return (
          <>
            {data ? (
              <div className="pubYes">Yes</div>
            ) : (
              <div className="pubNo">No</div>
            )}
            {data}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "project_id",
      width: 55,
      align: "center",
      render: (_data, record) => {

        const onSelect = (e: any) => {          
          if (
            [MenuKeys.EDIT_PROJECT, MenuKeys.MANAGE_PROJECT_USERS].includes(
              parseInt(e.key)
            )
          ) {
            setDrawerState(true);
            setDrawerOptions(parseInt(e.key), record);
          }
          else if(MenuKeys.CLONE_PROJECT === parseInt(e.key)){
            setCloneProjectDetails({
              projectSlug: record.slug,
              projectTitle: record.title,
              projectRoleId: record.project_role_id
            })
          } else if ([MenuKeys.DELETE_PROJECT].includes(parseInt(e.key))) {
            setShowConfirmDelete(!showConfirmDelete);
            setSelectedProject(record);
          } else if ([MenuKeys.CODING_REVIEW].includes(parseInt(e.key))) {
            navigate(organizationBaseRouteUrl + `/project/${record.slug}/coding-review`);
          } else if ([MenuKeys.PUBLISH_PROJECT].includes(parseInt(e.key))) {
            navigate(organizationBaseRouteUrl + `/project/${record.slug}/publish`);
          } else if ([MenuKeys.ERROR_SAMPLING].includes(parseInt(e.key))) {
            navigate(organizationBaseRouteUrl + `/project/${record.slug}/error-sampling`);
          }
        };

        const filteredKebabMenuItems = _menuItems
        .filter((item) => !item.disableOnList)
        .filter(item => {
            if( item.questionCount === undefined || item.recordCount === undefined || ((item.questionCount === Boolean(record?.question_count)) && (item.recordCount === Boolean(record?.record_count)))){
              // Render all independent option
              if(item.show_published === undefined) {
                return true
              }

              //Filter options based on Project published or not
              if(record.is_published) {
                return item.show_published
              } else if(!record.is_published) {
                return !item.show_published
              }
            }
            return false
          }
        );

        const listActionItemsList: MenuProps["items"] = filterKebabMenuItems(filteredKebabMenuItems, hasPermissions, {project_role_id: record.project_role_id, direct_permissions: record?.direct_permissions});

        return (
          <Fragment>
            {listActionItemsList.some((item) => item?.key) ? (
              <CustomDropdownMenu menuItems={listActionItemsList} onSelect={onSelect}>
                <MoreOutlined />
              </CustomDropdownMenu>
            ) : (
              <span />
            )}
          </Fragment>
        );
      },
    },
  ];

  const paginationRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  const onChange: TableProps<IProjectListData>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    if (sorter) {
      const { field, order } = sorter as SorterResult<IProjectListData>;
      field && setCurrentSortColumn(field.toString());
      order && setCurrentSortOrder(order === "ascend" ? "asc" : "desc");
    }
  };

  const getShowingDataString = () => {
    const startIndex = (currentPage - 1) * currentPageSize + 1;
    const endIndex =
      projectsCount < currentPage * currentPageSize
        ? projectsCount
        : currentPage * currentPageSize;
    return `Showing ${startIndex} to ${endIndex} of ${projectsCount} Items`;
  };

  const fetchProjectList = useCallback(async () => {
    refreshProjectList({
      page_index: currentPage,
      page_size: currentPageSize,
      sort_column: currentSortColumn,
      sort_order: currentSortOrder,
      search: search === "" ? null : search,
      role: role,
      tag: tag.length > 0 ? tag.join(",") : null,
      published: published,
      group_id: group_id,
      member_id: member
    }).catch(console.error);
  }, [
    currentPage,
    currentPageSize,
    currentSortColumn,
    currentSortOrder,
    role,
    tag,
    published,
    search,
    group_id,
    member,
    refreshProjectList,
  ]);

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={colorConfigs.DEFAULT_TAG_COLOR}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  function handleChange(event: any) {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      setSearch("");
    }
  }

  const getTagList = useCallback(async () => {
    if (!tagList) {
      try {
        const result = await new ProjectListService().getProjectTagListData();
        const { tagList } = result.data.data;
        setTagList(tagList);
      } catch (error) {
        console.error(error);
        await message.error({
          content: "Something went wrong please try again!",
        });
      }
    }
  }, [setTagList, tagList]);

  const getProjectRoleData = useCallback(async () => {
    if (!projectAllRoleData) {
      try {
        const result = await new ProjectListService().getProjectRoleMasterData({
          show_all: true,
        });
        const { roleList } = result.data.data;
        setProjectAllRoleData(roleList);
      } catch (error) {
        console.error(error);
        await message.error({
          content: "Something went wrong please try again!",
        });
      }
    }
  }, [setProjectAllRoleData, projectAllRoleData]);

  const getOrganizationUsers = useCallback(async () => {
    if(!memberList){
      try {
        const result = await new OrganizationService().getOrganizationUserList();
        const { userList } = result.data.data;
        setMemberList(userList);
      } catch (error) {
        console.error(error);
        message.error({
          content: 'Something went wrong please try again!'
        });
      }
    }
  }, [setMemberList, memberList]);

  useEffect(() => {
    const locationSearch = location.search;
    if (locationSearch?.includes("filter")) {
      try {
        const queryObject = getQueryParams(locationSearch);
        if (queryObject.filter) {
          const filter = queryObject.filter;
          if (filter.role) {
            setRole(filter.role);
          }
          if(filter.group_id && filter.group_name) {
            setGroupId(filter.group_id);
            setGroupName(filter.group_name);
          }
        }
      } catch (e) {
        console.error("Failed to extract role information");
      }
    }
    setIsPageReady(true);
  }, [setRole, setGroupId, location.search]);

  useEffect(() => {
    getTagList().catch(console.error);
  }, [getTagList]);

  useEffect(() => {
    getProjectRoleData().catch(console.error);
  }, [getProjectRoleData]);

  useEffect(() => {
    getOrganizationUsers().catch(console.error);
  },[getOrganizationUsers])

  useEffect(() => {
    if (isPageReady) fetchProjectList().catch(console.error);
  }, [fetchProjectList, isPageReady]);

  useEffect(() => {
    if (projectCreateEditStatus && projectCreateEditStatus !== "UNKNOWN_ERROR")
      fetchProjectList().catch(console.error);
  }, [fetchProjectList, projectCreateEditStatus]);

  useEffect(() => {
    return () => {
      setMemberList(null);
      resetState();
    };
    // eslint-disable-next-line 
  }, [resetState]);

  return (
    <div className="tableWrapper">
      <div className="tableSettingBlk">
        { (group_id && groupName) &&
          <Tag 
            closable 
            closeIcon={<CloseOutlined style={{color: 'white'}} />} 
            className="outdatedLaw" 
            onClose={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              navigate(organizationBaseRouteUrl + '/projects', { replace: true });
              resetState();
            }}
          >
            Group Name : {groupName?.length > 25 ? <Tooltip title={groupName} trigger={["hover", "focus"]}>{`${groupName?.slice(0,25)}...`}</Tooltip> : groupName}
          </Tag>
        }
        <Search
          aria-label={"Search Projects"}
          title={"Search Projects"}
          placeholder="Search Projects"
          allowClear={{
            clearIcon: (
              <button className="clearBtn">
                <CloseCircleFilled />
              </button>
            ),
          }}
          value={searchValue}
          onSearch={() => {
            if (
              searchValue === null ||
              searchValue.length < 1 ||
              showTableLoader
            ) {
              return;
            }
            setSearch(searchValue);
          }}
          onChange={handleChange}
        />
        <Form.Item label="Tag">
          <Select
            virtual={false}
            aria-label={"Select Tag"}
            mode="multiple"
            value={tag}
            tagRender={tagRender}
            allowClear
            maxTagCount="responsive"
            maxTagPlaceholder={(omittedValues) => {
              if(omittedValues.length > 0){
                let stringValues = omittedValues.map( each => each.label?.toString() as string )
                return renderExtraTags(stringValues)
              }
            }}
            onChange={(value) => {
              setTag(value);
              setSearchValue("");
            }}
            onClear={() => setTag([])}
            placeholder="Select Tag"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={tagList ?? []}
            disabled={showTableLoader}
          />
        </Form.Item>
        <RBAC allowedPermissions={[ERbacPermissions.ORG_PROJECT_RECORD_SHOW_ROLE_FILTER]}>
          <Form.Item label="Role">
            <Select
              virtual={false}
              aria-label={"Select Role"}
              showSearch
              allowClear
              value={role}
              placeholder="Select Role"
              optionFilterProp="children"
              onSelect={(value) => {
                setRole(value);
                setSearchValue("");
              }}
              onClear={() => setRole(null)}
              filterOption={(input, option: any) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={(projectAllRoleData ?? []).map((d) => ({
                value: d.name,
                label: toTitleCase(d.name),
              }))}
              disabled={showTableLoader}
              style={{ width: '115px' }}
            />
          </Form.Item>
        </RBAC>
       
        <Form.Item label="Published">
          <Select
            virtual={false}
            aria-label={"Select Published"}
            showSearch
            allowClear
            value={published}
            placeholder="Select Published"
            optionFilterProp="children"
            onSelect={(value) => {
              setPublished(value);
              setSearchValue("");
            }}
            onClear={() => setPublished(null)}
            filterOption={(input, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: true,
                label: "Yes",
              },
              {
                value: false,
                label: "No",
              },
            ]}
            disabled={showTableLoader}
            style={{ width: '147px' }}
          />
        </Form.Item>

        <RBAC allowedPermissions={[ERbacPermissions.ORG_PROJECT_RECORD_SHOW_MEMBER_FILTER]}>
          <Form.Item label="Member">
            <Select
              virtual={false}
              aria-label={"Select member"}
              showSearch
              allowClear
              value={member}
              placeholder="Select Member"
              optionFilterProp="children"
              onSelect={(value) => {
                setMember(value);
                setSearchValue("");
              }}
              onClear={() => setMember(null)}
              filterOption={(input, option: any) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
              options={(memberList ?? []).filter(user => user.is_active === true).map((d) => ({
                value: d.id,
                label: `${d.full_name} (${d.user_name})`,
              }))}
              disabled={showTableLoader}
              style={{ width: '200px' }}
            />
          </Form.Item>
        </RBAC>
      </div>
      <div className="mainTable">
        <Table
          columns={projectListColumns}
          dataSource={projectListData}
          onChange={onChange}
          pagination={false}
          // scroll={{
          //   y: window.innerHeight - 330,
          //   scrollToFirstRowOnChange: true,
          // }}
          className="scrollableTable projectListTbl"
          loading={showTableLoader}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          rowKey={(record) => record.project_id}
        />
      </div>
      {!showTableLoader && (
        <Paginator
          currentPageSize={currentPageSize}
          count={projectsCount}
          setCurrentPageSize={setCurrentPageSize}
          setCurrentPage={setCurrentPage}
          getShowingDataString={getShowingDataString}
          paginationRender={paginationRender}
          currentPage={currentPage}
        />
      )}

      {cloneProjectDetails?.projectSlug && cloneProjectDetails.projectTitle &&
        <CloneProject setCloneProjectDetails={setCloneProjectDetails} showCloneProjectModal={true} projectSlug={cloneProjectDetails.projectSlug} projectTitle={cloneProjectDetails.projectTitle} projectRoleId={cloneProjectDetails.projectRoleId} resetCloneProjectDetails={()=>{
          setCloneProjectDetails(null);
        }} />
      }

      {showConfirmDelete && (
        <DeleteProject />
      )}
    </div>
  );
};

export default OrgProjectList;
