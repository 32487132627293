function validateHierarchicalOrder(existingOrders: string[], newOrder: string) {
    const maxLevels = 4;

    // Validate the newOrder format
    const orderRegex = new RegExp(`^\\d+(\\.\\d+){0,${maxLevels - 1}}$`);
    if (!orderRegex.test(newOrder)) {
        return { valid: false, message: "Invalid order format. Ex: 1, 1.1, 1.1.1, 1.1.1.1" };
    }

    const newOrderParts = newOrder.split('.');

    // Check if parent order exists
    if (newOrderParts.length > 1) {
        const parentOrder = newOrderParts.slice(0, -1).join('.');
        if (!existingOrders.includes(parentOrder)) {
            return { valid: false, message: `Parent order ${parentOrder} does not exist.` };
        }
    }

    // Ensure order uniqueness
    if (existingOrders.includes(newOrder)) {
        return { valid: false, message: `Order ${newOrder} already exists.` };
    }

    // Check sibling sequence
    if (newOrderParts.length > 1) {
        const parentOrder = newOrderParts.slice(0, -1).join('.');
        const siblingNumber = parseInt(newOrderParts[newOrderParts.length - 1], 10);
        for (let i = 1; i < siblingNumber; i++) {
            const siblingOrder = `${parentOrder}.${i}`;
            if (!existingOrders.includes(siblingOrder)) {
                return {
                    valid: false,
                    message: `Sibling order ${siblingOrder} does not exist. Cannot create ${newOrder}.`,
                };
            }
        }
    } else {
        // For root-level orders, check sequence
        const orderNumber = parseInt(newOrderParts[0], 10);
        for (let i = 1; i < orderNumber; i++) {
            if (!existingOrders.includes(`${i}`)) {
                return {
                    valid: false,
                    message: `Order ${i} does not exist. Cannot create ${newOrder}.`,
                };
            }
        }
    }

    return { valid: true, message: "Order is valid." };
}

const validateDNS = (
    value: string,
    currentKey: string,
    dataObj: any
): {
    isValid: boolean;
    errorMessage?: string
} => {
    // Default configuration
    const config = {
        maxDepth: 4,
        maxLength: 10,
        allowedChars: /^[0-9.]+$/
    };

    const mergedOptions = { ...config };

    // Trim the value
    const trimmedValue = value.trim();

    // Check if empty (optional)
    if (!trimmedValue) {
        return { isValid: true };
    }

    // Check total length
    if (trimmedValue.length > mergedOptions.maxLength) {
        return {
            isValid: false,
            errorMessage: `Maximum length is ${mergedOptions.maxLength} characters`
        };
    }

    // Check allowed characters
    if (!mergedOptions.allowedChars.test(trimmedValue)) {
        return {
            isValid: false,
            errorMessage: 'Only numbers and dots are allowed'
        };
    }

    // Split into segments
    const segments = trimmedValue.split('.');

    // Check depth
    if (segments.length > mergedOptions.maxDepth) {
        return {
            isValid: false,
            errorMessage: `Maximum hierarchy depth is ${mergedOptions.maxDepth - 1}`
        };
    }

    // Validate each segment
    for (const segment of segments) {
        // Ensure no leading zeros (except single 0)
        if (segment.length > 1 && segment.startsWith('0')) {
            return {
                isValid: false,
                errorMessage: 'No leading zeros allowed in segments'
            };
        }

        // Ensure segment is a valid number
        if (!/^\d+$/.test(segment)) {
            return {
                isValid: false,
                errorMessage: 'Segments must be numeric'
            };
        }

        // Optional: Limit segment value (e.g., max 999 in each segment)
        if (parseInt(segment, 10) > 999) {
            return {
                isValid: false,
                errorMessage: 'Segment values cannot exceed 999'
            };
        }
    }

    // Hierarchical order validation
    const existingOrders = dataObj.dataList
        .filter((item: any) => item.key !== currentKey)
        .map((item: any) => item.order)
        .filter((order: any) => order !== undefined && order !== null);

    // Check hierarchical order
    //console.log(existingOrders, value);

    validateHierarchicalOrder(existingOrders, value)

    // Check for duplicate order
    const isDuplicate = existingOrders.includes(trimmedValue);
    if (isDuplicate) {
        return {
            isValid: false,
            errorMessage: 'This order already exists'
        };
    }

    return { isValid: true };
};



const validateQuestionOrder = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    if (item['order']?.trim().length < 1) {
        if (errorRefs?.current[`order_${index}`]) {
            errorRefs.current[`order_${index}`].style.border = '1px solid red';
        }
        !validationGlobalErrors.hasOwnProperty(`order_${globalIndex}`) && setValidationGlobalErrors(`order_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: 'Order is mandatory' });
    }
    if ((item['order']?.trim().length > 0)) {
        const inputValue = item['order'];
        const validationObj = validateDNS(inputValue, item['key'], dataObj);

        if (!validationObj?.isValid) {
            if (errorRefs?.current[`order_${index}`]) {
                errorRefs.current[`order_${index}`].style.border = '1px solid red';
            }
            !validationGlobalErrors.hasOwnProperty(`order_${globalIndex}`) && setValidationGlobalErrors(`order_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: `${validationObj?.errorMessage}` });
        } else {
            if (errorRefs?.current[`order_${index}`]) {
                errorRefs.current[`order_${index}`].style.border = '1px solid white';
            }
            validationGlobalErrors.hasOwnProperty(`order_${globalIndex}`) && setValidationGlobalErrors(`order_${globalIndex}`, 'Delete', '');
        }
    }
}

const validateQuestionVariable = (item: any, globalIndex: number, dataObj: any) => {
    const {
        dataList,
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    const variableName = item['variable_name']?.trim();

    if (!variableName) {
        if (errorRefs?.current[`variable_name_${index}`]) {
            errorRefs.current[`variable_name_${index}`].style.border = '1px solid red';
        }
        !validationGlobalErrors.hasOwnProperty(`variable_name_${globalIndex}`) && setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: 'Variable name is mandatory' });
    }

    if (variableName.length > 0) {
      const variableNamePattern = /^[A-Za-z0-9-_]+$/; // Allowed characters
      const isVariableNameValid = variableNamePattern.test(variableName) && variableName.length >= 3 && !/\s/.test(variableName); // Added space check
      console.log(isVariableNameValid);
      

      // Check for duplicates
      const isDuplicate = dataList.some((item: any) => item.variable_name === variableName && item.key !== dataList[globalIndex].key);

      // Update validation logic to include duplicate check and pattern match
      if (!isVariableNameValid || isDuplicate) {
        if (errorRefs?.current[`variable_name_${index}`]) {
          errorRefs.current[`variable_name_${index}`].style.border = '1px solid red';
        }
        let errorMessage = '';
        if (!isVariableNameValid) {
          errorMessage = 'Variable name must be at least 3 characters and can only contain alphanumeric characters, underscores, and hyphens.';
        } 
        if (isDuplicate) {
          errorMessage = 'This variable name already exists.';
        }
        !validationGlobalErrors.hasOwnProperty(`variable_name_${globalIndex}`) && setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: errorMessage });
      } else {
        if (errorRefs?.current[`variable_name_${index}`]) {
          errorRefs.current[`variable_name_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`variable_name_${globalIndex}`) && setValidationGlobalErrors(`variable_name_${globalIndex}`, 'Delete', '');
      }
    }
}

const validateQuestionName = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    const questionName = item['question']?.trim();

    if (!questionName.length) {
        if (errorRefs?.current[`question_${index}`]) {
            errorRefs.current[`question_${index}`].style.border = '1px solid red';
        }
        !validationGlobalErrors.hasOwnProperty(`question_${globalIndex}`) && setValidationGlobalErrors(`question_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: 'Question name is mandatory' });
    } else {
        if (errorRefs?.current[`question_${index}`]) {
          errorRefs.current[`question_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`question_${globalIndex}`) && setValidationGlobalErrors(`question_${globalIndex}`, 'Delete', '');
      }
}

const validateQuestionType = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)
    const questionType = item['question_type']?.trim();

    if (!questionType) {
        if (errorRefs?.current[`question_type_${index}`]) {
            errorRefs.current[`question_type_${index}`].style.border = '1px solid red';
        }
        !validationGlobalErrors.hasOwnProperty(`question_type_${globalIndex}`) && setValidationGlobalErrors(`question_type_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: 'Question Type is mandatory' });
    } else {
        if (errorRefs?.current[`question_type_${index}`]) {
          errorRefs.current[`question_type_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`question_type_${globalIndex}`) && setValidationGlobalErrors(`question_type_${globalIndex}`, 'Delete', '');
      }
}

const validatePossibleOptions = (item: any, globalIndex: number, dataObj: any) => {
    const {
        currentPage,
        currentPageSize,
        validationGlobalErrors,
        errorRefs,
        setValidationGlobalErrors,
    } = dataObj;
    const index = globalIndex - ((currentPage - 1) * currentPageSize)

    if (( !['TEXT', 'DATE', 'NUMBER'].includes(item['question_type']) && item['possible_options']?.length < 2)) {
        if (errorRefs?.current[`possible_options_${index}`]) {
          errorRefs.current[`possible_options_${index}`].style.border = '1px solid red';
        }
        !validationGlobalErrors.hasOwnProperty(`possible_options_${globalIndex}`) && setValidationGlobalErrors(`possible_options_${globalIndex}`, 'Add', { page: currentPage, row: index + 1, message: 'Atleast two options is mandatory' });
      } else {
        if (errorRefs?.current[`possible_options_${index}`]) {
          errorRefs.current[`possible_options_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`possible_options_${globalIndex}`) && setValidationGlobalErrors(`possible_options_${globalIndex}`, 'Delete', '');
      }
}

export const validateQuestions = (dataObj: any) => {
    const { dataList } = dataObj;
    dataList.forEach((item: any, globalIndex: number) => {
        validateQuestionOrder(item, globalIndex, dataObj);
        validateQuestionVariable(item, globalIndex, dataObj);
        validateQuestionName(item, globalIndex, dataObj);
        validateQuestionType(item, globalIndex, dataObj);
        validatePossibleOptions(item, globalIndex, dataObj);

    });

}
