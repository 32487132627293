export interface ILogicalExpression extends Array<string | number | ILogicalExpression> {}

export class EquationParser{
    public equationRaw: string;
    private allTokens: string[];
    private tokens: string[];
    public equationStack: ILogicalExpression;

    constructor(equation: string){
        this.equationRaw = equation;
        const _tokenize = this.tokenize(equation);
        this.allTokens = _tokenize.allTokens;
        this.tokens = _tokenize.token;
        this.equationStack = this.parse(this.tokens);
    }

    getEquation(){
        return this.equationRaw;
    }

    getEquationStack(){
        return this.equationStack;
    }

    getHasError(){
        return this.validateEquationSyntax(this.allTokens, this.tokens, this.equationRaw);
    }

    private validateEquationParenthesisSyntax(tokens: string[]) {
    
   
        let stack = [];
        let prevToken = null;
    
        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
    
            if (token === '(') {
                // Push opening parenthesis onto the stack
                stack.push(token);
            } else if (token === ')') {
                // Pop an opening parenthesis from the stack
                if (stack.pop() !== '(') {
                    return i + 1; // Mismatched parentheses
                }
            }
    
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            prevToken = token;
        }
    
        // Check if there are any unmatched opening parentheses
        return stack.length === 0 ? 0 : tokens.length + 1; // Unclosed parenthesis
    }
    
    private validateEquationSyntax(allTokens:string[], tokens:string[], equation: string) {
        const operatorPattern = /^(AND|QAND|OR|QOR|IMPORT|EQUAL|NOTEQUAL|COMPARE|QHASANSWER|QHASNOTANSWER)$/;
        if (!allTokens || equation === '') {
            return 0;
        }
    
        if(this.validateEquationParenthesisSyntax(tokens)>0){
            return allTokens.length + 1;
        }
    
        let prevToken = null;
    
        for (let i = 0; i < allTokens.length; i++) {
            const token = allTokens[i];
            const nextToken = allTokens[i + 1];
    
            
    
            if(i === 0 &&  !operatorPattern.test(token)){
                return i + 1; // Invalid starting operator
            }
            else if(!nextToken){
                if(token !== ')'){
                    return i + 1; // Last token must be operator closing bracket
                }
            }
            else if (token === '(') {
                if (!prevToken || !operatorPattern.test(prevToken)) {
                    return i + 1; // Invalid position for opening parenthesis
                }
                else if(nextToken === ')'){
                    return i + 1; // Operator parameter required 
                }
    
            } else if (token === ')') {
                
            } else if (operatorPattern.test(token)) {
                if(prevToken === ')'){
                    return i + 1;
                }
                // Operators should be followed by opening parenthesis or another operator
                else if (nextToken !== '(') {
                    return i + 1; // Missing opening parenthesis or unexpected operator after operator
                }
                else if(operatorPattern.test(nextToken)){
                    return i + 1; // Next token of the operator can not be possible
                }
    
            }else if(token === ','){
                if(!nextToken){
                    return i + 1; // Can not last token coma(,)
                }
                else if(nextToken === ',' || prevToken === ','){
                    return i + 1; // Coma(,) after coma can not be allowed
                }
                else if(prevToken === '(' || nextToken === ')'){
                    return i + 1; // Can not operator opening and closing brackets before and after comma(,)
                }
    
            }
    
            prevToken = token;
        }
    
        const parseArr = this.parse(tokens);
        if(parseArr.length !== 2 ){
            return allTokens.length + 1; 
        }
    
        return 0; // Unclosed parenthesis
    }

    private tokenize(input: string) {
        const tokens:string[] = [];
        const allTokens:string[] = [];
        const operators = ['AND', 'QAND', 'OR', 'QOR', 'EQUAL', 'NOTEQUAL', 'COMPARE', 'QHASANSWER', 'QHASNOTANSWER', 'IMPORT'];
        let currentToken = '';
    
        for (let i = 0; i < input.length; i++) {
            const char = input[i];
    
            if (char === '(') {
                if (currentToken) {
                    tokens.push(currentToken.trim());
                    allTokens.push(currentToken.trim());
                    currentToken = '';
                }
                tokens.push('(');
                allTokens.push('(');
            } else if (char === ')') {
                if (currentToken) {
                    tokens.push(currentToken.trim());
                    allTokens.push(currentToken.trim());
                    currentToken = '';
                }
                tokens.push(')');
                allTokens.push(')');
            } else if (char === ',') {
                if (currentToken) {
                    tokens.push(currentToken.trim());
                    allTokens.push(currentToken.trim());
                    currentToken = '';
                }
                allTokens.push(char.trim());
            } else {
                currentToken += char;
                if (i === input.length - 1) {
                    tokens.push(currentToken.trim());
                    allTokens.push(currentToken.trim());
                }
            }
        }
    
        return{
            token: tokens.filter(token => operators.includes(token) || token !== ''),
            allTokens: allTokens.filter(token => operators.includes(token) || token !== '')
        };
    }

    private parse(tokens: string[]) {
        let currentToken = 0;
    
        function parseExpression() {
            let expr:ILogicalExpression = [];
            
            while (currentToken < tokens.length) {
                if (tokens[currentToken] === '(') {
                    currentToken++;
                    expr.push(parseExpression());
                } else if (tokens[currentToken] === ')') {
                    currentToken++;
                    return expr;
                } else {
                    const token = tokens[currentToken];
                    currentToken++;
                    expr.push(token);
                }
            }
    
            return expr;
        }
    
        return parseExpression();
    }
    
}