import { useEffect } from "react";
import { Outlet, useLocation,  } from "react-router-dom";
import useWindowFocus from "use-window-focus";
import { useAuth0 } from "@auth0/auth0-react";
import { useSwitchOrganizationStore } from "modules/auth/store";

const SwitchOrganizationOutlet = () => {

    const { user_id, org_id, org_key, is_app_admin } = useSwitchOrganizationStore();
    const windowFocused = useWindowFocus();
    const { user } = useAuth0();
    const location = useLocation();

    useEffect(() => {
      
        if(windowFocused && user_id && (location.pathname.includes('/app') || location.pathname.includes('/org/'))){
            if(!is_app_admin){
                if(user_id !== user?.sub || user?.org_id !== org_id){
                  setTimeout(()=>{
                      window.location.href = "/org/" + org_key;
                  }, 600)
                }
            }
            //&& user_id !== user?.sub
            if(is_app_admin && !location.pathname.includes('/app')){
              window.location.href = "/app";
            }
        }
      }, [windowFocused, user_id, org_id, org_key, is_app_admin, location, user])

    return (
        <Outlet />
    );
}

export default SwitchOrganizationOutlet;