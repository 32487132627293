import { Fragment } from "react";
import { RBAC, withRbac } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import ErrorBoundary from "modules/shared/components/ErrorBoundary";
import { usePageTitle } from "modules/shared/hooks";
import OrgCreateSite from "./components/OrgCreateSite";
import OrgSiteProjectList from "./components/OrgSiteProjectList";
import { useSiteStore } from "../store/siteStore";
import { Button } from "antd";
import { useOrganizationRoutes } from "routes/organizationRoute";
import OrgDeleteSite from './components/OrgDeleteSite';
import { useNavigate } from "react-router-dom";

const OrgSiteListPage = () => {
  usePageTitle("MonQcle - Sites");
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { site,showTableLoader } = useSiteStore();
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(`${organizationBaseRouteUrl}/site/${site}`);
  }

  return (
    <Fragment>
      <div className="pageHeadingWrap pageTopSection">
        <h2>Site Management</h2>
        {site > 0 ? (
          <div className="btnWrap">
            <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_EDIT]}>
              <ErrorBoundary>
                <Button type="primary" className="flexLeftGap" onClick={handleOnClick} disabled={showTableLoader}>Site Info</Button>
              </ErrorBoundary>
            </RBAC>
            <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_DELETE]}>
              <OrgDeleteSite />
            </RBAC>
          </div>
        ) : (
          <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_CREATE]}>
            <ErrorBoundary>
              <OrgCreateSite />
            </ErrorBoundary>
          </RBAC>
        )}
      </div>
      <ErrorBoundary>
        <OrgSiteProjectList />
      </ErrorBoundary>
    </Fragment>
  );
};

export default withRbac(OrgSiteListPage, { allowedPermissions: [ERbacPermissions.ORG_SITE_LIST_ALL,ERbacPermissions.ORG_SITE_LIST_ASSIGNED], isAccessFromPage: true });