import { IApplicationConfiguration, IProjectRolePermissions, IUserDetails } from "models/interface";
import AppStorage from "../../../models/class/StorageWrapper";
import { GetTokenSilentlyOptions } from "@auth0/auth0-react";

enum Locals {
  ORGANIZATION_KEY = 'organization_key',
  CURRENT_USER_KEY = 'current_user_key'
}

interface ICurrentUserLocalStorage {
  user: IUserDetails;
  projectRolePermissions: IProjectRolePermissions[];
  applicationConfiguration: IApplicationConfiguration;
}

export default class AuthService extends AppStorage<Locals> {
  private static instance?: AuthService;
  private getAccessTokenSilentlyFunction?: () => Promise<string>;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AuthService();
    }
    return this.instance;
  }


  public getCurrentUserLocalStorage(): ICurrentUserLocalStorage | null {
    const userData = this.get(Locals.CURRENT_USER_KEY);
    if(!userData){
      return null;
    }
    try {
      const currentUserLocalStorage:ICurrentUserLocalStorage = JSON.parse(userData);
      if(currentUserLocalStorage?.user?.org_id && currentUserLocalStorage.user.id && currentUserLocalStorage.user.email && currentUserLocalStorage.projectRolePermissions && currentUserLocalStorage.applicationConfiguration){
        return currentUserLocalStorage;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  public setCurrentUserLocalStorage(userData: ICurrentUserLocalStorage) {
    if(userData?.user?.org_id && userData.user.id && userData.user.email && userData.projectRolePermissions && userData.applicationConfiguration){
      this.set(Locals.CURRENT_USER_KEY, JSON.stringify(userData), 1*60*60*1000);
    }
  }

  public clearCurrentUserLocalStorage() {
    this.clearItem(Locals.CURRENT_USER_KEY);
  }


  public getGetCurrentOrganization() {
    return this.get(Locals.ORGANIZATION_KEY);
  }

  public setCurrentOrganization(organizationId: string) {
    this.set(Locals.ORGANIZATION_KEY, organizationId);
  }

  public clearCurrentOrganization() {
    this.clearItem(Locals.ORGANIZATION_KEY);
  }

  public setAccessTokenSilentlyFunction(func: (opts?: GetTokenSilentlyOptions) => Promise<string>){
    this.getAccessTokenSilentlyFunction = func;
  }

  public async getAccessToken(){
    if(this.getAccessTokenSilentlyFunction){
      return await this.getAccessTokenSilentlyFunction();
    }
    return null;
  }
}