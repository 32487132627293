import React, { useState } from "react";
import CustomDropdownMenu from "modules/shared/components/CustomDropdownMenu";
import { SettingOutlined } from "@ant-design/icons";
import {
  useCreateNewProjectStore,
  useProjectDetailsStore,
  useProjectListStore,
} from "modules/organization/store";
import { _menuItems } from "modules/organization/orgProjectList/components/OrgProjectList";
import { filterKebabMenuItems } from "modules/organization/utility/commonFunctions";
import { MenuProps } from "antd";
import { MenuKeys } from "modules/organization/models/enums";
import { useRbac } from "auth/rbac/rbac";
import {
  TProjectRoleId,
} from "modules/organization/models/interface";
import DeleteProject from "modules/organization/orgProjectList/components/DeleteProject";
import CloneProject from "modules/organization/orgProjectList/components/OrgCloneProject";
import OrgCreateNewProject from "modules/organization/orgProjectList/components/OrgCreateNewProject";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useNavigate } from "react-router-dom";
import SuggestiveTextHistoryDrawer from "./SuggestiveTextHistoryDrawer";

interface Props {
    disabled: boolean;
    entityCount: { questionCount: number, recordCount: number };
}

const ProjectGearActions: React.FC<Props> = ({disabled, entityCount}) => {
  const { setDrawerOptions, resetState, setDrawerState } =
    useCreateNewProjectStore();
  const { projectDetails } = useProjectDetailsStore();
  const { showConfirmDelete, setShowConfirmDelete, setSelectedProject } = useProjectListStore();
  const [cloneProjectDetails, setCloneProjectDetails] = useState<{
    projectSlug: string | undefined;
    projectTitle: string | undefined;
    projectRoleId: TProjectRoleId | null | number;
  } | null>(null);

  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { setShowSuggestiveTextHistory } = useProjectDetailsStore();

  const navigate = useNavigate();

  const { hasPermissions } = useRbac();

  const { setGearAction } = useCreateNewProjectStore();

  const filteredKebabMenuItems = _menuItems.filter((item) => {
    if( item.questionCount === undefined || item.recordCount === undefined || (item.questionCount === Boolean(entityCount.questionCount) && item.recordCount === Boolean(entityCount.recordCount)) ){
      // Render all independent option
      if (item.show_published === undefined) {
        return true;
      }

      //Filter options based on Project published or not
      if (projectDetails?.is_published) {
        return item.show_published;
      } else if (!projectDetails?.is_published) {
        return !item.show_published;
      }
    } 
    return false;
  })
  .map((item) => {
    if (item.key === MenuKeys.SUGGESTIVE_TEXTS_HISTORY) {
      item.disabled = !projectDetails?.is_suggestive_text_history_enabled;
    }
    return item;
  });

  const listActionItemsList: MenuProps["items"] = filterKebabMenuItems(
    filteredKebabMenuItems,
    hasPermissions,
    { project_role_id: projectDetails?.project_role_id, direct_permissions: projectDetails?.direct_permissions }
  );

  const onSelect = (e: any) => {
    resetState();
    if (
      [MenuKeys.EDIT_PROJECT, MenuKeys.MANAGE_PROJECT_USERS].includes(
        parseInt(e.key)
      )
    ) {
      setGearAction(true);
      setDrawerState(true);
      setDrawerOptions(parseInt(e.key), projectDetails ?? undefined);
    } else if (MenuKeys.CLONE_PROJECT === parseInt(e.key)) {
      setCloneProjectDetails({
        projectSlug: projectDetails?.slug,
        projectTitle: projectDetails?.title,
        projectRoleId: projectDetails?.project_role_id as number,
      });
    } else if ([MenuKeys.DELETE_PROJECT].includes(parseInt(e.key))) {
      setGearAction(true);
      setShowConfirmDelete(!showConfirmDelete);
      setSelectedProject(projectDetails);
    } else if ([MenuKeys.CODING_REVIEW].includes(parseInt(e.key))) {
      navigate(
        organizationBaseRouteUrl + `/project/${projectDetails?.slug}/coding-review`
      );
    } else if ([MenuKeys.PUBLISH_PROJECT].includes(parseInt(e.key))) {
      navigate(
        organizationBaseRouteUrl + `/project/${projectDetails?.slug}/publish`
      );
    } else if ([MenuKeys.ERROR_SAMPLING].includes(parseInt(e.key))) {
      navigate(organizationBaseRouteUrl + `/project/${projectDetails?.slug}/error-sampling`);
    } else if ([MenuKeys.SUGGESTIVE_TEXTS_HISTORY].includes(parseInt(e.key))) {      
      setShowSuggestiveTextHistory(true);
    }
  };

  return (
    <div>
      {listActionItemsList.length > 0 && (
        <CustomDropdownMenu menuItems={listActionItemsList} disabled={disabled} onSelect={onSelect}>
          <SettingOutlined />
        </CustomDropdownMenu>
      )}
      <OrgCreateNewProject />
      {showConfirmDelete && <DeleteProject />}
      {cloneProjectDetails?.projectSlug && cloneProjectDetails?.projectTitle && (
        <CloneProject
          showCloneProjectModal={true}
          setCloneProjectDetails={setCloneProjectDetails}
          projectSlug={cloneProjectDetails.projectSlug}
          projectTitle={cloneProjectDetails.projectTitle}
          projectRoleId={cloneProjectDetails.projectRoleId}
          resetCloneProjectDetails={() => {
            setCloneProjectDetails(null);
          }}
        />
      )}
      <SuggestiveTextHistoryDrawer />
    </div>
  );
};

export default ProjectGearActions;
