import React, { useCallback, useEffect } from "react";
import { useSiteStore } from "modules/organization/store/siteStore";
import OrgSiteUserList from "./OrgSiteUserList";

const SiteUserTab: React.FC = () => {
  const { site, siteInfo,isFirstLoad, setSite } = useSiteStore();
  const setSiteId = useCallback(() => {
    if (siteInfo && isFirstLoad){
        setSite(siteInfo.id);
    } 
  }, [siteInfo,setSite,isFirstLoad]);

  useEffect(() => {
    setSiteId();
  }, [setSiteId]);

  return (
    <div className="tabContent">
        {site > 0 && <OrgSiteUserList/>}
    </div>
  );
};

export default SiteUserTab;
