import { Button, Result } from 'antd';
import React, { Fragment } from 'react';
import { usePageTitle } from '../hooks';
import { ExceptionStatusType } from 'antd/es/result';
import HttpStatusCode from 'models/enums/httpStatusCode.enums';
import { Link } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { useOrganizationRoutes } from 'routes/organizationRoute';

interface Props {
  statusCode?: HttpStatusCode;
}

const contents: {[key: number]: {
  title: string;
  subTitle: string;
}} = {
  404: {
    title: "Page not found",
    subTitle: "Sorry, the page you visited does not exist."
  },
  500: {
    title: "Something went wrong",
    subTitle: "Sorry, there is a system error."
  },
  403: {
    title: "Access denied",
    subTitle: "You do not have the permission to access this page."
  }
} as const;

const NotFound: React.FC<Props> = ({statusCode = 404}) => {

  const { organizationBaseRouteUrl } = useOrganizationRoutes();

  usePageTitle("MonQcle - Page not found");
  return (
    <Fragment>
          <div className="pageTopSection">
            <div className="backLink">
              <Link
                to={
                  organizationBaseRouteUrl + `/projects`
                }
              >
                <LeftOutlined />
                Back to Project List
              </Link>
            </div>
          </div>
          <div className="verifyMsgWrap" style={{ minHeight: "80vh" }}>
            <Result
              status={[404, 500, 403].includes(statusCode) ? statusCode as ExceptionStatusType : 500}
              title={contents[[404, 500, 403].includes(statusCode) ? statusCode : 500].title}
              subTitle={contents[[404, 500, 403].includes(statusCode) ? statusCode : 500].subTitle}
              extra={<Button type="primary" href='/'>Back Home</Button>}
            />
          </div>
        </Fragment>
  );
};

export default NotFound;
