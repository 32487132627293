import { Button } from 'antd';
import { importingSteps, useImporterToolStore } from 'modules/organization/store/importerToolStore';

//statusDone active

const StageStepStatus = () => {

  const { finishStep, currentStep, setCurrentStep } = useImporterToolStore();

  const statusClassNames = (key: number) => {
    if (currentStep.key === key) {
      return 'active';
    } else if (finishStep >= key) {
      return 'statusDone';
    } else if (key === finishStep + 1) {
      return 'enabled';
    } else {
      return undefined;
    }
  }

  return (
    <div className='publishSteps importerSteps'>
      <ul>
        {Object.values(importingSteps).filter(eachSteps => !eachSteps.hideStepsControl).map(eachSteps => 
          <li className={statusClassNames(eachSteps.key)} key={eachSteps.key} aria-label={eachSteps.name}>
            <Button onClick={() => (eachSteps.key <= finishStep + 1) && setCurrentStep(eachSteps.key)} className='stepBtn' disabled={eachSteps.key > finishStep + 1}>{eachSteps.key}</Button>
            <strong onClick={() => (eachSteps.key <= finishStep + 1) && setCurrentStep(eachSteps.key)}>{eachSteps.name}</strong>
            <br />
          </li>
        )}
      </ul>
    </div>
  );
}

export default StageStepStatus;
