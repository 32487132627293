import { Button, Form, Select, Space, Spin, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import { useProjectRecordsStore } from "modules/organization/store";
import { useWatch } from "antd/es/form/Form";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";
import GoogleMapViewer from "./GoogleMapViewer";
import { useJsApiLoader } from "@react-google-maps/api";
import { appConfig } from "config/config";
import { copyToClipboard } from "modules/shared/utility";

function createUniqueArray(arr: any) {
  const uniqueArray: any = [];

  return arr?.filter((item: any) => {
    if (!uniqueArray.includes(item.value)) {
      uniqueArray.push(item.value);
      return true;
    }
    return false;
  });
}

async function fetchPlacesList(
  search: string,
  setData: Function,
  type: any,
  setSearchLoading: Function
) {
  setSearchLoading(true);

  const layer = type === "all" || type === "county" ? "" : `&type=${type}`;

  const res = await fetch(
    `https://api.geoapify.com/v1/geocode/autocomplete?text=${search}&apiKey=1b48259b810e48ddb151889f9ea58db0&limit=100${
      type === "state" || type === "city" || type === "county"
        ? "&filter=countrycode:us&bias=countrycode:us"
        : ""
    }${layer}`
  );

  const body: any = await res.json();

  let data = [];

  data =
    type !== "all"
      ? await body?.features?.filter(
          (each: any) => each.properties.result_type === type
        )
      : await body?.features;

  data = await data?.map((place: any) => ({
    label: (
      <>
        <strong>({place.properties.result_type})</strong>{" "}
        {Array.from(
          new Set([
            place.properties?.housenumber,
            place.properties?.name,
            place.properties?.street,
            place.properties?.city,
            place.properties?.county,
            place.properties?.state_district,
            place.properties?.state,
            place.properties?.postcode,
            place.properties?.country,
          ])
        )
          .filter((each) => each?.length > 1)
          .join(", ")}
      </>
    ),
    value: place.properties.place_id,
    type: place.properties.result_type,
  }));

  setData(createUniqueArray(data));
  return setSearchLoading(false);
}

const CustomJurisdiction = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [data, setData] = useState([]);
  const [type, setType] = useState("state");
  const [searchLoading, setSearchLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchTimeOut, setSearchTimeOut] = useState<any>(null);
  const { recordDetails } = useProjectRecordsStore();
  const placeIds = useWatch(["place", "place_id"], form);
  const [copied, setCopied] = useState<boolean>(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [geoData, setGeoData] = useState<any>(null);
  const [placeIdsArray, setPlaceIdsArray] = useState<any[]>([]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: appConfig.REACT_APP_MAP_API_KEY,
  });

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  function findDeletedIndexes(originalArray: any[], newArray: any[]) {
    const deletedIndexes: any[] = [];

    originalArray?.forEach((element, index) => {
      if (!newArray.includes(element)) {
        deletedIndexes.push(index);
      }
    });

    return deletedIndexes;
  }

  useEffect(() => {
    const deletedIndexes = findDeletedIndexes(placeIdsArray, placeIds);
    if (deletedIndexes?.length > 0) {
      setGeoData((prev: any) => {
        const features = prev?.features;
        features?.splice(deletedIndexes[0], 1)
        return {
          type: "FeatureCollection",
          features: [...features],
        };
      });
      setPlaceIdsArray(placeIds);
    }
  }, [placeIds]);

  useEffect(() => {
    console.log(geoData);
  },[geoData])

  function handleChange(value: string) {
    if (value.length < 3) {
      return;
    }

    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        setSearchKeyword(value);
        fetchPlacesList(value, setData, type, setSearchLoading);
      }, 500)
    );
  }

  async function handleSelect(value: string) {
    setLoadingMap(true);
    fetch(
      `https://api.geoapify.com/v2/place-details?id=${
        value.split("_")[0]
      }&lang=en&apiKey=1b48259b810e48ddb151889f9ea58db0`
    )
      .then((r) => r.json())
      .then(async (d) => {
        setPlaceIdsArray((prev: any[]) => {
          return Array.from(new Set([...prev, value]));
        });
        setGeoData((prevData: any) => {
          if (prevData === null) {
            return {
              type: "FeatureCollection",
              features: [d?.features[0]],
            };
          }
          return {
            type: "FeatureCollection",
            features: prevData ? [...prevData.features, d?.features[0]] : [d?.features[0]],
          };
        });
        return setLoadingMap(false);
      })
      .catch((e) => {
        console.error(e);
        setGeoData(null);
        return setLoadingMap(false);
      });
  }

  const handleCopy = async () => {
    const success = await copyToClipboard(
      JSON.stringify(placeIds.map((each: any) => each.split("_")[0]))
    );
    setCopied(success);
  };

  useEffect(() => {
    return () => {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
    };
  }, [searchTimeOut]);

  return (
    <div className="mainContent">
      <Form
        form={form}
        autoComplete="off"
        initialValues={{
          jurisdiction_id: null,
          place: {
            type: "state",
          },
        }}
      >
        <div className="coveredFldWrap jurisdictionFldWrap">
          <div className="coveredFld">
            <Form.Item
              label="Find Place"
              name={["place", "type"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                showSearch
                disabled={Boolean(recordDetails)}
                onChange={(value) => {
                  setType(value);
                }}
                onSelect={() => {
                  setData([]);
                  form.setFieldValue(
                    ["place", "jurisdiction_value"],
                    undefined
                  );
                  setSearchKeyword("");
                }}
                className="jurisdictionSelect"
                options={[
                  {
                    label: "Countries",
                    value: "country",
                  },
                  {
                    label: "States(US)",
                    value: "state",
                  },
                  {
                    label: "Cities(US)",
                    value: "city",
                  },
                  {
                    label: "Counties(US)",
                    value: "county",
                  },
                  {
                    label: "All Places",
                    value: "all",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name={["place", "place_id"]}
              className="jurisdictionFld"
              style={{ maxWidth: "none" }}
              rules={[
                {
                  required: true,
                  message: "Please enter jurisdiction name",
                },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                allowClear={!searchLoading}
                placeholder="Search..."
                defaultActiveFirstOption={false}
                showArrow={searchLoading}
                maxTagCount={"responsive"}
                loading={searchLoading}
                filterOption={false}
                onSelect={handleSelect}
                notFoundContent={
                  searchKeyword.length !== 0 &&
                  !searchLoading &&
                  "No data found"
                }
                onSearch={handleChange}
              >
                {data.map((item: any) => {
                  return (
                    <Select.Option
                      key={`${item.value}`}
                      aria-label={item.label.props.children[2].toString()}
                      value={`${item.value}_${item.geofile}_${item.type}`}
                    >
                      <Tooltip
                        placement="top"
                        trigger={['hover','focus']}
                        title={item.label.props.children[2].toString()}
                      >
                        {item.label}
                      </Tooltip>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
      {placeIds?.length ? (
        <Space direction="vertical">
          <Space>
            <h4>Place Id</h4>
            <Button
              className="iconOnlyBtn"
              onClick={handleCopy}
            >
              {!copied ? <CopyOutlined /> : <CheckOutlined />}
            </Button>
          </Space>
          <Typography>
            <pre>
              {JSON.stringify(placeIds.map((each: any) => each.split("_")[0]))}
            </pre>
          </Typography>
        </Space>
      ) : null}
      <div>
        {isLoaded && (
          <Spin spinning={loadingMap}>
            {!loadingMap && Boolean(geoData) && (
              <GoogleMapViewer geoData={geoData} loadingMap={loadingMap} />
            )}
            {loadingMap && <div style={{ height: "450px" }}></div>}
          </Spin>
        )}
      </div>
    </div>
  );
};

export default CustomJurisdiction;
