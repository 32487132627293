import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { Outlet, useNavigate } from "react-router-dom";
import { appConfig } from "../config/config";
import AuthService from "../modules/shared/services/authService";
import Auth0Check from "./Auth0Check";
const authService = AuthService.getInstance();

const Auth0Outlet= ( ) => {
  const navigate = useNavigate();
  const domain = appConfig.REACT_APP_AUTH0_DOMAIN;
  const clientId = appConfig.REACT_APP_AUTH0_CLIENT_ID;
  const redirectUri = window.location.origin;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo ?? window.location.pathname);
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  const authorizationParams:any = {
    audience: appConfig.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: redirectUri,
   // scope: 'profile email read:users',
  }

  const currentOrganization = authService.getGetCurrentOrganization();
  if(currentOrganization && currentOrganization !== ''){
    authorizationParams.organization = currentOrganization;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0Check>
        <Outlet />
      </Auth0Check>
    </Auth0Provider>
  );
};


export default Auth0Outlet;