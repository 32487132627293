import React, { useCallback, useEffect, useState } from "react";
import siteLogo from "../../assets/images/site-logo.svg";
import { Button, Form, Input } from "antd";
import { Link, useLocation } from "react-router-dom";
import { UserService } from "../shared/services";
import { IInvitationRegistrationData } from "../../models/interface";
import PageLoader from "../shared/components/PageLoader";
import CustomCreatePasswordWrapper from 'modules/shared/components/CustomCreatePasswordWrapper';

const AcceptInvitation = () => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [invitationInfo, setInvitationInfo] = useState({
    orgName: "",
    userEmail: "",
    isValidToken: null,
    isRegistrationPending: null,
    isExistingUser: null,
    adminName: "",
    isLoading: true,
  });
  const [errorObject, setErrorObject] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [successObject, setSuccessObject] = useState<{
    isSuccess: boolean;
    message: string;
  }>({ isSuccess: false, message: "" });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ticket = params.get("ticket") ?? "";
  console.log(ticket);

  const postToken = useCallback(async () => {
    if (ticket !== "") {
      try {
        const tokenData = (await new UserService().postInvitationLink(ticket))
          .data?.data;
        setInvitationInfo((prevState: any) => {
          return {
            ...prevState,
            isLoading: false,
            orgName: tokenData.data?.org_name ?? "",
            userEmail: tokenData.data?.email ?? "",
            isValidToken: tokenData.token_status,
            isExistingUser: tokenData.data?.existing_user_status,
            adminName: tokenData.data?.admin_name ?? "",
            isRegistrationPending: tokenData.data?.pending_status,
          };
        });
      } catch (err) {
        setInvitationInfo((prevState: any) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        console.log(err);
      }
    } else {
      setInvitationInfo((prevState: any) => {
        return {
          ...prevState,
          isLoading: false,
          isValidToken: false,
        };
      });
    }
  }, [setInvitationInfo, ticket]);
  useEffect(() => {
    postToken();
  }, [postToken]);

  useEffect(() => {
    if (
      invitationInfo.isValidToken === false ||
      invitationInfo.isRegistrationPending === false
    ) {
      setErrorObject({
        isError: true,
        message: "Invitation is Invalid or Expired",
      });
    }
  }, [invitationInfo.isValidToken, invitationInfo.isRegistrationPending]);

  const onFinish = async (formValues: IInvitationRegistrationData) => {
    console.log(formValues);
    console.log(invitationInfo);
    setErrorObject({ isError: false, message: "" });
    setIsSubmitting(true);
    if (invitationInfo.isExistingUser) {
      formValues.fullName = "";
      formValues.userName = "";
      formValues.password = "";
    }
    try {
      const registrationResponse: { token_status: boolean; data: any } = (
        await new UserService().postInvitedRegistration(
          ticket,
          formValues.fullName,
          formValues.userName,
          formValues.password
        )
      ).data?.data;
      // log registrationResponse
      if (registrationResponse?.token_status) {
        setIsSubmitting(false);
        setSuccessObject({isSuccess: true, message: "Account Created Successfully!"});
      } else {
        setErrorObject({
          isError: true,
          message: registrationResponse?.data?.message,
        });
        if (registrationResponse?.data?.message === "User already Registered") {
          setInvitationInfo((prevState: any) => {
            return {
              ...prevState,
              isRegistrationPending: false,
            };
          });
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setErrorObject({
        isError: true,
        message: "Submit Unsuccessful. Please try again",
      });
      console.log(error);
    }
  };
  const onFinishFailed = () => {
    setErrorObject({ isError: true, message: "Unable to register" });
  };

  return (
    <React.Fragment>
      {invitationInfo.isLoading ? (
        <PageLoader></PageLoader>
      ) : (
          <main className="loginWrapper">
            <div className="picBlk"></div>
            <div className="loginFormWrap">
              <div className="formBlk">
                <h1>
                  <img src={siteLogo} alt="MonQcle by the Center for Public Health Law Research" />
                </h1>
                {invitationInfo.isValidToken &&
                  !successObject.isSuccess &&
                  invitationInfo.isRegistrationPending && 
                  invitationInfo.adminName && (
                    <p>
                      Invitation from <span>{invitationInfo.adminName}</span>
                    </p>
                  )}
                {errorObject.isError && (
                  <div className="msgError bottomGap1">
                    {errorObject.message}
                  </div>
                )}
                {successObject.isSuccess && invitationInfo.isRegistrationPending && (
                <React.Fragment>
                  <div className="regSuccess bottomGap1">
                    <p>{successObject.message}</p>
                  </div>
                  <div className="supportLink topGap1">
                    <Link to="/">
                      Go to Log In
                    </Link>
                  </div>
                </React.Fragment>)}
                {!successObject.isSuccess && invitationInfo.isValidToken &&
                  invitationInfo.isRegistrationPending && (
                    <React.Fragment>
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        className="drawerContent"
                      >
                        <Form.Item
                          name="orgName"
                          label="Organization Name"
                          initialValue={invitationInfo.orgName}
                        >
                          <Input disabled />
                        </Form.Item>

                        <Form.Item
                          name="userEmail"
                          label="Your Email"
                          id="email"
                          initialValue={invitationInfo.userEmail}
                          rules={[{ type: "email" }]}
                        >
                          <Input disabled />
                        </Form.Item>
                        {!invitationInfo.isExistingUser ? (
                          <React.Fragment>
                            <Form.Item
                              name="fullName"
                              id="fullName"
                              label="Your Full Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Full name can not be empty",
                                },
                              ]}
                            >
                              <Input placeholder="Enter Your Full Name" maxLength={100} />
                            </Form.Item>
                            <Form.Item
                              name="userName"
                              id="userName"
                              label="Your User Name"
                              rules={[
                                {
                                  required: true,
                                  message: "User name can not be empty",
                                },
                                {
                                  pattern: /^[a-zA-Z0-9_-]{6,10}$/,
                                  message:
                                    "Username must be 6 to 10 characters long and can only contain alphanumeric characters, underscore (_) and hyphen (-) without space",
                                },
                              ]}
                            >
                              <Input placeholder="Create User Name" />
                            </Form.Item>
                            
                            <CustomCreatePasswordWrapper />

                            <Form.Item>
                              <Button
                                className="btnStyle1"
                                type="primary"
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                              >
                                Register
                              </Button>
                            </Form.Item>
                          </React.Fragment>
                        ) : (
                            <Form.Item>
                              <Button
                                className="btnStyle1"
                                type="primary"
                                htmlType="submit"
                                disabled={isSubmitting}
                                loading={isSubmitting}
                              >
                                Accept Invitation
                              </Button>
                            </Form.Item>
                        )}
                      </Form>

                      <div className="termsCondition">
                        By registering for a MonQcle account, you agree to our{" "}
                        <Link to="..">User Agreement</Link> and{" "}
                        <Link to="..">Privacy Policy</Link>.
                      </div>
                    </React.Fragment>
                  )}
              </div>
            </div>
          </main>
      )}
    </React.Fragment>
  );
};

export default AcceptInvitation;
