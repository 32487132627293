import { useEffect, useRef } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, message, Modal } from "antd";
import {
  useCreateNewProjectStore,
    useProjectListStore,
} from "modules/organization/store";
import { ProjectListService } from "modules/organization/services";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { useNavigate } from "react-router-dom";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";

const DeleteProject: React.FC<{ getRecentProjects?: Function }> = ({ getRecentProjects }) => {

  const {
    currentPage,
    currentPageSize,
    currentSortColumn,
    currentSortOrder,
    search,
    role,
    tag,
    published,
    group_id,
    member,
    selectedProject,
    refreshProjectList,
    showConfirmDelete,
    setShowConfirmDelete,
  } = useProjectListStore();

  const { gearAction, setGearAction } = useCreateNewProjectStore();

  const okButtonRef = useRef<HTMLButtonElement>(null);
  const {setPromptType,setPromptText} = usePromptText();

  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const navigate = useNavigate();
  const headingRef = useRef<HTMLParagraphElement>(null);

  const doDeleteAProject = async () => {
    const deleteProjectKey = "deleteProjectKey";
    let promptText = "Deleting project...";
    if (selectedProject) {
      setPromptType("assertive");
      setPromptText(promptText);
      message.open({
        key: deleteProjectKey,
        content: promptText,
        type: "loading",
        duration: 0,
      });
      setShowConfirmDelete(false);
      try {
        if (selectedProject) {
          await new ProjectListService().deleteProject({
            project_slug: selectedProject?.slug,
          })
          promptText = "Project deleted successfully";
          setPromptType("assertive");
          setPromptText(promptText);
          message.success(promptText);
          if(gearAction === true){
            setGearAction(false)
            navigate(
              organizationBaseRouteUrl + `/projects`
            );
          } else if (getRecentProjects) {
            getRecentProjects().catch(console.error);
          } else {
            refreshProjectList({
              page_index: currentPage,
              page_size: currentPageSize,
              sort_column: currentSortColumn,
              sort_order: currentSortOrder,
              search: search === "" ? null : search,
              role: role,
              tag: tag.length > 0 ? tag.join(",") : null,
              published: published,
              group_id: group_id,
              member_id: member,
            }).catch(console.error);
          }
        }
      } catch (error) {
        console.log(error);
        promptText = "Failed to delete project";
        setPromptType("assertive");
        setPromptText(promptText);
        message.error(promptText);
      }
    }
    message.destroy(deleteProjectKey);
  };
  useEffect(()=>{
    setTimeout(()=>{
      headingRef.current?.focus();
      headingRef.current?.click();
    },1000)

  }, [])


  return (
    <Modal
        className="confirmationModal"
        title={<>Delete Project</>}
        centered
        open={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(false)}
        footer={[
          <Button key="no" onClick={() => setShowConfirmDelete(false)}>
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={doDeleteAProject}
            ref={okButtonRef}
          >
            Delete Project
          </Button>,
        ]}
      >
        <div className="notificationTxtWrap">
          <ExclamationCircleFilled className="mediumColor notificationIcon" aria-hidden aria-label=""/>
          <div className="notificationTxt">
            <p tabIndex={-1} ref={headingRef}>Are you sure you want to delete <strong>{selectedProject?.title}</strong>?</p>
          </div>
        </div>
      </Modal>
  );
};

export default DeleteProject;