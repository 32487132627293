import { IApiBaseResponse } from 'models/interface';
import { ApiService } from 'modules/shared/services';
import { IResponseQuestionSnapshot } from '../models/interface';
import { RoutesEnum } from 'models/enums';
import { IRankingTemplateFormData, IResponseCreateEditRankingTemplate, IResponseRankingTemplateListData } from '../models/interface/rankingSetup.interface';
import { IEquationAnswer } from '../store';

export class RankingProjectService {
    public async getQuestionSnapshot(ranking_project_id: number) {
        const formattedApiUrl = RoutesEnum.ORG_RANKING_PROJECT_GET_QUESTION.replace('{ranking_project_id}', `${ranking_project_id}`);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseQuestionSnapshot>>(formattedApiUrl);
    }
    public async getRankingTemplates() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseRankingTemplateListData>>(RoutesEnum.ORG_RANKING_PROJECT_GET_RANKING_TEMPLATES);
    }
    
    public async createRankingTemplate(payload: IRankingTemplateFormData) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditRankingTemplate>>(RoutesEnum.ORG_RANKING_PROJECT_CREATE_RANKING_TEMPLATE, payload)
    }

    public async editRankingTemplate(payload: IRankingTemplateFormData) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditRankingTemplate>>(RoutesEnum.ORG_RANKING_PROJECT_EDIT_RANKING_TEMPLATE, payload)
    }

    public async deleteRankingTemplate(payload: { id: number }) {
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseCreateEditRankingTemplate>>(RoutesEnum.ORG_RANKING_PROJECT_DELETE_RANKING_TEMPLATE, { data: { ...payload } })
    }

    public async getRecordAnswers(ranking_project_id: number, record_id: number) {
        const formattedApiUrl = RoutesEnum.ORG_RANKING_PROJECT_GET_RECORD_ANSWERS
            .replace('{ranking_project_id}', `${ranking_project_id}`)
            .replace('{record_id}', `${record_id}`);
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{ answers: IEquationAnswer[] }>>(formattedApiUrl);
    }
}