import { DatePicker, Input, InputNumber, Select, Table, TableColumnsType } from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { dateFormat } from "config/commonConfig";
import { mnDate } from "modules/shared/services/date.service";
import { debounce, isEmpty } from "lodash";

function findDuplicateIndices(array: any[], keys: any[]): number[] {
  const combinations = new Map(); // Map to store combinations and their indices

  for (let index = 0; index < array.length; index++) {
    // Generate a unique string by combining the values of the specified keys
    const normalizedValues = keys.map(key => {
      const value =  key === 'effective_from' || key === 'through_to' ? mnDate(array[index][key], false).format(dateFormat.default) : array[index][key]; // Normalize date format
      return `${key}:${value}`;
    });

    const combination = normalizedValues.join('|');

    if (!combinations.has(combination)) {
      combinations.set(combination, []); // Initialize an array to store indices
    }
    combinations.get(combination).push(index); // Add the current index to the combination
  }

  // Collect all duplicate indices into a 1D array
  const duplicateIndices: number[] = [];
  combinations.forEach(indices => {
    if (indices.length > 1) {
      duplicateIndices.push(...indices); // Add all indices to the result if it's a duplicate
    }
  });

  return duplicateIndices;
}


const Jurisdictions = () => {
  const { projectDetails } = useProjectDetailsStore();
  const {
    showLoader,
    headerList,
    dataList,
    currentPage,
    currentPageSize,
    validationGlobalErrors,
    setValidationGlobalErrors,
    setDataList,
    refreshDataList,
    resetDataState
  } = useImporterToolStore();

  const errorRefs = useRef([] as any);
  const rowRefs = useRef<any>({});

  const setErrorRef = useCallback(
    (id: string, ref: any) => {
      if (ref) {
        errorRefs.current[id] = ref;
      }
    },
    []
  );

  const paginatedData = useMemo(() => {
    return dataList?.slice(
      (currentPage - 1) * currentPageSize,
      currentPage * currentPageSize
    );
  }, [dataList, currentPage, currentPageSize]);

  const handleChange = useCallback((rowIndex: number, dataIndex: string) => {
    return debounce((newValue) => {
      setDataList(rowIndex, dataIndex, newValue);
    }, 300);
  }, []);

  const validateRequiredFields = (
    item: any,
    fields: string[],
    index: number,
    errorRefs: React.RefObject<any>,
    setValidationGlobalErrors: (key: string, action: string, message: any) => void,
  ) => {
    const globalIndex = index + ((currentPage - 1) * currentPageSize)
    fields.forEach((field) => {
      const isEmpty = item[field]?.length < 1; // Check if the field is empty
              
      if(isEmpty) {
        if (errorRefs?.current[`${field}_${index}`]) {
          errorRefs.current[`${field}_${index}`].style.border = '1px solid red';
          errorRefs.current[`${field}_${index}`].id = `${field}_${globalIndex}`;
        }
        !validationGlobalErrors.hasOwnProperty(`${field}_${globalIndex}`) && setValidationGlobalErrors(`${field}_${globalIndex}`, 'Add', { page: currentPage, row: index+1, message: `${field.charAt(0).toUpperCase()}${field.slice(1)} is mandatory` });
      } else {
        if(errorRefs?.current[`${field}_${index}`]) {
          errorRefs.current[`${field}_${index}`].style.border = '1px solid white';
        }
        validationGlobalErrors.hasOwnProperty(`${field}_${globalIndex}`) && setValidationGlobalErrors(`${field}_${globalIndex}`, 'Delete', '');
      }
    });
  };

  const validateData = (data: any[]) => {

    data.forEach((item, globalIndex) => {
      const index = globalIndex - ((currentPage - 1) * currentPageSize)
      validateRequiredFields(item, ['series'], index, errorRefs, setValidationGlobalErrors);
      if( item['effective_from']?.length < 1 ) {
        if (errorRefs?.current[`effective_from_${index}`]) {
          errorRefs.current[`effective_from_${index}`].style.border = '1px solid red';
          errorRefs.current[`effective_from_${index}`].id = `effective_from_${globalIndex}`;
        }
        !validationGlobalErrors.hasOwnProperty(`effective_from_${globalIndex}`) && setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Add', { page: currentPage, row: index+1, message: 'Effective date is mandatory' });
      }
      if( item['through_to']?.length < 1 ) {
        if (errorRefs?.current[`through_to_${index}`]) {
          errorRefs.current[`through_to_${index}`].style.border = '1px solid red';
          errorRefs.current[`through_to_${index}`].id = `through_to_${globalIndex}`;
        }
        !validationGlobalErrors.hasOwnProperty(`through_to_${globalIndex}`) && setValidationGlobalErrors(`through_to_${globalIndex}`, 'Add', { page: currentPage, row: index+1, message: 'Through date is mandatory' });
      }
      if((item['effective_from']?.length > 0) && (item['through_to']?.length > 0)){
        if( (mnDate(item['effective_from'], false).date).isAfter(mnDate(item['through_to'], false).date) ) {
          if(errorRefs?.current[`effective_from_${index}`]) {
            errorRefs.current[`effective_from_${index}`].style.border = '1px solid red';
            errorRefs.current[`through_to_${index}`].style.border = '1px solid red';
            errorRefs.current[`effective_from_${index}`].id = `effective_from_${globalIndex}`;
            errorRefs.current[`through_to_${index}`].id = `through_to_${globalIndex}`;
          }
          !validationGlobalErrors.hasOwnProperty(`effective_from_${globalIndex}`) && setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Add', { page: currentPage, row: index+1, message: 'Effective date > Through date' });
          !validationGlobalErrors.hasOwnProperty(`through_to_${globalIndex}`) && setValidationGlobalErrors(`through_to_${globalIndex}`, 'Add', { page: currentPage, row: index+1, message: 'Through date < Effective date' });
        } else {
          if(errorRefs?.current[`effective_from_${index}`]) {
            errorRefs.current[`effective_from_${index}`].style.border = '1px solid white';
            errorRefs.current[`through_to_${index}`].style.border = '1px solid white';
          }
          validationGlobalErrors.hasOwnProperty(`effective_from_${globalIndex}`) && setValidationGlobalErrors(`effective_from_${globalIndex}`, 'Delete', '');
          validationGlobalErrors.hasOwnProperty(`through_to_${globalIndex}`) && setValidationGlobalErrors(`through_to_${globalIndex}`, 'Delete', '');
        }
      }
      // Add more validation rules as needed
    });
  };

  const renderFunction = useCallback(
    (
      value: any,
      record: any,
      type: string,
      dynamic: boolean = false,
      child: any,
      rowIndex: number,
      dataIndex: string
    ) => {

      if (dynamic) {
        return !child.dataIndex.startsWith("caution") ? (
          ['CAT-ME', 'CAT-CA', 'BIN'].includes(record[`type_${child.dataIndex}`]) ? (
            <Select
              bordered={false}
              style={{ width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.42)" }}
              defaultValue={value ?? undefined}
              mode={
                record[`type_${child.dataIndex}`] === "CAT-CA"
                  ? "multiple"
                  : undefined
              }
              placeholder="Select option"
              options={record[`options_${child?.dataIndex}`]?.map(
                (item: any) => ({
                  value: item,
                  label: item,
                })
              )}
              onChange={(val) => {
                if (val !== value) {
                  // Check if value has changed
                  handleChange(rowIndex, dataIndex)(val);
                }
              }}
              allowClear
            />
          ) : (
            record[`type_${child.dataIndex}`] === 'TEXT' ? (
              <Input.TextArea
                defaultValue={value}
                className="importerInputs"
                placeholder="Enter text response"
                style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                onBlur={(e) => {
                  if (e.target.value !== (value??"")) { handleChange(rowIndex, dataIndex)(e.target.value) }}
                }
                rows={1}
                allowClear
              />
            ) : (
              record[`type_${child.dataIndex}`] === 'DATE' ? (
                <DatePicker
                  defaultValue={value}
                  className="importerInputs"
                  placeholder="Enter text response"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                  onBlur={(e) => {
                    if (e.target.value !== (value??"")) { handleChange(rowIndex, dataIndex)(e.target.value) }}
                  }
                />
              ) : (
                <InputNumber
                  defaultValue={value}
                  className="importerInputs"
                  placeholder="Enter text response"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
                  onBlur={(e) => {
                    if (e.target.value !== (value??"")) { handleChange(rowIndex, dataIndex)(e.target.value) }}
                  }
                />
              )
            )
          )
        ) : (
          <Input.TextArea
            defaultValue={value}
            className="importerInputs"
            placeholder="Enter caution note"
            style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
            onBlur={(e) => {
              if (e.target.value !== (value??"")) { handleChange(rowIndex, dataIndex)(e.target.value) }}
            }
            rows={1}
            allowClear
          />
        );
      }

      if (type === "date") {
        // 
        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <DatePicker
              defaultValue={value ? mnDate(value, false).date : undefined}
              className="importerInputs"
              style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
              aria-label="date"
              placeholder="Pick date"
              format={dateFormat.default}
              onBlur={(e) => {
                if (e.target.value !== mnDate(value, false).format(dateFormat.default)) {
                  handleChange(rowIndex, dataIndex)(e.target.value);
                }
              }}                 
            />
          </div>
        );
      } else if(type === "text") {
        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <Input
              defaultValue={value}
              className="importerInputs"
              placeholder={`Enter ${dataIndex}`}
              style={{ backgroundColor: "rgba(255, 255, 255, 0.42)" }}
              onBlur={(e) => {
                if (e.target.value !== (value??"")) { handleChange(rowIndex, dataIndex)(e.target.value) }}
              }
              allowClear
            />
          </div>
        );
      } else if(type === "select") {
        return (
          <Select
            bordered={false}
            style={{ width: "100%", height: "100%" }}
            defaultValue={value?.length > 0 ? value : null}
            mode={undefined}
            placeholder="Select option"
            options={child.map(
              (item: any) => ({
                value: item,
                label: item,
              })
            )}
            onChange={(val) => {
              if (val !== value) {
                // Check if value has changed
                handleChange(rowIndex, dataIndex)(val);
              }
            }}
            allowClear
          />
        )
      }
    },
    [handleChange]
  );

  const columns: TableColumnsType<any> = [
    {
      title: "",
      children: [
        {
          title: "Jurisdiction",
          dataIndex: "jurisdiction",
          key: "jurisdiction",
          width: 130,
          fixed: "left",
        },
      ],
    },
    {
      title: "",
      children: [
        {
          title: "Jurisdiction types",
          dataIndex: "jurisdiction_types",
          key: "jurisdiction_types",
          width: 150,
          // render: (value: any, record: any, rowIndex: number) =>
          //   renderFunction(
          //     value,
          //     record,
          //     "select",
          //     false,
          //     ["Country", "City", "State", "County"],
          //     rowIndex,
          //     "jurisdiction_types"
          //   ),
        },
        {
          title: "Country",
          dataIndex: "country",
          key: "country",
          width: 150,
          // render: (value: any, record: any, rowIndex: number) =>
          //   renderFunction(
          //     value,
          //     record,
          //     "text",
          //     false,
          //     [],
          //     rowIndex,
          //     "country"
          //   ),
        },
        {
          title: "Series",
          dataIndex: "series",
          key: "series",
          width: 150,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "text",
              false,
              [],
              rowIndex,
              "series"
            ),
        },
        {
          title: "Effective From",
          dataIndex: "effective_from",
          key: "effective_from",
          width: 150,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "date",
              false,
              [],
              rowIndex,
              "effective_from"
            ),
        },
        {
          title: "Through to",
          dataIndex: "through_to",
          key: "through_to",
          width: 150,
          render: (value: any, record: any, rowIndex: number) =>
            renderFunction(
              value,
              record,
              "date",
              false,
              [],
              rowIndex,
              "through_to"
            ),
        },
      ],
    },
  ];

  const mapHeaderList = (header: []) => {
    return header.slice(1).map((item: any) => {
      return {
        ...item,
        children: item.children.map((child: any) => {
          return {
            title: child.title,
            dataIndex: child.dataIndex,
            key: child.dataIndex,
            width: 300,
            render: (value: any, record: any, rowIndex: number) =>
              renderFunction(
                value,
                record,
                "",
                true,
                child,
                rowIndex,
                child.dataIndex
              ),
          };
        }),
      };
    });
  };

  useEffect(() => {
    const fetchJurisdictionData = async () => {
      try {
        const result =
          await new ProjectImporterToolService().getImporterToolJurisdiction(
            projectDetails?.slug ?? ""
          );
        const { jurisdictionData } = result?.data.data;
        const { header, data } = jurisdictionData;
        const mapHeader = mapHeaderList(header);
        refreshDataList(
          [...columns, ...mapHeader],
          data.map((each: any) => {
            return {
              ...each,
              key: `${each.jurisdiction}-${each.series}-${each.effective_from}-${each.through_to}_${Math.random()}`,
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchJurisdictionData();
    
    return () => {
      resetDataState();
    }
  }, []);

  const onRow = (_: any, index: number) => ({
    ref: (node: any) => {
      if (node) {
        rowRefs.current[index] = node;
      }
    },
  });

  useEffect(() => {
    validateData(dataList);
    const values = findDuplicateIndices(dataList, ['jurisdiction', 'series', 'effective_from', 'through_to']);
    dataList.forEach((_, index) => {
      const rowElement = rowRefs.current[index];
      if (rowElement) {
        rowElement.classList.remove('blinkInvalidRow'); // Example of interaction
      }
    })
    values.forEach((each) => {
      const rowElement = rowRefs.current[each - ((currentPage - 1) * currentPageSize)];
      if (rowElement) {
        rowElement.classList.add('blinkInvalidRow'); // Example of interaction
        rowElement.id = `${each}`;
      }
    })

    const overlappings = values.map((globalIndex) => {
      const page = Math.floor(globalIndex / currentPageSize) + 1;
      const row = (globalIndex % currentPageSize) + 1;
      return {
        id: globalIndex,
        page,
        row 
      }
    })
    setValidationGlobalErrors(`overlap`, (values.length > 0) ? 'Add' : 'Delete', (values.length > 0) ? overlappings : '');
  }, [dataList, currentPage]);

  if (!projectDetails) return <>Loading...</>;

  return (
    <Table
      loading={showLoader}
      columns={headerList}
      dataSource={paginatedData as any}
      bordered
      size="small"
      pagination={false}
      scroll={{ x: "max-content", y: "calc(100vh - 418px)" }}
      onRow={onRow as any}
    />
  )
};

export default Jurisdictions;
