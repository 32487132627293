import { Link } from "react-router-dom";

export const FooterLinks = () => {
  return (
    <ul className="footerMenu">
        <li>
        <Link to="https://monqcle.com/legal/privacy" target="_blank">Privacy</Link>
        </li>
        <li>
        <Link to="https://monqcle.com/legal/terms" target="_blank">Terms of Service</Link>
        </li>
        <li>
        <Link to="https://monqcle.com/legal/acceptable_use" target="_blank">Acceptable Use</Link>
        </li>
        <li>
        <Link to="https://monqcle.com/browser_requirement" target="_blank">Minimum System Requirements</Link>
        </li>
    </ul>
  );
};
