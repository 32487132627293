import React, { useCallback, useEffect, useState } from "react";
import siteLogo from "../../assets/images/site-logo.svg";
import { Link, useLocation } from "react-router-dom";
import { UserService } from "modules/shared/services";
import PageLoader from "modules/shared/components/PageLoader";

const VerifyIndividualUser = () => {
  const [registrationInfo, setRegistrationInfo] = useState({
    isValidToken: null,
    isLoading: true,
  });
  const [errorObject, setErrorObject] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [successObject, setSuccessObject] = useState<{
    isSuccess: boolean;
    message: string;
  }>({ isSuccess: false, message: "" });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const ticket = params.get("ticket") ?? "";
  console.log(ticket);

  const postToken = useCallback(async () => {
    if (ticket !== "") {
      try {
        const tokenData = (await new UserService().postRegistrationLink(ticket))
          .data?.data;
        setRegistrationInfo((prevState: any) => {
          return {
            ...prevState,
            isLoading: false,
            isValidToken: tokenData.token_status,
          };
        });
        if (tokenData.token_status) {
          setSuccessObject({
            isSuccess: true,
            message: "Account Verified Successfully!",
          });
        }
      } catch (err) {
        setRegistrationInfo((prevState: any) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
        console.log(err);
      }
    } else {
      setRegistrationInfo((prevState: any) => {
        return {
          ...prevState,
          isLoading: false,
          isValidToken: false,
        };
      });
    }
  }, [setRegistrationInfo, ticket]);
  useEffect(() => {
    postToken();
  }, [postToken]);

  useEffect(() => {
    if (registrationInfo.isValidToken === false) {
      setErrorObject({
        isError: true,
        message: "Invitation is Invalid or Expired",
      });
    }
  }, [registrationInfo.isValidToken]);

  return (
    <React.Fragment>
      {registrationInfo.isLoading ? (
        <PageLoader></PageLoader>
      ) : (
          <main className="loginWrapper">
            <div className="picBlk"></div>
            <div className="loginFormWrap">
              <div className="formBlk">
                <h1>
                  <img
                    src={siteLogo}
                    alt="MonQcle by the Center for Public Health Law Research"
                  />
                </h1>
                {errorObject.isError && (
                  <div className="msgError bottomGap1">
                    {errorObject.message}
                  </div>
                )}
                {successObject.isSuccess && (
                  <React.Fragment>
                    <div className="regSuccess bottomGap1">
                      <p>
                        {successObject.message}
                      </p>
                    </div>
                    <div className="supportLink topGap1">
                      <Link to="/">Go to Log In</Link>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </main>
      )}
    </React.Fragment>
  );
};

export default VerifyIndividualUser;
