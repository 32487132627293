import { BroadcastChannel } from 'broadcast-channel';

class AuthSwitchOrganizationChannelService {

    private static switchOrganizationChannelInstance?: AuthSwitchOrganizationChannelService;

    public static getSwitchOrganizationInstance() {
        if (!this.switchOrganizationChannelInstance) {
          this.switchOrganizationChannelInstance = new AuthSwitchOrganizationChannelService();
        }
        return this.switchOrganizationChannelInstance;
    }

    public getSwitchOrganizationChannel() {
        return new BroadcastChannel('appSwitchOrganizationChannel');
    }

    public getLogoutChannel() {
        return new BroadcastChannel('appLogoutChannel');
    }
}

export default AuthSwitchOrganizationChannelService;