import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Table, Input, PaginationProps, Button, Tooltip } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  IRequestSiteUserListData,
  ISiteUserListData,
} from "../../models/interface";
import { SorterResult } from "antd/es/table/interface";
import {
  DeleteOutlined,
  CloseCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  downloadBufferAsFile,
  TooltipParagraph,
} from "../../../shared/utility";
import Paginator from "modules/shared/components/Paginator";
import { useSiteStore } from "modules/organization/store/siteStore";
import { SiteService } from "modules/organization/services";
import SiteAddUser from "./SiteAddUser";
import { RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import SiteRemoveUser from "./SiteRemoveUser";
import { dateFormat } from 'config/commonConfig';
import { mnDate } from 'modules/shared/services';

const { Search } = Input;

const OrgSiteUserList = () => {
  const buttonEle = useRef<HTMLButtonElement>(null);

  const {
    siteUserListData,
    showUsersTableLoader,
    usersCount,
    userListCurrentPageSize,
    userListCurrentPage,
    userListCurrentSortColumn,
    userListCurrentSortOrder,
    userListSearch,
    site,
    siteInfo,
    isUserListFirstLoad,
    needUserListRefresh,
    showConfirmDeleteUser,

    setUserListCurrentPageSize,
    setUserListCurrentPage,
    setUserListCurrentSortColumn,
    setUserListCurrentSortOrder,
    setUserListSearch,
    refreshSiteUserList,
    setNeedUserListRefresh,
    setIsUserListFirstLoad,
    setShowConfirmDeleteUser,
    setSelectedUser,
  } = useSiteStore();

  const [searchValue, setSearchValue] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const userListColumns: ColumnsType<ISiteUserListData> = [
    {
      title: "Username",
      dataIndex: "user_name",
      defaultSortOrder: "ascend",
      sorter: true,
      render: (data, record) => {
        return <>{data}</>;
      },
    },
    {
      title: "Full name",
      dataIndex: "full_name",
      sorter: true,
      render: (data, record) => {
        return (
          <div className="tblTitle" aria-hidden>
            <div
              className="titleLinkWrap flexAlignCenter"
              aria-label={`Full name: ${data}`}
            >
              <TooltipParagraph className="recent-projects" toLink={null}>
                {data}
              </TooltipParagraph>
            </div>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      sorter: true,
      render: (data) => {
        return <>{data}</>;
      },
    },

    {
      title: "",
      dataIndex: "user_id",
      width: 55,
      align: "center",
      render: (_data, record) => {
        return (
          <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_MANAGE_USER]}>
            <Tooltip title={"Remove User"} trigger={["hover", "focus"]}>
              <Button
                onClick={() => handleRemoveUser(record)}
                className="actionBtn"
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </RBAC>
        );
      },
    },
  ];
  const handleRemoveUser = (record: ISiteUserListData) => {
    setSelectedUser(record);
    setShowConfirmDeleteUser(true);
  };

  const paginationRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  const onChange: TableProps<ISiteUserListData>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    if (sorter) {
      const { field, order } = sorter as SorterResult<ISiteUserListData>;
      if (field) {
        setNeedUserListRefresh(true);
        setUserListCurrentSortColumn(field.toString());
      }
      if (order) {
        setNeedUserListRefresh(true);
        setUserListCurrentSortOrder(order === "ascend" ? "asc" : "desc");
      }
    }
  };

  const getShowingDataString = () => {
    const startIndex = (userListCurrentPage - 1) * userListCurrentPageSize + 1;
    const endIndex =
      usersCount < userListCurrentPage * userListCurrentPageSize
        ? usersCount
        : userListCurrentPage * userListCurrentPageSize;
    return `Showing ${startIndex} to ${endIndex} of ${usersCount} Items`;
  };

  const fetchSiteUserList = useCallback(async () => {
    refreshSiteUserList({
      page_index: userListCurrentPage,
      page_size: userListCurrentPageSize,
      sort_column: userListCurrentSortColumn,
      sort_order: userListCurrentSortOrder,
      search: userListSearch === "" ? null : userListSearch,
      site_id: site,
    }).catch(console.error);
  }, [
    userListCurrentPage,
    userListCurrentPageSize,
    userListCurrentSortColumn,
    userListCurrentSortOrder,
    userListSearch,
    site,
    refreshSiteUserList,
  ]);

  function handleChange(event: any) {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      setNeedUserListRefresh(true);
      setUserListSearch("");
    }
  }

  useEffect(() => {
    if (isUserListFirstLoad || needUserListRefresh) {
      fetchSiteUserList().catch(console.error);
      setNeedUserListRefresh(false);
      setIsUserListFirstLoad(false);
    }
  }, [
    fetchSiteUserList,
    isUserListFirstLoad,
    needUserListRefresh,
    setNeedUserListRefresh,
    setIsUserListFirstLoad,
  ]);

  const getExportData = async () => {
    const params: IRequestSiteUserListData = {
      page_index: userListCurrentPage,
      page_size: userListCurrentPageSize,
      sort_column: userListCurrentSortColumn,
      sort_order: userListCurrentSortOrder,
      search: userListSearch === "" ? null : userListSearch,
      site_id: site,
    };
    try {
      setIsExporting(true);
      const result = await new SiteService().getSiteUserListData(params);
      const { excelBufferData } = result.data.data;
      return excelBufferData;
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsExporting(false);
    }
  };

  const exportData = async () => {
    const fileName = `User list of ${
      siteInfo?.site_title
    } Site-${mnDate().format(dateFormat.fileTimestamp)}`;
    let excelBufferData: any = await getExportData();
    if (excelBufferData) {
      downloadBufferAsFile(
        excelBufferData,
        `${fileName}.csv`,
        "application/octet-stream"
      );
    }
    buttonEle.current?.blur();
  };

  return (
    <Fragment>
      <div className="tableWrapper">
        <div className="tableSettingBlk">
          <Search
            aria-label={"Search Users"}
            title={"Search Users"}
            placeholder="Search Users"
            allowClear={{
              clearIcon: (
                <button className="clearBtn">
                  <CloseCircleFilled />
                </button>
              ),
            }}
            value={searchValue}
            onSearch={() => {
              if (
                searchValue === null ||
                searchValue.length < 1 ||
                showUsersTableLoader
              ) {
                return;
              }
              setNeedUserListRefresh(true);
              setUserListSearch(searchValue);
            }}
            onChange={handleChange}
          />
          <div className="flexLeftGap" style={{display:"flex"}}>
            {usersCount > 0 && (
              <Button
                onClick={exportData}
                loading={isExporting}
                icon={<DownloadOutlined />}
                style={{marginRight:"8px"}}
                ref={buttonEle}
              >
                Export Data
              </Button>
            )}
            <SiteAddUser />
          </div>
        </div>
        <div className="mainTable">
          <Table
            columns={userListColumns}
            dataSource={siteUserListData}
            onChange={onChange}
            pagination={false}
            className="scrollableTable projectListTbl"
            loading={showUsersTableLoader}
            showSorterTooltip={false}
            sortDirections={["ascend", "descend", "ascend"]}
            rowKey={(record) => `${record.user_id}`}
          />
        </div>
        {!showUsersTableLoader && (
          <Paginator
            currentPageSize={userListCurrentPageSize}
            count={usersCount ?? 0}
            setCurrentPageSize={setUserListCurrentPageSize}
            setCurrentPage={setUserListCurrentPage}
            getShowingDataString={getShowingDataString}
            paginationRender={paginationRender}
            currentPage={userListCurrentPage}
          />
        )}
      </div>
      {showConfirmDeleteUser && <SiteRemoveUser />}
    </Fragment>
  );
};

export default OrgSiteUserList;
