import React, { Fragment, useEffect, useState } from "react";
import { Button, Drawer, message } from "antd";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
const ViewStateTableErrors: React.FC<{}> = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { validationGlobalErrors, showLoader, currentStep } = useImporterToolStore();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    if (!(Object.keys(validationGlobalErrors)?.length > 0)) {
      setOpenDrawer(false);
    }
  }, [validationGlobalErrors]);

  function scrollToElementById(id: string, type: string, page: number) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center', 
        inline: type !== 'overlap' ? 'center' : undefined // Conditionally add 'center' or undefined
      });
    } else {
      message.info(`Please head over to page ${page}`);
    }
  }
  

  return (
    <React.Fragment>
      {!showLoader &&
        validationGlobalErrors &&
        Object.keys(validationGlobalErrors)?.length > 0 && (
          <Button
            className="btnStyle3 smlBtn blinkingBtn"
            type="primary"
            onClick={showDrawer}
          >
            View Errors
          </Button>
        )}

      <Drawer
        title={<h2>{currentStep?.name?.slice(0, -1)} Errors</h2>}
        placement={"right"}
        className="customDrawer"
        // width={referenceWidth+25}
        closable={false}
        onClose={onClose}
        open={openDrawer}
        mask={false}
        extra={
          <Button onClick={onClose} className="closeBlk">
            Close
          </Button>
        }
      >
        <ul className="drawerItemList suggestiveHistorylist">
          {Object.entries(validationGlobalErrors)?.map(([key, value]) => {
            if(key === 'overlap') {
                return (
                  <ul key={key}>
                      {
                        value.map((each: any) => {
                          return (
                              <li key={each.id} onClick={() => scrollToElementById(`${each.id}`, key, each.page)}>
                                  <div className="shContent">
                                    <div className="itemListHeading">
                                        <span className="suggestiveTitle">Overlapping Row</span>
                                    </div>
                                    <div className="itemDate">
                                        Page: {each.page} | Row: {each.row}
                                    </div>
                                  </div>
                              </li>
                          )
                        })
                      }
                  </ul>
                )
            } else {
                return (
                  <li key={key} onClick={() => scrollToElementById(key, key, value.page)}>
                      <div className="shContent">
                          <div className="itemListHeading">
                              <span className="suggestiveTitle">{value.message}</span>
                          </div>
                          <div className="itemDate">
                              Page: {value.page} | Row: {value.row} | Column: {key.split("_")[0]}
                          </div>
                      </div>
                  </li>
                )
            }
          })}
        </ul>
      </Drawer>
    </React.Fragment>
  );
};

export default ViewStateTableErrors;
