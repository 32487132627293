import { Button, Drawer, Spin } from "antd";
import { CheckOutlined, LoadingOutlined, StopOutlined, CloseCircleOutlined, ClockCircleOutlined, FileExclamationOutlined } from "@ant-design/icons";
import { useProjectDetailsStore, useProjectRecordDetailsStore } from "modules/organization/store";
import { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { formatDate } from "modules/shared/utility";
import { mnDate } from "modules/shared/services";
import { dateFormat } from "config/commonConfig";
import { IProjectSuggestiveTextHistory } from "modules/organization/models/interface";


const SuggestiveTextHistoryDrawer: FC = () => {
  const [loadingSuggestiveTextHistory, setLoadingSuggestiveTextHistory] = useState(false);
  const { project_slug } = useParams();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { projectDetails } = useProjectDetailsStore();
  const {
    showSuggestiveTextHistory,
    projectSuggestiveTextHistory,
    setShowSuggestiveTextHistory,
    getProjectSuggestiveTextHistory,
    clearProjectSuggestiveTextHistory,
  } = useProjectDetailsStore((state) => ({
    showSuggestiveTextHistory: state.showSuggestiveTextHistory,
    projectSuggestiveTextHistory: state.projectSuggestiveTextHistory,
    setShowSuggestiveTextHistory: state.setShowSuggestiveTextHistory,
    getProjectSuggestiveTextHistory: state.getProjectSuggestiveTextHistory,
    clearProjectSuggestiveTextHistory: state.clearProjectSuggestiveTextHistory,
  }));
  const { recordDetails, showRecordSuggestiveTextHistory, setShowRecordSuggestiveTextHistory } = useProjectRecordDetailsStore();

  const onAfterOpenChange = async (open: boolean) => {
    if (open && project_slug) {
      setLoadingSuggestiveTextHistory(true);
      if (showRecordSuggestiveTextHistory && recordDetails) {
        await getProjectSuggestiveTextHistory(project_slug, recordDetails.record_id);
      } else {
        await getProjectSuggestiveTextHistory(project_slug);
      }
      setLoadingSuggestiveTextHistory(false);
    } else {
      clearProjectSuggestiveTextHistory();
    }
  };

  // render message icon
  const renderMessageIcon = (messageType: string) => {
    switch (messageType) {
      case 'success':
        return <CheckOutlined />;
      case 'failed':
        return <CloseCircleOutlined />;
      case 'requested':
        return <ClockCircleOutlined />;
      case 'inProgress':
        return <LoadingOutlined />;
      case 'aborted':
        return <StopOutlined />;
      case 'noContent':
        return <FileExclamationOutlined />;
    }
  }

  // render message
  const renderMessage = (suggestiveTextHistory: IProjectSuggestiveTextHistory) => {
    const messageType = suggestiveTextHistory.status;
    const completedAt = formatDate(suggestiveTextHistory.completed_at ?? suggestiveTextHistory.created_at);
    const createdAt = formatDate(suggestiveTextHistory.created_at);
    switch (messageType) {
      case 'success':
        return `Generated ${completedAt === 'today' || completedAt === 'yesterday' ? "" : "at"} ${completedAt}`;
      case 'failed':
        return `Failed ${completedAt === 'today' || completedAt === 'yesterday' ? "" : "at"} ${completedAt}`;
      case 'requested':
        return `Requested ${createdAt === 'today' || createdAt === 'yesterday' ? "" : "at"} ${createdAt}`;
      case 'inProgress':
        return `Generating suggested text...`;
      case 'aborted':
        return `Aborted ${completedAt === 'today' || completedAt === 'yesterday' ? "" : "at"} ${completedAt}`;
      case 'noContent':
        return `No content`;
    }
  }

  useEffect(() => {
    return () => {
      clearProjectSuggestiveTextHistory();
    }
  }, [])

  return (
    <Drawer
      open={showSuggestiveTextHistory || showRecordSuggestiveTextHistory}
      title={<h2>Suggested Text History</h2>}
      closable={false}
      width={500}
      afterOpenChange={onAfterOpenChange}
      className="collapsibleDrawer"
      extra={
        <Button
          onClick={() => {
            setShowSuggestiveTextHistory(false);
            setShowRecordSuggestiveTextHistory(false);
          }
          }
          className="closeBlk"
        >
          Close
        </Button>
      }
    >
      <Spin spinning={loadingSuggestiveTextHistory}>
        <ul className="drawerItemList suggestiveHistorylist">
          {projectSuggestiveTextHistory?.map((suggestiveTextHistory) =>
            <li key={suggestiveTextHistory.job_id} className={suggestiveTextHistory.expired_at ? "expired" : ""}>
              <span className={`relatedIcon ${suggestiveTextHistory.status ? suggestiveTextHistory.status : ''}`}>
                {renderMessageIcon(suggestiveTextHistory.status)}
              </span>
              <div className="shContent">
                <div className="itemListHeading">
                  <span className="suggestiveTitle">{renderMessage(suggestiveTextHistory)}</span>
                </div>
                {!recordDetails &&
                  <Link className="suggestiveUrl"
                    to={
                      organizationBaseRouteUrl +
                      `/project/${projectDetails?.slug}/record/${suggestiveTextHistory.record_id}`
                    }
                  >
                    <span>{suggestiveTextHistory.jurisdiction_name} | {suggestiveTextHistory.series_title}</span>
                  </Link>}
                {!recordDetails &&
                  <div className="itemDate">
                    Effective:{" "}
                    {mnDate(suggestiveTextHistory.effective_from).format(dateFormat.default)} -
                    Through:
                    {mnDate(suggestiveTextHistory.through_to).format(dateFormat.default)}
                  </div>}
              </div>
            </li>
          )}
        </ul>
      </Spin>
    </Drawer>
  );
};

export default SuggestiveTextHistoryDrawer;