import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { Spin } from "antd";
import React, { Fragment, useState } from "react";

const containerStyle = {
  height: "450px",
  backgroundRepeat: "no-repeat",
};

const GoogleMapViewer: React.FC<{
  geoData: any;
  loadingMap: boolean;
}> = ({ geoData, loadingMap }) => {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker: any) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      map.data.addGeoJson(geoData);
      let bounds = new google.maps.LatLngBounds();

      map.data.forEach(function (feature) {
        let geo = feature.getGeometry();
        geo?.forEachLatLng(function (LatLng) {
          bounds.extend(LatLng);
        });
      });
      map.fitBounds(bounds);
    },
    [geoData]
  );

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    console.log("Unmounted");
  }, []);

  return (
    <Spin spinning={loadingMap}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={5}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={{
          scrollwheel: false,
          streetViewControl: false,
          draggable: true,
        }}
      >
        {geoData?.features?.map((props: any) => (
          <Marker
            key={props?.properties?.place_id}
            position={{
              lat: props?.properties?.lat,
              lng: props?.properties?.lon,
            }}
            onClick={() => handleActiveMarker(props?.properties?.place_id)}
          >
            {activeMarker === props?.properties?.place_id ? (
              <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                <Fragment>
                  <div>
                    Name :{" "}
                    {props?.properties?.datasource?.raw["name:en"] ||
                      props?.properties?.datasource?.raw["name"]} ({(props?.properties?.city ? props?.properties?.city+', ':"")+(props?.properties?.state ? props?.properties?.state+', ':"")+props?.properties?.country})
                  </div>
                  {props?.properties?.datasource?.raw["osm_id"] && (
                    <div>
                      OSM Id:{" "}
                      {props?.properties?.datasource?.raw["osm_id"]}
                    </div>
                  )}
                  {props?.properties?.datasource?.raw["ref"] && (
                    <div>
                      State Code:{" "}
                      {props?.properties?.datasource?.raw["ref"]}
                    </div>
                  )}
                  {props?.properties?.datasource?.raw["country_code"] && (
                    <div>
                      Country Code:{" "}
                      {props?.properties?.datasource?.raw["country_code"]?.toUpperCase()}
                    </div>
                  )}
                </Fragment>
              </InfoWindow>
            ) : null}
          </Marker>
        ))}
      </GoogleMap>
    </Spin>
  );
};

export default React.memo(GoogleMapViewer);
