import { Input, Select, Table, TableColumnsType } from "antd";
import { debounce } from "lodash";
import { ProjectImporterToolService } from "modules/organization/services/projectImporterTool.service";
import { useProjectDetailsStore } from "modules/organization/store";
import { useImporterToolStore } from "modules/organization/store/importerToolStore";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { validateQuestions } from "./validateQuestions";

const Questions = () => {
  const { projectDetails } = useProjectDetailsStore();

  const {
    showLoader,
    headerList,
    dataList,
    currentPage,
    currentPageSize,
    validationGlobalErrors,
    setDataList,
    refreshDataList,
    setValidationGlobalErrors,
    resetDataState } = useImporterToolStore();

  const errorRefs = useRef([] as any);

  const setErrorRef = useCallback(
    (id: string, ref: any) => {
      if (ref) {
        errorRefs.current[id] = ref;
      }
    },
    []
  );

  const paginatedData = useMemo(() => {
    return dataList.slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize);
  }, [dataList, currentPage, currentPageSize]);

  const handleChange = useCallback((rowIndex: number, dataIndex: string) => {
    return debounce((newValue) => {
      setDataList(rowIndex, dataIndex, newValue);
    }, 300);
  }, []);

  const renderFunction = useCallback(
    (
      value: any,
      record: any,
      rowIndex: number,
      dataIndex: string
    ) => {
      if (dataIndex === 'question_type') {
        const options = ['BIN', 'CAT-ME', 'CAT-CA', 'TEXT', 'NUMBER', 'DATE'];
        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <Select
              bordered={false}
              style={{ width: "100%", height: "100%" }}
              defaultValue={value ?? undefined}
              placeholder="Select option"
              allowClear
              options={options.map(
                (item: any) => ({
                  value: item,
                  label: item,
                })
              )}
              onChange={(val) => {
                if (val !== value) {
                  // Check if value has changed
                  handleChange(rowIndex, dataIndex)(val);
                }
              }}
            />
          </div>
        )
      } else if (dataIndex === 'possible_options') {
        if(!record.question_type) return null;

        if (['TEXT', 'NUMBER', 'DATE'].includes(record.question_type)) {
          return null;
        }

        if (record.question_type === 'BIN') {
          return (
            <Select
              bordered={false}
              mode="multiple"
              value={['YES', 'NO']}
              disabled
              suffixIcon={null}
              style={{ backgroundColor: "transparent" }}
            />
          );
        }

        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <Select
              bordered={false}
              style={{ width: "100%", height: "100%" }}
              value={Array.isArray(record.possible_options) ? record.possible_options : [record.possible_options]}
              mode={
                (record[`question_type`] === "CAT-CA" || record[`question_type`] === "CAT-ME")
                  ? "tags"
                  : undefined
              }
              placeholder="Select option"
              options={(Array.isArray(record.original_possible_options) ? record.original_possible_options : [record.original_possible_options]).map(
                (item: any) => ({
                  value: item,
                  label: item,
                })
              )}
              onChange={(val) => {
                if (val !== value) {
                  // Check if value has changed
                  handleChange(rowIndex, dataIndex)(val);
                }
              }}
              allowClear
            />
          </div>
        )
      } else if (dataIndex === 'order') {
        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <Input
              type="text"
              defaultValue={value}
              className="importerInputs"
              placeholder={`Enter ${dataIndex}`}
              onKeyDown={(e) => {
                const char = e.key;
                const currentValue = e.currentTarget.value;
                // Allow only numbers, dots, and control keys
                if (!/^[0-9.]$/.test(char) && e.key !== "Backspace" && e.key !== "Tab") {
                  e.preventDefault();
                }
              }}
              onBlur={(e) => {
                if (e.target.value !== value) {
                  // Check if value has changed
                  handleChange(rowIndex, dataIndex)(e.target.value.trim());
                }
              }}
            />
          </div>
        );
      } else {
        return (
          <div ref={(ref) => setErrorRef(`${dataIndex}_${rowIndex}`, ref)}>
            <Input
              defaultValue={value}
              className="importerInputs"
              placeholder={`Enter ${dataIndex}`}
              onBlur={(e) => handleChange(rowIndex, dataIndex)(e.target.value.trim())}
            />
          </div>
        );
      }
    },
    [handleChange]
  );


  const columns: TableColumnsType<any> = [
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: "10%",
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "order"
        ),
    },
    {
      title: "Variable Name",
      dataIndex: "variable_name",
      key: "variable_name",
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "variable_name"
        ),
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "question"
        ),
    },
    {
      title: "Question Type",
      dataIndex: "question_type",
      key: "question_type",
      width: 130,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "question_type"
        ),
    },
    {
      title: "Possible Options",
      dataIndex: "possible_options",
      key: "possible_options",
      width: 300,
      render: (value: any, record: any, rowIndex: number) =>
        renderFunction(
          value,
          record,
          rowIndex,
          "possible_options"
        ),
    },
  ];

  useEffect(() => {
    const fetchQuestionData = async () => {
      try {
        const result = await new ProjectImporterToolService().getImporterToolQuestion(projectDetails?.slug ?? "");
        const { questionData } = result?.data.data;
        const { header, data } = questionData;

        data.forEach((item: any) => {
          item.original_possible_options = item.possible_options;
        });

        refreshDataList(columns, data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestionData();

    return () => {
      resetDataState();
    }
  }, []);

  useEffect(() => {
    validateQuestions({
      dataList,
      currentPage,
      currentPageSize,
      validationGlobalErrors,
      errorRefs,
      setValidationGlobalErrors,
    });
  }, [dataList, currentPage]);

  console.log(validationGlobalErrors)

  if (!projectDetails) return <>Loading...</>;

  return (
    <Table
      loading={showLoader}
      columns={headerList}
      dataSource={paginatedData as any}
      bordered
      size="small"
      pagination={false}
      scroll={{ x: "max-content", y: "calc(100vh - 364px)" }}
    />
  );
};

export default Questions;
