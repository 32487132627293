import { useEffect } from "react";
import AuthService from "../shared/services/authService";

const authService = AuthService.getInstance();
const DemoPrivatePage = () => {

    const getAccessToken = async() => {
        console.log(await authService.getAccessToken());
    }
    
    useEffect(()=>{
        getAccessToken();
    }, [])
    
    return (
      <div>
        Private Page
      </div>
    );
}

export default DemoPrivatePage