import { Tooltip, Typography } from "antd";
import { IHttpError } from "models/interface";
import { SyncOutlined } from "@ant-design/icons";
import { Fragment } from 'react';
import NotFound from './NotFound';

const CustomError: React.FC<{
  errorDetails: IHttpError;
  refreshAction?: (params?: any) => void;
  refreshActionParams?: any;
  reloadPage?: boolean;
  isAccessFromPage?: boolean;
}> = ({ errorDetails, refreshAction, refreshActionParams, reloadPage, isAccessFromPage = false }) => {
  const executeRefresh = () => {
    if (reloadPage === true) {
      window.location.reload();
    } else if (refreshAction && refreshActionParams) {
        refreshAction(refreshActionParams);
      } else if (refreshAction && !refreshActionParams) {
        refreshAction();
      }
  };

  return (
    <Fragment>
      {isAccessFromPage ?
      <NotFound statusCode={errorDetails.code}/> : <div className="errorWrap">
      <div className="errorContent">
        <div className="errorCode">{errorDetails?.code}</div>
        <div className="errorContentInner">
          <div className="errorImg">Error Image</div>
          <div>
            <div className="errorHeading">Error</div>
            <p>{errorDetails?.message}</p>
            <p>
                Please try after sometime
                {
                  refreshAction && 
                    <Fragment>
                      {" "}or{" "}
                      <Typography.Link onClick={executeRefresh}>
                        <Tooltip title={"Click to reload"} trigger={["hover", "focus"]}>
                          <SyncOutlined />
                        </Tooltip>
                      </Typography.Link>
                    </Fragment>
                }
              </p>
          </div>
        </div>
      </div>
    </div>}
    </Fragment>
    
  );
};
export default CustomError;
