import { create } from 'zustand';

export interface IPublishingProject {
  state: number;
  key: string;
  site_id: number;
  publish_id: number;
  project_id: number;
  project_title: string;
  site_title: string;
}

interface INotificationState {
  currentPublishingProject: IPublishingProject | null;

  setCurrentPublishingProject: (value: IPublishingProject) => void;
}

const useStore = create<INotificationState>((set) => ({
  currentPublishingProject: null,

  setCurrentPublishingProject: (value) => {
    set(() => ({ currentPublishingProject: value }));
  }
}));

export const useNotificationStore = useStore;
