import { pageSizeConfigs } from 'config/commonConfig';
import { create } from 'zustand';

export type TImportingKey = typeof STAGE_STEPS[keyof typeof STAGE_STEPS]
export const STAGE_STEPS = {
    STEP_1: 1,
    STEP_2: 2
} as const;

interface IImporterStagingStep {
    key: TImportingKey;
    name: string;
    backBtnLabel?: string;
    continueBtnLabel: string;
    getBtnLabel?: (isPublicationPublic: boolean) => any;
    hideStepsControl?: boolean;
}

export const importingSteps: { [K in TImportingKey]: IImporterStagingStep } = {
    [STAGE_STEPS.STEP_1]: {
        key: STAGE_STEPS.STEP_1,
        name: "Questions",
        backBtnLabel: "Cancel",
        continueBtnLabel: "Validate & Proceed",
    },
    [STAGE_STEPS.STEP_2]: {
        key: STAGE_STEPS.STEP_2,
        name: "Jurisdictions",
        backBtnLabel: "Back",
        continueBtnLabel: "Import to Project",
    }
}

interface IProjectImportingState {
    currentStep: IImporterStagingStep;
    finishStep: number;
    showLoader: boolean;
    dataSubmission: boolean;
    headerList: any[];
    dataList: any[];
    columnMapping: Object;
    dataCount: number;
    currentPageSize: number;
    currentPage: number;
    validationGlobalErrors: { [key: string]: any };

    setShowLoader: (value: boolean) => void;
    setDataList: (rowIndex: number, dataIndex: string, value: any) => void;
    setDataCount: (count: number) => void;
    setCurrentPageSize: (pageSize: number) => void;
    setCurrentPage: (page: number) => void;
    setDataSubmission: (value: boolean) => void;
    setFinishStep: (value: number) => void;
    setCurrentStep: (step: TImportingKey) => void;
    setValidationGlobalErrors: (key: string, action: string, value: any) => void;
    refreshDataList: (header: any[], data: any[]) => void;
    resetDataState: () => void;
    resetState: () => void;
}

const useStore = create<IProjectImportingState>((set) => ({
    currentStep: importingSteps[1],
    finishStep: 0,
    showLoader: true,
    dataSubmission: false,
    headerList: [],
    dataList: [],
    columnMapping: {},
    dataCount: 0,
    currentPageSize: pageSizeConfigs.OPTIONS[0].value,
    currentPage: 1,
    validationGlobalErrors: {},

    setDataList: (rowIndex, dataIndex, value) => {
        set((state) => {
            const actualRowIndex = ((state.currentPage - 1) * state.currentPageSize ) + rowIndex
            const updatedDataList = [...state.dataList];
            if (updatedDataList[actualRowIndex]) {
                updatedDataList[actualRowIndex] = {
                    ...updatedDataList[actualRowIndex],
                    [dataIndex]: value
                };
            }
            return { dataList: updatedDataList };
        });
    },
    setShowLoader: (value) => {
        set(() => ({
            showLoader: value
        }));
    },
    setDataCount: (count) => {
        set(() => ({
            dataCount: count,
        }));
    },
    setCurrentPageSize: (pageSize) => {
        set(() => ({
            currentPageSize: pageSize,
            currentPage: 1
        }));
    },
    setCurrentPage: (page) => {
        set(() => ({
            currentPage: page
        }));
    },
    setDataSubmission: (value: boolean) => {
        set(() => ({
            dataSubmission: value
        }))
    },
    setFinishStep: (value: number) => {
        set(() => ({
            finishStep: value
        }))
    },
    setCurrentStep: (step) =>  {    
        set(() => ({
            currentStep: importingSteps[step]
        }));
    },
    setValidationGlobalErrors: (key, action, value) => {
        set((state) => {
            if(action === 'Add') {
                return { 
                    validationGlobalErrors: {
                        ...state.validationGlobalErrors,
                        [key]: value
                    } 
                };
            } else {
                const tempObj = { ...state.validationGlobalErrors }
                delete tempObj[key]
                return { validationGlobalErrors: tempObj };
            }
        });
    },
    refreshDataList: async (header, data) => {
        set(() => ({
            headerList: header.length > 0 ? [...header] : [],
            dataList: data.length > 0 ? [...data] : [],
            dataCount: data.length,
            showLoader: false,
        }));
    },
    resetDataState: () => {
        set(() => ({
            showLoader: true,
            dataSubmission: false,
            headerList: [],
            dataList: [],
            columnMapping: {},
            dataCount: 0,
            currentPageSize: pageSizeConfigs.OPTIONS[0].value,
            currentPage: 1,
            validationGlobalErrors: {},
        }))
    },
    resetState: () => {
        set(() => ({
            currentStep: importingSteps[1],
            finishStep: 0,
            showLoader: true,
            dataSubmission: false,
            headerList: [],
            dataList: [],
            columnMapping: {},
            dataCount: 0,
            currentPageSize: pageSizeConfigs.OPTIONS[0].value,
            currentPage: 1,
            validationGlobalErrors: {},
        }))
    }
}));

export const useImporterToolStore = useStore;
