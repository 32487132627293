import { initializeApp } from 'firebase/app';
import { ApiService } from "../../shared/services";
import { IApiBaseResponse } from "../../../models/interface";
import { RoutesEnum } from "../../../models/enums";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  onSnapshot,
  QuerySnapshot,
  DocumentData,
  getDocs,
  getCountFromServer,
} from "firebase/firestore";

export class FirebaseService {
  private readonly firestore;

  constructor() {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    };
    // Initialize Firebase app and Firestore
    const app = initializeApp(firebaseConfig);
    this.firestore = getFirestore(app);
  }

  // Listen for notifications in real-time
  public listenForNotifications = (
    orgId: string,
    userId: string,
    callback: (notifications: any[], unreadCount: number) => void
  ) => {
    const notificationsRef = collection(
      this.firestore,
      `${process.env.REACT_APP_FIREBASE_MASTER_COLLECTION}/${orgId}/notification/${userId}/notifications`
    );
    // Query to get the latest 10 notifications ordered by timestamp
    const notificationsQuery = query(
      notificationsRef,
      orderBy('created_on', 'desc'),
      limit(10)
    );

    // Attach a listener to this query
    const unsubscribe = onSnapshot(notificationsQuery, (snapshot: QuerySnapshot) => {
      const notifications: any[] = [];
      let unreadCount = 0;

      snapshot.forEach((doc) => {
        const notificationData = doc.data();
        notifications.push({ id: doc.id, ...notificationData });

      });
      unreadCount = notifications.length;
      // Callback to update the UI
      callback(notifications, unreadCount);
    });

    return unsubscribe; // Allow unsubscribing when not needed anymore
  };

  public async deleteSingleNotification(payload: any) {
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<any>>(RoutesEnum.DELETE_SINGLE_NOTIFICATION, { data: payload })
  }

  public async deleteAllNotification(payload: any) {
    return (await new ApiService().privateClient()).delete<IApiBaseResponse<any>>(RoutesEnum.DELETE_ALL_NOTIFICATION, { data: payload })
  }

  // Fetch initial notifications
  public fetchInitialNotifications = async (
    orgId: string,
    userId: string,
    callback: (snapshot: any) => void,
    pageSize: number = 10
  ) => {
    const notificationsRef = collection(
      this.firestore,
      `${process.env.REACT_APP_FIREBASE_MASTER_COLLECTION}/${orgId}/notification/${userId}/notifications`
    );

    // Query to get the latest notifications ordered by timestamp
    const notificationsQuery = query(
      notificationsRef,
      orderBy('created_on', 'desc'),
      limit(pageSize)
    );

    // Use onSnapshot with the query and a callback to handle snapshot data
    return onSnapshot(notificationsQuery, (snapshot) => {
      callback(snapshot); // Pass the snapshot to your callback
    });
  };

  // Fetch more notifications for pagination
  public async fetchMoreNotifications(
    orgId: string,
    userId: string,
    lastVisible: DocumentData
  ) {
    const notificationsRef = collection(
      this.firestore,
      `${process.env.REACT_APP_FIREBASE_MASTER_COLLECTION}/${orgId}/notification/${userId}/notifications`
    );

    const notificationsQuery = query(
      notificationsRef,
      orderBy("created_on", "desc"),
      startAfter(lastVisible), // Continue after the last visible document
      limit(10) // Load 10 more notifications
    );

    const snapshot = await getDocs(notificationsQuery);

    const notifications: any[] = [];
    let lastVisibleDoc: DocumentData | null = null;

    snapshot.forEach((doc) => {
      notifications.push({ id: doc.id, ...doc.data() });
    });

    if (!snapshot.empty) {
      lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
    }

    return { notifications, lastVisible: lastVisibleDoc };
  }

// Fetch the total count of notifications
public async fetchTotalNotificationCount(orgId: string, userId: string): Promise<number> {
  const notificationsRef = collection(
    this.firestore,
    `${process.env.REACT_APP_FIREBASE_MASTER_COLLECTION}/${orgId}/notification/${userId}/notifications`
  );

  const notificationsQuery = query(notificationsRef);

  // Use Firestore's getCountFromServer() to get the total count
  const snapshot = await getCountFromServer(notificationsQuery);
  return snapshot.data().count;
}

}
