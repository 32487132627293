import { mnDate } from 'modules/shared/services';
import { FooterLinks } from "./FooterLinks";

export const FooterSlim = () => {
  return (
    <footer className="slimFooter">
      <div className="footerLeft">
        Copyright {mnDate().year()}, Temple University. All rights reserved.
      </div>
      <div className="footerRight">
        <FooterLinks />
      </div>
    </footer>
  );
};
