import { useAuth0 } from '@auth0/auth0-react';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import AuthService from 'modules/shared/services/authService';
import PageLoader from 'modules/shared/components/PageLoader';
import { useSearchParams } from 'react-router-dom';
import { Button, Result } from 'antd';
const authService = AuthService.getInstance();

const Auth0Check: React.FC<{
    children: React.ReactNode;
}>  = ({children}) => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  
  const [searchParams] = useSearchParams();
  
  if (searchParams.get("error") && searchParams.get("error_description")) {
    return (
      <div className="verifyMsgWrap">
        <Result
          status="error"
          title={<span aria-live="polite">{searchParams.get("error_description")}</span>}
          extra={[
            <Button type="primary" key="logOut" onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              })}>
              Go Back to Login
            </Button>
          ]}
        />
      </div>
    );
    
  }

  if(isLoading){
    return <PageLoader />
  }
  
  if(!isLoading && !isAuthenticated){
    if(authService.getGetCurrentOrganization()){
      authService.clearCurrentOrganization();
    }
  }
  
  authService.setAccessTokenSilentlyFunction(getAccessTokenSilently);

  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
}

export default Auth0Check;

