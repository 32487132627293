import { useState, useEffect, useRef, RefObject } from "react";

export const useIsVisible = (
  state: boolean
): [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  RefObject<HTMLDivElement>
] => {
  const [isVisible, setIsVisible] = useState(state);
  const dropdownRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.isEqualNode(document.getElementById("logout"))) {
        return;
      }
      if (event.target.isEqualNode(document.getElementById("editProfile"))) {
        setTimeout(() => {
          setIsVisible(false);
        }, 300);
        return;
      }
      if (event.target?.classList[0] === "orgSwitchOrganization") {
        setTimeout(() => {
          setIsVisible(false);
        }, 400);
        return;
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsVisible(false);
        }, 150);
      }
    };

    const handleEscKey = (event: any) => {
      if (event.key === 'Escape') {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keyup", handleEscKey, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [dropdownRef]);

  return [isVisible, setIsVisible, dropdownRef];
};
