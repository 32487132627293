import { FC, Fragment } from "react";
import { useUserStore } from "modules/shared/store";
import { useAuth0 } from "@auth0/auth0-react";

const SwitchOrganization: FC<{
  doSwitchOrganization?: (org_key: string, org_id: string) => void;
}> = ({ doSwitchOrganization }) => {
  const { userDetails } = useUserStore();
  const { user } = useAuth0();

  const switchOrganization = (org_key: string, org_id: string) => {
    if (doSwitchOrganization) {
      doSwitchOrganization(org_key, org_id);
    }
  };

  const getOrgList = () => {
    return userDetails?.organization?.filter(each => each.id) ?? [];
  }

  return (
    <Fragment>
      {getOrgList().length > 0 && (
        <li>
          <span>Organizations</span>
          <ul>
            {getOrgList()?.map((eachOrg) => {
              return (
                <li key={eachOrg.org_key}>
                  <button
                    onClick={() => {
                      switchOrganization(eachOrg.org_key, eachOrg.id);
                    }}
                    className="orgSwitchOrganization"
                    disabled={user?.org_id === eachOrg.id}
                  >
                    {eachOrg.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </li>
      )}
    </Fragment>
  );
};

export default SwitchOrganization;
