import React, { Fragment, useRef, useState } from "react";
import { Input, Form, Button } from "antd";
import { RuleObject } from "antd/lib/form";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface Props {}

const CustomCreatePasswordWrapper: React.FC<Props> = (props) => {
  const form = Form.useFormInstance();
  const promptRef = useRef<HTMLSpanElement>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const validateConfirmPassword = (_: RuleObject, value: string) => {
    const password = form.getFieldValue("password");
    if (value && value !== password) {
      return Promise.reject(new Error("Passwords do not match!"));
    }
    return Promise.resolve();
  };
  const handleShowHidePassword = (type: "password" | "confirmPassword") => {
    let visible = false;
    if (type === "password") {
      setPasswordVisible((prevState) => !prevState);
      visible = !passwordVisible;
    } else {
      setConfirmPasswordVisible((prevState) => !prevState);
      visible = !confirmPasswordVisible;
    }
    if (promptRef.current) {
      promptRef.current.innerHTML = `${
        visible ? "Password is shown" : "Password is hidden"
      }`;
    }
  };
  return (
    <Fragment>
      <span
        role="status"
        aria-live={"polite"}
        aria-atomic="true"
        tabIndex={-1}
        ref={promptRef}
        className="promtHide"
      >
        Password is hidden
      </span>
      <Form.Item
        name="password"
        label="Create Your Password"
        rules={[
          {
            required: true,
            message: "Password can not be empty",
          },
          {
            pattern:
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/, //NO SONAR
            message:
              "Password must have at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.",
          },
        ]}
        hasFeedback
      >
        <div className="passwordFldWrap">
          <Input.Password
            placeholder="Create Password"
            autoComplete="new-password"
            visibilityToggle={{
              visible: passwordVisible,
              onVisibleChange: setPasswordVisible,
            }}
          />
          <Button
            className="iconOnlyBtn"
            onClick={() => handleShowHidePassword("password")}
            aria-label={passwordVisible ? "Hide Password" : "Show Password"}
          >
            {passwordVisible ? (
              <EyeOutlined role="img" aria-label="" />
            ) : (
              <EyeInvisibleOutlined role="img" aria-label="" />
            )}
          </Button>
        </div>
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        label="Confirm Password"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          { validator: validateConfirmPassword },
        ]}
        hasFeedback
      >
        <div className="passwordFldWrap">
          <Input.Password
            placeholder="Confirm Your Password"
            autoComplete="new-password"
            visibilityToggle={{
              visible: confirmPasswordVisible,
              onVisibleChange: setConfirmPasswordVisible,
            }}
          />

          <Button
            className="iconOnlyBtn"
            onClick={() => handleShowHidePassword("confirmPassword")}
            aria-label={
              confirmPasswordVisible ? "Hide Password" : "Show Password"
            }
          >
            {confirmPasswordVisible ? (
              <EyeOutlined role="img" aria-label="" />
            ) : (
              <EyeInvisibleOutlined role="img" aria-label="" />
            )}
          </Button>
        </div>
      </Form.Item>
    </Fragment>
  );
};

export default CustomCreatePasswordWrapper;
