import { FC, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Form, Input, InputRef, Modal, message } from "antd";
import { ICloneProjectFormData } from "modules/organization/models/interface";
import { ProjectListService } from "modules/organization/services";
import { useNavigate } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";
import { RBAC } from "auth/rbac/rbac";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { usePromptText } from "modules/shared/components/accessibility/PromptStore";
import { useProjectDetailsStore, useProjectRecordsStore } from "modules/organization/store";

const CloneProject: FC<{
  showCloneProjectModal: boolean;
  setCloneProjectDetails: Function;
  projectSlug: string;
  projectTitle: string;
  projectRoleId: number | null;
  resetCloneProjectDetails: () => void;
}> = ({
  showCloneProjectModal,
  setCloneProjectDetails,
  projectSlug,
  projectTitle,
  projectRoleId,
  resetCloneProjectDetails,
}) => {
  const navigate = useNavigate();
  const { organizationBaseRouteUrl } = useOrganizationRoutes();
  const { resetState: clearRecordListState } = useProjectRecordsStore();
  const [isCloningProject, setIsCloningProject] = useState(false);
  const [form] = Form.useForm<ICloneProjectFormData>();
  const projectTitleInputRef = useRef<InputRef | null>(null);
  const {setPromptType,setPromptText} = usePromptText();
  const { setCurrentMode } = useProjectDetailsStore();
  const doCloneProject = async (formValues: ICloneProjectFormData) => {
    setIsCloningProject(true);
    const cloningProjectKey = 'cloningProjectKey';
    let promptText = "Duplicating project...";
    setPromptType("assertive");
    setPromptText(promptText);
    message.open({
        key: cloningProjectKey,
        content: promptText,
        type: 'loading',
        duration: 0
    });
    try {
        setCurrentMode("question");
        clearRecordListState();
        const cloneProjectResponse = await new ProjectListService().cloneProject(projectSlug, formValues.new_project_title, formValues.should_associate_users);
        setCloneProjectDetails(null);
        navigate(
            `${organizationBaseRouteUrl}/project/${cloneProjectResponse.data.data.newProjectDetails.project_slug}`
        );
        promptText = "Project duplicated successfully";
        message.success(promptText);
    } catch (error) {
        console.log(error);
        promptText = "Failed to duplicated project";
        message.error(promptText);
    }
    setPromptType("assertive");
    setPromptText(promptText);
    message.destroy(cloningProjectKey);
    setIsCloningProject(false);
  };

  useEffect(()=>{
    if(showCloneProjectModal){
        form.setFieldsValue(
            {
                new_project_title: projectTitle.slice(0, 194)+ " (New)",
                should_associate_users: true
            }
        )
        projectTitleInputRef.current?.focus();
    }
  }, [showCloneProjectModal, form, projectTitle])

  return (
    <RBAC allowedPermissions={[ERbacPermissions.ORG_PROJECT_CLONE]} project_role_id={projectRoleId}>
      <Modal
        className="confirmationModal"
        wrapClassName="addModal"
        title={
          <h2>
            Duplicate project
          </h2>
        }
        centered
        open={showCloneProjectModal}
        closable={!isCloningProject}
        onCancel={() => resetCloneProjectDetails()}
        footer={[
          <Button htmlType="button" disabled={isCloningProject} key="cancel" onClick={() => resetCloneProjectDetails()}>
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              form.submit();
            }}
            htmlType="button"
            disabled={isCloningProject}
            key="create"
          >
            Duplicate project
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={doCloneProject}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              form.submit();
            }
          }}
        >

        <p>{projectTitle}</p>
           
          <Form.Item
            name="new_project_title"
            label={"Duplicate project title"}
            rules={[{ required: true, message: "Duplicate project title not be empty" }]}
          >
            <Input maxLength={200} ref={projectTitleInputRef} />
          </Form.Item>
          <RBAC allowedPermissions={[ERbacPermissions.ORG_PROJECT_SHOW_MANAGE_GROUP_MEMBER]}>
            <Form.Item
                name="should_associate_users"
                valuePropName="checked"
              >
                <Checkbox>
                  Include all project users?
                </Checkbox>
              </Form.Item>
          </RBAC>
            
        </Form>
      </Modal>
    </RBAC>
  );
};

export default CloneProject;
