import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthSwitchOrganizationChannelService from "modules/auth/services/switchOrganizationChannelService";
import AuthService from "modules/shared/services/authService";

const authService = AuthService.getInstance();
const authSwitchOrganizationChannelService =
  AuthSwitchOrganizationChannelService.getSwitchOrganizationInstance();

const AppLogout = () => {
  const { logout } = useAuth0();
  return (
    <li>
      <Link
        id="logout"
        to="#"
        onClick={() => {
          authService.clearCurrentOrganization();
          authService.clearCurrentUserLocalStorage();
          setTimeout(() => {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });

            authSwitchOrganizationChannelService
              .getLogoutChannel()
              .postMessage("logout");
          }, 700);
        }}
      >
        Logout
      </Link>
    </li>
  );
};

export default AppLogout;
