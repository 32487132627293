import { Fragment, useCallback, useEffect, useState } from "react";
import { Button, Form, message, Modal, Spin } from "antd";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { RBAC } from "auth/rbac/rbac";
import {
  OrganizationService,
  SiteService,
} from "modules/organization/services";
import { IOrganizationUser } from "modules/organization/models/interface";
import CustomSelectInput from "modules/shared/components/CustomSelectInput";
import { useSiteStore } from "modules/organization/store/siteStore";

interface IOrganizationUserOption {
  customLabel: string;
  value: string;
  label: string;
}
const SiteAddUser: React.FC = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [form] = Form.useForm();
  const [userList, setUserList] = useState<IOrganizationUser[] | null>(null);
  const [userListOption, setUserListOption] = useState<
    IOrganizationUserOption[] | null
  >(null);
  const {
    siteInfo,
    userListCurrentPage,
    userListCurrentPageSize,
    userListCurrentSortColumn,
    userListCurrentSortOrder,
    userListSearch,
    site,
    siteUserListData,
    refreshSiteUserList,
  } = useSiteStore();
  const [dataLoader, setDataLoader] = useState(true);

  const initialFormValue = {
    user_associated: undefined,
  };

  const getOrganizationUsers = useCallback(async () => {
    try {
      setDataLoader(true);
      let userOption: IOrganizationUserOption[] = [];
      const result = await new OrganizationService().getOrganizationUserList();
      const { userList } = result.data.data;
      setUserList(userList);
      console.log(siteUserListData);
      userList?.map((eachUser) => {
        if (
          (eachUser.role_alias === "site_administrator" ||
            eachUser.role_alias === "general_contributor") &&
          eachUser.is_active &&
          !siteUserListData.some((item) => item.user_id === eachUser.id)
        ) {
          let eachOption = {
            value: eachUser.id,
            label: `${eachUser.full_name} (${eachUser.role_name})`,
            customLabel: `${eachUser.full_name.split(' ').join('-')} (${eachUser.role_name})`
          };
          userOption = [...userOption, eachOption]
        }
        return userOption;
      });   
      userOption?.sort((a, b) => {
        const roleA = a.customLabel.split(" ")[1];
        const roleB = b.customLabel.split(" ")[1];
        if (roleA < roleB) return 1;
        if (roleA > roleB) return -1;
        return 0;
      });
      setUserListOption(userOption);
    } catch (error) {
      console.error(error);
      message.error({
        content: "Something went wrong please try again!",
      });
    } finally {
      setDataLoader(false);
    }
  }, [siteUserListData, setUserList]);

  useEffect(() => {
    showAddUserModal && getOrganizationUsers();
  }, [showAddUserModal, getOrganizationUsers]);

  const lookupValuesById = (id: string) => {
    const foundItem = userList?.find((item) => item.id === id);
    if (foundItem) {
      const { id, role_id, role_alias } = foundItem;
      return { user_id: id, role_id, role_alias };
    }
    return null;
  };

  const addUserToSite = async () => {
    await form.validateFields().catch((error) => console.error(error));
    const addUserKey = "addUserKey";
    const selectedUser: string[] = form.getFieldValue("user_associated");

    if (selectedUser?.length > 0 && siteInfo) {
      message.open({
        key: addUserKey,
        content: "Adding User...",
        type: "loading",
        duration: 0,
      });
      setShowAddUserModal(false);
      try {
        const users = selectedUser.map((id) => lookupValuesById(id));
        await new SiteService().postAddSiteUser(siteInfo.id, users);
        message.success("Successfully added users as site admin");
        refreshSiteUserList({
          page_index: userListCurrentPage,
          page_size: userListCurrentPageSize,
          sort_column: userListCurrentSortColumn,
          sort_order: userListCurrentSortOrder,
          search: userListSearch === "" ? null : userListSearch,
          site_id: site,
        }).catch(console.error);
        form.resetFields();
      } catch (error) {
        console.log(error);
        message.error("Failed to add user");
      }
    }
    message.destroy(addUserKey);
  };

  const handleOnCancel = () => {
    setShowAddUserModal(false);
    setUserList(null);
    form.resetFields();
  };

  return (
    <Fragment>
      <Modal
        className="confirmationModal"
        title={<>Add User</>}
        centered
        open={showAddUserModal}
        onCancel={handleOnCancel}
        footer={[
          <Button key="no" onClick={handleOnCancel}>
            Cancel
          </Button>,
          <Button
            key="yes"
            type="primary"
            onClick={addUserToSite}
          >
            Add User
          </Button>,
        ]}
      >
        <Spin spinning={dataLoader}>
          <Form
            form={form}
            autoComplete="off"
            layout="vertical"
            className="drawerContent"
            initialValues={initialFormValue}
          >
            <div className="blkContent">
              <CustomSelectInput
                options={userListOption}
                name="user_associated"
                label="User Full Name"
                placeholder={"Select users"}
                required={true}
              />
            </div>
          </Form>
        </Spin>
      </Modal>

      <RBAC allowedPermissions={[ERbacPermissions.ORG_SITE_MANAGE_USER]}>
        <Button
          role="button"
          type="primary"
          className={`addSiteUser`}
          onClick={() => {
            setShowAddUserModal(true);
          }}
        >
          Add User
        </Button>
      </RBAC>
    </Fragment>
  );
};

export default SiteAddUser;
