import { create } from "zustand";
import {
  IRequestSiteProjectListData,
  IRequestSiteUserListData,
  ISiteDetailsData,
  ISiteListData,
  ISiteProjectAction,
  ISiteProjectListData,
  ISiteUserListData,
} from "../models/interface";
import { pageSizeConfigs } from "../../../config/commonConfig";
import { SiteService } from "../services";
import { IHttpError } from "models/interface";
import { UniqueCheckStatus } from "./createNewProjectStore";
interface SiteState {
  siteProjectListData: ISiteProjectListData[];
  siteListData: ISiteListData[] | null;
  siteUserListData: ISiteUserListData[];
  showTableLoader: boolean;
  projectsCount: number;
  currentPageSize: number;
  currentPage: number;
  currentSortColumn: string;
  currentSortOrder: "asc" | "desc";
  search: string;

  showUsersTableLoader: boolean;
  usersCount: number;
  userListCurrentPageSize: number;
  userListCurrentPage: number;
  userListCurrentSortColumn: string;
  userListCurrentSortOrder: "asc" | "desc";
  userListSearch: string;
  site: number;
  published: boolean | null;
  isPreviewOnly: boolean;
  siteInfo: ISiteDetailsData | null;
  isLoadingSiteInfo: boolean;
  isFirstLoad: boolean;
  needPageRefresh: boolean;
  isUserListFirstLoad: boolean;
  needUserListRefresh: boolean;
  error: IHttpError | null;
  titleExists: UniqueCheckStatus;
  titleCheckOnSubmit: UniqueCheckStatus;
  showConfirmDeleteUser: boolean;
  selectedUser: ISiteUserListData | null;
  publishRow: null | number;
  siteProjectAction: ISiteProjectAction;
  restrictSiteListFirestoreUpdate: boolean;

  resetState: () => void;
  setSiteProjectListData: (projects: ISiteProjectListData[] | null) => void;
  setSiteUserListData: (users: any[] | null) => void;
  setSiteListData: (sites: ISiteListData[] | null) => void;
  setShowTableLoader: (value: boolean) => void;
  setProjectsCount: (count: number) => void;
  setCurrentPageSize: (pageSize: number) => void;
  setCurrentPage: (page: number) => void;
  setCurrentSortColumn: (column: string) => void;
  setCurrentSortOrder: (order: "asc" | "desc") => void;
  setSearch: (search: string) => void;

  setShowUsersTableLoader: (value: boolean) => void;
  setUsersCount: (count: number) => void;
  setUserListCurrentPageSize: (pageSize: number) => void;
  setUserListCurrentPage: (page: number) => void;
  setUserListCurrentSortColumn: (column: string) => void;
  setUserListCurrentSortOrder: (order: "asc" | "desc") => void;
  setUserListSearch: (search: string) => void;
  setSite: (site: number) => void;
 
  setPublished: (published: boolean | null) => void;
  setIsPreviewOnly: (isPreviewOnly: boolean) => void;
  setIsFirstLoad: (isFirstLoad: boolean) => void;
  setNeedPageRefresh: (needPageRefresh: boolean) => void;
  setIsUserListFirstLoad: (isFirstLoad: boolean) => void;
  setNeedUserListRefresh: (needPageRefresh: boolean) => void;
  setSiteInfoData: (siteData: ISiteDetailsData | null) => void;
  setIsLoadingSiteInfo: (isLoadingSiteInfo: boolean) => void;
  setTitleExists: (state: UniqueCheckStatus) => void;
  setTitleCheckOnSubmit: (state: UniqueCheckStatus) => void;
  setShowConfirmDeleteUser: (value: boolean) => void;
  setSelectedUser: (user: ISiteUserListData | null) => void;
  refreshSiteProjectList: (
    params: IRequestSiteProjectListData,
    silent?: boolean,
    initLoad?: boolean,
  ) => Promise<void>;
  refreshSiteInfo: (params: { site_id: string }) => Promise<void>;
  refreshSiteUserList: (params: IRequestSiteUserListData) => Promise<void>;
  setPublishRow: (key: number | null) => void;
  setSiteProjectAction: (value?: ISiteProjectAction) => void;
}
const useStore = create<SiteState>((set, get) => ({
  // state
  siteProjectListData: [],
  siteListData: null,
  siteUserListData: [],
  showTableLoader: false,
  projectsCount: 0,
  currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
  currentPage: 1,
  currentSortColumn: "updated_at",
  currentSortOrder: "desc",
  search: "",
  site: 0,
  published: null,
  isPreviewOnly: false,
  showUsersTableLoader: true,
  usersCount: 0,
  userListCurrentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
  userListCurrentPage: 1,
  userListCurrentSortColumn: "full_name",
  userListCurrentSortOrder: "asc",
  userListSearch: "",
  siteInfo: null,
  isLoadingSiteInfo: true,
  isFirstLoad: true,
  error: null,
  titleExists: null,
  titleCheckOnSubmit: null,
  needPageRefresh: false,
  isUserListFirstLoad: true,
  needUserListRefresh:false,
  showConfirmDeleteUser: false,
  selectedUser: null,
  publishRow: null,
  siteProjectAction: {
    type: null,
    details: null,
    showConfirm: false
  },
  restrictSiteListFirestoreUpdate: true,

  // actions
  resetState: () => {
    set(() => ({
      siteProjectListData: [],
      siteListData: null,
      siteUserListData: [],
      showTableLoader: false,
      projectsCount: 0,
      currentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
      currentPage: 1,
      currentSortColumn: "updated_at",
      currentSortOrder: "desc",
      search: "",
      site: 0,
      published: null,
      isPreviewOnly: false,
      showUsersTableLoader: true,
      usersCount: 0,
      userListCurrentPageSize: pageSizeConfigs.DEFAULT_PAGE_SIZE,
      userListCurrentPage: 1,
      userListCurrentSortColumn: "full_name",
      userListCurrentSortOrder: "asc",
      userListSearch: "",
      siteInfo: null,
      isLoadingSiteInfo: true,
      isFirstLoad: true,
      error: null,
      titleExists: null,
      titleCheckOnSubmit: null,
      needPageRefresh: false,
      isUserListFirstLoad: true,
      needUserListRefresh:false,
      showConfirmDeleteUser: false,
      selectedUser: null,
      publishRow: null,
      siteProjectAction: {
        type: null,
        details: null,
        showConfirm: false
      },
      restrictSiteListFirestoreUpdate: true
    }));
  },
  setSiteProjectListData: (projects) => {
    set(() => ({
      siteProjectListData: projects ?? [],
    }));
  },
  setSiteUserListData: (users) => {
    set(() => ({
      siteUserListData: users ?? [],
    }));
  },
  setSiteInfoData: (siteData) => {
    set(() => ({
      siteInfo: siteData ?? null,
    }));
  },
  setSiteListData: (sites) => {
    set(() => ({
      siteListData: sites ?? [],
    }));
  },
  setShowTableLoader: (value) => {
    set(() => ({
      showTableLoader: value,
    }));
  },
  setProjectsCount: (count) => {
    set(() => ({
      projectsCount: count,
    }));
  },
  setCurrentPageSize: (pageSize) => {
    set(() => ({
      currentPageSize: pageSize,
      currentPage: 1,
    }));
  },
  setCurrentPage: (page) => {
    set(() => ({
      currentPage: page,
    }));
  },
  setCurrentSortColumn: (column) => {
    set(() => ({
      currentSortColumn: column,
    }));
  },
  setCurrentSortOrder: (order) => {
    set(() => ({
      currentSortOrder: order,
    }));
  },
  setSearch: (search) => {
    set(() => ({
      search: search,
      currentPage: 1,
    }));
  },

  setShowUsersTableLoader: (value) => {
    set(() => ({
      showUsersTableLoader: value,
    }));
  },
  setUsersCount: (count) => {
    set(() => ({
      usersCount: count,
    }));
  },
  setUserListCurrentPageSize: (pageSize) => {
    set(() => ({
      userListCurrentPageSize: pageSize,
      userListCurrentPage: 1,
    }));
  },
  setUserListCurrentPage: (page) => {
    set(() => ({
      userListCurrentPage: page,
    }));
  },
  setUserListCurrentSortColumn: (column) => {
    set(() => ({
      userListCurrentSortColumn: column,
    }));
  },
  setUserListCurrentSortOrder: (order) => {
    set(() => ({
      userListCurrentSortOrder: order,
    }));
  },
  setUserListSearch: (search) => {
    set(() => ({
      userListSearch: search,
      userListCurrentPage: 1,
    }));
  },

  setSite: (site) => {
    set(() => ({
      site: site,
      currentPage: 1,
    }));
  },
  setPublished: (published) => {
    set(() => ({
      isPreviewOnly: false,
      published: published,
      currentPage: 1,
    }));
  },
  setIsPreviewOnly: (isPreviewOnly) => {
    set(() => ({
      published: null,
      isPreviewOnly: isPreviewOnly,
      currentPage: 1,
    }));
  },

  setTitleExists: (state) => {
    set(() => ({
      titleExists: state,
    }));
  },
  setTitleCheckOnSubmit: (state) => {
    set(() => ({
      titleCheckOnSubmit: state,
    }));
  },

  setIsLoadingSiteInfo: (value) => {
    set(() => ({
      isLoadingSiteInfo: value,
    }));
  },

  setIsFirstLoad: (isFirstLoad) => {
    set(() => ({
      isFirstLoad: isFirstLoad,
    }));
  },

  setNeedPageRefresh: (needPageRefresh) => {
    set(() => ({
      needPageRefresh: needPageRefresh,
    }));
  },

  setIsUserListFirstLoad: (isFirstLoad) => {
    set(() => ({
      isUserListFirstLoad: isFirstLoad,
    }));
  },

  setNeedUserListRefresh: (needPageRefresh) => {
    set(() => ({
      needUserListRefresh: needPageRefresh,
    }));
  },

  setShowConfirmDeleteUser: (value) => {
    set(() => ({
      showConfirmDeleteUser: value,
    }));
  },

  setSelectedUser: (user) => {
    set(() => ({
      selectedUser: user,
    }));
  },

  refreshSiteProjectList: async (params, silent = false, initLoad = false) => {
    set(() => ({ showTableLoader: get().siteProjectListData.length > 0 ? !silent : true }));
    try {
      const result = await new SiteService().getSiteProjectListData(params);
      if(initLoad) {
        set(() => ({
          restrictSiteListFirestoreUpdate: false,
        }))
      }
      const { projects, count } = result.data.data;
      set(() => ({
        siteProjectListData: count > 0 ? [...projects] : [],
        projectsCount: count,
        showTableLoader: false,
        error: null,
      }));
    } catch (error: any) {
      set(() => ({
        showTableLoader: false,
        error: error
          ? {
              ...error,
              code: error?.code ?? 500,
              message: error?.message ?? "Something went wrong",
            }
          : null,
      }));
      console.error(error);
    }
  },

  refreshSiteUserList: async (params) => {
    set(() => ({ showUsersTableLoader: true }));
    try {
      const result = await new SiteService().getSiteUserListData(params);
      const { users, count } = result.data.data;
      set(() => ({
        siteUserListData: count > 0 ? [...users] : [],
        usersCount: count,
        showUsersTableLoader: false,
        error: null,
      }));
    } catch (error: any) {
      set(() => ({
        showUsersTableLoader: false,
        error: error
          ? {
              ...error,
              code: error?.code ?? 500,
              message: error?.message ?? "Something went wrong",
            }
          : null,
      }));
      console.error(error);
    }
  },

  refreshSiteInfo: async (params) => {
    try {
      const result = await new SiteService().getSiteDetailsData(params);
      const { site_details } = result.data.data;
      set(() => ({
        siteInfo: site_details,
        isLoadingSiteInfo: false,
        isFirstLoad: true,
        needPageRefresh: false,
        error: null,
      }));
    } catch (error: any) {
      set(() => ({
        isLoadingSiteInfo: false,
        isFirstLoad: true,
        needPageRefresh: false,
        error: error
          ? {
              ...error,
              code: error?.code ?? 500,
              message: error?.message ?? "Something went wrong",
            }
          : null,
      }));
      console.error(error);
    }
  },
  setPublishRow: (key) => {
    set(() => ({
      publishRow: key,
    }));
  },
  setSiteProjectAction: (value) => {
    set(() => ({
      siteProjectAction: value ?? { type: null, details: null, showConfirm: false },
    }));
  }
}));

export const useSiteStore = useStore;
