import React, { useEffect, useMemo } from "react";
import { Steps, StepProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { FirebaseService } from "modules/application/services";
import { useUserStore } from "modules/shared/store";
import { useProjectDetailsStore } from "modules/organization/store";

const StatusUpdate: React.FC<{
  status: string;
  projectId: number;
  projectSlug: string;
}> = ({ status, projectId, projectSlug }) => {
  const firebaseService = useMemo(() => new FirebaseService(), []);
  const { userDetails } = useUserStore();
  const { getProjectDetails } = useProjectDetailsStore();

  const stepStatuses = ["extracting", "transforming", "loading"];
  const stepStatusText = ["Extracting", "Processing", "Finalizing"];
  const stepDescriptions = [
    "Extracting data from the uploaded excel file",
    "Processing data from the extracted data",
    "Finalizing data from the processed data",
  ];
  const currentStepIndex = stepStatuses.indexOf(status);

  useEffect(() => {
    let unsubscribe: () => void;
    if (userDetails?.org_id && userDetails?.id) {
      unsubscribe = firebaseService.listenForNotifications(
        userDetails.org_id,
        userDetails.id,
        (notification) => {
          if (
            notification[0].id.startsWith("Importer") &&
            projectId === parseInt((notification[0].id).split("_")[1])
          ) {
            getProjectDetails(projectSlug).catch((error) => console.log(error));
          }
        }
      );
    }

    return () => {
      unsubscribe();
    };
  }, []);

  const steps: StepProps[] = stepStatuses.map((step, index) => ({
    title: `${stepStatusText[index]} Data`,
    description: stepDescriptions[index],
    icon: currentStepIndex === index ? <LoadingOutlined /> : undefined,
  }));

  return (
    <div style={{ height: "64vh" }}>
      <Steps
        className="importerStatusSteps"
        current={currentStepIndex}
        items={steps}
      />
    </div>
  );
};

export default StatusUpdate;
