import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useRbac } from '../auth/rbac/rbac';
import PageLoader from '../modules/shared/components/PageLoader';
import { FooterSlim } from 'modules/shared/layout/includes/FooterSlim';
import EditUserDetailPage from 'modules/auth/EditUserDetailPage';
import CustomJurisdiction from 'modules/application/appCustomJurisdiction/CustomJurisdiction';
import AppHeader from 'modules/shared/layout/includes/AppHeader';
import NotFound from 'modules/shared/components/NotFound';
const AppDashboardPage = React.lazy(
  () => import('../modules/application/appDashboard/AppDashboardPage')
);
const AppSettingsPage = React.lazy(
  () => import('../modules/application/appSettings/appSettingsPage')
);

export function ApplicationRoutes() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<PageLoader />}>
              <AppDashboardPage />
            </React.Suspense>
          }
        />
        <Route
          path="settings"
          element={
            <React.Suspense fallback={<PageLoader />}>
              <AppSettingsPage />
            </React.Suspense>
          }
        />
        <Route path="custom-jurisdiction" element={<CustomJurisdiction />} />
        <Route path="edit-profile" element={<EditUserDetailPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const AppLayout = () => {
  const { userDetails } = useRbac();

  return (
    <div className="mainWrapper">
      {userDetails?.is_app_admin ? (
        <React.Fragment>
          <AppHeader />
          <main className="mainContent">
            <Outlet />
          </main>
          <FooterSlim />
        </React.Fragment>
      ) : <NotFound />}
    </div>
  );
};
