import { create } from 'zustand';
import { ProjectListService } from '../services';
import { IHttpError } from 'models/interface';
import {
    ICodingReviewAnswersData,
    ICodingReviewCitationData,
    ICodingReviewRecordsData,
    ICodingReviewSeries,
    IProjectQuestionFlattenTree,
    IRequestCodingReviewData,
    IResponseCodingReview
} from '../models/interface';
import { dateFormat } from 'config/commonConfig';
import { mnDate } from 'modules/shared/services';

export interface ICodingReviewResult {
    jurisdictionId: number;
    recordId: number;
    seriesTitle: string;
    effectiveRecord: { startDate: string; endDate: string };
    totalCount: number;
    questions: ICodingReviewQuestion[];
    summary: IReviewSummary;
    total: IReviewSummary;
}

export interface IReviewSummary {
    answerCount: number;
    citationCount: number;
    cautionNotesCount: number;
}

export interface ICodingReviewQuestion {
    questionId: number;
    answers: IAnswerReview[];
    citations: ICitationReview[];
    cautionNotes: IAnswerReview;
}

export interface ICitationReview {
    sourceTitle: string;
    pinCite: string;
    tags: string;
    sourceText: string;
    isMismatched: boolean;
}

export interface IAnswerReview {
    text: string;
    isMismatched: boolean;
}

interface ICodingReviewState {
    seriesTitleList: ICodingReviewSeries[];
    loader: boolean;
    dataFetchError: IHttpError | null;
    hideFilters: boolean;
    resultsFilter: number;
    jurisdictionList: { value: number; label: string }[];
    formValues: IRequestCodingReviewData | null;
    questionList: IProjectQuestionFlattenTree[];
    firstSeries: ICodingReviewResult[];
    secondSeries: ICodingReviewResult[];
    selectedJurisdiction: number[];

    getFilters: (
        project_slug: string,
        question_list: IProjectQuestionFlattenTree[]
    ) => Promise<void>;
    getCodingReviewResults: (
        params: IRequestCodingReviewData,
        project_slug: string
    ) => Promise<void>;
    setHideFilters: () => void;
    setFormValues: (data: IRequestCodingReviewData) => void;
    setSelectedJurisdiction: (value: number[]) => void;
    setResultFilter: (value: number) => void;
    resetState: () => void;
}

const useStore = create<ICodingReviewState>((set, get) => ({
    seriesTitleList: [],
    loader: true,
    dataFetchError: null,
    hideFilters: false,
    resultsFilter: 1,
    jurisdictionList: [],
    formValues: null,
    questionList: [],
    firstSeries: [],
    secondSeries: [],
    selectedJurisdiction: [],

    resetState: () => {
        set(() => ({
            seriesTitleList: [],
            loader: true,
            dataFetchError: null,
            hideFilters: false,
            resultsFilter: 1,
            jurisdictionList: [],
            formValues: null,
            questionList: [],
            firstSeries: [],
            secondSeries: [],
            selectedJurisdiction: [],
        }))
    },
    getFilters: async (project_slug, question_list) => {
        set(() => ({ loader: true }));
        try {
            const { data } = await new ProjectListService().getCodingReviewFilters(
                project_slug
            );
            set(() => ({
                questionList: question_list,
                seriesTitleList: data.data.filtersData
                    ? [...data.data.filtersData]
                    : [],
                loader: false,
                dataFetchError: null
            }));
        } catch (error: any) {
            set(() => ({ dataFetchError: error, loader: false }));
            console.error(error);
        }
    },
    getCodingReviewResults: async (params, project_slug) => {
        set(() => ({ loader: true }));
        set(() => ({
            firstSeries: [],
            secondSeries: []
        }));
        try {
            const { data } = await new ProjectListService().getCodingReviewData(
                params,
                project_slug
            );
            const { jurisdictionList, firstSeries, secondSeries } = makeSeriesData(
                data.data.records,
                params.first_series ?? '',
                params.second_series ?? ''
            );

            const { reviewSeriesA, reviewSeriesB } = doCodingReview(
                jurisdictionList,
                firstSeries,
                secondSeries,
                data.data,
                get().questionList
            );


            set(() => ({
                firstSeries: [...reviewSeriesA],
                secondSeries: [...reviewSeriesB],
                jurisdictionList,
                loader: false,
                dataFetchError: null
            }));
        } catch (error: any) {
            set(() => ({ dataFetchError: error, loader: false }));
            console.error(error);
        }
    },
    setHideFilters: () => {
        set(() => ({ hideFilters: !get().hideFilters }));
    },
    setFormValues: (data) => {
        set(() => ({ formValues: { ...data } }));
    },
    setSelectedJurisdiction: (value) => {
        set(() => ({ selectedJurisdiction: value ? [...value] : [] }));
    },
    setResultFilter: (value) => {
        set(() => ({ resultsFilter: value }));
    }
}));

const makeSeriesData = (
    records: ICodingReviewRecordsData[],
    seriesA: string,
    seriesB: string
) => {
    const firstSeries: ICodingReviewRecordsData[] = [],
        secondSeries: ICodingReviewRecordsData[] = [];
    ((records?.length) ? records.filter((eachRecord) => eachRecord.series_title === seriesA) : [])
        .forEach((seriesARecord) => {
            const comparingSeries = records.find(
                (seriesBRecord) =>
                    seriesBRecord.series_title === seriesB &&
                    seriesARecord.jurisdiction_id === seriesBRecord.jurisdiction_id
            );
            if (comparingSeries) {
                firstSeries.push(seriesARecord);
                secondSeries.push(comparingSeries);
            }
        });
    const jurisdictionList = firstSeries.map((eachRecord) => ({
        value: eachRecord.jurisdiction_id,
        label: eachRecord.name
    }));
    return { firstSeries, secondSeries, jurisdictionList };
};

const doCodingReview = (
    jurisdictionList: { value: number; label: string }[],
    firstSeries: ICodingReviewRecordsData[],
    secondSeries: ICodingReviewRecordsData[],
    data: IResponseCodingReview,
    questionList: IProjectQuestionFlattenTree[]
) => {
    let reviewSeriesA: ICodingReviewResult[] = [],
        reviewSeriesB: ICodingReviewResult[] = [];
    jurisdictionList.forEach((eachJurisdiction) => {
        const seriesA = firstSeries.find((eachData) => (eachData.jurisdiction_id === eachJurisdiction.value));
        const seriesB = secondSeries.find((eachData) => (eachData.jurisdiction_id === eachJurisdiction.value));

        if (seriesA && seriesB) {
            const { reviewForSeriesA, reviewForSeriesB } =
                doReviewForEachJurisdiction(seriesA, seriesB, data, questionList);
            reviewSeriesA.push({ ...reviewForSeriesA });
            reviewSeriesB.push({ ...reviewForSeriesB });
        }
    });
    
    return { reviewSeriesA, reviewSeriesB };
};

const doReviewForEachJurisdiction = (
    seriesA: ICodingReviewRecordsData,
    seriesB: ICodingReviewRecordsData,
    data: IResponseCodingReview,
    questionList: IProjectQuestionFlattenTree[]
) => {
    const { seriesAResult, seriesBResult, summary, total } = doReviewsForQuestion(
        data,
        questionList,
        seriesA,
        seriesB
    );

    const reviewForSeriesA: ICodingReviewResult = {
        recordId: seriesA.id,
        jurisdictionId: seriesA.jurisdiction_id,
        seriesTitle: seriesA.series_title,
        effectiveRecord: {
            startDate: seriesA.effective_from,
            endDate: seriesA.through_to
        },
        totalCount: seriesA.total_records,
        questions: [...seriesAResult],
        summary: { ...summary },
        total: { ...total }
    };
    const reviewForSeriesB: ICodingReviewResult = {
        recordId: seriesB.id,
        jurisdictionId: seriesB.jurisdiction_id,
        seriesTitle: seriesB.series_title,
        effectiveRecord: {
            startDate: seriesB.effective_from,
            endDate: seriesB.through_to
        },
        totalCount: seriesB.total_records,
        questions: [...seriesBResult],
        summary: { ...summary },
        total: { ...total }
    };
    return { reviewForSeriesA, reviewForSeriesB };
};

const doReviewsForQuestion = (
    data: IResponseCodingReview,
    questionList: IProjectQuestionFlattenTree[],
    seriesA: ICodingReviewRecordsData,
    seriesB: ICodingReviewRecordsData
) => {
    let seriesAResult: ICodingReviewQuestion[] = [],
        seriesBResult: ICodingReviewQuestion[] = [];
    const summary: IReviewSummary = {
        cautionNotesCount: 0,
        citationCount: 0,
        answerCount: 0
    };
    const total: IReviewSummary = {
        cautionNotesCount: 0,
        citationCount: 0,
        answerCount: 0
    };
    questionList.forEach((eachQuestion) => {
        const { seriesAMismatch, seriesBMismatch, totalCount, mismatchCount } =
            doReviewsForAnswers(data.total_answers ?? [], eachQuestion, seriesA, seriesB);
        const {
            seriesACitationMismatch,
            seriesBCitationMismatch,
            totalCitationCount,
            mismatchCitationCount
        } = doReviewsForCitations(
            data.total_citations ?? [],
            eachQuestion,
            seriesA,
            seriesB
        );
        const {
            seriesACautionMismatch,
            seriesBCautionMismatch,
            mismatchCautionCount,
            totalCautionCount
        } = doReviewsForCautionNotes(
            data.total_answers ?? [],
            eachQuestion,
            seriesA,
            seriesB
        );
        summary.cautionNotesCount += mismatchCautionCount;
        total.cautionNotesCount += totalCautionCount;
        summary.citationCount += mismatchCitationCount;
        total.citationCount += totalCitationCount;
        summary.answerCount += mismatchCount;
        total.answerCount += totalCount;
        if ((mismatchCautionCount + mismatchCitationCount + mismatchCount) > 0) {            
            seriesAResult.push({
                questionId: eachQuestion.data.question_id,
                answers: [...seriesAMismatch],
                citations: [...seriesACitationMismatch],
                cautionNotes: seriesACautionMismatch
            });
            seriesBResult.push({
                questionId: eachQuestion.data.question_id,
                answers: seriesBMismatch,
                citations: [...seriesBCitationMismatch],
                cautionNotes: seriesBCautionMismatch
            });
        }
    });

    return {
        seriesAResult,
        seriesBResult,
        summary: { ...summary },
        total: { ...total }
    };
};

const doReviewsForAnswers = (
    answers: ICodingReviewAnswersData[],
    question: IProjectQuestionFlattenTree,
    seriesA: ICodingReviewRecordsData,
    seriesB: ICodingReviewRecordsData
) => {
    let totalCount = 0,
        mismatchCount = 0;
    let seriesAMismatch: IAnswerReview[] = [],
        seriesBMismatch: IAnswerReview[] = [];
    const ansA = answers.find(
        (eachAns) =>
            eachAns.record_id === seriesA.id &&
            eachAns.question_id === question.data.question_id
    );
    const ansB = answers.find(
        (eachAns) =>
            eachAns.record_id === seriesB.id &&
            eachAns.question_id === question.data.question_id
    );
    const answeredOptionA = ansA?.response_id
        ? ansA.response_id.filter((eachAns) => eachAns !== null)
        : [];
    const answeredOptionB = ansB?.response_id
        ? ansB.response_id.filter((eachAns) => eachAns !== null)
        : [];
    
    // checking if answered
    
    const isAnsA: boolean = Boolean(ansA && (ansA?.open_response || (answeredOptionA && answeredOptionA.length > 0)));
    const isAnsB: boolean = Boolean(ansB && (ansB?.open_response || (answeredOptionB && answeredOptionB.length > 0)));

    if (!isAnsA) {
        seriesAMismatch.push({
            text: 'Not Answered',
            isMismatched: isAnsB
        });
    }

    if (!isAnsB) {
        seriesBMismatch.push({
            text: 'Not Answered',
            isMismatched: isAnsA
        });
    }

    if ([1, 2, 3, 7].includes(question.data.question_type_id)) {
        if ((ansA?.open_response ?? '') !== (ansB?.open_response ?? '')) {
            ansA?.open_response &&
                seriesAMismatch.push({ text: formatAnswer(ansA.open_response, question.data.question_type_id), isMismatched: true });
            ansB?.open_response &&
                seriesBMismatch.push({ text: formatAnswer(ansB.open_response, question.data.question_type_id), isMismatched: true });
            mismatchCount++;
            totalCount ++;
        } else {
            ansA?.open_response &&
                seriesAMismatch.push({ text: formatAnswer(ansA.open_response, question.data.question_type_id), isMismatched: false });
            ansB?.open_response &&
                seriesBMismatch.push({ text: formatAnswer(ansB.open_response, question.data.question_type_id), isMismatched: false });
            totalCount ++;
        }
    } else {
        totalCount += (question.data.question_type_id === 4 ? ( question.data.responses?.length ?? 0 ) : 1);

        isAnsA && answeredOptionA.length &&
            answeredOptionA?.forEach((eachOption) => {
                if (!eachOption) return;

                if (!isAnsB) {
                    seriesAMismatch.push({ text: eachOption, isMismatched: true });
                    mismatchCount++;
                    return;
                }

                if (!answeredOptionB?.includes(eachOption)) {
                    seriesAMismatch.push({ text: eachOption, isMismatched: true });
                    mismatchCount++;
                } else {
                    seriesAMismatch.push({ text: eachOption, isMismatched: false });
                    seriesBMismatch.push({ text: eachOption, isMismatched: false });
                }
            });
            isAnsB && answeredOptionB.length &&
            answeredOptionB?.forEach((eachOption) => {
                if (!eachOption) return;

                if (!isAnsA) {
                    seriesBMismatch.push({ text: eachOption, isMismatched: true });
                    mismatchCount++;
                    return;
                }

                if (!answeredOptionA?.includes(eachOption)) {
                    seriesBMismatch.push({ text: eachOption, isMismatched: true });
                    question.data.question_type_id === 4 && mismatchCount++;
                }
            });
    }

    return { totalCount, mismatchCount, seriesAMismatch, seriesBMismatch };
};

const filterCitationsBasedOnSeriesAndQuestion = (citations: ICodingReviewCitationData[], recordId: number, questionId: number) => {
    if (!citations) {
        return [];
    }

    return citations.filter(
        (eachCitation) =>
            eachCitation.record_id === recordId &&
            eachCitation.question_id === questionId
    )
    .map((eachCitation) => {
        return {
            sourceTitle: eachCitation.document_title,
            pinCite: eachCitation.pin_cite,
            tags: eachCitation.tags,
            sourceText: eachCitation.is_full_text
                ? 'Full text'
                : eachCitation.law_text
        };
    })
}

const doReviewsForCitations = (
    citations: ICodingReviewCitationData[],
    question: IProjectQuestionFlattenTree,
    seriesA: ICodingReviewRecordsData,
    seriesB: ICodingReviewRecordsData
) => {
    const filteredSeriesAData: Omit<ICitationReview, 'isMismatched'>[] = filterCitationsBasedOnSeriesAndQuestion(citations, seriesA.id, question.data.question_id);

    const filteredSeriesBData: Omit<ICitationReview, 'isMismatched'>[] = filterCitationsBasedOnSeriesAndQuestion(citations, seriesB.id, question.data.question_id);

    let totalCitationCount = 0,
        mismatchCitationCount = 0;
    let seriesACitationMismatch: ICitationReview[] = [],
        seriesBCitationMismatch: ICitationReview[] = [];

    filteredSeriesAData.forEach((obj1) => {
        const matchingObject = filteredSeriesBData.find((obj2) =>
            areObjectsEqual(obj1, obj2)
        );
        totalCitationCount++;
        if (!matchingObject) {
            mismatchCitationCount++;
            seriesACitationMismatch.push({ ...obj1, isMismatched: true });
        } else {
            seriesACitationMismatch.push({ ...obj1, isMismatched: false });
        }
    });
    

    filteredSeriesBData.forEach((obj1) => {
        const matchingObject = filteredSeriesAData.find((obj2) =>
            areObjectsEqual(obj1, obj2)
        );
        
        if (!matchingObject) {
            totalCitationCount++;
            mismatchCitationCount++;
            seriesBCitationMismatch.push({ ...obj1, isMismatched: true });
        } else {
            seriesBCitationMismatch.push({ ...obj1, isMismatched: false });
        }
    });

    return {
        seriesACitationMismatch,
        seriesBCitationMismatch,
        totalCitationCount,
        mismatchCitationCount
    };
};

const doReviewsForCautionNotes = (
    answers: ICodingReviewAnswersData[],
    question: IProjectQuestionFlattenTree,
    seriesA: ICodingReviewRecordsData,
    seriesB: ICodingReviewRecordsData
) => {
    let totalCautionCount = 0,
        mismatchCautionCount = 0;
    let seriesACautionMismatch: IAnswerReview,
        seriesBCautionMismatch: IAnswerReview;
    const cautionA = answers.find(
        (eachAns) =>
            eachAns.record_id === seriesA.id &&
            eachAns.question_id === question.data.question_id
    )?.caution_note;
    const cautionB = answers.find(
        (eachAns) =>
            eachAns.record_id === seriesB.id &&
            eachAns.question_id === question.data.question_id
    )?.caution_note;
    
    if ((cautionA ?? '') !== (cautionB ?? '')) {
        seriesACautionMismatch = { text: cautionA ?? '', isMismatched: true };
        seriesBCautionMismatch = { text: cautionB ?? '', isMismatched: true };
        mismatchCautionCount++;
        totalCautionCount++;
    } else {
        seriesACautionMismatch = { text: cautionA ?? '', isMismatched: false };
        seriesBCautionMismatch = { text: cautionB ?? '', isMismatched: false };
        (cautionA || cautionB) && totalCautionCount++;
    }

    return {
        seriesACautionMismatch,
        seriesBCautionMismatch,
        mismatchCautionCount,
        totalCautionCount
    };
};

const areObjectsEqual = (
    obj1: Omit<ICitationReview, 'isMismatched'>,
    obj2: Omit<ICitationReview, 'isMismatched'>
): boolean => {
    return (
        obj1.pinCite.trim() === obj2.pinCite.trim() &&
        obj1.sourceText.trim() === obj2.sourceText.trim() &&
        obj1.tags.trim() === obj2.tags.trim()
    );
};

const formatAnswer = (answer: string, typeId: number) => {
    switch (typeId) {
        case 3:
            return `$${answer}`;
        case 7:
            return mnDate(answer,false).format(dateFormat.default);
        default:
            return answer;
    }
}

const mergeCitations = (seriesACitations: ICitationReview[], seriesBCitations: ICitationReview[]) => {
    const result: (ICitationReview | null)[][] = [];
    (seriesACitations).forEach((citationA) => {
        if (citationA.isMismatched) {
            result.push([{...citationA}, null]);
        } else {
            const citationB = seriesBCitations.find(({ pinCite, sourceText, tags }) => (pinCite.trim() === citationA.pinCite.trim() && sourceText.trim() === citationA.sourceText.trim() && tags.trim() === citationA.tags.trim()));
            citationB ? result.push([{...citationA}, {...citationB}]) : result.push([{...citationA}, null]);
        }
    });
    seriesBCitations.filter(({isMismatched}) => isMismatched).forEach((citationB) => result.push([null, {...citationB}]));
    
    return [...result];
}

export const doMergeCitations = mergeCitations
export const useCodingReviewStore = useStore;
