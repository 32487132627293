import React, { useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form, Input, message, Space, Spin, Tooltip, Upload } from 'antd';
import { PlusCircleOutlined, DeleteOutlined, UploadOutlined, CloseOutlined } from '@ant-design/icons';
import { usePromptText } from 'modules/shared/components/accessibility/PromptStore';
import { useRankingSetupStore } from 'modules/organization/store/rankingSetupStore';
import { IRankingTemplateFormData, IRankingTemplateRank } from 'modules/organization/models/interface/rankingSetup.interface';
import { RankingProjectService } from 'modules/organization/services/rankingProject.service';
import { RcFile } from 'antd/es/upload';
import { useWatch } from 'antd/es/form/Form';



const CreateNewRankingTemplateForm: React.FC<{ visibility: boolean }> = ({
  visibility
}) => {
  const tooltipTitle = `Maximum file size should be less than 2 MB.
                        Supported file type png and webp.
                        Image should be maximum 32x32px.`
  const [form] = Form.useForm<IRankingTemplateFormData>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setPromptType, setPromptText } = usePromptText();
  const watchRanks = useWatch("ranks", form);

  const {
    rankingTemplateDrawerState,
    rankingTemplateDrawerOptions,

    setRankingTemplateDrawerState,
    setFormLoader,
    rankingTemplateInfo,
    refreshRankingTemplateList,
  } = useRankingSetupStore();

  const validateUniqueRankName = (_: any, value: string) => {
    const filteredRanks: IRankingTemplateFormData[] = form
      .getFieldValue('ranks')
      .filter(
        (rank: IRankingTemplateRank) =>
          rank?.rank_name &&
          rank.rank_name?.toLowerCase() === value.toLowerCase()
      );

    if (filteredRanks.length > 1) {
      return Promise.reject(new Error('Rank name should be unique.'));
    }
    return Promise.resolve();
  };

  const validateImage = async (file: RcFile, name: number) => {
    const allowedExtensions = /(\.webp|\.png)$/i;
    if (!allowedExtensions.exec(file.name.toLocaleLowerCase())) {
      message.error({
        content: `Please upload image having extensions .png or .webp only.`,
        className: 'toastMsg savedFailed'
      });
    }
    const fileSize = file.size / 1024 / 1024; // in MiB
    if (fileSize < 2) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (event: any) => {
        const _loadedImageUrl: string = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          const checkDimension = (width <= 32 || height <= 32) && Math.abs(width - height) <= 4;          
          if (checkDimension) {
            // set image width and height to your state here
            const type = file.name.split(".").pop()?.toLowerCase() ?? "";
            form.setFieldValue(["ranks", name, "rank_icon_extension"], type)
            form.setFieldValue(["ranks", name, "rank_icon_preview"], _loadedImageUrl)
            form.setFieldValue(["ranks", name, "rank_icon_type"], file.type)
          } else {
            message.error(`Please upload a square image with maximum dimensions of 32x32px.`);
          }
        });
      });
    } else {
      message.error(`File size should be less than 2 MB.`);
    }
    return false;
  };

  const removeRankIcon = (name: number) => {
    form.setFieldValue(["ranks", name, "rank_icon_preview"], null)
    form.setFieldValue(["ranks", name, "rank_icon_url"], null)

  }

  const isDeleteEnabled = (numberOfRank: number, rankIndex: number) => {
    return (
      numberOfRank > 2
    );
  };

  const validateForm = () => {
    form.validateFields();
  };


  const rankingTemplateFormData: IRankingTemplateFormData = {
    title: undefined,
    ranks: [
      { rank_name: undefined, display_order: undefined, rank_icon_url: undefined, rank_icon_preview: undefined },
      { rank_name: undefined, display_order: undefined, rank_icon_url: undefined, rank_icon_preview: undefined }
    ],
  };

  const onFinish = async (values: IRankingTemplateFormData) => {
    const createRankingTemplateKey = 'createRankingTemplate';
    let promptText = rankingTemplateDrawerOptions.toastMessage?.loading as string;
    setPromptType("assertive");
    setPromptText(promptText);
    setIsSubmitting(true);
    message.open({
      key: createRankingTemplateKey,
      content: promptText,
      type: 'loading',
      duration: 0
    });

    createEditRankingTemplate(formatValues(values), createRankingTemplateKey);
  };

  const formatValues = (values: IRankingTemplateFormData) => {
    values.ranks = values.ranks?.map((rank, index) => ({
      ...rank,
      rank_icon_preview: rank?.rank_icon_preview?.toString(),
      display_order: index + 1
    }));
    return values;
  }

  const editManageRankingTemplate = (values: IRankingTemplateFormData) => {
    return new RankingProjectService().editRankingTemplate(values);
  };

  const createEditRankingTemplate = async (
    values: IRankingTemplateFormData,
    createRankingTemplateKey: any
  ) => {
    try {

      rankingTemplateInfo?.id
        ? await editManageRankingTemplate({ ...values, id: rankingTemplateInfo?.id })
        : await new RankingProjectService().createRankingTemplate(values);
      setRankingTemplateDrawerState(false);
      refreshRankingTemplateList();
      setPromptType("assertive");
      setPromptText(rankingTemplateDrawerOptions.toastMessage?.success as string)
      message.success({
        key: createRankingTemplateKey,
        content: rankingTemplateDrawerOptions.toastMessage?.success,
        duration: 2,
      });
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setPromptType("assertive");
      setPromptText(rankingTemplateDrawerOptions.toastMessage?.error as string)
      message.error({
        key: createRankingTemplateKey,
        content: rankingTemplateDrawerOptions.toastMessage?.error,
      });
    }
  };

  useEffect(() => {
    if (!visibility) {
      form.resetFields();
    }
  }, [visibility, form]);

  useEffect(() => {
    if (rankingTemplateInfo && rankingTemplateDrawerState) {
      const {
        title,
        ranks
      } = rankingTemplateInfo;

      form.setFieldsValue({
        title,
        ranks: ranks ?? [],
      });
      setFormLoader(false);
    }
  }, [rankingTemplateDrawerState, rankingTemplateInfo, form, setFormLoader]);

  return (
    <Form
      form={form}
      disabled={isSubmitting}
      layout="vertical"
      onFinish={(props) => {
        onFinish(props).catch(console.error);
      }}
      autoComplete="off"
      className="drawerContent"
      initialValues={rankingTemplateFormData}
      name="form"
    >
      <div className="blkContent">
        <Form.Item
          name="title"
          label="Template Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Ranking template name is required'
            }
          ]}
        >
          <Input
            aria-label="Enter Ranking Template Name"
            placeholder="Enter ranking template name"
            maxLength={100}
            autoFocus
          />
        </Form.Item>
        <Form.Item label="Ranks" className='rankFormItems'>
          <Form.List name="ranks">
            {(fields, { add, remove }) => (
              <>
                <ul className="fldWthDropdown">
                  {fields.map(({ key, name, ...restField }) => (
                    <li key={key} >
                      <Form.Item
                        {...restField}
                        name={[name, 'rank_name']}
                        validateFirst
                        rules={[
                          {
                            required: true,
                            message: 'Rank name is required'
                          },
                          {
                            validator: validateUniqueRankName
                          }
                        ]}
                      >
                        <Input placeholder="Rank Name" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, 'rank_icon_url']}
                        shouldUpdate
                      ><Tooltip title={tooltipTitle} trigger={["hover", "focus"]}>
                          <Upload
                            beforeUpload={(file) => validateImage(file, name)}
                            accept={'.webp, .png'}
                            maxCount={1}
                            showUploadList={false}
                          >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                          </Upload>
                        </Tooltip>
                      </Form.Item>
                      {watchRanks && (watchRanks[name]?.rank_icon_preview || watchRanks[name]?.rank_icon_url) &&
                        <div className="uploadedImgWrap">
                          <img src={watchRanks[name]?.rank_icon_preview ?? `${process.env.REACT_APP_RANKING_ICON_URL}/${watchRanks[name]?.rank_icon_url}`} alt="Rank icon" />
                          <Button
                            onClick={() => {
                              removeRankIcon(name);
                            }}
                            aria-label='Remove Rank'
                            icon={<CloseOutlined aria-hidden />}
                            className="noPaddingBtn"
                          ></Button>
                        </div>}
                      {isDeleteEnabled(fields.length, name) &&
                        <Button
                          onClick={() => {
                            remove(name);
                            setTimeout(validateForm, 100);
                          }}
                          aria-label='Remove Rank'
                          icon={<DeleteOutlined aria-hidden />}
                          className="noPaddingBtn"
                        ></Button>
                      }
                    </li>
                  ))}
                </ul>
                <Button
                  onClick={() => {
                    const index = form
                      .getFieldValue('ranks')
                      .findIndex(
                        (rank: IRankingTemplateRank) =>
                          !rank?.rank_name
                      );
                    if (index < 0) {
                      add();
                    } else {
                      form
                        .getFieldInstance(['ranks', index, 'rank_name'])
                        .focus();
                    }
                  }}
                  icon={<PlusCircleOutlined aria-label="" aria-hidden="true" />}
                  className="noPaddingBtn"
                >
                  Add Rank
                </Button>
              </>
            )}
          </Form.List>
        </Form.Item>
      </div>
      <div className="blkFooter">
        <Form.Item>
          <Space>
            <Button htmlType="button" onClick={() => setRankingTemplateDrawerState(false)}>
              Cancel
            </Button>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, getFieldsError }) => {
                const isTemplateName = isFieldsTouched(['title']);
                const isRankName = isFieldsTouched(['ranks']);
                return (
                  <Button
                    role="button"
                    type="primary"
                    htmlType="submit"
                    disabled={Boolean(
                      (!isTemplateName || !isRankName ||
                        getFieldsError().filter(({ errors }) => errors.length)
                          .length)
                    )}
                  >
                    {rankingTemplateDrawerOptions.submitBtnLabel}
                  </Button>
                );
              }}
            </Form.Item>
          </Space>
        </Form.Item>
      </div>
    </Form>

  );
};

const OrgCreateNewRankingTemplate: React.FC<{
  createButton?: { label: string; className?: string };
}> = ({ createButton }) => {
  const [visibility, setVisibility] = useState<boolean>(false);

  const {
    rankingTemplateDrawerState,
    rankingTemplateDrawerOptions,
    formLoader,

    resetDrawerState,
    setRankingTemplateDrawerState,
    setRankingTemplateDrawerOptions,
  } = useRankingSetupStore();
  const buttonEle = useRef<HTMLButtonElement>(null);


  const afterOpenChange = (visible: boolean) => {
    setVisibility(visible);
    if (!visible) {
      resetDrawerState();
    }
  };

  return (
    <React.Fragment>
      {createButton && (
        <Button
          type="primary"
          className={`${createButton.className} flexLeftGap`}
          onClick={() => {
            setRankingTemplateDrawerOptions();
            setRankingTemplateDrawerState(true);
            buttonEle.current?.blur();
          }}
          ref={buttonEle}
        >
          {createButton.label}
        </Button>
      )}
      <Drawer
        title={<h2>{`${rankingTemplateDrawerOptions.title}`}</h2>}
        maskClosable={false}
        destroyOnClose={true}
        className="fixedFooterBtns"
        width={720}
        closable={false}
        onClose={() => setRankingTemplateDrawerState(false)}
        open={rankingTemplateDrawerState}
        afterOpenChange={afterOpenChange}
        extra={
          <Button onClick={() => setRankingTemplateDrawerState(false)} className="closeBlk">
            Close
          </Button>
        }
      >
        <Spin spinning={formLoader}>
          <CreateNewRankingTemplateForm visibility={visibility} />
        </Spin>
      </Drawer>
    </React.Fragment>
  );
};

export default OrgCreateNewRankingTemplate;
