import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input, Radio, RadioChangeEvent } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { UserService } from "modules/shared/services";
import { IRegistrationData } from "models/interface";
import siteLogo from "../../assets/images/site-logo.svg";
import PageLoader from "modules/shared/components/PageLoader";
import { usePageTitle } from "modules/shared/hooks";
import CustomCreatePasswordWrapper from "modules/shared/components/CustomCreatePasswordWrapper";

const RegistrationPage = () => {
  usePageTitle("MonQcle - Registration");
  const { isLoading, isAuthenticated } = useAuth0();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const [registrationType, setRegistrationType] = useState("organization");
  const [isUnavailable, _] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isOrgRegistrationComplete, setIsOrgRegistrationComplete] = useState<boolean>(false);
  const [isIndvlRegistrationComplete, setIsIndvlRegistrationComplete] = useState<boolean>(false);
  const promtRef = useRef<HTMLDivElement>(null);
  const [errorObject, setErrorObject] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });

  const onChangeRegistrationType = (e: RadioChangeEvent) => {
    setRegistrationType(e.target.value);
  };
  useEffect(() => {
    if (isOrgRegistrationComplete || isIndvlRegistrationComplete) {
      setTimeout(() => {
        promtRef.current?.focus();
        promtRef.current?.click();
      }, 1000);
    }
  }, [isOrgRegistrationComplete, isIndvlRegistrationComplete]);

  const onFinish = async (formValues: IRegistrationData) => {
    setIsSubmitting(true);
    setErrorObject({ isError: false, message: "" });
    try {
      const response = (
        await new UserService().postUserRegistration({
          registration_type: registrationType,
          email: formValues.email,
          org_name: formValues.orgName,
          full_name: formValues.fullName,
          user_name: formValues.userName,
          password: formValues.password,
          baseUrl: baseUrl,
        })
      ).data?.data;
      if (response?.registration_status === false) {
        setErrorObject({ isError: true, message: response?.message });
      } else if (
        response?.registration_status === true &&
        registrationType === "organization"
      ) {
        setIsOrgRegistrationComplete(true);
      } else if (
        response?.registration_status === true &&
        registrationType === "individual"
      ) {
        setIsIndvlRegistrationComplete(true);
      }
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);
      console.log(error);
      setErrorObject({ isError: true, message: error?.message });
    }
  };

  if (isLoading) {
    return <PageLoader />;
  }

  if (!isLoading && isAuthenticated) {
    navigate("/");
  }

  return (
      <div className="loginWrapper">
        <div className="picBlk"></div>
        <div className="loginFormWrap">
          <div className="formBlk">
            <h1>
              <img
                src={siteLogo}
                alt="MonQcle by the Center for Public Health Law Research"
              />
            </h1>
            <div className="supportLink">
              <span>Already have an account?</span>
              <Link to="/">Log In</Link>
            </div>
            {isUnavailable ? (
              <div className="regInfo" tabIndex={-1} ref={promtRef}>
                <p>For new registration please contact</p>
                <p>
                  <b>Elizabeth A Platt</b> ({" "}
                  <a href="mailto:elizabeth.platt@temple.edu">
                    elizabeth.platt@temple.edu
                  </a>{" "}
                  )
                </p>
              </div>
            ) : (
              <>
                {errorObject.isError && (
                  <div className="msgError bottomGap1">
                    {errorObject.message}
                  </div>
                )}
                {isOrgRegistrationComplete || isIndvlRegistrationComplete ? (
                  <React.Fragment>
                    {isOrgRegistrationComplete && (
                      <div className="regSuccess" tabIndex={-1} ref={promtRef}>
                        <p>
                          We have received your organization’s registration.
                        </p>
                        <p>We will let you know once it is approved.</p>
                      </div>
                    )}
                    {isIndvlRegistrationComplete && (
                      <div className="regSuccess" tabIndex={-1} ref={promtRef}>
                        <p>We have received your registration.</p>
                        <p>Please verify your email to start using MonQcle.</p>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Radio.Group
                      className="boxCustomControl twoColControl"
                      onChange={onChangeRegistrationType}
                      value={registrationType}
                    >
                      <div className="customRadio">
                        <Radio value={"individual"}>Individual</Radio>
                      </div>
                      <div className="customRadio">
                        <Radio value={"organization"}>Organization</Radio>
                      </div>
                    </Radio.Group>
                      <Form
                        form={form}
                        id="org-registration"
                        layout="vertical"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="drawerContent"
                      >
                        {registrationType === "organization" && (
                          <React.Fragment>
                            <Form.Item
                              name="orgName"
                              id="name"
                              label="Business Name"
                              rules={[
                                {
                                  required: true,
                                  message: " Business Name can not be empty",
                                },
                                {
                                  pattern:
                                    /^[a-zA-Z0-9][a-zA-Z0-9-!@#$%^&*()=_+~`[\]{}|\\;:'",.<>/?\s]{4,99}$/,
                                  message:
                                    "Invalid business name! It should be 5 to 100 characters long, starting with an alpha-numeric character.",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Business Name"
                                autoComplete="organization"
                                name="organization"
                              />
                            </Form.Item>
                            <Form.Item
                              name="email"
                              id="email"
                              label="Business Email"
                              rules={[
                                {
                                  required: true,
                                  message: "Email can not be empty",
                                },
                                { type: "email", message: "Invalid email!" },
                              ]}
                            >
                              <Input
                                placeholder="Enter Business Email"
                                autoComplete="email"
                              />
                            </Form.Item>
                          </React.Fragment>
                        )}
                        {registrationType === "individual" && (
                          <React.Fragment>
                            <Form.Item
                              name="email"
                              label="Enter Your Email"
                              id="email"
                              rules={[
                                { type: "email" },
                                {
                                  required: true,
                                  message: "Full name can not be empty",
                                },
                              ]}
                            >
                              <Input placeholder="Enter Your Email Address" />
                            </Form.Item>

                            <Form.Item
                              name="fullName"
                              id="fullName"
                              label="Enter Your Full Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Full name can not be empty",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Your Full Name"
                                maxLength={100}
                              />
                            </Form.Item>

                            <Form.Item
                              name="userName"
                              id="userName"
                              label="Your User Name"
                              rules={[
                                {
                                  required: true,
                                  message: "User name can not be empty",
                                },
                                {
                                  pattern: /^[a-zA-Z0-9_-]{6,10}$/,
                                  message:
                                    "Username must be 6 to 10 characters long and can only contain alphanumeric characters, underscore (_) and hyphen (-) without space",
                                },
                              ]}
                            >
                              <Input placeholder="Create User Name" />
                            </Form.Item>

                            <CustomCreatePasswordWrapper />
                          </React.Fragment>
                        )}
                        <Form.Item>
                          <Button
                            className="btnStyle1"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                          >
                            Register
                          </Button>
                        </Form.Item>
                      </Form>

                    <div className="termsCondition">
                      By registering for a MonQcle account, you agree to our{" "}
                      <Link to="#">User Agreement</Link> and{" "}
                      <Link
                        to="https://monqcle.com/legal/privacy"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                      .
                    </div>
                  </React.Fragment>
                )}
              </>
            )}
          </div>
        </div>
      </div>
  );
};
export default RegistrationPage;
