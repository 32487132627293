import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IResponseCreateEditProject, IResponseProjectListData, IResponseGroupListData, IGroupFormData, IResponseGroupDetails } from '../models/interface';

export class GroupService {
    
    public async getGroupListData() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseGroupListData>>(RoutesEnum.ORG_GET_GROUP_LIST, { })
    }

    public async getGroupDetailsData(group_id: number | undefined) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseGroupDetails>>(RoutesEnum.ORG_GET_GROUP_DETAILS, { params : { group_id } })
    }

    public async getProjectListData() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseProjectListData>>(RoutesEnum.ORG_GET_GROUP_PROJECT_LIST, {})
    }

    public async createGroup(payload: IGroupFormData) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditProject>>(RoutesEnum.ORG_CREATE_GROUP, payload)
    }

    public async editGroup(payload: IGroupFormData) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseCreateEditProject>>(RoutesEnum.ORG_EDIT_GROUP, payload)
    }

    public async deleteGroup(payload: { group_id: number }) {
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<IResponseCreateEditProject>>(RoutesEnum.ORG_DELETE_GROUP, { data: { ...payload } })
    }

    public async favoriteGroup(groupId: number, is_favourite?: boolean) {
        const _is_favourite = is_favourite ?? false
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{is_favourite: boolean}>>(RoutesEnum.ORG_GROUP_FAVORITE, { group_id: groupId ,is_favourite: !_is_favourite})
    }

}

