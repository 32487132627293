import { ApiService } from ".";
import { RoutesEnum } from "../../../models/enums";
import {
  IApiBaseResponse,
  IRequestEditUserProfile,
  IResponseEditUserProfile,
  IUserDetailsApiResponse,
} from "../../../models/interface";

export class UserService {
  public getUserDetails = async () => {
    return (await new ApiService().privateClient()).get<
      IApiBaseResponse<IUserDetailsApiResponse>
    >(RoutesEnum.USER_DETAILS);
  };

  public postInvitationLink = async (token: string) => {
    return (await new ApiService().publicClient()).post<IApiBaseResponse>(
      RoutesEnum.VERIFY_USER_INVITATION_LINK,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  public postInvitedRegistration = async (
    token: string,
    fullName: string,
    userName: string,
    password: string
  ) => {
    return (await new ApiService().publicClient()).post<IApiBaseResponse>(
      RoutesEnum.INVITED_USER_REGISTRATION,
      { fullName, userName, password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  public postUserRegistration = async (data: {
    registration_type: string,
    email: string,
    org_name?: string,
    full_name?: string,
    user_name?: string,
    password?: string,
    baseUrl?: string,
  }) => {
    return (await new ApiService().publicClient()).post<IApiBaseResponse>(
      RoutesEnum.USER_REGISTRATION,
      { registration_type: data.registration_type, email: data.email, org_name: data.org_name, 
        full_name: data.full_name, user_name: data.user_name, password: data.password, base_url: data.baseUrl }
    );
  };
  
  public editUserProfile = async (payload: IRequestEditUserProfile) => {
    return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseEditUserProfile>>(RoutesEnum.UPDATE_USER, payload);
  }

  public postRegistrationLink = async (token: string) => {
    return (await new ApiService().publicClient()).post<IApiBaseResponse>(
      RoutesEnum.VERIFY_INDIVIDUAL_USER_REGISTRATION_LINK,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

}
