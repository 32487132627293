import { useEffect, useState } from "react";
import { Outlet, useLocation,  } from "react-router-dom";
import useWindowFocus from "use-window-focus";
import AuthSwitchOrganizationChannelService from "modules/auth/services/switchOrganizationChannelService";
import { useSwitchOrganizationStore } from "modules/auth/store";
const authSwitchOrganizationChannelService = AuthSwitchOrganizationChannelService.getSwitchOrganizationInstance();

const SwitchOrganizationSignalOutlet = () => {
    const [isUserLoggedOut, setIsUserLoggedOut] = useState(false);
    const location = useLocation();
    const { setCurrentSwitchedOrganizationData } = useSwitchOrganizationStore();


    useEffect(()=>{

        /** For Switch Organization */
        authSwitchOrganizationChannelService.getSwitchOrganizationChannel().onmessage = (data) => {
            setCurrentSwitchedOrganizationData(
                data.user_id,
                data.org_id,
                data.org_key,
                data.is_app_admin
            )
          setIsUserLoggedOut(false);
        }

        /** For all tab logout */
        authSwitchOrganizationChannelService.getLogoutChannel().onmessage = () => {
          setTimeout(()=>{
            setIsUserLoggedOut(true);
          }, 500);
        }
        
    }, [setCurrentSwitchedOrganizationData])

    const windowFocused = useWindowFocus();
    useEffect(() => {
        if(windowFocused && isUserLoggedOut && (location.pathname.includes('/app') || location.pathname.includes('/org/'))){
            setTimeout(()=>{
                window.location.href = '/'
            }, 700);
        }
    }, [windowFocused, isUserLoggedOut, location])

    return (
        <Outlet />
    );
}

export default SwitchOrganizationSignalOutlet;