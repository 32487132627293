import { Button, Dropdown, DropDownProps, MenuProps } from "antd";
import React, { ReactNode, useState } from "react";

interface CustomDropdownMenuProps {
  menuItems: MenuProps["items"] | undefined
  onSelect: (value: any) => void;
  placement?: DropDownProps["placement"];
  allowClass?: boolean;
  disabled?: boolean;
  children: ReactNode;
}

const CustomDropdownMenu: React.FC<CustomDropdownMenuProps> = ({
  menuItems,
  onSelect,
  placement,
  allowClass = true,
  disabled = false,
  children,
}) => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleKeyDown = (e: any) => {
    const keyCode = e.keyCode || e.which;
    const items = document.querySelectorAll(".ant-dropdown-menu-item");
    if (items?.length) {
      switch (keyCode) {
        case 38: {// Up arrow key
          e.preventDefault();
          const previousItem =
            document?.activeElement?.previousElementSibling as HTMLElement ??
            items[items.length - 1];
          previousItem?.focus();

          break;
        }
        case 40: {// Down arrow key
          e.preventDefault();
          const nextItem =
            document?.activeElement?.nextElementSibling as HTMLElement ?? items[0];
          nextItem.focus();
          break;
        }
        default:
          break;
      }
    }
  };
  return (
    <Dropdown
      disabled={disabled}
      open={isDropdownOpen}
      menu={{
        items: menuItems,
        onClick: (data) => {
          setIsDropdownOpen(false);
          onSelect(data);
        },
      }}
      trigger={["click"]}
      destroyPopupOnHide={true}
      onOpenChange={(status) => {
        if (!status) setIsDropdownOpen(false);
      }}
      placement={placement}
    >
      <Button
        onClick={(e: any) => {
          setIsDropdownOpen(!isDropdownOpen);
          e.preventDefault();
        }}
        onKeyDown={handleKeyDown}
        className={allowClass ? "actionBtn" : ""}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export default CustomDropdownMenu;
