import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IApplyOrganizationAddonsPayload, IRequestOrganizationListData, IRequestOrganizationSettings, IResponseApplyOrganizationAddons, IResponseOrganizationAddonList, IResponseOrganizationListData } from "../models/interface";


export class AppOrganizationListService {

    public async getOrganizationListData(params: IRequestOrganizationListData) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseOrganizationListData>>(RoutesEnum.APP_GET_ORGANIZATION_LIST, { params })
    }

    public async applyOrganizationSettings(payload: IRequestOrganizationSettings) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseOrganizationListData>>(RoutesEnum.APP_APPLY_ORG_CUSTOM_SETTINGS, payload)
    }

    public async expireReactivateOrganization(payload: {org_id: string; expire: boolean;}) {
        return (await new ApiService().privateClient()).delete<IApiBaseResponse<{org_admins: string; org_name: string;}>>(RoutesEnum.APP_EXPIRE_REACTIVATE_ORGANIZATION, { data: payload })
    }

    public async generateOrganizationPublishToken(payload: {organization_id: string; organization_name: string;}) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<{key_value: string; key_callable_value: string; org_key: string;}>>(RoutesEnum.APP_PUBLISH_API_CREATE_ORGANIZATION_TOKEN, payload)
    }

    public async fetchOrganizationPublishToken(params: {organization_id: string}) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{orgToken: string}>>(RoutesEnum.APP_PUBLISH_API_FETCH_ORGANIZATION_TOKEN, { params })
    }

    public async updateOrganizationPublishToken(payload:{organization_id: string; organization_name: string;}) {
        return (await new ApiService().privateClient()).patch<IApiBaseResponse<{orgToken: string}>>(RoutesEnum.APP_PUBLISH_API_UPDATE_ORGANIZATION_TOKEN, payload)
    }

    public async getOrganizationAddonsData() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponseOrganizationAddonList>>(RoutesEnum.APP_GET_ORGANIZATION_ADDON_LIST, {})
    }

    public async applyOrganizationAddons(payload: IApplyOrganizationAddonsPayload) {
        return (await new ApiService().privateClient()).post<IApiBaseResponse<IResponseApplyOrganizationAddons>>(RoutesEnum.APP_APPLY_ORG_ADDONS, payload)
    }
}

