import { RoutesEnum } from "../../../models/enums";
import { IApiBaseResponse } from "../../../models/interface";
import { ApiService } from "../../shared/services";
import { IPublishDetails, IPublishStepOne, IPublishStepTwo, IResponsePreviewData, IRequestPublishStepThree, IResponsePublishInfoOfSite, IResponsePublishingSites, ILaw, IResponsePreviewWithPassCodeData, IRequestCheckURLUniqueSlug, IRequestPublishStepFour, ICitationWithText } from '../models/interface';
import { PUBLICATION_STEPS, TPublicationKey } from '../store/projectPublishStore';
import { URLService } from "./url.service";

export class ProjectPublishService {

    public async checkURLUniqueSlug(params: IRequestCheckURLUniqueSlug) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{exists: boolean}>>(RoutesEnum.ORG_CHECK_UNIQUE_URL_SLUG, {params})
    }

    public async getPublishingSites() {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponsePublishingSites>>(RoutesEnum.ORG_GET_PUBLISHING_SITES, {})
    }

    public async getPublishInfoOfSite(siteId: number | null, projectSlug: string) {
        const { updatedURL } = new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_GET_PUBLISHING_SITE_INFO)
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponsePublishInfoOfSite>>(updatedURL, { params: { site_id: siteId } });
    }

    public async savePublishData(payload: IPublishStepOne | IPublishStepTwo | IRequestPublishStepThree | IRequestPublishStepFour, projectSlug: string, currentStepKey: TPublicationKey) {

        const publishStepUrl: {
            [key: number]: string
        } = {
            [PUBLICATION_STEPS.STEP_1]: new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_SAVE_PUBLISH_STEP_1).updatedURL,
            [PUBLICATION_STEPS.STEP_2]: new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_SAVE_PUBLISH_STEP_2).updatedURL,
            [PUBLICATION_STEPS.STEP_3]: new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_SAVE_PUBLISH_STEP_3).updatedURL,
            [PUBLICATION_STEPS.STEP_4]: new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_SAVE_PUBLISH_STEP_4).updatedURL
        }

        return (await new ApiService().privateClient()).post<IApiBaseResponse<{publishDetails: IPublishDetails}>>(publishStepUrl[currentStepKey], payload)
    }

    public async getPreviewData(projectSlug: string, publishId?: number) {
        let params: {publish_id?: number;} = {};
        if(publishId){
            params.publish_id = publishId;
        }
        const { updatedURL } = new URLService({}, projectSlug).formatURL(RoutesEnum.ORG_GET_PREVIEW_DATA)
        return (await new ApiService().privateClient()).get<IApiBaseResponse<IResponsePreviewData>>(updatedURL, { params: params })
    }

    public async getLawData(lawIds: number[]) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{lawList: ILaw[]}>>(RoutesEnum.ORG_GET_LAW_DATA, { params: { law_ids: lawIds } })
    }

    public async getCitationData(citationIds: number[]) {
        return (await new ApiService().privateClient()).get<IApiBaseResponse<{citationList: ICitationWithText[]}>>(RoutesEnum.ORG_GET_CITATION_DATA, { params: { citation_ids: citationIds } })
    }

    public async getPreviewDataWithPassCode(org_key: string, site_key: string, project_slug: string,pass_code?: string) {
        let updatedURL =  RoutesEnum.GET_PREVIEW_DATA_WITH_PASS_CODE.replace("{org_key}", org_key);
        updatedURL =  updatedURL.replace("{site_key}", site_key);
        updatedURL =  updatedURL.replace("{project_slug}", project_slug);
        let params: {pass_code?: string;} = {};
        if(pass_code && pass_code !== ''){
            params.pass_code = pass_code
        }

        return (await new ApiService().publicClient()).get<IApiBaseResponse<IResponsePreviewWithPassCodeData>>(updatedURL, { params: params })
    }

    public async getPreviewLawDataWithPassCode(org_key: string, site_key: string, project_slug: string, lawIds: number[] ,pass_code?: string, ) {
        let updatedURL =  RoutesEnum.GET_PREVIEW_LAW_DATA_WITH_PASS_CODE.replace("{org_key}", org_key);
        updatedURL =  updatedURL.replace("{site_key}", site_key);
        updatedURL =  updatedURL.replace("{project_slug}", project_slug);
        let params: {law_ids: number[]; pass_code?: string;} = {
            law_ids: lawIds
        };
        if(pass_code && pass_code !== ''){
            params.pass_code = pass_code
        }
        return (await new ApiService().publicClient()).get<IApiBaseResponse<{lawList: ILaw[]}>>(updatedURL, { params: params })
    }

    public async getPreviewCitationDataWithPassCode(org_key: string, site_key: string, project_slug: string, citationIds: number[] ,pass_code?: string, ) {
        let updatedURL =  RoutesEnum.GET_PREVIEW_CITATION_DATA_WITH_PASS_CODE.replace("{org_key}", org_key);
        updatedURL =  updatedURL.replace("{site_key}", site_key);
        updatedURL =  updatedURL.replace("{project_slug}", project_slug);
        let params: {citation_ids: number[]; pass_code?: string;} = {
            citation_ids: citationIds
        };
        if(pass_code && pass_code !== ''){
          params.pass_code = pass_code;
        }
      return (await new ApiService().publicClient()).get<IApiBaseResponse<{ citationList: ICitationWithText[] }>>(updatedURL, { params: params });
    }

}
