import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AuthService from "modules/shared/services/authService";
import { useUserStore } from "../../modules/shared/store/userStore";
import { UserService } from "../../modules/shared/services";
import PageLoader from "../../modules/shared/components/PageLoader";
import { Forbidden } from "../../modules/shared/components/Forbidden";
import { TProjectRoleId } from "modules/organization/models/interface";

const authService = AuthService.getInstance();

const useRbac = () =>{
    const { user } = useAuth0();
    const {
        setUserDetails,
        userDetails,
        projectRolePermissionsList
    } = useUserStore();
    const auth0UserDetails = user;

    const [ isLoadingRbac, setIsLoadingRbac ] = useState(true);
    const dataFetchedRef = useRef(false);
    
    const getUserDetails = useCallback(() => {
        const userDataFromLocalStorage = authService.getCurrentUserLocalStorage();
        if(userDataFromLocalStorage){
            setUserDetails(userDataFromLocalStorage.user, userDataFromLocalStorage.projectRolePermissions, userDataFromLocalStorage.applicationConfiguration)
        }
        else{
            new UserService().getUserDetails().then(userResponseData => {
                setUserDetails(userResponseData.data.data.userDetails, userResponseData.data.data.projectRolePermissionsList, userResponseData.data.data.appConfigurationList);
                authService.setCurrentUserLocalStorage({
                    user: userResponseData.data.data.userDetails,
                    projectRolePermissions: userResponseData.data.data.projectRolePermissionsList,
                    applicationConfiguration: userResponseData.data.data.appConfigurationList
                })
            }).catch(e =>  {
                setIsLoadingRbac(false) ;
            })
        }
        
    }, [setUserDetails]);

    useEffect(()=>{
        if(!userDetails){
            if(!dataFetchedRef.current){
                dataFetchedRef.current = true;
                getUserDetails();
            }
        }
        else{
            setIsLoadingRbac(false)
        }
    }, [userDetails, getUserDetails]);

    const getProjectPermissionsByRoleId = (project_role_id?: number) =>{
        if(project_role_id){
            return projectRolePermissionsList.find(each=>each.project_role_id === project_role_id)?.permissions ?? [];
        }
        return [];
    }

    const getAllPermissions = (project_role_id?: number, direct_permissions?: string[] | null) => {
        
        if(direct_permissions){
            return Array.from(new Set(userDetails?.permissions.concat(getProjectPermissionsByRoleId(project_role_id)).concat(direct_permissions)));
        }
        return Array.from(new Set(userDetails?.permissions.concat(getProjectPermissionsByRoleId(project_role_id))));
    }

    const hasPermissions = (permissions: string[], otherParams?:{
        project_role_id?: TProjectRoleId;
        record_role_id?: number;
        direct_permissions?: string[] | null;
    }) => {
        let roleId:number | undefined = undefined;
        if(otherParams?.record_role_id){
            roleId = otherParams.record_role_id;
        }
        else if(otherParams?.project_role_id === null || typeof(otherParams?.project_role_id) === 'number'){
            //** If project_role_id is null then viewer */
            roleId = otherParams.project_role_id ?? 3;
        }

        return getAllPermissions(roleId,otherParams?.direct_permissions).some(value => permissions.includes(value));
    }

    return { isLoadingRbac, userDetails, hasPermissions, auth0UserDetails};
}

const withRbac = (WrappedComponent: React.ComponentType, params?:{allowedPermissions?: string[], isAccessFromPage?: boolean, project_role_id?:TProjectRoleId, record_role_id?:number, direct_permissions?: string[] | null }) => {
    return (props: any) => {
      const {isLoadingRbac, userDetails, hasPermissions, auth0UserDetails} = useRbac();
      return (
        <React.Fragment>
            {!isLoadingRbac &&
                <React.Fragment>
                    {params?.allowedPermissions &&
                        <React.Fragment>
                            {hasPermissions(params?.allowedPermissions, {project_role_id: params.project_role_id, record_role_id: params.record_role_id, direct_permissions: params.direct_permissions }) &&
                                <WrappedComponent {...props} userDetails={userDetails} auth0UserDetails={auth0UserDetails} hasPermissions={hasPermissions} isLoadingRbac={isLoadingRbac} />
                            }
                            {!hasPermissions(params.allowedPermissions, {project_role_id: params.project_role_id, record_role_id: params.record_role_id, direct_permissions: params.direct_permissions}) &&
                                <Forbidden isAccessFromPage={params.isAccessFromPage} />
                            }
                        </React.Fragment>
                    }

                    {(!params?.allowedPermissions) &&
                        <WrappedComponent {...props} userDetails={userDetails} auth0UserDetails={auth0UserDetails} hasPermissions={hasPermissions} isLoadingRbac={isLoadingRbac} />
                    }
                </React.Fragment>
            }
            {isLoadingRbac &&
                <PageLoader />
            }
        </React.Fragment>
      );
    };
}

const RBAC: React.FC<{
    children: React.ReactNode,
    allowedPermissions?: string[],
    allowedRoles?: string[],
    project_role_id?: TProjectRoleId,
    record_role_id?: number,
    direct_permissions?: string[] | null
  }> = ({ children, allowedPermissions, allowedRoles, project_role_id, record_role_id, direct_permissions }) => {
    const {isLoadingRbac, hasPermissions } = useRbac();

    const checkPermissions = hasPermissions(
        allowedPermissions ?? [], 
        {
            project_role_id,
            record_role_id,
            direct_permissions,
        }
    );

    const checkRoleAndPermission = () => {
        if(isLoadingRbac) {
            return false;
        }
        else if(allowedPermissions &&  allowedRoles &&  checkPermissions){
            return true;
        }
        else if(allowedPermissions && !allowedRoles && checkPermissions){
            return true;
        }
        else if(allowedRoles && !allowedPermissions){
            return true;
        }
        else{
            return false;
        }
    }

    if(checkRoleAndPermission()){
        return <React.Fragment>{children}</React.Fragment>
    }
    else{
        return null;
    }
};

export {
    useRbac,
    withRbac,
    RBAC,
}