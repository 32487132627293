interface IStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}

export default abstract class AppStorage<T extends string> {
  private readonly storage: IStorage;

  public constructor(getStorage = (): IStorage => window.localStorage) {
    this.storage = getStorage();
  }

  // public constructor(getStorage = (): IStorage => window.sessionStorage) {
  //   this.storage = getStorage();
  // }

  private isJsonString(str:string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  protected get(key: T): string | null {
    const storageData = this.storage.getItem(key);
    if(!storageData){
      return null;
    }
    if(this.isJsonString(storageData)){
      const item = JSON.parse(storageData);
      if(item?.expiry){
        const now = new Date();
        if (now.getTime() > item.expiry) {
          this.clearItem(key);
          return null
        }
        return item.value;
      }
      return storageData;
    }
    return storageData;
  }

  protected set(key: T, value: string, expiryTime?: number): void {
    let item = value;
    if(expiryTime){
      item = JSON.stringify({
        value: value,
        expiry: new Date().getTime() + expiryTime,
      });
    }
    this.storage.setItem(key, item);
  }

  protected clearItem(key: T): void {
    this.storage.removeItem(key);
  }

  protected clearItems(keys: T[]): void {
    keys.forEach((key) => this.clearItem(key));
  }
}
