import { withAuthenticationRequired } from "@auth0/auth0-react";
import AuthService from "../modules/shared/services/authService";
import React from "react";
import { withRbac } from "./rbac/rbac";
import PageLoader from "../modules/shared/components/PageLoader";
const authService = AuthService.getInstance();

const onRedirectingLoader = () => {
  return <PageLoader />
}

export const AuthenticationGuard: React.FC<{
  component: React.ComponentType<object>;
}> = ({ component }) => {

  const Component = withAuthenticationRequired(withRbac(component), {
    loginOptions: {
      authorizationParams: {
        screen_hint: "login",
        //organization: undefined
      },
    },
    returnTo: window.location.pathname,

    onRedirecting: () =>  onRedirectingLoader(),

    onBeforeAuthentication: async () => {
      authService.clearCurrentOrganization();
      authService.clearCurrentUserLocalStorage();
    },
  });

  return <Component />;
};
