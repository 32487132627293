import { Spin } from 'antd';
import {
  useProjectDetailsStore,
  useRankingToolStore
} from 'modules/organization/store';
import ErrorBoundary from 'modules/shared/components/ErrorBoundary';
import PageLoader from 'modules/shared/components/PageLoader';
import { Fragment, FC, useEffect } from 'react';
import QuestionListContainer from './components/QuestionListContainer';
import RankingToolsEditor from './components/RankingToolsEditor';
import "./tool-editor.css"

const OrgRankingEquationEditorPage: FC = (props) => {
  const { projectDetails, isLoadingProjectDetails } = useProjectDetailsStore();
  const { getQuestionSnapshot, questionSnapshotList } = useRankingToolStore();

  useEffect(() => {
    if (projectDetails) {
      getQuestionSnapshot().catch(console.error);
    }
  }, [getQuestionSnapshot, projectDetails]);

  return (
    <ErrorBoundary>
      <Fragment>
        {isLoadingProjectDetails ? (
          <PageLoader />
        ) : (
          <Spin spinning={false}>
            {projectDetails && (
              <Fragment>
                {/* <div className="pageTopSection">
                      <div className="backLink">
                        <Link
                          to={
                            organizationBaseRouteUrl +
                            `/project/${projectDetails?.slug}`
                          }
                        >
                          <LeftOutlined role="img" aria-label=""/>
                          Back to Project Home
                        </Link>
                      </div>
                      <div className="btnWrap">
                        <Button href='http://www.raosoft.com/samplesize.html' target='_blank'>Guide for Sampling</Button>
                        <Button type="primary" onClick={exportData}>Export to Excel</Button>
                      </div>
                    </div> */}
                <div className="pageHeadingWrap">
                  <h2 id="tab-header">Equation Editor</h2>
                </div>
                <ErrorBoundary>
                  <div className="equationEditorWrap">
                    <QuestionListContainer />
                    <RankingToolsEditor questions={questionSnapshotList ?? undefined} />
                  </div>
                </ErrorBoundary>
              </Fragment>
            )}
          </Spin>
        )}
      </Fragment>
    </ErrorBoundary>
  );
};

export default OrgRankingEquationEditorPage;