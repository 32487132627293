import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Table,
  Input,
  Select,
  PaginationProps,
  Tooltip,
  message,
  Form,
  Checkbox,
  Button,
  Tag,
} from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import {
  IRequestSiteProjectListData,
  ISiteProjectListData,
} from "../../models/interface";
import { SiteService } from "../../services";
import { SorterResult } from "antd/es/table/interface";
import {
  MoreOutlined,
  CloseCircleFilled,
  ApiOutlined,
  DisconnectOutlined,
  EyeOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  downloadBufferAsFile,
  TooltipParagraph,
} from "../../../shared/utility";
import Paginator from "modules/shared/components/Paginator";
import { PublishedProjectStatusKeys, SiteListMenuKeys } from "modules/organization/models/enums/menuKey.enums";
import CustomDropdownMenu from "modules/shared/components/CustomDropdownMenu";
import { ERbacPermissions } from "auth/rbac/rbacPermissionsList";
import { useSiteStore } from "modules/organization/store/siteStore";
import { TargetUrl } from "modules/shared/components/CustomAppIcons";
import { Link } from "react-router-dom";
import { useOrganizationRoutes } from "routes/organizationRoute";
import OrgSiteGetPasscodeModal from "./OrgSiteGetPasscodeModal";
import { dateFormat } from 'config/commonConfig';
import OrgProjectUnpublish from './OrgProjectUnpublish';
import { mnDate } from 'modules/shared/services';
import OrgGetSiteProjectIframeModal from "./OrgGetSiteProjectIframeModal";
import OrgGetLawAtlasIframeModal from "./OrgGetLawAtlasIframeModal";
import { FirebaseService } from "modules/application/services";
import { useUserStore } from "modules/shared/store";

const { Search } = Input;

export const _menuItems = [
  {
    label: "Unpublish",
    key: SiteListMenuKeys.UNPUBLISH_PROJECT,
    icon: <ApiOutlined />,
    allowedPermissions: [ERbacPermissions.ORG_SITE_EDIT],
  },

  {
    label: "Unlink",
    key: SiteListMenuKeys.UNLINK_PROJECT,
    icon: <DisconnectOutlined />,
    allowedPermissions: [ERbacPermissions.ORG_SITE_EDIT],
  },
];

const OrgSiteProjectList = ({ isFromSiteDetails = false }) => {
  const [form] = Form.useForm();
  const { organizationBaseRouteUrl, organizationKey } = useOrganizationRoutes();
  const buttonEle = useRef<HTMLButtonElement>(null);
  const firebaseService = useMemo(() => new FirebaseService(), []);

  const {
    siteProjectListData,
    showTableLoader,
    projectsCount,
    currentPageSize,
    currentPage,
    currentSortColumn,
    currentSortOrder,
    search,
    published,
    siteListData,
    site,
    isPreviewOnly,
    siteInfo,
    isFirstLoad,
    needPageRefresh,
    publishRow,
    siteProjectAction,
    restrictSiteListFirestoreUpdate,

    resetState,
    setCurrentPageSize,
    setCurrentPage,
    setCurrentSortColumn,
    setCurrentSortOrder,
    setSearch,
    setSiteListData,
    setPublished,
    refreshSiteProjectList,
    setSite,
    setIsPreviewOnly,
    setNeedPageRefresh,
    setIsFirstLoad,
    setPublishRow,
    setSiteProjectAction
  } = useSiteStore();
  const { userDetails } = useUserStore();

  const [searchValue, setSearchValue] = useState(search);
  const [isExporting, setIsExporting] = useState(false);

  const getPreviewUrl = (siteData: ISiteProjectListData) =>{
    return `${window.location.origin}/preview/${organizationKey}/${siteData.site_key}/${siteData.project_slug}`
  }

  const getStatus = (status: string) => {
    switch (status) {
      case PublishedProjectStatusKeys.TO_DO:
        return <Tag icon={<ClockCircleOutlined />} color="default">In Queue</Tag>;
      case PublishedProjectStatusKeys.IN_PROGRESS:
        return <Tag icon={<SyncOutlined spin />} color="processing">In Progress</Tag>;
      case PublishedProjectStatusKeys.SUCCESS:
        return <Tag icon={<CheckCircleOutlined />} color="success">Completed</Tag>;
      case PublishedProjectStatusKeys.FAILED:
        return <Tag icon={<CloseCircleOutlined style={{ color: 'red' }} />} color="error">Failed</Tag>;
      default:
        return "-";
    }
  };

  const commonColumns: ColumnsType<ISiteProjectListData> = [
    {
      title: "Projects",
      dataIndex: "title",
      sorter: true,
      render: (data, record) => {
        return (
          <div className="tblTitle">
            <div
              className="titleLinkWrap flexAlignCenter"
              aria-label={`Project title: ${data}`}
            >
              <TooltipParagraph
                className="recent-projects"
                toLink={organizationBaseRouteUrl + `/project/${record.slug}`}
              >
                {data}
              </TooltipParagraph>
              {record.is_publication_public && (
                <Tooltip
                  title="Go to published link"
                  placement="right"
                  trigger={["hover", "focus"]}
                >
                  <a
                    href={`${record.site_url}${record.url_slug}`}
                    target="_blank"
                    rel="noreferrer"
                    className="iconLink"
                    aria-label="Go to published link"
                  >
                    <TargetUrl aria-hidden/>
                  </a>
                </Tooltip>
              )}{" "}
              {record.preview_id && organizationKey && (
                <Tooltip title="Go to Preview link" placement="right" trigger={["hover", "focus"]}>
                  <Link
                    target="_blank"
                    to={getPreviewUrl(record)}
                    className="iconLink"
                    aria-label="Go to Preview link"
                  >
                    <EyeOutlined className="activeIcon" aria-hidden/>
                  </Link>
                </Tooltip>
              )}
              {record.is_publication_public &&
                <OrgGetSiteProjectIframeModal siteProjectData={record}/>
              }
              {record.enable_law_atlas_iframe && record.is_publication_public && record.published_at && record.is_law_atlas_migrated &&
                <OrgGetLawAtlasIframeModal siteProjectData={record}/>
              }
            </div>
          </div>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "is_publication_public",
      width: 180,
      sorter: true,
      render: (data, record) => {
        return (
          <>
            {data && record.published_at && <div className="liveYes"><Tooltip title={mnDate(record.published_at).format(dateFormat.tableDateTime)} trigger={['hover']}>LIVE</Tooltip></div>}
            {!data && record.published_at && <div className="liveYes">PREVIEW</div>}
            {data && !record.published_at && <div className="liveNo">NOT PUBLISHED</div>}
          </>
        );
      },
    },
    {
      title: "LAST UPDATED",
      dataIndex: "updated_at",
      defaultSortOrder: "descend",
      width: 200,
      sorter: true,
      render: (data, record) => {
        return <div>{data ? mnDate(data).format(dateFormat.tableDateTime) : '-'}</div>;
      },
    },
    {
      title: "PREVIEW PASSCODE",
      dataIndex: "project_id",
      width: 180,
      align: "center",
      render: (data, record) => {
        return (
          <OrgSiteGetPasscodeModal siteData={record} previewUrl={getPreviewUrl(record)} />
        );
      },
    },
    {
      title: "PROGRESS STATUS",
      dataIndex: "queue_status",
      align: "center",
      width: 160,
      render: (data, record) => {
        return (
          <>
            {record?.is_publication_public ? getStatus(data) : '-'}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "project_id",
      width: 55,
      align: "center",
      render: (_data, record) => {
        const onSelect = (e: any) => {
          setSiteProjectAction({
            type: parseInt(e.key),
            details: record,
            showConfirm: true
          });
        };
        const filteredKebabMenuItems = _menuItems.filter(item => {
          // Render all independent option
          if(item.key === SiteListMenuKeys.UNPUBLISH_PROJECT && !record.is_publication_public) {
            return false;
          }

          return true;
        });

        return (
          <Fragment>
            {filteredKebabMenuItems.some((item) => item?.key) ? (
              <CustomDropdownMenu menuItems={filteredKebabMenuItems} onSelect={onSelect}>
                <MoreOutlined />
              </CustomDropdownMenu>
            ) : (
              <span />
            )}
          </Fragment>
        );
      },
    },
  ];
  const projectListColumns: ColumnsType<ISiteProjectListData> =
    isFromSiteDetails
      ? commonColumns
      : [
          commonColumns[0],
          {
            title: "sites",
            dataIndex: "site_name",
            sorter: true,
            width: 400,
            render: (data) => {
              return (
                <>
                  {data && (
                    <div>
                      {data.length > 50 ? (
                        <Tooltip title={data} trigger={["hover", "focus"]}>
                          <span tabIndex={0} aria-label={data}>
                            {`${data.substring(
                            0,
                            50
                          )}...`}
                          </span>
                        </Tooltip>
                      ) : (
                        <span tabIndex={0}>{data}</span>
                      )}
                    </div>
                  )}
                </>
              );
            },
          },
          ...commonColumns.slice(1),
        ];

  const paginationRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <span>Previous</span>;
    }
    if (type === "next") {
      return <span>Next</span>;
    }
    return originalElement;
  };

  const onChange: TableProps<ISiteProjectListData>["onChange"] = (
    _pagination,
    _filters,
    sorter
  ) => {
    if (sorter) {
      const { field, order } = sorter as SorterResult<ISiteProjectListData>;
      if (field) {
        isFromSiteDetails && setNeedPageRefresh(true);
        setCurrentSortColumn(field.toString());
      }
      if (order) {
        isFromSiteDetails && setNeedPageRefresh(true);
        setCurrentSortOrder(order === "ascend" ? "asc" : "desc");
      }
    }
  };

  const getShowingDataString = () => {
    const startIndex = (currentPage - 1) * currentPageSize + 1;
    const endIndex =
      projectsCount < currentPage * currentPageSize
        ? projectsCount
        : currentPage * currentPageSize;
    return `Showing ${startIndex} to ${endIndex} of ${projectsCount} Items`;
  };

  const fetchSiteProjectList = useCallback(async (silent?: boolean, initLoad?: boolean) => {
    refreshSiteProjectList({
      page_index: currentPage,
      page_size: currentPageSize,
      sort_column: currentSortColumn,
      sort_order: currentSortOrder,
      search: search === "" ? null : search,
      published: published,
      is_preview_only: isPreviewOnly,
      site_id: site,
    }, silent, initLoad).catch(console.error);
  }, [
    currentPage,
    currentPageSize,
    currentSortColumn,
    currentSortOrder,
    published,
    search,
    site,
    isPreviewOnly,
    refreshSiteProjectList,
  ]);

  function handleChange(event: any) {
    setSearchValue(event.target.value);
    if (event.target.value === "") {
      isFromSiteDetails && setNeedPageRefresh(true);
      setSearch("");
    }
  }

  const getSiteList = useCallback(async () => {
    if (!siteListData) {
      try {
        const result = await new SiteService().getSiteListData();
        const { sites } = result.data.data;
        setSiteListData(sites);
      } catch (error) {
        console.error(error);
        await message.error({
          content: "Something went wrong please try again!",
        });
      }
    }
  }, [setSiteListData, siteListData]);

  useEffect(() => {
    !isFromSiteDetails && getSiteList().catch(console.error);
  }, [getSiteList, isFromSiteDetails]);

  useEffect(() => {
    if (isFromSiteDetails) {
      if (isFirstLoad || needPageRefresh) {
        fetchSiteProjectList(false, true).catch(console.error);
        setNeedPageRefresh(false);
        setIsFirstLoad(false);
      }
    } else {
      fetchSiteProjectList(false, true).catch(console.error);
    }
  }, [
    fetchSiteProjectList,
    isFromSiteDetails,
    isFirstLoad,
    needPageRefresh,
    setNeedPageRefresh,
    setIsFirstLoad,
  ]);

  useEffect(() => {
    if( userDetails?.org_id && userDetails?.id && !restrictSiteListFirestoreUpdate) {
      firebaseService.listenForNotifications(userDetails.org_id,userDetails.id, (notification) => {
        if(notification[0].action === "sites" ) {
          fetchSiteProjectList(siteProjectListData.length > 0, false).catch(console.error);
        }
      })
    }
  },[restrictSiteListFirestoreUpdate])

  useEffect(() => {
    form.setFieldValue("isPreviewOnly", isPreviewOnly);
  }, [isPreviewOnly, form]);

  useEffect(() => {
    return () => {
      !isFromSiteDetails && resetState();
    };
  }, [resetState, isFromSiteDetails]);

  const handleOnIsPreviewOnly = () => {
    setIsPreviewOnly(!isPreviewOnly);
  };

  const getExportData = async () => {
    const params: IRequestSiteProjectListData = {
      page_index: currentPage,
      page_size: currentPageSize,
      sort_column: currentSortColumn,
      sort_order: currentSortOrder,
      search: search === "" ? null : search,
      published: published,
      is_preview_only: isPreviewOnly,
      site_id: site,
    };
    try {
      setIsExporting(true);
      const result = await new SiteService().getSiteProjectListData(params);
      const { excelBufferData } = result.data.data;
      return excelBufferData;
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      setIsExporting(false);
    }
  };

  const exportData = async () => {
    const fileName = `Project list of ${
      siteInfo?.site_title
    } site-${mnDate().format(dateFormat.fileTimestamp)}`;
    let excelBufferData: any = await getExportData();
    if (excelBufferData) {
      downloadBufferAsFile(
        excelBufferData,
        `${fileName}.csv`,
        "application/octet-stream"
      );
    }
    buttonEle.current?.blur();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPublishRow(null);
    }, 15000);
    return () => clearTimeout(timeout);
  }, [setPublishRow]);

  return (
    <div className="tableWrapper">
      <div className="tableSettingBlk">
        <Search
          aria-label={"Search Projects"}
          title={"Search Projects"}
          placeholder="Search Projects"
          allowClear={{
            clearIcon: (
              <button className="clearBtn">
                <CloseCircleFilled />
              </button>
            ),
          }}
          value={searchValue}
          onSearch={() => {
            if (
              searchValue === null ||
              searchValue.length < 1 ||
              showTableLoader
            ) {
              return;
            }
            isFromSiteDetails && setNeedPageRefresh(true);
            setSearch(searchValue);
          }}
          onChange={handleChange}
        />
        {!isFromSiteDetails && (
          <Form.Item label="Sites">
            <Select
              virtual={false}
              aria-label={"Site Dropdown"}
              showSearch
              allowClear={Boolean(site)}
              defaultValue={0}
              value={site}
              placeholder="Select Site"
              optionFilterProp="children"
              onSelect={(value) => {
                setSite(value);
              }}
              onClear={() => setSite(0)}
              filterOption={(input, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                { value: 0, label: "All Sites" },
                ...(siteListData ?? []).map((d) => ({
                  value: d.site_id,
                  label: d.name,
                })),
              ]}
              disabled={showTableLoader}
            />
          </Form.Item>
        )}

        <Form.Item label="Status">
          <Select
            virtual={false}
            aria-label={"Select status"}
            showSearch
            allowClear
            value={published}
            placeholder="Select Status"
            optionFilterProp="children"
            onSelect={(value) => {
              isFromSiteDetails && setNeedPageRefresh(true);
              setPublished(value);
            }}
            onClear={() => {
              isFromSiteDetails && setNeedPageRefresh(true);
              setPublished(null);
            }}
            filterOption={(input, option: any) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={[
              {
                value: true,
                label: "Live",
              },
              {
                value: false,
                label: "Not Published",
              },
            ]}
            disabled={showTableLoader}
          />
        </Form.Item>
        {!isFromSiteDetails && (
          <Form form={form}>
            <Form.Item
              name="isPreviewOnly"
              valuePropName="checked"
              initialValue={isPreviewOnly}
            >
              <Checkbox
                onChange={(e) => handleOnIsPreviewOnly()}
                disabled={showTableLoader}
              >
                Display Projects with Preview Only
              </Checkbox>
            </Form.Item>
          </Form>
        )}

        {isFromSiteDetails && projectsCount > 0 && (
          <Button
            onClick={exportData}
            loading={isExporting}
            icon={<DownloadOutlined />}
            className="flexLeftGap"
            ref={buttonEle}
          >
            Export Data
          </Button>
        )}
      </div>
      <div className="mainTable">
        <Table
          columns={projectListColumns}
          dataSource={siteProjectListData}
          onChange={onChange}
          pagination={false}
          className="scrollableTable projectListTbl"
          loading={showTableLoader}
          showSorterTooltip={false}
          sortDirections={["ascend", "descend", "ascend"]}
          rowKey="preview_id"
          rowClassName={(record) => {
            return record.preview_id === publishRow ? 'blinkSiteRow' : '';
          }}
        />
      </div>
      {!showTableLoader && (
        <Paginator
          currentPageSize={currentPageSize}
          count={projectsCount}
          setCurrentPageSize={setCurrentPageSize}
          setCurrentPage={setCurrentPage}
          getShowingDataString={getShowingDataString}
          paginationRender={paginationRender}
          currentPage={currentPage}
        />
      )}

      {siteProjectAction.showConfirm && (
        <OrgProjectUnpublish />
      )}
    </div>
  );
};

export default OrgSiteProjectList;
