import { lazy, Suspense } from "react";
import { Outlet, Routes, Route } from "react-router-dom";
import { Header } from "../modules/shared/layout/includes/Header";
import { FooterSlim } from "modules/shared/layout/includes/FooterSlim";
import PageLoader from "modules/shared/components/PageLoader";
import NotFound from "modules/shared/components/NotFound";
import { useRbac } from "auth/rbac/rbac";
import AppHeader from "modules/shared/layout/includes/AppHeader";

const ProjectPreviewPage = lazy(
  () => import("../modules/preview/projectPreview/projectPreviewPage")
);

export function ProjectPreviewRoute() {
  return (
    <Routes>
      <Route element={<ProjectPreviewLayout />}>
        <Route path=":preview_org_name/:preview_site_key">
          <Route
            index
            path=":preview_project_slug"
            element={
              <Suspense fallback={<PageLoader />}>
                <ProjectPreviewPage />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

const ProjectPreviewLayout = () => {
  const {userDetails} = useRbac()
  return (
    <div className="mainWrapper">
        { ( userDetails?.is_app_admin && !userDetails.org_id) ?  <AppHeader /> : <Header />}
        
        <main className="mainContent">
          <Outlet />
        </main>
        <FooterSlim />
    </div>
  )
}