import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Drawer, Form, Input, message, Space, Spin } from 'antd';
import { useGroupStore } from '../../store/groupStore';
import { OrganizationService, GroupService } from '../../services';
import CustomSelectInput from '../../../shared/components/CustomSelectInput';
import { IGroupFormData } from '../../models/interface';
import { useRbac } from '../../../../auth/rbac/rbac';
import CustomManageUserList from 'modules/shared/components/CustomManageUserList';
import { useCreateNewProjectStore } from 'modules/organization/store';
import { GroupCardMenuKeys } from 'modules/organization/models/enums';
import { usePromptText } from 'modules/shared/components/accessibility/PromptStore';
import { findMissingPermissions } from 'modules/shared/utility';

const ProjectInfoSection: React.FC<{}> = () => {

  const { groupProjectList } = useGroupStore();

  return (
    <React.Fragment>
      <Form.Item
        name="name"
        label="Group Name"
        rules={[
          {
            required: true,
            whitespace: true,
            message: 'Group name is required'
          }
        ]}
      >
        <Input
          aria-label="Enter Group Name"
          placeholder="Enter group name"
          maxLength={100}
          autoFocus
        />
      </Form.Item>
      <CustomSelectInput
        options={groupProjectList as any}
        name="project_ids"
        label="Project List"
        placeholder={'Add projects'}
        mode="multiple"
        showArrow={false}
      />
    </React.Fragment>
  );
};

const ProjectUsersSection: React.FC<{}> = () => {
  const { groupUserList, groupDetails } = useGroupStore();

  return (
      <CustomManageUserList
        userList={groupUserList}
        usersAssociated={groupDetails?.users_associated??[]}
        show_direct_permission={true}
        currentUserRoleId={groupDetails?.group_role_id}
      />
  );
};

const CreateNewGroupForm: React.FC<{ visibility: boolean }> = ({
  visibility
}) => {
  const [form] = Form.useForm<IGroupFormData>();
  const { userDetails } = useRbac();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {setPromptType,setPromptText} = usePromptText();

  const {
    groupDrawerState,
    groupDetails,
    getGroupDrawerDetails,
    setGroupDrawerState,
    groupDrawerOptions,
    setFormLoader,
    refreshGroupList
  } = useGroupStore();

  const {
    projectRoleData,
  } = useCreateNewProjectStore();

  const groupFormData: IGroupFormData = {
    name: undefined,
    project_ids: [],
    users_associated: [
      { user_id: undefined, permissions_id: undefined, role_id: undefined },
      { user_id: undefined, permissions_id: undefined, role_id: undefined }
    ],
    permissions_id_removed: [
      { user_id: undefined, permissions_id: undefined, role_id: undefined },
      { user_id: undefined, permissions_id: undefined, role_id: undefined }
    ]
  };

  const formatFormData = (values: IGroupFormData) => {
    let tmpUsersAssociated = values.users_associated?.filter(
      ({ user_id }) => user_id
    );
    
    if(groupDrawerOptions.key !== GroupCardMenuKeys.EDIT_GROUP) {
      tmpUsersAssociated = tmpUsersAssociated
      ? [
          ...tmpUsersAssociated,
          { user_id: userDetails?.sub, permissions_id: undefined, role_id: 1 }
        ]
      : [{ user_id: userDetails?.sub, permissions_id: undefined, role_id: 1 }];
    }

    values.users_associated = tmpUsersAssociated ?? [];

    values.permissions_id_removed = findMissingPermissions(groupDetails?.users_associated, values.users_associated);

    return values;
  };

  const onFinish = async (values: IGroupFormData) => {
    const createGroupKey = 'createGroup';
    let promptText = groupDrawerOptions.toastMessage?.loading as string;
    setPromptType("assertive");
    setPromptText(promptText);
    setIsSubmitting(true);
    message.open({
      key: createGroupKey,
      content: promptText,
      type: 'loading',
      duration: 0
    });

    values = formatFormData(values);
    createEditGroup(values, createGroupKey);
  };

  const editManageGroup = (values: IGroupFormData) => {
    return new GroupService().editGroup(values);
  };

  const createEditGroup = async (
    values: IGroupFormData,
    createGroupKey: any
  ) => {
    try {

      groupDetails?.group_id
        ? await editManageGroup({ ...values, group_id: groupDetails?.group_id })
        : await new GroupService().createGroup(values);
      setGroupDrawerState(false);
      refreshGroupList();
      setPromptType("assertive");
      setPromptText(groupDrawerOptions.toastMessage?.success as string)
      message.success({
        key: createGroupKey,
        content: groupDrawerOptions.toastMessage?.success,
        duration: 2,
      });
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setPromptType("assertive");
      setPromptText(groupDrawerOptions.toastMessage?.error as string)
      message.error({
        key: createGroupKey,
        content: groupDrawerOptions.toastMessage?.error,
      });
    }
  };

  useEffect(() => {
    if (!visibility) {
      form.resetFields();
    }
  }, [visibility, form]);

  useEffect(() => {
    if (groupDrawerState && groupDrawerOptions?.data && !groupDetails) {
      getGroupDrawerDetails(projectRoleData, groupDrawerOptions.data.group_id).catch(
        console.error
      );
    }
  }, [
    groupDrawerOptions,
    groupDrawerState,
    projectRoleData,
    getGroupDrawerDetails,
    groupDetails
  ]);

  useEffect(() => {
    if ( groupDetails && groupDrawerState) {
      const {
        name,
        project_ids,
        users_associated,
      } = groupDetails;
      form.setFieldsValue({
        name,
        project_ids: project_ids ?? [],
        users_associated: users_associated ?? []
      });
      setFormLoader(false);
    }
  }, [groupDrawerState, groupDetails, form, setFormLoader]);

  return (
    <Form
      form={form}
      disabled={isSubmitting}
      layout="vertical"
      onFinish={(props) => {
        onFinish(props).catch(console.error);
      }}
      autoComplete="off"
      className="drawerContent"
      initialValues={groupFormData}
      name="form"
    >
      <div className="blkContent">
        <ProjectInfoSection />
        <ProjectUsersSection />
      </div>
      <div className="blkFooter">
        <Form.Item>
          <Space>
            <Button htmlType="button" onClick={() => setGroupDrawerState(false)}>
              Cancel
            </Button>
            <Form.Item shouldUpdate>
              {({ isFieldsTouched, isFieldValidating, getFieldsError }) => {
                const isGroupName = isFieldsTouched(['name']);
                return (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={Boolean(
                        (!isGroupName ||
                          getFieldsError().filter(({ errors }) => errors.length)
                            .length)
                    )}
                  >
                    {groupDrawerOptions.submitBtnLabel}
                  </Button>
                );
              }}
            </Form.Item>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
};

const OrgCreateNewGroup: React.FC<{
  createButton?: { label: string; className?: string };
}> = ({ createButton }) => {
  const [visibility, setVisibility] = useState<boolean>(false);

  const {
    groupDrawerState,
    setGroupDrawerState,
    setGroupProjectList,
    resetDrawerState,
    groupDrawerOptions,
    setGroupDrawerOptions,
    setGroupUserList,
    formLoader
  } = useGroupStore();
  const {setPromptType,setPromptText} = usePromptText();
  const buttonEle = useRef<HTMLButtonElement>(null);

  const getProjectList = useCallback(async () => {
    try {
      const result = await new GroupService().getProjectListData();
      const { projects } = result.data.data;
      setGroupProjectList(projects ? [...projects] : []);
    } catch (error) {
      console.error(error);
      let promptText = 'Something went wrong please try again!';
      setPromptType("assertive");
      setPromptText(promptText);
      message.error({
        content: promptText
      });
      setGroupDrawerState(false);
    }
  }, [setGroupProjectList, setPromptType, setPromptText, setGroupDrawerState]);

  const getOrganizationUsers = useCallback(async () => {
    try {
      const result = await new OrganizationService().getOrganizationUserList();
      const { userList } = result.data.data;
      setGroupUserList(userList);
    } catch (error) {
      let promptText = 'Something went wrong please try again!';
      setPromptType("assertive");
      setPromptText(promptText);
      console.error(error);
      message.error({
        content: promptText
      });
      setGroupDrawerState(false);
    }
  }, [setGroupUserList, setPromptType, setPromptText, setGroupDrawerState]);

  const afterOpenChange = (visible: boolean) => {
    setVisibility(visible);
    if (!visible) {
      resetDrawerState();
    }
  };

  useEffect(() => {
    if (groupDrawerState && !groupDrawerOptions.data) {
      getProjectList().catch(console.error);
      getOrganizationUsers().catch(console.error);
    }
  }, [
    getProjectList,
    groupDrawerState,
    getOrganizationUsers,
    groupDrawerOptions
  ]);

  return (
    <React.Fragment>
      {createButton && (
        <Button
          role="button"
          type="primary"
          className={`${createButton.className} flexLeftGap`}
          onClick={() => {
            setGroupDrawerOptions();
            setGroupDrawerState(true);
            buttonEle.current?.blur();
          }}
          ref={buttonEle}
        >
          {createButton.label}
        </Button>
      )}
      <Drawer
        title={<h2>{`${groupDrawerOptions.title}`}</h2>}
        maskClosable={false}
        destroyOnClose={true}
        className="fixedFooterBtns"
        width={720}
        closable={false}
        onClose={() => setGroupDrawerState(false)}
        open={groupDrawerState}
        afterOpenChange={afterOpenChange}
        extra={
          <Button onClick={() => setGroupDrawerState(false)} className="closeBlk">
            Close
          </Button>
        }
      >
        <Spin spinning={formLoader}>
          <CreateNewGroupForm visibility={visibility} />
        </Spin>
      </Drawer>
    </React.Fragment>
  );
};

export default OrgCreateNewGroup;
